import { FC, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { closeNotification, NotificationType } from './notificationSlice';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { Failure, Unsent, NotificationAccepted } from 'assets/icons';

const fadeInAnimation = keyframes`
  from {
    transform: translateX(25rem);
  }
  to {
    transform: translateX(0px);
  }
`;

const fadeOutAnimation = keyframes`
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(25rem);
  }
`;

const NotificationPopupContainer = styled.div<{
  notificationType: NotificationType;
  direction: 'in' | 'out';
}>`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-width: 0.125rem;
  border-style: solid;
  min-height: 4rem;
  min-width: 16.25rem;
  position: fixed;
  top: 4.6875rem;
  right: 1.25rem;
  z-index: 100;
  animation: ${({ direction }) => (direction === 'in' ? fadeInAnimation : fadeOutAnimation)} 0.3s
    linear;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 1rem;
  }
  ${({ notificationType, theme }) => {
    switch (notificationType) {
      case 'ACCEPTED':
        return css`
          border-color: ${theme.extraGreen};
          background-color: ${theme.transparentGreen};
          color: ${theme.extraGreen};
        `;
      case 'FAILED':
        return css`
          border-color: ${theme.extraGreen};
          background-color: ${theme.transparentGreen};
          color: ${theme.extraGreen};
        `;
      default:
        return css`
          border-color: ${theme.extraGreen};
          background-color: ${theme.transparentGreen};
          color: ${theme.extraGreen};
        `;
    }
  }}
`;

export const NotificationPopup: FC = () => {
  const [animationDirection, setAnimationDirection] = useState<'in' | 'out'>('in');
  const { notification } = useAppSelector((state) => state.notificationSlice);
  const { type, copy, isVisible } = notification;
  const dispatch = useAppDispatch();
  const iconMapping = {
    ACCEPTED: <NotificationAccepted />,
    FAILED: <Failure />,
    NEUTRAL: <Unsent />,
  };

  useEffect(() => {
    if (animationDirection === 'in' && isVisible) {
      setTimeout(() => {
        setAnimationDirection('out');
      }, 3000);
    }
  }, [animationDirection, isVisible]);

  const onAnimationEnd = () => {
    if (animationDirection === 'out') {
      setTimeout(() => setAnimationDirection('in'), 0);
      dispatch(closeNotification());
    }
  };

  return isVisible ? (
    <NotificationPopupContainer
      notificationType={type}
      direction={animationDirection}
      onAnimationEnd={onAnimationEnd}
    >
      {iconMapping[type]}
      {copy}
    </NotificationPopupContainer>
  ) : (
    <></>
  );
};
