import { LoadingScreen } from 'components/LoadingScreen';
import { FC, useState } from 'react';
import { ControlRow } from './components/ControlRow';
import { TopControlRow } from './components/TopControlRow';
import { AreasMasterdata } from './components/AreasMasterdata';
import { useAreas, useConnectionPointRemoval } from './areasApi';
import { SortDirection } from 'utils/types';
import { SortType } from 'utils/types/generalTypes';
import { ConfirmationPopupText, DeletionConfirmationPopup } from 'components';

export const AreasTopology: FC = () => {
  const [activeSort, setActiveSort] = useState<SortType>({
    name: 'sortOrder',
    direction: 'asc',
  });
  const [searchKeywoard, setSearchKeyword] = useState('');
  const setSearch = (keyword: string) => setSearchKeyword(keyword);
  const { data, isLoading } = useAreas(activeSort, searchKeywoard);

  const updateSort = (name: string, direction: SortDirection) => {
    setActiveSort({ name, direction });
  };

  return (
    <>
      <TopControlRow />
      <ControlRow setSearch={setSearch} />
      {data?.content && (
        <AreasMasterdata areas={data.content} updateSort={updateSort} activeSort={activeSort} />
      )}
      {isLoading && <LoadingScreen />}
      <DeletionConfirmationPopup
        confirmationPopupId="connectionPointRemovalConfirmationModal"
        deleteQuery={useConnectionPointRemoval}
      >
        <ConfirmationPopupText>Are you sure you want to remove this entry?</ConfirmationPopupText>
      </DeletionConfirmationPopup>
    </>
  );
};
