import { FC, useMemo, useContext } from 'react';
import { ConnectionPointContainer, ConnectionPointInfoCell } from '../StyledNominationComponents';
import { StatusIndicator, CarouselControl, PlusMinusExpandToggleButton } from 'components';
import styled from 'styled-components';
import { BalanceGroups } from '../BalanceGroups';
import { ConnectionPointValuesRow } from './ConnectionPointValuesRow';
import { calculateConnectionPointHourlyValues, calculateRowSum } from '../../utils';
import { Checkbox } from 'components/Checkbox';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectConnectionPoint, unselectConnectionPoint } from '../../nominationReduxSlice';
import { searchBalanceGroups } from '../../utils';
import { MarketAreaThemeContext } from '../../index';
import { ConnectionPointNominationType } from 'utils/types';

const ConnectioPointInfoContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 33.4375rem;
`;
const ConnectionPointInfoColumn = styled.div<{ flexValue?: string; maxWidth?: string }>`
  display: flex;
  flex-direction: column;
  ${({ flexValue }) => flexValue && `flex: ${flexValue};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
`;

const RowContainer = styled.div`
  margin-bottom: 1rem;
`;

const ConnectionPointHeadingCell = styled(ConnectionPointInfoCell)`
  height: 2.5rem;
  background-color: ${({ theme }) => theme.connectionPointHeadingColor};
`;

const ExpandButtonContainerCell = styled(ConnectionPointInfoCell)`
  margin: 0;
  background-color: transparent;
`;

export interface ConnectionPointProps {
  connectionPoint: ConnectionPointNominationType;
  areaId: number;
  setScrollPosition: (scrollPos: number) => void;
  scrollPosition: number;
  displayHeading: boolean;
  isExpanded: boolean;
  setIsExpanded: () => void;
}

// TODO: think about sendig less info to TableCarousel
export const ConnectionPoint: FC<ConnectionPointProps> = ({
  connectionPoint,
  areaId,
  setScrollPosition,
  scrollPosition,
  displayHeading = false,
  isExpanded,
  setIsExpanded,
}) => {
  const {
    name,
    messageStatus,
    balanceGroups,
    id,
    isSelected,
    internalAccounts,
    gasDayHourDefinition,
  } = connectionPoint;

  const { searchKeyword, filterActive, statusFilter, selectFilters } = useAppSelector(
    (state) => state.nominationReducer,
  );
  const themeColor = useContext(MarketAreaThemeContext);
  const dispatch = useAppDispatch();

  const filterFunction = () => {
    const activeFilters = Object.entries(statusFilter).filter(
      ([optionName, value]) => value && optionName,
    );
    const balanceGroupFilter = selectFilters.find((filter) => filter.filterName === 'balanceGroup');

    return balanceGroups.filter((balanceGroup) => {
      const shouldBalanceGroupDisplay = balanceGroupFilter?.options.findIndex(
        (balanceGroupFilteredName) => balanceGroupFilteredName === balanceGroup.destinationName,
      );
      if (activeFilters.length > 0) {
        for (const index in activeFilters) {
          const [keyname] = activeFilters[index];
          if (
            balanceGroup.messageStatus === keyname.toLocaleUpperCase() ||
            (shouldBalanceGroupDisplay && shouldBalanceGroupDisplay > -1)
          ) {
            return balanceGroup;
          }
        }
      } else if (
        balanceGroupFilter &&
        shouldBalanceGroupDisplay &&
        shouldBalanceGroupDisplay > -1
      ) {
        return balanceGroup;
      }
    });
  };

  const toggleSelection = () =>
    isSelected
      ? dispatch(
          unselectConnectionPoint({
            areaId,
            connectionId: id,
            internalAccountsName: internalAccounts[0].name,
          }),
        )
      : dispatch(
          selectConnectionPoint({
            areaId,
            connectionId: id,
            internalAccountsName: internalAccounts[0].name,
          }),
        );
  const connectionPointHourlyValues = calculateConnectionPointHourlyValues(balanceGroups);
  const totalValuesSum = useMemo(
    () => calculateRowSum(connectionPointHourlyValues),
    [connectionPointHourlyValues],
  );
  const filteredBalanceGroups = filterActive ? filterFunction() : balanceGroups;
  filterFunction();
  const balanceGroupsToDisplay =
    searchKeyword.length > 0
      ? searchBalanceGroups(searchKeyword, filteredBalanceGroups)
      : filteredBalanceGroups;
  const connectionPointExpanded =
    isExpanded || (searchKeyword.length > 0 && balanceGroupsToDisplay.length > 0);

  const shouldHeadingDisplay = isExpanded || displayHeading;
  return (
    <RowContainer>
      <ConnectionPointContainer isExpanded={isExpanded}>
        <ConnectioPointInfoContainer>
          <ConnectionPointInfoColumn>
            {shouldHeadingDisplay && <ConnectionPointHeadingCell />}
            <ExpandButtonContainerCell width="2.5rem">
              {balanceGroupsToDisplay.length > 0 && (
                <PlusMinusExpandToggleButton
                  isExpanded={connectionPointExpanded}
                  toggleFunction={setIsExpanded}
                />
              )}
            </ExpandButtonContainerCell>
          </ConnectionPointInfoColumn>
          <ConnectionPointInfoColumn flexValue="1">
            {shouldHeadingDisplay && (
              <ConnectionPointHeadingCell>Connection point</ConnectionPointHeadingCell>
            )}
            <ConnectionPointInfoCell>
              <Checkbox checked={isSelected} onChange={toggleSelection} />
              {name}
            </ConnectionPointInfoCell>
          </ConnectionPointInfoColumn>
          <ConnectionPointInfoColumn maxWidth="15rem" flexValue="1">
            {shouldHeadingDisplay && (
              <ConnectionPointHeadingCell>Balance group</ConnectionPointHeadingCell>
            )}
            <ConnectionPointInfoCell>{internalAccounts[0].name}</ConnectionPointInfoCell>
          </ConnectionPointInfoColumn>
          <ConnectionPointInfoColumn>
            {shouldHeadingDisplay && (
              <ConnectionPointHeadingCell width="3.5625rem">Status</ConnectionPointHeadingCell>
            )}
            <ConnectionPointInfoCell align="center" width="3.5625rem">
              <StatusIndicator status={messageStatus} />
            </ConnectionPointInfoCell>
          </ConnectionPointInfoColumn>
          <ConnectionPointInfoColumn>
            {shouldHeadingDisplay && (
              <ConnectionPointHeadingCell width="4rem" align="center">
                Sum
              </ConnectionPointHeadingCell>
            )}
            <ConnectionPointInfoCell
              width="4rem"
              align="center"
              bgColor={themeColor}
              alwaysDarkFont
            >
              {parseFloat(totalValuesSum.toFixed(3))}
            </ConnectionPointInfoCell>
          </ConnectionPointInfoColumn>
        </ConnectioPointInfoContainer>
        <CarouselControl
          scrollControl={setScrollPosition}
          gasDayHourDefinition={gasDayHourDefinition}
          scrollPosition={scrollPosition}
          displayHeading={shouldHeadingDisplay}
        >
          <ConnectionPointValuesRow connectionPoint={connectionPoint} />
        </CarouselControl>
      </ConnectionPointContainer>
      {connectionPointExpanded && balanceGroupsToDisplay.length > 0 && (
        <BalanceGroups
          balanceGroups={balanceGroupsToDisplay}
          areaId={areaId}
          connectionId={id}
          connectionPointSelected={isSelected}
          connectionPointName={name}
          connectionPointIndetifier={internalAccounts[0].name}
        />
      )}
    </RowContainer>
  );
};
