import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterType } from 'utils/translateFiltersToPath';

interface State {
  searchPhrase: string;
  filters: Array<FilterType>;
  filterActive: boolean;
}

const initialState = {
  searchPhrase: '',
  filters: [],
  filterActive: false,
} as State;

const messageDefinitionSlice = createSlice({
  name: 'messageDefinition',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<FilterType>) {
      if (state.filters.length === 0) {
        state.filters.push(action.payload);
      } else {
        const existingFilterIndex = state.filters.findIndex(
          (filter) => filter.filterName === action.payload.filterName,
        );
        if (existingFilterIndex !== -1) {
          if (action.payload.options.length === 0) {
            state.filters.splice(existingFilterIndex, 1);
          } else {
            state.filters[existingFilterIndex].options = action.payload.options;
          }
        } else {
          state.filters.push(action.payload);
        }
      }
      state.filterActive = true;
    },
    clearFilters(state) {
      state.filters = [];
      state.filterActive = false;
    },
    setSearchPhrase(state, action: PayloadAction<{ searchPhrase: string }>) {
      state.searchPhrase = action.payload.searchPhrase;
    },
  },
});

export const { setFilters, clearFilters, setSearchPhrase } = messageDefinitionSlice.actions;

export default messageDefinitionSlice.reducer;
