import { FC } from 'react';
import styled from 'styled-components';
import { CounterpartyRow } from './CounterpartyRow';
import { GridTableHeadingCell, TableHeadingWithSort } from 'components';
import { Counterparty, SortDirection } from 'utils/types';
import { SortType } from 'utils/types/generalTypes';
const MasterdataTableContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Row = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: minmax(13rem, 1fr) minmax(11rem, 1fr) minmax(12rem, 1fr) minmax(14rem, 1fr) 4.5rem 3.125rem 2.5rem;
  grid-column-gap: 0.3125rem;
`;

const BlankCell = styled.div`
  width: 2.5rem;
  display: flex;
`;

const TableHeadingSortContainer = styled(GridTableHeadingCell)`
  padding: 0 0.625rem;
`;

interface MasterdataCounterpartiesProps {
  counterparties: Array<Counterparty>;
  updateSort: (name: string, direction: SortDirection) => void;
  activeSort: SortType;
}

export const MasterdataCounterparties: FC<MasterdataCounterpartiesProps> = ({
  counterparties,
  updateSort,
  activeSort,
}) => {
  const { name: sortName, direction: sortDirection } = activeSort;
  return (
    <MasterdataTableContainer>
      <Row>
        <TableHeadingSortContainer>
          <TableHeadingWithSort
            name="name"
            direction={sortName === 'name' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            Counterparty
          </TableHeadingWithSort>
        </TableHeadingSortContainer>
        <TableHeadingSortContainer>
          <TableHeadingWithSort
            name="code"
            direction={sortName === 'code' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            Display Name
          </TableHeadingWithSort>
        </TableHeadingSortContainer>
        <TableHeadingSortContainer>Counterparty Identifier</TableHeadingSortContainer>

        <TableHeadingSortContainer>Roles</TableHeadingSortContainer>

        <GridTableHeadingCell align="center">Active</GridTableHeadingCell>
        <GridTableHeadingCell align="center">Action</GridTableHeadingCell>
        <BlankCell />
      </Row>
      {counterparties.map((counterparty) => (
        <CounterpartyRow key={counterparty.id} counterparty={counterparty} />
      ))}
    </MasterdataTableContainer>
  );
};
