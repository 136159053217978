import { ReactElement, useContext, useEffect } from 'react';
import ReactModal from 'react-modal';
import styled, { ThemeContext } from 'styled-components';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/modalSlice';
import { ModalId } from 'store/modalSlice';

ReactModal.setAppElement('#root');

export const ModalCloseButton = styled.button`
  position: absolute;
  cursor: pointer;
  top: 1.25rem;
  right: 0.625rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.mainBackgroundColor};

  ::after,
  ::before {
    position: absolute;
    top: 0.3125rem;
    left: 0.6875rem;
    content: ' ';
    height: 0.9375rem;
    width: 0.125rem;
    background-color: ${({ theme }) => theme.modalCloseButtonColor};
  }

  ::after {
    transform: rotate(-45deg);
  }

  ::before {
    transform: rotate(45deg);
  }

  :hover {
    opacity: 0.8;
  }
`;

const ModalHeader = styled.div<{ headerType: 'error' | 'warning' | 'info' }>`
  width: 100%;
  height: 4rem;
  background-color: ${({ theme, headerType }) => {
    switch (headerType) {
      case 'error':
        return theme.extraRed;
      case 'warning':
        return theme.transparentYellow;
      default:
        return theme.modalHeaderBgColor;
    }
  }};
  position: relative;
`;

interface ModalProps {
  modalId: ModalId;
  children: ReactElement;
  headerContentComponent?: ReactElement;
  externalContentStyles?: {
    height?: string;
    width?: string;
    padding?: string;
  };
  headerType?: 'error' | 'warning' | 'info';
}

export const Modal = ({
  modalId,
  children,
  headerContentComponent,
  externalContentStyles = {},
  headerType = 'info',
}: ModalProps): ReactElement => {
  const { isOpen } = useAppSelector((state) => state.modalReducer[modalId]);
  const dispatch = useAppDispatch();
  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }
  }, []);

  const closeCurrentModal = () => dispatch(closeModal({ modalId }));

  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      height: 'auto',
      backgroundColor: theme.mainBackgroundColor,
      border: 'none',
      transform: 'translate(-50%, -50%)',
      borderRadius: '0.25rem',
      padding: '0',
      overflow: 'initial',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: 1000,
    },
  };

  const combinedStyles = {
    ...modalStyles,
    content: {
      ...modalStyles.content,
      ...externalContentStyles,
    },
  };

  return (
    <ReactModal isOpen={isOpen} style={combinedStyles} onRequestClose={closeCurrentModal}>
      <ModalHeader headerType={headerType}>
        {headerContentComponent}
        <ModalCloseButton onClick={closeCurrentModal} />
      </ModalHeader>

      {children}
    </ReactModal>
  );
};
