import {
  BaseButton,
  ActiveIndicator,
  CountryFlag,
  GridMergedCell,
  NestedDataExpandButton,
  IdentifierPreviewBox,
} from 'components';
import { FC } from 'react';
import styled from 'styled-components';
import { EditIcon, UserDefinedMasterdataIcon, SystemMasterdataIcon } from 'assets/icons';
import { GridTableCell } from 'components';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ConnectionPointTable } from './ConnectionPointTable';
import { Area } from 'utils/types';
import { standardDateFormat } from 'utils/dateFormating';
import { toggleSection } from 'store/expandedSectionsSlice';

interface AreaRowProps {
  area: Area;
}

const Row = styled.div`
  display: grid;
  grid-template-columns: 14rem 8rem minmax(14rem, 1fr) minmax(15rem, 1fr) minmax(11rem, 1fr) 5rem 4.5rem 4rem 2.5rem;
  grid-column-gap: 0.3125rem;
  margin-bottom: 0.3125rem;
`;

const AreaStateInfo = styled.div`
  display: flex;
`;

const AreaNameContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
    min-width: 24px;
  }
`;

export const AreaRow: FC<AreaRowProps> = ({ area }) => {
  const {
    name,
    code,
    country,
    identifiers,
    counterparty,
    validFrom,
    validTo,
    id,
    userDefined,
    sortOrder,
    active,
  } = area;
  const history = useHistory();
  const { authorities } = useAppSelector((state) => state.authReducer);
  const expandedSections = useAppSelector((state) => state.expandedSectionsSlice);
  const dispatch = useAppDispatch();
  const isAdmin = authorities.includes('ADMIN') || authorities.includes('SYSADMIN');

  const toggleArea = () => {
    dispatch(toggleSection({ section: 'areas', sectionId: id }));
  };

  const isExpanded = expandedSections.areas.includes(id);
  return (
    <>
      <Row>
        <GridTableCell minWidth="11.9375rem" align="space-between">
          <AreaNameContainer>
            <CountryFlag countryCode={country.isoAlpha2Code} />
            {name}
          </AreaNameContainer>
          <AreaStateInfo>
            {userDefined ? <UserDefinedMasterdataIcon /> : <SystemMasterdataIcon />}
            {/* <ChildCount>{connectionPoints.length}</ChildCount> */}
            {/* <ChildCount>2</ChildCount> */}
          </AreaStateInfo>
        </GridTableCell>
        <GridTableCell>{code}</GridTableCell>
        <GridTableCell align="space-between" overflowEnabled>
          {identifiers.length > 0 && (
            <>
              {identifiers[0].identifier}
              <IdentifierPreviewBox identifiers={identifiers} />
            </>
          )}
        </GridTableCell>

        <GridTableCell>{counterparty.name}</GridTableCell>
        <GridMergedCell>
          <GridTableCell>{standardDateFormat(new Date(validFrom))}</GridTableCell>
          <GridTableCell>{standardDateFormat(new Date(validTo))}</GridTableCell>
        </GridMergedCell>

        <GridTableCell align="center">{sortOrder}</GridTableCell>
        <GridTableCell align="center">
          <ActiveIndicator isActive={active} />
        </GridTableCell>
        <GridTableCell align="center">
          <BaseButton onClick={() => history.push(`/app/areas/edit/${id}`)} disabled={!isAdmin}>
            <EditIcon />
          </BaseButton>
        </GridTableCell>
        <NestedDataExpandButton isExpanded={isExpanded} setIsExpanded={toggleArea} />
      </Row>
      {isExpanded && (
        <ConnectionPointTable areaSlim={{ id, name }} isAdmin={isAdmin} areaSortOrder={sortOrder} />
      )}
    </>
  );
};
