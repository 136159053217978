import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, openModal } from 'store/modalSlice';
import { API, useApiGet } from 'utils/hooks';
import { Area, AreaConnectionPoint, Counterparty, MainCustomErrorType } from 'utils/types';

export const useConnectionPoint = (id?: string) => {
  const fetchConnectionPoint = useApiGet(`/connection-point/${id}`);
  return useQuery<AreaConnectionPoint, Error>(['connectionPoint'], fetchConnectionPoint, {
    enabled: !!id,
  });
};

export const useArea = (id: number) => {
  const fetchAreas = useApiGet(`/area/${id}`);
  return useQuery<Area, Error>(['connection-point-area'], fetchAreas);
};

export interface ConnectionPointCounterparties {
  content: Array<Counterparty>;
}

export const useCounterparties = () => {
  const fetchCounterparties = useApiGet(`/counterparty?size=0&sort=name,asc`);
  return useQuery<ConnectionPointCounterparties, Error>(['counterparties'], fetchCounterparties);
};

// TODO: Extract better abstraction for data saving
export const useConnectionPointSave = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  return useMutation(
    (data: AreaConnectionPoint) => {
      if (token.length) {
        return API.PUT('/connection-point', {
          headers: {
            Authorization: `${token}`,
          },
          body: JSON.stringify(data),
        });
      }
      return Promise.reject();
    },
    {
      onError: (error: MainCustomErrorType) => {
        dispatch(
          openModal({
            modalId: 'globalErrorModal',
            props: { errors: error.errors, message: error.message },
          }),
        );
      },
      onSuccess: async () => {
        dispatch(closeModal({ modalId: 'connectionPointConfirmationModal' }));
        queryClient.invalidateQueries('areas');
        history.push('/app/areas/');
      },
    },
  );
};
