import { FC, useState, useEffect, useCallback } from 'react';
import {
  SelectButton,
  SelectContainer,
  OptionsContainer,
  SelectOption,
  Icon,
  PlaceholderText,
  SelectedText,
  SearchContainer,
} from './styled';
import { useClickOutside } from 'utils/hooks';
import {
  Controller,
  Control,
  useForm,
  UseFormSetError,
  FieldValues,
  FieldError,
} from 'react-hook-form';
import { SelectOptionType } from 'utils/types';
import { SearchInput } from 'components';
import { InputError } from '../InputError';

interface SelectInputProps {
  selectOption?: (option: SelectOptionType) => void; // TODO: this could probably be replaced with form functions across whole app
  options?: Array<SelectOptionType>;
  placeholder: string;
  width?: string;
  preselectedOption?: SelectOptionType;
  isFrameless?: boolean;
  filtersCleared?: boolean;
  isDisabled?: boolean;
  identifierSelect?: boolean; // This flag is used when we want to select identifiers it enables displaying both schema and identifier name
  accountSelect?: boolean; // This flag is used when we want to select accounts it enables displaying account and balancegroup name
  setError?: UseFormSetError<FieldValues>;
  fieldName?: string;
  error?: FieldError;
  bgColor?: string;
  openUpwards?: boolean;
}

export const SelectInput: FC<SelectInputProps> = ({
  preselectedOption,
  selectOption,
  options = [{ id: 0, name: '' }],
  placeholder,
  width,
  isFrameless,
  filtersCleared,
  isDisabled = false,
  identifierSelect = false,
  accountSelect = false,
  setError,
  fieldName,
  error,
  bgColor,
  openUpwards,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SelectOptionType>({ id: 0, name: '' });
  const [searchKeyword, setSearchKeyword] = useState('');
  const closeOptionsContainer = () => setIsExpanded(false);
  const ref = useClickOutside(closeOptionsContainer);
  const { register, reset, getValues, watch } = useForm({
    defaultValues: {
      selectInputSearch: '',
    },
  });

  const setOption = (option: SelectOptionType) => {
    setSelectedOption(option);
    selectOption && selectOption(option);
    closeOptionsContainer();
  };

  useEffect(() => {
    if (preselectedOption) {
      setSelectedOption(preselectedOption);
    }
  }, [preselectedOption]);

  useEffect(() => {
    if (filtersCleared) {
      setSelectedOption({ id: 0, name: '' });
    }
  }, [filtersCleared]);

  const selectInputSearchValue = watch('selectInputSearch');

  const handleUpdate = useCallback(() => {
    const { selectInputSearch } = getValues();
    setSearchKeyword(selectInputSearch.toLowerCase());
  }, [setSearchKeyword]);

  const optionsToDisplay =
    searchKeyword.length > 2 && options
      ? options.filter(
          ({ name, description, role, code, release, counterparty }) =>
            name?.toLowerCase().includes(searchKeyword) ||
            description?.toLowerCase().includes(searchKeyword) ||
            code?.toLowerCase().includes(searchKeyword) ||
            role?.toLowerCase().includes(searchKeyword) ||
            release?.toLocaleString().toLowerCase().includes(searchKeyword) ||
            counterparty?.code?.toLowerCase().includes(searchKeyword),
        )
      : options;

  useEffect(() => {
    const isSelectedOptionValid = options.some(
      (option) => option.id === 0 || option.id === selectedOption.id || selectedOption.id === 0,
    );
    if (!isSelectedOptionValid && fieldName && setError && options.length > 0) {
      setError(fieldName, {
        message: 'This value is no longer valid, please select different value',
      });
    }
  }, [options, selectedOption]);

  const buildOptionName = (option: SelectOptionType): string => {
    if (identifierSelect) {
      return `${option.name || option.description}  - ${option.scheme || option.code}`;
    }

    if (accountSelect) {
      return `${option.counterparty?.code} [${option.name}]`;
    }

    return `${
      option.name ||
      option.description ||
      option.role ||
      option.code ||
      option.release ||
      option.channelName
    }`;
  };

  return (
    <SelectContainer ref={ref} width={width} bgColor={bgColor}>
      <SelectButton
        type="button"
        onClick={() => setIsExpanded(!isExpanded)}
        isFrameless={isFrameless}
        disabled={isDisabled}
        isOpen={isExpanded}
        isError={!!error}
      >
        {selectedOption.id === 0 && <PlaceholderText>{placeholder}</PlaceholderText>}
        {selectedOption.id !== 0 && <SelectedText>{buildOptionName(selectedOption)}</SelectedText>}
        <Icon $isOpen={isExpanded} />
      </SelectButton>
      {!!error && <InputError>{error.message}</InputError>}
      {isExpanded && (
        <OptionsContainer containerSize={options.length} openUpwards={openUpwards}>
          {options.length > 9 && (
            <SearchContainer>
              <SearchInput
                register={register('selectInputSearch')}
                reset={() => {
                  reset();
                  setSearchKeyword('');
                }}
                handleUpdate={handleUpdate}
                placeholder="Search"
                value={selectInputSearchValue}
              />
            </SearchContainer>
          )}
          {optionsToDisplay.map((option) => (
            <SelectOption onClick={() => setOption(option)} key={option.id}>
              {buildOptionName(option)}
            </SelectOption>
          ))}
        </OptionsContainer>
      )}
    </SelectContainer>
  );
};

interface ControlledSelectInputProps extends SelectInputProps {
  name: string;
  control: Control;
  required?: boolean;
}

export const ControlledSelectInput: FC<ControlledSelectInputProps> = ({
  name,
  options,
  placeholder,
  width,
  control,
  required,
  isDisabled,
  identifierSelect,
  accountSelect,
  setError,
  openUpwards,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <SelectInput
          options={options}
          placeholder={placeholder}
          selectOption={(option: SelectOptionType) => onChange(option)}
          preselectedOption={value}
          width={width}
          isDisabled={isDisabled}
          identifierSelect={identifierSelect}
          fieldName={name}
          setError={setError}
          error={error}
          accountSelect={accountSelect}
          openUpwards={openUpwards}
        />
      )}
    />
  );
};
