import { FC, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { TrippleDot } from 'assets/icons';
import { useAppDispatch } from 'store/hooks';
import { openModal } from 'store/modalSlice';
import { BalanceGroup } from 'utils/types';
import { BaseButton } from 'components';

const BalanceGroupMenuContainer = styled.div`
  position: relative;
  width: 2.625rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const MenuButton = styled(BaseButton)<{ isSelected?: boolean }>`
  min-width: 2.625rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.mainBackgroundColor};
  svg {
    rect {
      fill: ${({ isSelected, theme }) => (isSelected ? theme.primary : theme.mainBackgroundColor)};
    }
    path {
      fill: ${({ isSelected, theme }) => (isSelected ? theme.white : theme.baseBlack)};
    }
  }
`;

const ExpandedMenuButton = styled(MenuButton)`
  font-size: 0.875rem;
  height: auto;
  :hover {
    text-decoration: underline;
  }
`;

const StyledMenuContainer = styled.div`
  position: absolute;
  padding: 0.625rem 0.9375rem;
  width: 10rem;
  box-shadow: 0px 0.25rem 0.25rem rgba(144, 164, 174, 0.2);
  background-color: ${({ theme }) => theme.mainBackgroundColor};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  z-index: 3;
  top: 2.5rem;
  left: 0.3125rem;
`;
interface BalanceGroupExpandableMenuProps {
  balanceGroup: BalanceGroup;
  connectionPointName: string;
  connectionPointId: number;
}
export const BalanceGroupExpandableMenu: FC<BalanceGroupExpandableMenuProps> = ({
  balanceGroup,
  connectionPointName,
  connectionPointId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { sourceId, destinationId, counterparty, destinationName, nominationTrackId } =
    balanceGroup;
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (!ref?.current?.contains(event?.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
  const openVersionModal = () => {
    dispatch(
      openModal({
        modalId: 'versionModal',
        props: {
          sourceId,
          destinationId,
          counterparty,
          connectionPointId,
          balanceGroupName: destinationName,
          connectionPointName,
          nominationTrackId,
        },
      }),
    );
  };
  return (
    <BalanceGroupMenuContainer>
      <MenuButton onClick={() => setIsOpen(!isOpen)} isSelected={isOpen} tabIndex={0}>
        <TrippleDot />
      </MenuButton>

      {isOpen && (
        <StyledMenuContainer ref={ref}>
          <ExpandedMenuButton onClick={openVersionModal}>History</ExpandedMenuButton>
        </StyledMenuContainer>
      )}
    </BalanceGroupMenuContainer>
  );
};
