import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { SearchInput } from 'components';
import styled from 'styled-components';

const SearchForm = styled.form`
  max-width: 18.75rem;
  width: 100%;
`;

interface MasterdataSearchProps {
  setSearchKeyword: (keyword: string) => void;
}

export const MasterdataSearch: FC<MasterdataSearchProps> = ({ setSearchKeyword }) => {
  const { register, watch, reset, getValues } = useForm({
    defaultValues: { masterdataSearchKeyword: '' },
  });
  const masterdataSearchKeyword = watch('masterdataSearchKeyword');

  const handleUpdate = useCallback(() => {
    const { masterdataSearchKeyword } = getValues();
    setSearchKeyword(masterdataSearchKeyword);
  }, [masterdataSearchKeyword]);

  const resetSearch = () => {
    setSearchKeyword('');
    reset();
  };
  return (
    <SearchForm>
      <SearchInput
        register={register('masterdataSearchKeyword')}
        placeholder="Search"
        handleUpdate={handleUpdate}
        value={masterdataSearchKeyword}
        reset={resetSearch}
      />
    </SearchForm>
  );
};
