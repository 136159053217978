import { FC } from 'react';
import styled from 'styled-components';
import { ComboFilter } from './ComboFilter';

const FilterRowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 0;
`;

export const FilterRow: FC = () => {
  return (
    <FilterRowContainer>
      <ComboFilter />
    </FilterRowContainer>
  );
};
