import { Logo } from 'assets/icons';
import { ClassicButton, VARIANT } from 'components';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  align-items: center;
`;

const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 2.5rem;
    margin: 2rem 0;
  }
`;

export const LogoutPage: FC = () => {
  const history = useHistory();
  return (
    <PageContainer>
      <Content>
        <Logo />
        <h1>You were sucessfully signed out from dispoGas!</h1>
        <ClassicButton
          variant={VARIANT.PRIMARY}
          onClick={() => history.push('/login/')}
          width="11rem"
        >
          Sign in!
        </ClassicButton>
      </Content>
    </PageContainer>
  );
};
