import { FC } from 'react';
import { ButtonWithLoader, Modal } from 'components';
import styled from 'styled-components';
import { ClassicButton, VARIANT } from 'components/Buttons/ClassicButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/modalSlice';
import { useNominationSave } from '../nominationsApi';

const SubmitModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 3.125rem;
`;

const ModalHeading = styled.h2`
  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const ModalParagraph = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 21.875rem;
  display: flex;
  justify-content: space-between;
`;

const externalContentStyles = {
  height: '22.75rem',
  width: '37.5rem',
  padding: '0',
};

interface SaveModalProps {
  selectedDate: Date;
  numberOfDays: number;
}

export const SaveModal: FC<SaveModalProps> = ({ selectedDate, numberOfDays }) => {
  const dispatch = useAppDispatch();

  const { changedData } = useAppSelector((state) => state.nominationReducer);
  const { mutate: nominationSave, isLoading } = useNominationSave(true);

  const goToSubmitModal = () => {
    dispatch(openModal({ modalId: 'submitModal' }));
  };

  return (
    <Modal modalId="saveModal" externalContentStyles={externalContentStyles}>
      <SubmitModalContainer>
        <ModalHeading>Confirmation</ModalHeading>
        <ModalParagraph>
          You have unsaved changes, do you want to save it before continuing
        </ModalParagraph>
        <ButtonContainer>
          <ClassicButton
            onClick={goToSubmitModal}
            width="10rem"
            variant={VARIANT.SECONDARY}
            disabled={isLoading}
          >
            Skip
          </ClassicButton>
          <ButtonWithLoader
            isLoading={isLoading}
            onClick={() =>
              nominationSave({
                changedData,
                selectedDate,
                numberOfDays,
                sideEffect: goToSubmitModal,
              })
            }
            width="10rem"
            variant={VARIANT.PRIMARY}
          >
            Save
          </ButtonWithLoader>
        </ButtonContainer>
      </SubmitModalContainer>
    </Modal>
  );
};
