import styled from 'styled-components';

const BreadcrumbsContainer = styled.div`
  width: 10%;
  min-width: 18.75rem;
  min-height: 100%;
`;

const HeadingBar = styled.div`
  display: flex;
  align-items: center;
  margin: 0 5rem;
  padding: 5rem 0 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.milkGrey};
  justify-content: space-between;
`;

const StepLabel = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.darkGrey};
`;

export { HeadingBar, StepLabel, BreadcrumbsContainer };
