import { Loader } from 'components';
import { UserNotificationSettingsForm } from './UserNotificationSettingsForm';
import { FC } from 'react';

import { useUserNotificationSettings, useUserNotificationSettingsSave } from './settingsApi';

export const UserNotificationSettings: FC = () => {
  const { data: notificationSettings, isLoading: notificationSettingsFetchingInProgress } =
    useUserNotificationSettings();
  const { mutate: notificationMutation, isLoading } = useUserNotificationSettingsSave();

  return notificationSettingsFetchingInProgress ? (
    <Loader />
  ) : notificationSettings ? (
    <UserNotificationSettingsForm
      notificationSettings={notificationSettings}
      isLoading={isLoading}
      saveAction={notificationMutation}
    />
  ) : (
    <p> Something went wrong </p>
  );
};
