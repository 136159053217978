import styled, { css } from 'styled-components';
import { Triangle } from 'assets/icons';
import { BaseButton } from 'components/Buttons/ClassicButton';

const SelectButton = styled(BaseButton)<{
  isFrameless?: boolean;
  isOpen: boolean;
  isError?: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.darkGrey};
  background-color: ${({ theme }) => theme.mainBackgroundColor};

  ${({ isFrameless, theme, isOpen, isError }) =>
    !isFrameless &&
    css`
      border: 1px solid ${isError ? theme.extraRed : theme.inputBorderColor};
      border-radius: 0.25rem;
      height: 2.5rem;

      ${!isOpen &&
      css`
        :hover:enabled {
          border-color: ${theme.primary};
        }
      `}
      :disabled {
        color: ${theme.lightGrey};
      }
    `};
`;

const SelectContainer = styled.div<{ width?: string; bgColor?: string }>`
  position: relative;
  ${({ width }) => width && `width: ${width};`}
  background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.mainBackgroundColor)};
`;

const OptionsContainer = styled.div<{
  customWidth?: string;
  containerSize?: number;
  openUpwards?: boolean;
}>`
  ${({ theme, customWidth }) => css`
    width: ${customWidth ? customWidth : '100%'};
    border: 1px solid ${theme.inputBorderColor};
    background-color: ${theme.mainBackgroundColor};
  `}
  display: flex;
  flex-direction: column;
  position: absolute;

  max-height: calc(9 * 2.5rem); // Max height to fit up to 8 select options on one screen
  border-top: none;
  top: 2.3125rem;
  padding: 1.25rem;
  z-index: 100;
  overflow: auto;

  ${({ openUpwards, containerSize, theme }) =>
    openUpwards &&
    containerSize &&
    css`
      top: calc(-${containerSize < 8 ? containerSize : 8} * 2.5rem - 2.3125rem);
      border-top: 1px solid ${theme.inputBorderColor};
      border-bottom: none;
    `}
`;

const SelectOption = styled(BaseButton)`
  display: flex;
  align-items: center;
  min-height: 2.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.inputBorderColor};
  flex-shrink: 0;
  text-align: left;
  padding: 0.5rem 0;
  svg {
    margin-right: 0.5rem;
  }
`;

const Icon = styled(Triangle)<{ $isOpen: boolean }>`
  transform: rotate(${({ $isOpen }) => ($isOpen ? '90deg' : '-90deg')});
  width: 0.5625rem;
  path {
    fill: ${({ theme }) => theme.primary};
  }
`;

const PlaceholderText = styled.span`
  color: ${({ theme }) => theme.darkGrey};
`;

const SelectedText = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.baseBlack};
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  svg {
    margin-right: 0.5rem;
  }
`;

const SearchContainer = styled.div`
  flex-shrink: 0;
  height: 2.5rem;
  margin-bottom: 1rem;
`;

export {
  SelectButton,
  PlaceholderText,
  SelectContainer,
  OptionsContainer,
  SelectOption,
  Icon,
  SelectedText,
  SearchContainer,
};
