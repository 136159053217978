import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal, closeModal } from 'store/modalSlice';
import { API, useApiGet } from 'utils/hooks';
import {
  EdigasVersionType,
  GasDirectionType,
  MarketRoleType,
  SelectOptionType,
  UnitOfmeasureType,
  DocumentType,
  ContractType,
  MarketParticipantSlimType,
  MessageType,
  MessageDefinitionSlim,
  RoleType,
} from 'utils/types';
import { MessageOutputSettingType } from 'utils/types/messageDefinitionTypes';
import { MainCustomErrorType } from 'utils/types';

export const useMessageDefinition = (messageDefId?: string) => {
  const fetchCounterparty = useApiGet(`/message-definition/${messageDefId}`, !!messageDefId);
  return useQuery<MessageDefinitionSlim, Error>(['messageDef', messageDefId], fetchCounterparty, {
    enabled: !!messageDefId,
  });
};

export const useAreasSlimList = () => {
  const fetchAreaSlimList = useApiGet('/less/area');
  return useQuery<Array<SelectOptionType>, Error>(['areaList'], fetchAreaSlimList);
};

export const useEdigasVerion = () => {
  const fetchEdigasVersions = useApiGet('/edigas-version');
  return useQuery<Array<EdigasVersionType>, Error>(['edigasVer'], fetchEdigasVersions);
};

export const useMeasureUnit = (edigasVersion?: string) => {
  const fetchMeasureUnit = useApiGet(
    `/unit-of-measure-type?filters=edigasVersion.version.baseVersion,${edigasVersion}`,
  );
  return useQuery<Array<UnitOfmeasureType>, Error>(
    ['measureUnit', edigasVersion],
    fetchMeasureUnit,
    {
      enabled: !!edigasVersion,
    },
  );
};

export const useMarketRole = (edigasVersion?: string) => {
  const fetchMarketRole = useApiGet(
    `/role-type?filters=edigasVersion.version.baseVersion,${edigasVersion}`,
  );
  return useQuery<Array<MarketRoleType>, Error>(['marketRole', edigasVersion], fetchMarketRole, {
    enabled: !!edigasVersion,
  });
};

export const useGasDirection = (edigasVersion?: string) => {
  const fetchGasDirection = useApiGet(
    `/gas-direction-type?filters=edigasVersion.version.baseVersion,${edigasVersion}`,
  );
  return useQuery<Array<GasDirectionType>, Error>(
    ['gasDirection', edigasVersion],
    fetchGasDirection,
    {
      enabled: !!edigasVersion,
    },
  );
};

export const useMessageType = () => {
  const fetchMessageTypes = useApiGet('/message-type');
  return useQuery<Array<MessageType>, Error>(['messageType'], fetchMessageTypes);
};

export const useDocumentType = (edigasVersion?: string) => {
  const fetchDocumentType = useApiGet(
    `/document-type?filters=edigasVersion.version.baseVersion,${edigasVersion}`,
  );
  return useQuery<Array<DocumentType>, Error>(['documentType', edigasVersion], fetchDocumentType, {
    enabled: !!edigasVersion,
  });
};

export const useContractType = (edigasVersion?: string) => {
  const fetchContractType = useApiGet(
    `/reference-type?filters=edigasVersion.version.baseVersion,${edigasVersion}`,
  );
  return useQuery<Array<ContractType>, Error>(['contractType', edigasVersion], fetchContractType, {
    enabled: !!edigasVersion,
  });
};

export const useMarketParticipant = (roleType?: RoleType) => {
  const fetchMarketParticipant = useApiGet(`/market-participant?role=${roleType?.id}`);
  return useQuery<Array<MarketParticipantSlimType>, Error>(
    ['marketParticipant', roleType],
    fetchMarketParticipant,
    {
      enabled: !!roleType,
    },
  );
};

export const useOutputSetting = () => {
  const fetchOutputSettings = useApiGet('/lov/message-output-setting');
  return useQuery<Array<MessageOutputSettingType>, Error>(['outputSettings'], fetchOutputSettings);
};

export const useMessageDefinitionSave = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  return useMutation(
    (data: MessageDefinitionSlim) => {
      if (token.length) {
        return API.PUT('/message-definition/', {
          headers: {
            Authorization: token,
          },
          body: JSON.stringify(data),
        });
      }
      return Promise.reject();
    },
    {
      onError: (error: MainCustomErrorType) => {
        dispatch(
          openModal({
            modalId: 'globalErrorModal',
            props: { errors: error.errors, message: error.message },
          }),
        );
      },
      onSuccess: async () => {
        dispatch(closeModal({ modalId: 'messageDefinitionConfirmationModal' }));
        queryClient.invalidateQueries('message-definitions');
        history.push('/app/message-definitions/');
      },
    },
  );
};
