import styled, { css } from 'styled-components';

const DivTableCell = styled.div<{
  width?: string;
  minWidth?: string;
  align?: string;
  padding?: string;
  overflowEnabled?: boolean;
}>`
  display: flex;
  flex: 1;
  max-height: 3rem;
  height: 3rem;
  align-items: center;
  overflow: hidden;
  font-size: 0.875rem;
  margin-right: 0.3125rem;

  :last-child {
    margin-right: 0;
  }

  ${({ theme, width, minWidth, align, padding, overflowEnabled }) => css`
    justify-content: ${align || 'initial'};
    background-color: ${theme.milkGrey};
    padding: ${padding || '0.5625rem 0.625rem'};
    ${width &&
    css`
      max-width: ${width};
      width: ${width};
    `}
    ${minWidth && `min-width: ${minWidth};`}
    ${overflowEnabled && 'overflow: initial;'}
  `}
`;
const GridTableCell = styled.div<{
  minWidth?: string;
  align?: string;
  padding?: string;
  overflowEnabled?: boolean;
}>`
  display: flex;
  width: 100%;
  max-height: 3rem;
  height: 3rem;
  align-items: center;
  overflow: hidden;
  font-size: 0.875rem;

  ${({ theme, minWidth, align, padding, overflowEnabled }) => css`
    justify-content: ${align || 'initial'};
    background-color: ${theme.milkGrey};
    padding: ${padding || '0.5625rem 0.625rem'};
    ${minWidth && `min-width: ${minWidth};`}
    ${overflowEnabled && 'overflow: initial;'}
  `}
`;

const GridTableHeadingCell = styled(GridTableCell)`
  ${({ theme }) => `background-color: ${theme.divTableHeadingBg};`}
`;

const DivTableHeadingCell = styled.div<{
  width?: string;
  minWidth?: string;
  align?: string;
  padding?: string;
}>`
  ${({ width, minWidth, align, padding, theme }) => css`
    justify-content: ${align || 'initial'};
    padding: ${padding || '0.5625rem 0.625rem'};
    background-color: ${theme.divTableHeadingBg};
    ${width &&
    css`
      max-width: ${width};
      width: ${width};
    `}
    ${minWidth && `min-width: ${minWidth};`}
  `}
  display: flex;
  flex: 1;
  max-height: 2.5rem;
  height: 2.5rem;
  align-items: center;
  overflow: hidden;
  font-size: 0.875rem;
  margin-right: 0.3125rem;

  :last-child {
    margin-right: 0;
  }
`;

const GridMergedCell = styled.div`
  display: flex;
  flex: 1;
`;

const MergedCell = styled.div`
  display: flex;
  flex: 1;
  margin-right: 0.3125rem;
  align-items: center;

  ${DivTableHeadingCell}, ${DivTableCell} {
    margin-right: 0;
  }
`;

const StandardNestedGridTableCell = styled.div<{
  align?: string;
  padding?: string;
  overflowEnabled?: boolean;
}>`
  display: flex;
  flex: 1;
  min-height: 2.5rem;
  /* height: 2.5rem; */
  justify-content: ${({ align }) => align || 'initial'};
  align-items: center;
  overflow: hidden;
  font-size: 0.875rem;
  padding: 0 0.625rem;

  ${({ overflowEnabled }) => overflowEnabled && 'overflow: initial;'}
`;

const StandardNestedGridTableHeadingCell = styled(StandardNestedGridTableCell)`
  min-height: 1.5rem;
  /* height: 1.5rem; */
`;

const GridWhiteTableCell = styled(GridTableCell)`
  background-color: ${({ theme }) => theme.white};
`;

export {
  MergedCell,
  DivTableCell,
  DivTableHeadingCell,
  GridMergedCell,
  GridTableHeadingCell,
  GridTableCell,
  StandardNestedGridTableCell,
  StandardNestedGridTableHeadingCell,
  GridWhiteTableCell,
};
