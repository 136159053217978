import { FC } from 'react';
import { PageContainer, SectionTitle, PageFeatureContainer } from 'components';
import { AreasTopology } from 'features/AreasTopology';

export const AreasTopologyPage: FC = () => {
  return (
    <PageContainer>
      <SectionTitle section="areasTopologyPage" />
      <PageFeatureContainer>
        <AreasTopology />
      </PageFeatureContainer>
    </PageContainer>
  );
};
