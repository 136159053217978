import { DefaultTheme } from 'styled-components';

// this should be deprecated
const lightThemeColors = {
  primary: '#e91e63',
};

const colorsList = {
  black: '#000000',
  baseBlack: '#171a1c',
  darkGrey: '#90a4ae',
  lightGrey: '#c6d5dd',
  milkGrey: '#edf1f4',
  white: '#ffffff',
  primary: '#5796b9',
  superLightGrey: '#f7f9fb',
  superDarkGrey: '#292c2e',
  darkBlue: '#507081',
  secondaryBlack: '#131313',
  violet: 'rgb(135, 101, 139)',
  // Extra colors
  extraViolet: '#8977a0',
  extraRed: '#c25454',
  extraGreen: '#80c68f',
  extraOrange: '#dea30c',
  superLightMilkGrey: '#f9f9f6',

  // Transparent colors
  transparentViolet: '#d0c9d9',
  transparentRed: '#edcccc',
  transparentGreen: '#cce8d2',
  transparentBlue: '#cbe1eb',
  transparentYellow: '#f3e2b6',
};

export const lightTheme: DefaultTheme = {
  inputInactiveColor: '#cfd8e3',
  loginLinkColor: lightThemeColors.primary, // this one should be deprecated
  formErrorColor: lightThemeColors.primary, // this one should be deprecated
  // Base colors
  baseBlack: '#171a1c',
  darkGrey: '#90a4ae',
  lightGrey: '#c6d5dd',
  milkGrey: '#edf1f4',
  white: '#ffffff',
  primary: '#5796b9',
  superLightGrey: '#f7f9fb',
  // Extra colors
  extraViolet: '#8977a0',
  extraRed: '#c25454',
  extraGreen: '#80c68f',
  extraOrange: '#dea30c',
  // Transparent colors
  transparentViolet: 'rgba(137, 119, 160, 0.4)',
  transparentRed: 'rgba(194, 84, 84, 0.3)',
  transparentGreen: 'rgba(128, 198, 143, 0.4)',
  transparentBlue: 'rgba(126, 180, 205, 0.4)',
  transparentYellow: 'rgba(214, 157, 12, 0.3)',

  // SHADOWs
  navigationShadow: '0px 1.25rem 0.75rem rgba(144, 164, 174, 0.4)',
  baseShadow: '0px 0px 12px rgba(144, 164, 174, 0.4)',
  globalHeaderShadow: '0rem 0rem 0.75rem rgba(144, 164, 174, 0.4)',
  carouselButtonShadow: '0 0 0.75rem rgba(144, 164, 174, 0.4)',
  identifierPreviewShadow: '0 0 0.75rem rgba(144, 164, 174, 0.4)',

  // SPECIFIC COLORS

  logoColor: colorsList.baseBlack,
  pageSectionTitleBg: colorsList.superLightGrey,
  inputBackground: colorsList.white,
  formBackground: colorsList.white,
  nominationCellDefaultColor: colorsList.white,
  balanceGroupValueCellColor: colorsList.superLightGrey,
  switchContainerColor: colorsList.superLightMilkGrey,
  dividerLineColor: colorsList.milkGrey,
  defaultFontColor: colorsList.baseBlack,
  alwaysDarkFont: colorsList.baseBlack,
  divTableHeadingBg: colorsList.lightGrey,
  tableHeadingBg: colorsList.lightGrey,
  tableDividingLine: colorsList.lightGrey,
  mainBackgroundColor: colorsList.white,
  messagesCheckboxBg: colorsList.superLightGrey,
  messageHistoryInfoCellBg: colorsList.white,
  evenRowColor: colorsList.superLightGrey,
  connectionPointHeadingColor: colorsList.white,
  connectionPointInfoCellColor: colorsList.milkGrey,
  balanceGroupEditCellBg: colorsList.white,
  balanceGroupEditCellTextColor: colorsList.baseBlack,
  sortTypePillColor: colorsList.primary,
  modalHeaderBgColor: colorsList.lightGrey,
  nestedDataRowBorderColor: colorsList.lightGrey,
  messagePreviewBg: colorsList.white,
  qualityStatusCellBg: colorsList.superLightGrey,
  messagePreviewTableHeadingBg: colorsList.milkGrey,
  additionalHourHeading: colorsList.transparentBlue,
  messageDetailsGroupHeading: colorsList.milkGrey,

  // Date picker
  datePickerTriangleColor: '#f0f0f0',
  datePickerNavigationButtonColor: '#cccccc',
  datePickerBgColor: '#ffffff',
  datePickerButtonBorder: `1px solid ${colorsList.violet}`,
  datePickerInputBorder: `1px solid ${colorsList.violet}`,
  datePickerButtonBgColor: colorsList.violet,
  datePickerButtonBgColorHover: '#d0a9d5',
  datePickerHeaderBgColor: '#f0f0f0',
  datePickerSelectedDateBgColor: '#216ba5',
  datePickerSelectedDateTextColor: '#ffffff',

  // This is for identifier and role form edit box
  comboFormBgColor: colorsList.superLightGrey,
  comboFormContentCellBorderColor: colorsList.milkGrey,

  // Buttons
  primaryButtonTextColor: colorsList.white,
  primaryButtonBgColor: colorsList.primary,
  primaryButtonHvrColor: colorsList.baseBlack,

  secondaryButtonTextColor: colorsList.baseBlack,
  secondaryButtonBorderColor: colorsList.baseBlack,

  blackButtonTextColor: colorsList.white,
  blackButtonBgColor: colorsList.baseBlack,

  disabledButtonBgColor: colorsList.milkGrey,
  disabledButtonTextColor: colorsList.lightGrey,

  secondaryUnderlineBgColor: 'none',
  secondaryUnderlineTextColor: colorsList.primary,
  secondaryUnderlineTextStyle: 'underline',

  nominationExpandBtnHoverColor: colorsList.baseBlack,

  modalCloseButtonColor: colorsList.primary,

  nestedDataExpandButtonColor: colorsList.lightGrey,

  mediumLoaderBg: colorsList.milkGrey,

  // Inputs

  checkboxSelectedBgColor: colorsList.white,
  checkboxSelectedTickColor: colorsList.primary,

  inputBorderColor: colorsList.lightGrey,
  inputHoverBg: colorsList.milkGrey,
  inputHoverBorder: colorsList.darkGrey,
  inputActiveBorder: colorsList.baseBlack,

  // Notifications

  messageContainerBg: colorsList.white,
};

export const darkTheme: DefaultTheme = {
  inputInactiveColor: '#cfd8e3',
  loginLinkColor: lightThemeColors.primary, // this one should be deprecated
  formErrorColor: lightThemeColors.primary, // this one should be deprecated
  // Base colors
  baseBlack: '#ffffff',
  darkGrey: '#90a4ae',
  lightGrey: '#c6d5dd',
  milkGrey: '#292c2e',
  white: '#171a1c',
  primary: '#5796b9',
  superLightGrey: '#f7f9fb',
  // Extra colors
  extraViolet: '#8977a0',
  extraRed: '#c25454',
  extraGreen: '#80c68f',
  extraOrange: '#dea30c',
  // Transparent colors
  transparentViolet: 'rgba(137, 119, 160, 0.4)',
  transparentRed: 'rgba(194, 84, 84, 0.3)',
  transparentGreen: 'rgba(128, 198, 143, 0.4)',
  transparentBlue: 'rgba(126, 180, 205, 0.4)',
  transparentYellow: 'rgba(214, 157, 12, 0.3)',

  // SHADOWs
  navigationShadow: '0px 0.25rem 0.25rem rgba(0, 0, 0, 0.65)',
  baseShadow: '0px 0px 0.75rem rgba(144, 164, 174, 0.4)',
  globalHeaderShadow: '0px 0.25rem 0.25rem rgba(0, 0, 0, 0.65)',
  carouselButtonShadow: '0px 0.25rem 0.25rem rgba(0, 0, 0, 0.65);',
  identifierPreviewShadow: '0 0 0.75rem rgba(0, 0, 0, 0.65))',

  // SPECIFIC COLORS
  logoColor: colorsList.white,
  pageSectionTitleBg: colorsList.baseBlack,
  inputBackground: colorsList.baseBlack,
  formBackground: colorsList.baseBlack,
  nominationCellDefaultColor: colorsList.baseBlack,
  balanceGroupValueCellColor: colorsList.superDarkGrey,
  switchContainerColor: colorsList.superDarkGrey,
  dividerLineColor: colorsList.superDarkGrey,
  defaultFontColor: colorsList.white,
  alwaysDarkFont: colorsList.baseBlack,
  divTableHeadingBg: colorsList.black,
  tableHeadingBg: colorsList.superDarkGrey,
  tableDividingLine: colorsList.darkBlue,
  mainBackgroundColor: colorsList.baseBlack,
  messagesCheckboxBg: colorsList.secondaryBlack,
  messageHistoryInfoCellBg: colorsList.superDarkGrey,
  evenRowColor: colorsList.secondaryBlack,
  connectionPointHeadingColor: colorsList.baseBlack,
  connectionPointInfoCellColor: colorsList.black,
  balanceGroupEditCellBg: colorsList.milkGrey,
  balanceGroupEditCellTextColor: colorsList.baseBlack,
  sortTypePillColor: colorsList.extraViolet,
  modalHeaderBgColor: colorsList.baseBlack,
  nestedDataRowBorderColor: 'none',
  messagePreviewBg: colorsList.superDarkGrey,
  qualityStatusCellBg: colorsList.superDarkGrey,
  messagePreviewTableHeadingBg: colorsList.baseBlack,
  additionalHourHeading: colorsList.darkGrey,
  messageDetailsGroupHeading: colorsList.black,

  // Date picker
  datePickerTriangleColor: colorsList.black,
  datePickerNavigationButtonColor: colorsList.primary,
  datePickerBgColor: colorsList.baseBlack,
  datePickerButtonBorder: `1px solid ${colorsList.darkGrey}`,
  datePickerInputBorder: `1px solid ${colorsList.darkGrey}`,
  datePickerButtonBgColor: colorsList.baseBlack,
  datePickerButtonBgColorHover: colorsList.lightGrey,
  datePickerHeaderBgColor: colorsList.black,
  datePickerSelectedDateBgColor: colorsList.primary,
  datePickerSelectedDateTextColor: '#ffffff',

  // This is for identifier and role form edit box
  comboFormBgColor: colorsList.superDarkGrey,
  comboFormContentCellBorderColor: colorsList.lightGrey,

  // Buttons
  primaryButtonTextColor: colorsList.white,
  primaryButtonBgColor: colorsList.primary,
  primaryButtonHvrColor: colorsList.superDarkGrey,

  secondaryButtonTextColor: colorsList.milkGrey,
  secondaryButtonBorderColor: colorsList.milkGrey,

  blackButtonTextColor: colorsList.white,
  blackButtonBgColor: colorsList.superDarkGrey,

  disabledButtonBgColor: 'rgba(237, 241, 244, 0.4)',
  disabledButtonTextColor: 'rgba(0, 0, 0, 0.3)',

  secondaryUnderlineBgColor: colorsList.black,
  secondaryUnderlineTextColor: colorsList.white,
  secondaryUnderlineTextStyle: 'none',

  nominationExpandBtnHoverColor: colorsList.milkGrey,

  modalCloseButtonColor: colorsList.primary,

  nestedDataExpandButtonColor: colorsList.darkGrey,

  mediumLoaderBg: 'rgba(237, 241, 244, 1)',

  // Inputs

  checkboxSelectedBgColor: colorsList.primary,
  checkboxSelectedTickColor: colorsList.white,

  inputBorderColor: colorsList.darkGrey,
  inputHoverBg: colorsList.baseBlack,
  inputHoverBorder: colorsList.darkGrey,
  inputActiveBorder: colorsList.milkGrey,

  // Notifications
  messageContainerBg: colorsList.baseBlack,
};

export const courierInboxLightTheme = {
  container: {
    backgroundColor: colorsList.primary,
  },
  messageList: {
    container: {
      overflow: 'auto',
    },
  },
};

export const courierInboxDarkTheme = {
  container: {
    backgroundColor: colorsList.secondaryBlack,
  },
  footer: {
    backgroundColor: colorsList.baseBlack,
  },
  header: {
    backgroundColor: colorsList.baseBlack,
  },
  messageList: {
    container: {
      overflow: 'auto',
    },
  },
  message: {
    actions: {
      container: {
        backgroundColor: colorsList.secondaryBlack,
      },
      // details?: React.CSSProperties;
      // dismiss?: React.CSSProperties;
    },
    body: {
      backgroundColor: colorsList.baseBlack,
    },
    container: {
      backgroundColor: colorsList.baseBlack,

      '&.read': {
        backgroundColor: colorsList.baseBlack,
      },
    },
    icon: {
      backgroundColor: colorsList.baseBlack,
    },
    title: {
      color: colorsList.primary,
    },
    unreadIndicator: {
      backgroundColor: colorsList.primary,
    },
  },
  tabList: {
    tab: {
      backgroundColor: colorsList.superDarkGrey,
      border: 'none',
      color: colorsList.primary,

      '&.active': {
        color: colorsList.white,
      },
    },
    container: {
      background: colorsList.black,
      border: 'none',
    },
  },
};
