import styled, { css } from 'styled-components';
import { CarouselStyledCell } from 'components';

export const StyledBalanceGroupValueCell = styled(CarouselStyledCell)<{
  isAdditionalHour?: boolean;
}>`
  background-color: ${({ theme, isAdditionalHour }) =>
    isAdditionalHour ? theme.additionalHourHeading : theme.balanceGroupValueCellColor};
  height: 100%;
`;

export const MarketAreaContainer = styled.section<{ frameColor: string }>`
  margin-bottom: 3.125rem;
`;

export const ConnectionPointContainer = styled.div<{ isExpanded: boolean }>`
  display: flex;
  border-radius: 0 0.25rem 0.25rem 0;
  margin-bottom: ${({ isExpanded }) => (isExpanded ? '0' : '0.3125rem')};
`;

export const InfoCell = styled.div<{
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  align?: string;
  bgColor?: string;
  padding?: string;
  flexValue?: string;
  alwaysDarkFont?: boolean;
}>`
  ${({
    width,
    minWidth,
    maxWidth,
    bgColor,
    theme,
    padding,
    flexValue,
    align,
    alwaysDarkFont,
  }) => css`
    min-width: ${minWidth ? `${minWidth}` : width ? `${width}` : 'auto'};
    width: ${width ? `${width}` : 'auto'};
    justify-content: ${align || 'initial'};
    background-color: ${bgColor || theme.nominationCellDefaultColor};
    padding: ${padding || '0.5625rem 0.5rem'};
    max-width: ${maxWidth ? `${maxWidth}` : width ? `${width}` : 'auto'};
    ${flexValue && `flex: ${flexValue};`}
    ${alwaysDarkFont && `color: ${theme.alwaysDarkFont};`}
  `}
  height: 100%;
  display: flex;
  margin-right: 0.5rem;
  font-size: 0.875rem;
  align-items: center;
  overflow: hidden;
`;

export const TextOverflow = styled.p`
  overflow: hidden;
  font-size: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;

  :hover {
    overflow: initial;
  }
`;

export const ConnectionPointInfoCell = styled(InfoCell)`
  height: 2.5rem;
  background-color: ${({ bgColor, theme }) => bgColor || theme.connectionPointInfoCellColor};
`;

export const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  max-width: 116.5625rem; //This is width of 24 cells + buttons + spacings between cells - last spacer
`;
