import { ReactElement, useContext, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AppRouter } from './AppRouter';
import { LoginPage, LogoutPage, NotFoundPage } from 'pages';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { authorizeUser } from 'store/authSlice';
import { TenantInfoContext } from 'App';

interface PrivateRouteProps {
  path: string;
  children: ReactElement;
}
const PrivateRoute = ({ path, children }: PrivateRouteProps): ReactElement => {
  const { accounts, instance, inProgress } = useMsal();
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.authReducer);
  const {
    login: { clientIdentifier },
  } = useContext(TenantInfoContext);

  useEffect(() => {
    const accessTokenRequest = {
      scopes: [`api://${clientIdentifier}/default`, 'email', 'profile'],
      account: accounts[0],
    };
    if (
      inProgress === InteractionStatus.None &&
      accounts.length > 0 &&
      (!process.env.REACT_APP_LOCAL_TOKEN || process.env.REACT_APP_LOCAL_TOKEN === '')
    ) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          const token = `Bearer ${accessTokenResponse.accessToken}`;
          dispatch(authorizeUser({ token, mail: accounts[0].username }));
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          } else {
            console.error(error);
          }
        });
    }

    if (process.env.REACT_APP_LOCAL_TOKEN && process.env.REACT_APP_LOCAL_TOKEN !== '') {
      dispatch(
        authorizeUser({ token: process.env.REACT_APP_LOCAL_TOKEN, mail: 'test_account@mail.com' }),
      );
    }
  }, [instance, accounts, inProgress]);

  return (
    <>
      <AuthenticatedTemplate>
        {token && <Route path={path}>{children}</Route>}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {process.env.REACT_APP_LOCAL_TOKEN && process.env.REACT_APP_LOCAL_TOKEN !== '' ? (
          <Route path={path}>{children}</Route>
        ) : (
          <Redirect to="/login" />
        )}
      </UnauthenticatedTemplate>
    </>
  );
};

const AuthRoute = ({ path, children }: PrivateRouteProps): ReactElement => {
  const { instance } = useMsal();

  return (
    <Route
      path={path}
      render={() => (instance.getAllAccounts().length > 0 ? <Redirect to="/app/" /> : children)}
    />
  );
};

export const Router = (): ReactElement => {
  return (
    <Switch>
      <AuthRoute path="/login/">
        <LoginPage />
      </AuthRoute>
      <AuthRoute path="/logout/">
        <LogoutPage />
      </AuthRoute>

      <PrivateRoute path="/app/">
        <AppRouter />
      </PrivateRoute>
      <Route path="/404/">
        <NotFoundPage />
      </Route>
      <Redirect from="/" to="/login/" />
    </Switch>
  );
};
