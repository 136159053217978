import { FC } from 'react';
import styled from 'styled-components';
import { BalancegroupInfoRow } from './BalancegroupInfoRow';
import { ClassicButton, StandardNestedGridTableHeadingCell, VARIANT } from 'components';
import { useHistory } from 'react-router-dom';
import { BalancegroupInfo } from 'utils/types';

const BalancegroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.75rem;
`;

const StyledHeading = styled.h3`
  font-size: 1rem;
  color: ${({ theme }) => theme.baseBlack};
  padding: 0 0.75rem;
  margin-bottom: 0.625rem;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns:
    3.125rem minmax(13rem, 1fr) minmax(13rem, 1fr) minmax(13rem, 1fr) minmax(9rem, 1fr)
    minmax(9rem, 1fr) minmax(9rem, 1fr) 3.75rem 6.25rem;
  border-bottom: 1px solid ${({ theme }) => theme.darkGrey};
  padding-bottom: 1rem;
  grid-column-gap: 0.3125rem;
`;

const ButtonContaienr = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.25rem 0;
`;

interface BalancegroupTableProps {
  counterpartyName: string;
  balanceGroups?: Array<BalancegroupInfo>;
  isAdmin: boolean;
  counterpartyId: number;
}

export const BalancegroupTable: FC<BalancegroupTableProps> = ({
  counterpartyName,
  balanceGroups,
  isAdmin,
  counterpartyId,
}) => {
  const history = useHistory();
  return (
    <BalancegroupsContainer>
      {balanceGroups && balanceGroups.length > 0 && (
        <>
          <StyledHeading>Balancegroups</StyledHeading>
          <Row>
            <StandardNestedGridTableHeadingCell></StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>Area</StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>Display name</StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>
              Balance group identifier
            </StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>Messages</StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>Valid from</StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>Valid to</StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>Active</StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell></StandardNestedGridTableHeadingCell>
          </Row>
          {balanceGroups.map((balancegroup, key) => (
            <BalancegroupInfoRow
              balancegroup={balancegroup}
              key={balancegroup.id}
              isAdmin={isAdmin}
              counterpartyName={counterpartyName}
              counterpartyId={counterpartyId}
              isOdd={key % 2 !== 0}
            />
          ))}
        </>
      )}
      <ButtonContaienr>
        <ClassicButton
          variant={VARIANT.SECONDARY}
          width="12.5rem"
          onClick={() =>
            history.push(
              `/app/masterdata/${encodeURIComponent(
                counterpartyName,
              )}/${counterpartyId}/balancegroup/create`,
            )
          }
          disabled={!isAdmin}
        >
          Add new balance group
        </ClassicButton>
      </ButtonContaienr>
    </BalancegroupsContainer>
  );
};
