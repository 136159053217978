import styled from 'styled-components';

export { ReactComponent as Austria } from './austria.svg';
export { ReactComponent as Germany } from './germany.svg';
export { ReactComponent as France } from './france.svg';
export { ReactComponent as Italy } from './italy.svg';
export { ReactComponent as Switzerland } from './switzerland.svg';
export { ReactComponent as Netherlands } from './netherlands.svg';
export { ReactComponent as Belgium } from './belgium.svg';
export { ReactComponent as CompanyIcon } from './default.svg';
export { ReactComponent as Denmark } from './denmark.svg';

import { ReactComponent as CzechRepublicSVG } from './czech-republic.svg';
export const CzechRepublic = styled(CzechRepublicSVG)`
  border-radius: 4px;
`;
import { ReactComponent as BulgariaSVG } from './bulgaria.svg';
export const Bulgaria = styled(BulgariaSVG)`
  border-radius: 4px;
`;
import { ReactComponent as GreatBritainSVG } from './great-britain.svg';
export const GreatBritain = styled(GreatBritainSVG)`
  border-radius: 4px;
`;
import { ReactComponent as EstoniaSVG } from './estonia.svg';
export const Estonia = styled(EstoniaSVG)`
  border-radius: 4px;
`;
import { ReactComponent as FinlandSVG } from './finland.svg';
export const Finland = styled(FinlandSVG)`
  border-radius: 4px;
`;
import { ReactComponent as HungarySVG } from './hungary.svg';
export const Hungary = styled(HungarySVG)`
  border-radius: 4px;
`;
import { ReactComponent as LatviaSVG } from './latvia.svg';
export const Latvia = styled(LatviaSVG)`
  border-radius: 4px;
`;
import { ReactComponent as LithuaniaSVG } from './lithuania.svg';
export const Lithuania = styled(LithuaniaSVG)`
  border-radius: 4px;
`;
import { ReactComponent as NorwaySVG } from './norway.svg';
export const Norway = styled(NorwaySVG)`
  border-radius: 4px;
`;
import { ReactComponent as PolandSVG } from './poland.svg';
export const Poland = styled(PolandSVG)`
  border-radius: 4px;
`;
import { ReactComponent as PortugalSVG } from './portugal.svg';
export const Portugal = styled(PortugalSVG)`
  border-radius: 4px;
`;
import { ReactComponent as RomaniaSVG } from './romania.svg';
export const Romania = styled(RomaniaSVG)`
  border-radius: 4px;
`;
import { ReactComponent as SlovakiaSVG } from './slovakia.svg';
export const Slovakia = styled(SlovakiaSVG)`
  border-radius: 4px;
`;
import { ReactComponent as SloveniaSVG } from './slovenia.svg';
export const Slovenia = styled(SloveniaSVG)`
  border-radius: 4px;
`;
import { ReactComponent as SpainSVG } from './spain.svg';
export const Spain = styled(SpainSVG)`
  border-radius: 4px;
`;
