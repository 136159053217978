import { FC, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  GridTableCell,
  Checkbox,
  StatusIndicator,
  LinkToMessage,
  BaseButton,
  PlusMinusExpandToggleButton,
} from 'components';
import { TableArrow, XmlOpenIcon, SentIcon } from 'assets/icons';
import { toggleSelectMessage } from '../messagesSlice';
import styled from 'styled-components';

import {
  MergedContentCell,
  CustomContainer,
  PartyNameContainer,
  OutgoingStyledIcon,
  CheckboxCellContainer,
  Row,
} from './StyledComponents';
import { Message } from 'utils/types';
import { TextOverflow } from '../../Nominations/components/StyledNominationComponents';
import { formatFromUtc, standardDateFormat } from 'utils/dateFormating';
import { ReplyTable } from 'features/Messages/components/ReplyTable';
import { useXmlDocument } from 'utils/hooks';

const MessageRow = styled(Row)<{ isReplies?: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.tableDividingLine};
`;

const XmlStyledIcon = styled(XmlOpenIcon)`
  path {
    fill: ${({ theme }) => theme.primary};
  }
`;

const TableCell = styled(GridTableCell)<{ bgColor?: string }>`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
`;

const CenterAlignedDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageGroupContainer = styled.div<{ isGray?: boolean }>`
  display: flex;
  flex-direction: column;
  /* ${({ isGray, theme }) => isGray && `background-color: ${theme.superLightGrey};`} */
  ${({ isGray, theme }) => isGray && `background-color: ${theme.evenRowColor};`}
`;

interface MessageTableRowProps {
  message: Message;
  isReplies?: boolean;
  isGray?: boolean;
}

export const MessagesTableRow: FC<MessageTableRowProps> = ({ message, isReplies, isGray }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useAppDispatch();
  const {
    contractReference,
    internalAccountName,
    messageStatus,
    sender,
    recipient,
    issuerMarketParticipant,
    recipientMarketParticipant,
    creationTime,
    sendTime,
    documentName,
    documentType,
    messageDefinitionDescription,
    gasDate,
    direction,
    id,
    documentIdentifier,
    documentVersion,
    replyMessages,
  } = message;
  const { timezone: userTimezone } = useAppSelector((store) => store.globalSlice);
  const [date, hour] = creationTime
    ? formatFromUtc(new Date(`${creationTime}Z`), userTimezone?.timeZone).split(' ')
    : '';
  const [dateSend, hourSend] = sendTime
    ? formatFromUtc(new Date(`${sendTime}Z`), userTimezone?.timeZone).split(' ')
    : '';
  const { selected } = useAppSelector((state) => state.messageReducer);
  const isMessageSelected =
    selected.findIndex((selectedMessage) => selectedMessage && selectedMessage.id === id) !== -1;

  const formatedGasdate = gasDate ? standardDateFormat(new Date(gasDate)) : '';
  const { mutate } = useXmlDocument();

  return (
    <MessageGroupContainer isGray={isGray}>
      <MessageRow>
        <CenterAlignedDiv>
          {!isReplies && replyMessages.length > 0 && (
            <PlusMinusExpandToggleButton
              isExpanded={isExpanded}
              toggleFunction={() => setIsExpanded(!isExpanded)}
            />
          )}
        </CenterAlignedDiv>
        <TableCell>
          <CheckboxCellContainer title={direction.toLowerCase()} noChecbox={isReplies}>
            {!isReplies && (
              <Checkbox
                checked={isMessageSelected}
                onChange={() =>
                  dispatch(toggleSelectMessage({ id, documentIdentifier, documentVersion }))
                }
              />
            )}
            {direction === 'SENT' ? <OutgoingStyledIcon /> : <SentIcon />}
          </CheckboxCellContainer>
        </TableCell>
        <TableCell align="center">
          <StatusIndicator status={messageStatus} />
        </TableCell>
        <TableCell>{messageDefinitionDescription}</TableCell>
        <TableCell align="center">{documentVersion}</TableCell>
        <TableCell>{documentType}</TableCell>
        <TableCell padding="0">
          <MergedContentCell>
            <CustomContainer>{date}</CustomContainer>
            <CustomContainer>{hour}</CustomContainer>
          </MergedContentCell>
        </TableCell>
        <TableCell padding="0">
          <MergedContentCell>
            <CustomContainer>{dateSend}</CustomContainer>
            <CustomContainer>{hourSend}</CustomContainer>
          </MergedContentCell>
        </TableCell>
        <TableCell align="center">{formatedGasdate}</TableCell>
        <TableCell padding="0">
          <MergedContentCell>
            <PartyNameContainer title={sender}>{issuerMarketParticipant}</PartyNameContainer>
            <TableArrow />
            <PartyNameContainer title={recipient}>{recipientMarketParticipant}</PartyNameContainer>
          </MergedContentCell>
        </TableCell>
        <TableCell>{contractReference ? contractReference : internalAccountName}</TableCell>
        <TableCell title={documentIdentifier}>
          <TextOverflow>{documentIdentifier}</TextOverflow>
        </TableCell>
        <TableCell>
          <BaseButton onClick={() => mutate({ id, direction, name: documentName })}>
            <XmlStyledIcon />
          </BaseButton>
        </TableCell>
        <TableCell align="center">
          <LinkToMessage link={`/app/history/message/${id}`} />
        </TableCell>
      </MessageRow>
      {isExpanded && <ReplyTable replyMessages={replyMessages} />}
    </MessageGroupContainer>
  );
};
