import { FC } from 'react';
import { GridTableHeadingCell, Modal, NotificationTypeIndicator } from 'components';
import styled from 'styled-components';
import { ClassicButton, VARIANT } from 'components/Buttons/ClassicButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'store/modalSlice';
import { GridWhiteTableCell } from 'components/StyledDivTableComponents';
import { NotificationType } from 'utils/types';

const SubmitModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 3.125rem;
  height: calc(100% - 10rem);
`;

const ModalHeading = styled.h2`
  display: flex;

  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  align-items: center;
  svg {
    margin-left: 0.5rem;
    height: 100%;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 21.875rem;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const externalContentStyles = {
  height: '65%',
  width: '80%',
  padding: '0',
};

const ValidationTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ValidationMessageCell = styled(GridWhiteTableCell)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: auto;
  max-height: 100%;
  height: auto;
`;

const ValidationTableRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 10rem 5rem 1fr;
  grid-gap: 0.25rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.nestedDataRowBorderColor}`};
  align-items: center;
`;

const StatusContainer = styled.div`
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const UploadWarningModal: FC = () => {
  const dispatch = useAppDispatch();
  const { props } = useAppSelector((state) => state.modalReducer.uploadFileWarningModal);

  const constructModalHeadingCopy = (): { copy: string; status: 'info' | 'warning' | 'error' } => {
    if (props && props.fileUploadResponse) {
      const { workbookName, results } = props.fileUploadResponse;
      const containsValidSheet = results.some((result) => result.sheetValid);
      const sheetsWithValidationErrors = results.filter(
        (result) =>
          result.nominationTracksNotFound.length > 0 ||
          Object.keys(result.nominationTrackValidationErrors).length > 0,
      );

      if (sheetsWithValidationErrors.length > 0) {
        return {
          copy: `${workbookName} partially imported`,
          status: 'warning',
        };
      } else if (containsValidSheet) {
        return {
          copy: `${workbookName}  successfully imported!`,
          status: 'info',
        };
      } else {
        return {
          copy: `${workbookName} could not be imported`,
          status: 'error',
        };
      }
    }
    return {
      copy: `Something went wrong`,
      status: 'error',
    };
  };

  const getStatusForSheet = (sheetName: string): 'WARNING' | 'SUCCESS' | 'INFO' | 'ERROR' => {
    if (props && props.fileUploadResponse) {
      const { results } = props.fileUploadResponse;
      const sheetResult = results.find((result) => result.sheetName === sheetName);
      if (sheetResult) {
        if (
          sheetResult.nominationTracksNotFound.length > 0 ||
          Object.keys(sheetResult.nominationTrackValidationErrors).length > 0
        ) {
          return 'WARNING';
        } else if (
          sheetResult.sheetValid &&
          !sheetResult.sheetIgnored &&
          sheetResult.nominationTracksNotFound.length === 0 &&
          Object.keys(sheetResult.nominationTrackValidationErrors).length === 0
        ) {
          return 'SUCCESS';
        } else if (sheetResult.sheetValid || sheetResult.sheetIgnored) {
          return 'INFO';
        } else {
          return 'ERROR';
        }
      }
    }
    return 'ERROR';
  };

  const { copy, status } = constructModalHeadingCopy();

  return (
    <Modal
      modalId="uploadFileWarningModal"
      externalContentStyles={externalContentStyles}
      headerType={status}
    >
      <SubmitModalContainer>
        <ModalHeading>{copy}</ModalHeading>
        {status === 'info' || status === 'warning' ? (
          <ValidationTable>
            <ValidationTableRow>
              <GridTableHeadingCell>Sheet name</GridTableHeadingCell>
              <GridTableHeadingCell align="center">Status</GridTableHeadingCell>
              <GridTableHeadingCell>Validation message</GridTableHeadingCell>
            </ValidationTableRow>
            {props?.fileUploadResponse?.results.map(
              ({
                sheetName,
                validationMessage,
                nominationTracksNotFound,
                nominationTrackValidationErrors,
              }) => (
                <ValidationTableRow key={sheetName}>
                  <GridWhiteTableCell>{sheetName}</GridWhiteTableCell>
                  <GridWhiteTableCell align="center">
                    <StatusContainer>
                      <NotificationTypeIndicator
                        notificationType={getStatusForSheet(sheetName) as NotificationType}
                      />
                    </StatusContainer>
                  </GridWhiteTableCell>
                  <ValidationMessageCell>
                    <div>{validationMessage}</div>
                    {nominationTracksNotFound.length > 0 && (
                      <div>
                        <p>Unknown External References:</p>
                        {nominationTracksNotFound.map((nominationtrack, key) => (
                          <p key={`${nominationtrack}_${key}`}>{nominationtrack}</p>
                        ))}
                      </div>
                    )}
                    {Object.keys(nominationTrackValidationErrors).length > 0 && (
                      <div>
                        <p>Validation Errors:</p>
                        {Object.entries(nominationTrackValidationErrors).map(
                          ([nominationTrack, validationErrors]) => (
                            <div key={nominationTrack}>
                              <p>{nominationTrack}:</p>
                              {validationErrors.map((error, key) => (
                                <p key={`${error}_${key}`}>{error}</p>
                              ))}
                            </div>
                          ),
                        )}
                      </div>
                    )}
                  </ValidationMessageCell>
                </ValidationTableRow>
              ),
            )}
          </ValidationTable>
        ) : (
          <> </>
        )}
        <ButtonContainer>
          <ClassicButton
            onClick={() => dispatch(closeModal({ modalId: 'uploadFileWarningModal' }))}
            width="10rem"
            variant={VARIANT.PRIMARY}
          >
            Ok
          </ClassicButton>
        </ButtonContainer>
      </SubmitModalContainer>
    </Modal>
  );
};
