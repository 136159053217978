import { FC, useState } from 'react';
import { useNominationHistory } from '../../nominationsApi';
import styled from 'styled-components';
import { CarouselControl } from 'components';
import { VersionRow } from './VersionRow';

const VersionTableContentContainer = styled.div`
  padding: 2.5rem;
`;

const RowContianer = styled.div`
  display: flex;
  width: 100%;
`;

const StyledTableHeadingCells = styled.div<{ width?: string; isLast?: boolean }>`
  ${({ width }) => width && `min-width: ${width}; max-width: ${width};`}
  color: ${({ theme }) => theme.darkGrey};
  margin-right: 0.5rem;
  display: flex;
  align-items: flex-end;
  font-weight: 600;
  ${({ isLast }) => isLast && 'margin-right: 0;'}
`;

const NoHistoryText = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
`;

interface VerionTableProps {
  selectedDate: Date;
  nominationTrackId?: number;
}

export const VersionTable: FC<VerionTableProps> = ({ selectedDate, nominationTrackId }) => {
  const { data } = useNominationHistory(selectedDate, nominationTrackId);
  const [scrollPosition, setScrollPosition] = useState(0);

  const triggerScroll = (newScrollPosition: number) => {
    setScrollPosition(newScrollPosition);
  };
  return (
    <VersionTableContentContainer>
      {data && (data.timeSeries.length > 1 || data.timeSeries[0].fipDate) ? (
        <>
          <RowContianer>
            <StyledTableHeadingCells width="2.5rem">Ver.</StyledTableHeadingCells>
            <StyledTableHeadingCells width="6rem">Date</StyledTableHeadingCells>
            <StyledTableHeadingCells width="5rem">Time</StyledTableHeadingCells>
            <StyledTableHeadingCells width="12rem" isLast>
              User
            </StyledTableHeadingCells>
            <CarouselControl
              scrollControl={triggerScroll}
              gasDayHourDefinition={data.gasDayHourDefinition}
            />
          </RowContianer>
          {data.timeSeries.map((version) => (
            <VersionRow key={version.version} version={version} scrollPosition={scrollPosition} />
          ))}
        </>
      ) : (
        <NoHistoryText>There is no history yet</NoHistoryText>
      )}
    </VersionTableContentContainer>
  );
};
