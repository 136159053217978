import { setError } from 'features/ErrorScreen/errorSlice';
import { useMutation } from 'react-query';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { API } from './useApi';

interface UseXMLDocumentProps {
  id: number;
  direction: string;
  name: string;
}
export const useXmlDocument = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  return useMutation(
    ({ id, direction }: UseXMLDocumentProps) => {
      if (token.length && id && direction) {
        return API.GET(`/message/${id}/xml`, {
          headers: {
            Authorization: token,
          },
        });
      }
      return Promise.reject();
    },
    {
      onError: (error: Error) => {
        dispatch(setError({ errorMessage: error.toString() }));
      },
      onSuccess: async (data, { name }) => {
        // This is workaround for fetching xml file directly from backend
        const parsedData = await data.text();
        const blob = new Blob([parsedData], { type: 'text/xml' });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.href = blobUrl;
        link.download = `${name}`;
        // Append link to the body
        document.body.appendChild(link);

        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        );

        // Remove link from body
        document.body.removeChild(link);
      },
    },
  );
};
