import {
  ButtonWithLoader,
  CheckboxCotainer,
  ConfirmationPopup,
  ConfirmationPopupText,
  ControlledCheckbox,
  ControlledDatePicker,
  FormContainer,
  FormSection,
  FormTitle,
  HeadingBar,
  StyledTextInput,
} from 'components';
import { format } from 'date-fns';

import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useAppDispatch } from 'store/hooks';
import { openModal } from 'store/modalSlice';
import styled from 'styled-components';
import { useResetGasday } from './adminPanelApi';

const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 22.125rem;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const ChecbkoxContainerWithMargin = styled(CheckboxCotainer)`
  margin-bottom: 2rem;
`;

export const ResetGasday: FC = () => {
  const {
    register,
    getValues,
    formState: { isDirty, isValid },
    control,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      gasday: new Date(),
      forceReset: false,
    } as FieldValues,
  });
  const { mutate: resetGasday, isLoading } = useResetGasday();
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    const { gasday, forceReset, secureString } = getValues();
    resetGasday({ gasday, forceReset, secureString });
  };

  const gasday = watch('gasday');

  return (
    <>
      <HeadingBar>
        <FormTitle>Reset Gasday</FormTitle>
      </HeadingBar>
      <FormContainer>
        <FormSection>
          <DatePickerContainer>
            <label>Select gasday:</label>
            <ControlledDatePicker name="gasday" control={control} required />
          </DatePickerContainer>
          <StyledTextInput
            type="text"
            label={`Secure string. To confirm type in DELETE_GASDAY_${format(
              gasday,
              'yyyy-MM-dd',
            )}`}
            placeholder="e.g. DELETE_GASDAY_YYYY-MM-DD"
            register={register('secureString', { required: true })}
            width="28rem"
            isLabelBold
          />
          <ChecbkoxContainerWithMargin>
            <ControlledCheckbox name="forceReset" control={control} />
            <label>Force delete messages (not available on prod)</label>
          </ChecbkoxContainerWithMargin>

          <ButtonWithLoader
            width="10rem"
            isLoading={false}
            onClick={() => dispatch(openModal({ modalId: 'resetGasdayConfirmation' }))}
            disabled={!isDirty || !isValid}
          >
            Reset
          </ButtonWithLoader>
        </FormSection>
      </FormContainer>
      <ConfirmationPopup
        isLoading={isLoading}
        confirmationPopupId="resetGasdayConfirmation"
        confirmButtonAction={() => onSubmit()}
      >
        <ConfirmationPopupText>
          {
            <>
              Shall we go ahead and remove <span>{format(gasday, 'dd-MM-yyyy')}</span> gas day?{' '}
            </>
          }
        </ConfirmationPopupText>
      </ConfirmationPopup>
    </>
  );
};
