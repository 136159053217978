import { ControlledSelectInput, FieldContainerWithLabel } from 'components';
import { nominatioTrackInputWidth } from './index';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { SelectOptionType } from 'utils/types';
import { AreaSlimType } from 'utils/types/areaTypes';
import { useInternalAccountsList } from './nominationTrackApi';

interface InternalAccountSelectFieldProps {
  control: Control;
  isDisabled: boolean;
  area: AreaSlimType;
}

export const InternalAccountSelectField: FC<InternalAccountSelectFieldProps> = ({
  control,
  isDisabled,
  area,
}) => {
  const { data: balanceGroups } = useInternalAccountsList(area);

  return (
    <FieldContainerWithLabel isDisabled={isDisabled}>
      <p>Internal Account</p>
      <ControlledSelectInput
        required
        control={control}
        width={nominatioTrackInputWidth}
        placeholder="Choose"
        options={balanceGroups?.content as unknown as Array<SelectOptionType>}
        name="internalAccount"
        accountSelect
        isDisabled={
          (balanceGroups?.content && balanceGroups.content.length <= 0) || !balanceGroups?.content
        }
      />
    </FieldContainerWithLabel>
  );
};
