import { ControlledSelectInput, FieldContainerWithLabel } from 'components';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { SelectOptionType } from 'utils/types';

import { usePartneships } from 'features/AdminPanel/adminPanelApi';

interface PartnershipSelectFieldProps {
  control: Control;
  isDisabled: boolean;
  counterpartyId: number;
  connectionType: 'AS4' | 'AS2';
}

export const PartnershipSelectField: FC<PartnershipSelectFieldProps> = ({
  control,
  isDisabled,
  counterpartyId,
  connectionType,
}) => {
  const { data: partnerships } = usePartneships(counterpartyId, connectionType);

  const formatPartnershipsForSelect = (): Array<SelectOptionType> => {
    return partnerships?.content.map((partnership) => ({
      id: partnership.id,
      name: partnership.counterparty.name,
    })) as Array<SelectOptionType>;
  };
  return (
    <FieldContainerWithLabel>
      <p>Partnerhisp</p>
      <ControlledSelectInput
        required
        control={control}
        width="22rem"
        placeholder="Choose"
        options={formatPartnershipsForSelect()}
        name="partnership"
        isDisabled={isDisabled}
      />
    </FieldContainerWithLabel>
  );
};
