import { FC } from 'react';
import { PageContainer, SectionTitle, PageFeatureContainer } from 'components';
import { MessageDefinitions } from 'features/MessageDefinitions';

export const MessageDefinitionsPage: FC = () => {
  return (
    <PageContainer>
      <SectionTitle section="messageDefinitionsPage" />
      <PageFeatureContainer>
        <MessageDefinitions />
      </PageFeatureContainer>
    </PageContainer>
  );
};
