import styled from 'styled-components';

const SettingContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 1px;
  background-color: ${({ theme }) => theme.lightGrey};
`;

const SettingsContainerRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 10rem 10rem 10rem 1fr;
  grid-gap: 1px;
  background-color: ${({ theme }) => theme.lightGrey};
`;

const UserSettingsRow = styled(SettingsContainerRow)`
  grid-template-columns: 1fr 1fr 15rem;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  padding: 1rem 0;
`;

const NameCell = styled(Cell)`
  text-transform: capitalize;
  font-size: 1.125rem;
  font-weight: 600;
`;

const CenteredNameCell = styled(NameCell)`
  justify-content: center;
`;

const CenteredCell = styled(Cell)`
  justify-content: center;
`;

const NameCellWithPadding = styled(NameCell)`
  padding: 0 1rem;
`;

const CellWithPadding = styled(Cell)`
  padding: 1rem;
`;

export {
  SettingContainer,
  SettingsContainerRow,
  Cell,
  NameCell,
  CenteredNameCell,
  CenteredCell,
  UserSettingsRow,
  NameCellWithPadding,
  CellWithPadding,
};
