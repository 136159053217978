import { TopControlRowProps } from '../types';
import { FC } from 'react';
import styled from 'styled-components';
import {
  ClassicButton,
  SplitButton,
  ToggleSwitch,
  VARIANT,
  StyledDatePicker,
  TopControlRowContainer,
} from 'components';
import { SubmitDaySplitButton } from './Buttons/SubmitDaySplitButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/modalSlice';
import { toggleZeroFilter } from '../nominationReduxSlice';
import { useCSVDocument, useXLSXDocument } from '../nominationsApi';
import { formatDateForInput } from 'utils/dateFormating';

const LeftSideContainer = styled.div`
  display: flex;

  > * {
    margin-right: 0.5rem;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchContainer = styled.div`
  background-color: ${({ theme }) => theme.switchContainerColor};
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding: 0 0.9375rem;
  border-radius: 0.25rem;

  span {
    color: ${({ theme }) => theme.baseBlack};
    font-size: 1rem;
    margin-right: 0.9375rem;
  }
`;

export const TopControlRow: FC<TopControlRowProps> = ({
  selectedDate,
  setSelectedDate,
  setNumberOfDays,
}) => {
  const dispatch = useAppDispatch();
  const { changedData, areas, zeroFilter } = useAppSelector((state) => state.nominationReducer);
  const { mutate: downloadCSV, isLoading: CSVisLoading } = useCSVDocument();
  const { mutate: downloadXLSX, isLoading: XLSXisLoading } = useXLSXDocument();

  const isSelected = areas?.filter((area) => area.isSelected).length === areas?.length;
  let isSomeSelected = false;
  if (!isSelected) {
    isSomeSelected = !!areas?.some(
      (area) =>
        area.isSelected ||
        area.connectionPoints.some((connectionPoint) => connectionPoint.isSelected),
    );
  }

  const submitAction = () => {
    return changedData.length > 0
      ? dispatch(openModal({ modalId: 'saveModal' }))
      : dispatch(openModal({ modalId: 'submitModal' }));
  };

  const toggleSwitch = () => {
    dispatch(toggleZeroFilter());
  };

  return (
    <TopControlRowContainer>
      <LeftSideContainer>
        <ClassicButton
          width="10rem"
          variant={VARIANT.SECONDARY}
          onClick={() => dispatch(openModal({ modalId: 'fileUploadModal' }))}
        >
          Import
        </ClassicButton>
        <SplitButton
          clickHandler={(value?: number) => {
            switch (value) {
              case 1:
                downloadCSV(formatDateForInput(selectedDate));
                break;
              default:
                downloadXLSX(formatDateForInput(selectedDate));
            }
          }}
          width="10rem"
          label="Export xlsx"
          options={[
            {
              label: 'Export csv',
              value: 1,
            },
          ]}
          isLoading={CSVisLoading || XLSXisLoading}
        />

        <ClassicButton
          width="10rem"
          onClick={() => dispatch(openModal({ modalId: 'copyFromModal' }))}
          variant={VARIANT.SECONDARY}
        >
          Copy from
        </ClassicButton>
        <ClassicButton
          width="10rem"
          onClick={() => dispatch(openModal({ modalId: 'copyToModal' }))}
          variant={VARIANT.SECONDARY}
        >
          Copy to
        </ClassicButton>
        <SubmitDaySplitButton
          clickHandler={submitAction}
          width="13rem"
          disabled={!(isSomeSelected || isSelected)}
          label="Submit"
          setNumberOfDays={setNumberOfDays}
          markSelected={false}
          options={[
            {
              label: 'Selected day + 1d',
              value: 1,
            },
            {
              label: 'Selected day + 2d',
              value: 2,
            },
            {
              label: 'Selected day + 3d',
              value: 3,
            },
            {
              label: 'Selected day + 4d',
              value: 4,
            },
            {
              label: 'Selected day + 5d',
              value: 5,
            },
            {
              label: 'Selected day + 6d',
              value: 6,
            },
            {
              label: 'Selected day + 7d',
              value: 7,
            },
          ]}
        />
        <SwitchContainer>
          <span>Hide 0</span>
          <ToggleSwitch isChecked={zeroFilter} onChange={toggleSwitch} />
        </SwitchContainer>
      </LeftSideContainer>
      <FlexContainer>
        <StyledDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      </FlexContainer>
    </TopControlRowContainer>
  );
};
