import { FC } from 'react';
import { PageContainer } from 'components';
import { useParams } from 'react-router-dom';
import { MessageDetails } from 'features/MessageDetails';

interface ParamTypes {
  id: string;
}

export const MessageDetailsPage: FC = () => {
  const { id } = useParams<ParamTypes>();
  return (
    <PageContainer>
      <MessageDetails messageId={id} />
    </PageContainer>
  );
};
