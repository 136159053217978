import { FC } from 'react';
import { ConnectioPointValuesProps } from '../../types';
import { CarouselStyledValueCell } from 'components';
import { BalanceGroup } from 'utils/types';

const calculateConnectionPointHourlyValues = (balanceGroups: Array<BalanceGroup>) => {
  const combinedTimetables = balanceGroups.map(
    (balanceGroup) => balanceGroup.hourlyValues.timetable,
  );
  const combinedHourlyValues = combinedTimetables.reduce((prev, next) => {
    return next.map((value, index) => {
      if (typeof value === 'string') {
        return (prev[index] || 0) + parseInt(value);
      }
      return (prev[index] || 0) + value;
    });
  }, []);
  return combinedHourlyValues;
};

export const ConnectionPointValuesRow: FC<ConnectioPointValuesProps> = ({ connectionPoint }) => {
  const { balanceGroups, name } = connectionPoint;

  const connectionPointValues = calculateConnectionPointHourlyValues(balanceGroups);

  return (
    <>
      {connectionPointValues.map((connectionPointValue, key) => (
        <CarouselStyledValueCell key={`${name}_${key}`}>
          {parseFloat(connectionPointValue.toFixed(3))}
        </CarouselStyledValueCell>
      ))}
    </>
  );
};
