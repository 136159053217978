import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, openModal } from 'store/modalSlice';
import { API, useApiGet } from 'utils/hooks';
import { Counterparty } from 'utils/types';
import { CustomErrorType, SortType } from 'utils/types/generalTypes';

export interface Counterparties {
  content: Array<Counterparty>;
  totalPages: number;
}

export const useCounterparties = (searchKeyword: string, activeSort: SortType) => {
  const fetchCounterparties = useApiGet(
    `/counterparty?size=0${
      activeSort.name !== '' && activeSort.direction !== 'none'
        ? `&sort=${activeSort.name},${activeSort.direction}`
        : ''
    }${searchKeyword.length > 3 ? `&search=${searchKeyword}` : ''}`,
  );
  return useQuery<Counterparties, Error>(
    ['counterparties', searchKeyword, activeSort],
    fetchCounterparties,
    { keepPreviousData: true },
  );
};

export const useBalanceGroupRemoval = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  return useMutation(
    (balanceGroupId: number) => {
      if (token.length) {
        return API.DELETE(`/balance-group/${balanceGroupId}`, {
          headers: {
            Authorization: token,
          },
        });
      }
      return Promise.reject();
    },
    {
      onError: (error: CustomErrorType) => {
        dispatch(
          openModal({
            modalId: 'deletionErrorModal',
            props: { errors: error?.errors },
          }),
        );
      },
      onSuccess: async () => {
        dispatch(closeModal({ modalId: 'balanceGroupRemovalConfirmationModal' }));
        queryClient.invalidateQueries('counterparties');
      },
    },
  );
};
