import { FC, ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { CarouselHourHeading } from './CarouselHourHeading';
import { Chevron } from 'assets/icons';
import { LeftButton, RightButton, Spacer } from './styled';
import { GasDayHourDefinitionType } from 'utils/types';

const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
`;

const TableRow = styled.div`
  display: flex;
  height: 2.5rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  min-width: 0;
  max-width: 121.875rem; //This is width of 24 cells + buttons + spacings between cells
`;

const ReversedChevron = styled(Chevron)`
  transform: rotate(180deg);
`;
interface CarouselControlProps {
  scrollControl?: (newScrollPosition: number) => void;
  gasDayHourDefinition?: GasDayHourDefinitionType;
  themeColor?: string;
  scrollPosition?: number;
  displayHeading?: boolean;
  children?: ReactNode;
  dailyGranularity?: boolean;
}
export const CarouselControl: FC<CarouselControlProps> = ({
  scrollControl,
  themeColor,
  children,
  gasDayHourDefinition,
  dailyGranularity,
  scrollPosition,
  displayHeading = true,
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (carouselRef.current && scrollPosition) {
      carouselRef.current.scrollLeft = scrollPosition;
    }
  }, [scrollPosition]);

  const scroll = (direction: 'left' | 'right') => {
    if (carouselRef.current && contentRef.current) {
      const { width: carouselWidth } = carouselRef.current.getBoundingClientRect();
      if (direction === 'left') {
        carouselRef.current.scrollLeft -= carouselWidth;
        if (scrollControl) {
          scrollControl(carouselRef.current.scrollLeft - carouselWidth);
        }
      } else {
        carouselRef.current.scrollLeft += carouselWidth;
        if (scrollControl) {
          scrollControl(carouselRef.current.scrollLeft + carouselWidth);
        }
      }
    }
  };

  return (
    <ContentContainer>
      {!displayHeading ? (
        <Spacer />
      ) : (
        <LeftButton onClick={() => scroll('left')} themeColor={themeColor}>
          <Chevron />
        </LeftButton>
      )}
      <CarouselContainer ref={carouselRef}>
        {displayHeading && (
          <TableRow ref={contentRef}>
            {dailyGranularity && dailyGranularity ? (
              <CarouselHourHeading hoursDefinition={[{ from: 'Daily', to: '' }]} />
            ) : (
              <CarouselHourHeading hoursDefinition={gasDayHourDefinition?.localTimeStrings} />
            )}
          </TableRow>
        )}
        {children && <TableRow>{children}</TableRow>}
      </CarouselContainer>
      {!displayHeading ? (
        <Spacer />
      ) : (
        <RightButton onClick={() => scroll('right')} themeColor={themeColor}>
          <ReversedChevron />
        </RightButton>
      )}
    </ContentContainer>
  );
};
