import { FC, useState, useEffect } from 'react';
import { Modal, ClassicButton, StyledDatePicker, VARIANT, ButtonWithLoader } from 'components';
import styled from 'styled-components';
import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/modalSlice';
import { useCopyGasDay } from '../nominationsApi';
import { subDays } from 'date-fns';

const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 1.5625rem;
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
`;

const StyledLabel = styled.label`
  margin-bottom: 0.5rem;
`;

const ButtonsConainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: space-between;
`;

interface CopyModalProps {
  currentDate: Date;
}

const CopyModalHeading = <StyledModalHeader>Copy nomination data from:</StyledModalHeader>;

export const CopyFromModal: FC<CopyModalProps> = ({ currentDate }) => {
  const [copySourceDate, setCopySourceDate] = useState(new Date(subDays(currentDate, 1)));
  const dispatch = useAppDispatch();
  const closeFileUploadModal = () => dispatch(closeModal({ modalId: 'copyFromModal' }));
  const { mutate: copy, isLoading } = useCopyGasDay();

  useEffect(() => {
    setCopySourceDate(new Date(subDays(currentDate, 1)));
  }, [currentDate]);

  return (
    <Modal
      modalId="copyFromModal"
      externalContentStyles={{ height: '15rem', width: '23.75rem' }}
      headerContentComponent={CopyModalHeading}
    >
      <ModalContentContainer>
        <StyledLabel>Select source gas day to copy data</StyledLabel>
        <StyledDatePicker selectedDate={copySourceDate} setSelectedDate={setCopySourceDate} />
        <ButtonsConainer>
          <ClassicButton
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={closeFileUploadModal}
            disabled={isLoading}
          >
            Cancel
          </ClassicButton>
          <ButtonWithLoader
            isLoading={isLoading}
            width="10rem"
            onClick={() => copy({ sourceDate: copySourceDate, selectedDate: currentDate })}
          >
            Copy
          </ButtonWithLoader>
        </ButtonsConainer>
      </ModalContentContainer>
    </Modal>
  );
};
