import { FC } from 'react';
import { PageContainer, SectionTitle, PageFeatureContainer } from 'components';
import { MessageAck } from './FakeMessagesAck';

export const MessageFakeResponsePage: FC = () => {
  return (
    <PageContainer>
      <SectionTitle section="messageHistoryAck" />
      <PageFeatureContainer>
        <MessageAck />
      </PageFeatureContainer>
    </PageContainer>
  );
};
