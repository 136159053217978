import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { SearchInput } from 'components';
import styled from 'styled-components';

const SearchForm = styled.form`
  flex: 1;
`;

interface MessageHistorySearchProps {
  setSearchKeyword: (keyword: string) => void;
}

export const MessageHistorySearch: FC<MessageHistorySearchProps> = ({ setSearchKeyword }) => {
  const { register, watch, reset, getValues } = useForm({
    defaultValues: { messageSearchKeyword: '' },
  });
  const messageSearchKeyword = watch('messageSearchKeyword');

  const handleUpdate = useCallback(() => {
    const { messageSearchKeyword } = getValues();
    setSearchKeyword(messageSearchKeyword);
  }, [messageSearchKeyword]);

  const resetSearch = () => {
    reset();
  };
  return (
    <SearchForm>
      <SearchInput
        register={register('messageSearchKeyword')}
        placeholder="Search"
        handleUpdate={handleUpdate}
        value={messageSearchKeyword}
        reset={resetSearch}
        isFrameless
      />
    </SearchForm>
  );
};
