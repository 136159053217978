import { useQuery } from 'react-query';
import { useApiGet } from 'utils/hooks';

interface NominationSendEnabled {
  key: string;
  type: string;
  value: string;
  defaultValue: string;
  description: string;
}

export const useNominationConfiguration = () => {
  const fetchConfig = useApiGet('/configproperty/nomination.send.as2_as4.enabled');
  return useQuery<NominationSendEnabled, Error>(['nomination-send-enabled'], fetchConfig);
};
