import {
  CheckboxCotainer,
  ControlledCheckbox,
  ControlledTimePicker,
  ControlledSelectInput,
  FieldContainerWithLabel,
  FormContainer,
  FormSection,
  FormSectionTitle,
  FormTitle,
  TextInput,
} from 'components';
import { FC } from 'react';
import { Control, UseFormRegister, FieldValues, FieldErrors } from 'react-hook-form';
import { useIdentifier } from 'utils/hooks/useIdentifier';
import { EdigasVersionType, SelectOptionType } from 'utils/types';
import { HeadingBar, StepLabel } from '../styled';
import { MeasureUnitSelectField } from './MeasureUnitSelectField';
import { GasDirectionSelectField } from './GasDirectionSelectField';
import { TimePickerContainer } from 'components/FormStyledComponents';

interface AccountAttributesFormProps {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors;
  isDisabled: boolean;
  edigasVersion: EdigasVersionType;
  control: Control;
}

export const AccountAttributesForm: FC<AccountAttributesFormProps> = ({
  register,
  errors,
  isDisabled,
  edigasVersion,
  control,
}) => {
  const cleanIdentifiers = useIdentifier();
  const schemaList = cleanIdentifiers.data?.content as unknown as Array<SelectOptionType>;

  return (
    <section id="account-attributes">
      <HeadingBar>
        <FormTitle>Account Attributes</FormTitle>
        <StepLabel>Step 3</StepLabel>
      </HeadingBar>
      <FormContainer>
        <form>
          <FormSection>
            <FieldContainerWithLabel isDisabled={isDisabled}>
              <p>Connection Point ID Coding Scheme</p>
              <ControlledSelectInput
                required
                control={control}
                width="19.5rem"
                placeholder="Choose"
                options={schemaList}
                name="connectionPointIdentifierType"
                isDisabled={isDisabled}
                identifierSelect
              />
            </FieldContainerWithLabel>
            <MeasureUnitSelectField
              control={control}
              isDisabled={isDisabled}
              edigasVersion={edigasVersion?.version.baseVersion}
            />
            <FieldContainerWithLabel isDisabled={isDisabled}>
              <p>External Account Coding Scheme</p>
              <ControlledSelectInput
                required
                control={control}
                width="19.5rem"
                placeholder="Choose"
                options={schemaList}
                name="externalAccountIdentifierType"
                isDisabled={isDisabled}
                identifierSelect
              />
            </FieldContainerWithLabel>
            <FieldContainerWithLabel isDisabled={isDisabled}>
              <p>Internal Account Coding Scheme</p>
              <ControlledSelectInput
                required
                control={control}
                width="19.5rem"
                placeholder="Choose"
                options={schemaList}
                name="internalAccountIdentifierType"
                isDisabled={isDisabled}
                identifierSelect
              />
            </FieldContainerWithLabel>
            <GasDirectionSelectField
              control={control}
              isDisabled={isDisabled}
              edigasVersion={edigasVersion?.version.baseVersion}
            />
          </FormSection>
          <FormSection>
            <FormSectionTitle>Other settings</FormSectionTitle>
            <TimePickerContainer>
              <label>Mismatch Error Notification Start Time:</label>
              <ControlledTimePicker
                name="mismatchErrorCutoffTime"
                control={control}
                required
                label="Mismatch Error Notification Start Time"
              />
            </TimePickerContainer>
            <CheckboxCotainer>
              <TextInput
                label="Delay(accept values from 0 to 1439)"
                width="19.5rem"
                register={register('delay', {
                  min: 0,
                  max: 1439,
                  pattern: { value: /^[\d]+$/, message: 'invalid delay value' },
                })}
                type="text"
                placeholder=""
                error={'delay' in errors ? errors.delay : ''}
              />
            </CheckboxCotainer>

            <CheckboxCotainer>
              <ControlledCheckbox name="includeAllZeroNominationTracks" control={control} />
              <label>Include all zero nomination tracks</label>
            </CheckboxCotainer>
            <CheckboxCotainer>
              <ControlledCheckbox name="ignoreUnknownNomresAccountPairs" control={control} />
              <label>Ignore Unknown Nomres Account Pairs</label>
            </CheckboxCotainer>
            <CheckboxCotainer>
              <ControlledCheckbox name="repeatConnectionPointClass" control={control} />
              <label>Repeat Connection Points per Account Pair</label>
            </CheckboxCotainer>
            <CheckboxCotainer>
              <ControlledCheckbox name="condensatePeriod" control={control} />
              <label>Condensate Periods</label>
            </CheckboxCotainer>
            <CheckboxCotainer>
              <ControlledCheckbox name="netting" control={control} />
              <label>Netting</label>
            </CheckboxCotainer>
          </FormSection>
        </form>
      </FormContainer>
    </section>
  );
};
