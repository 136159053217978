import { MessagesTableRow } from 'pages/MessageFakeResponsePage/FakeMessagesAck/components/MesssageTableRow';
import { FC } from 'react';
import styled from 'styled-components';
import { Message } from 'utils/types';

const Table = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface ReplyTableProps {
  replyMessages: Array<Message>;
}

export const ReplyTable: FC<ReplyTableProps> = ({ replyMessages }) => {
  return (
    <Table>
      {replyMessages.map((message) => (
        <MessagesTableRow message={message} key={message.id} isReplies />
      ))}
    </Table>
  );
};
