import { Triangle } from 'assets/icons';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.primary};
  text-decoration: underline;
`;

const StyledIcon = styled(Triangle)`
  path {
    fill: ${({ theme }) => theme.primary};
  }
  transform: rotate(180deg);
`;

interface LinkToMessageProps {
  link: string;
}
export const LinkToMessage: FC<LinkToMessageProps> = ({ link }) => {
  return (
    <StyledLink to={link}>
      <StyledIcon title="Link to message" />
    </StyledLink>
  );
};
