import { FC } from 'react';
import styled, { css } from 'styled-components';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

export const StyledInput = styled.input`
  padding: 0px 0.625rem;
  height: 2.5rem;
  width: 100%;
  border-radius: 0.3125rem;
  outline: none;
  font-size: 1rem;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  cursor: pointer;

  ${({ theme }) => css`
    border: 1px solid ${theme.inputBorderColor};
    background-color: ${theme.inputBackground};
    :hover:enabled {
      border: 1px solid ${theme.inputHoverBorder};
      background-color: ${theme.inputHoverBg};
    }

    :active {
      border: 1px solid ${theme.inputActiveBorder};
    }

    :focus {
      border: 1px solid ${theme.inputActiveBorder};
    }

    :disabled {
      border: 1px solid ${theme.lightGrey};
    }

    :disabled::placeholder {
      color: ${theme.lightGrey};
    }
  `}
`;

export const StyledLabel = styled.label<{
  width?: string;
  isLabelBold?: boolean;
  isDisabled: boolean;
  isSlim?: boolean;
}>`
  display: block;
  width: ${({ width }) => (width ? width : '100%')};
  ${({ isSlim }) => !isSlim && 'margin-bottom: 1.25rem;'}
  outline: none;

  span {
    display: block;
    padding-bottom: 0.5rem;
    ${({ isLabelBold }) => isLabelBold && 'font-weight: 600;'}
    ${({ isDisabled, theme }) => isDisabled && `color: ${theme.lightGrey}`}
  }

  p {
    color: ${({ theme }) => theme.formErrorColor};
    font-weight: 200;
  }
`;

const PrefixContainer = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 1rem;
    margin-right: 0.5rem;
    padding: 0;
  }
`;

const CustomParaghtaph = styled.p`
  font-weight: 400 !important;
`;

export interface TextInputProps {
  type: 'text' | 'email' | 'password' | 'number';
  placeholder: string;
  label?: string;
  register: UseFormRegisterReturn;
  error?: FieldError;
  width?: string;
  isLabelBold?: boolean;
  isDisabled?: boolean;
  isSlim?: boolean;
  prefix?: string;
}

export const TextInput: FC<TextInputProps> = ({
  type,
  placeholder,
  label,
  // name,
  register,
  error,
  width,
  isLabelBold,
  isDisabled = false,
  isSlim,
  prefix,
}) => {
  return (
    <StyledLabel width={width} isLabelBold={isLabelBold} isDisabled={isDisabled} isSlim={isSlim}>
      {label && <span>{label}</span>}
      <PrefixContainer>
        {prefix ? <span>{prefix}</span> : <></>}
        <StyledInput type={type} placeholder={placeholder} {...register} disabled={isDisabled} />
      </PrefixContainer>
      {error && <CustomParaghtaph>{error.message}</CustomParaghtaph>}
    </StyledLabel>
  );
};
