import { FC, useEffect } from 'react';
import styled from 'styled-components';
import {
  ControlledSelectInput,
  ControlledDatePicker,
  ClassicButton,
  VARIANT,
  TextInput,
} from 'components';
import { FieldValues, useForm } from 'react-hook-form';
import { Identifier, IdentifierType, SelectOptionType } from 'utils/types';
import { ContentCell, TableCell } from './styled';
import { formatDateForInput } from 'utils/dateFormating';

const IdentifierFormContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1rem;
  background-color: ${({ theme }) => theme.comboFormBgColor};
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const IdentifierFormRow = styled.form`
  width: 100%;
  display: flex;
  margin-bottom: 1.5rem;

  *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

interface IdentifierFormProps {
  identifierTypesList: Array<IdentifierType>;
  onCancel: () => void;
  submitIdentifier?: (identifier: Identifier) => void;
  identifier?: Identifier;
}

export const IdentifierForm: FC<IdentifierFormProps> = ({
  identifierTypesList,
  onCancel,
  submitIdentifier,
  identifier,
}) => {
  const currentDate = new Date();
  const {
    register,
    control,
    watch,
    getValues,
    reset,
    formState: { isValid, isDirty },
  } = useForm({
    mode: 'all',
    defaultValues: {
      type: { id: 0, scheme: '' } as IdentifierType,
      identifier: '',
      validFrom: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
      validTo: new Date('2099-09-30'),
    } as FieldValues,
  });

  useEffect(() => {
    if (identifier) {
      reset({
        type: identifier.type,
        identifier: identifier.identifier,
        validFrom: new Date(identifier.validFrom),
        validTo: new Date(identifier.validTo),
      });
    }
  }, [identifier]);

  const identifierScheme = watch('type')?.scheme || '';

  const onSubmit = () => {
    const { type, identifier, validFrom, validTo } = getValues();
    if (submitIdentifier && type && identifier && validFrom && validTo) {
      submitIdentifier({
        type,
        identifier,
        validFrom: formatDateForInput(validFrom),
        validTo: formatDateForInput(validTo),
      });
    }
  };

  return (
    <IdentifierFormContainer>
      <IdentifierFormRow>
        <ControlledSelectInput
          control={control}
          name="type"
          placeholder="Select Identifier"
          options={identifierTypesList as unknown as Array<SelectOptionType>}
          width="100%"
        />
        <ContentCell>{identifierScheme}</ContentCell>
        <TextInput
          register={register('identifier', { required: true })}
          placeholder="Type in identifier"
          type="text"
          isSlim
        />
        <ControlledDatePicker control={control} name="validFrom" isSlim withFrame />
        <ControlledDatePicker control={control} name="validTo" isSlim withFrame />
        <TableCell width="2rem" />
      </IdentifierFormRow>
      <ButtonRow>
        <ClassicButton
          width="10rem"
          variant={VARIANT.SECONDARY}
          onClick={onCancel}
          margin="0 2rem 0 0"
        >
          Cancel
        </ClassicButton>
        <ClassicButton
          width="10rem"
          variant={VARIANT.PRIMARY}
          onClick={onSubmit}
          disabled={!identifierScheme.length || !isDirty || !isValid}
        >
          Submit
        </ClassicButton>
        <TableCell width="2.5rem" />
      </ButtonRow>
    </IdentifierFormContainer>
  );
};
