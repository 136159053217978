import { useQuery, useQueryClient, useMutation } from 'react-query';
import { HOST_NAME } from 'utils/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { unselecteAll } from './messagesSlice';
import { setError } from '../../../features/ErrorScreen/errorSlice';
import { formatDateForInput } from 'utils/dateFormating';
import { SortType } from 'utils/types/generalTypes';
import { API, useApiGet, createDefaultHeaders } from 'utils/hooks';
import { Messages } from 'features/Messages/messageApi';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { closeModal } from '../../../store/modalSlice';
import { pushNotification } from '../../../features/NotificationPopup/notificationSlice';
import { DataFile } from './components/FileUploadModal';

export const useMessages = (
  selectedDate: Date,
  activeSort: SortType,
  searchKeyword: string,
  filters: string,
) => {
  const { sortType, selectedPage, amountOutItemsPerPage } = useAppSelector(
    (state) => state.messageAckReducer,
  );
  const formatedStartDate = formatDateForInput(selectedDate);
  const sortTypeNumber = sortType === 'creationDate' ? 'SENT' : 'GAS';
  const fetchMessages = useApiGet(
    `/message?size=${amountOutItemsPerPage}&page=${
      selectedPage - 1
    }&startDate=${formatedStartDate}&endDate=${formatedStartDate}&type=${sortTypeNumber}${filters}${
      activeSort.name !== '' && activeSort.direction !== 'none'
        ? `&sort=${activeSort.name},${activeSort.direction}`
        : ''
    }${searchKeyword.length > 0 ? `&search=${searchKeyword}` : ''}`,
  );
  return useQuery<Messages, Error>(
    [
      'messagesAck',
      selectedDate,
      activeSort,
      searchKeyword,
      sortType,
      filters,
      selectedPage,
      amountOutItemsPerPage,
    ],
    fetchMessages,
    { keepPreviousData: true, refetchInterval: 30000 },
  );
};

export const useSendMessages = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const { selected } = useAppSelector((state) => state.messageAckReducer);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  return useMutation(
    () => {
      if (token.length && selected.length > 0) {
        selected.forEach((sendMessageId) => {
          return fetch(`${HOST_NAME()}/sender/send/${sendMessageId.id}`, {
            method: 'GET',
            headers: new Headers({
              Authorization: token,
              ...createDefaultHeaders(),
            }),
          });
        });
      }
      dispatch(unselecteAll());
      queryClient.invalidateQueries('messages');
      return Promise.reject();
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('messages');
        dispatch(unselecteAll());
      },
    },
  );
};

export const useImportAcknowMessages = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const { selected } = useAppSelector((state) => state.messageAckReducer);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  return useMutation(
    () => {
      if (token.length && selected.length > 0) {
        selected.forEach((sendMessageId) => {
          return fetch(`${HOST_NAME()}/response/ack/${sendMessageId.id}?executeImport=true`, {
            method: 'GET',
            headers: new Headers({
              Authorization: token,
              ...createDefaultHeaders(),
            }),
          });
        });
      }
      dispatch(unselecteAll());
      queryClient.invalidateQueries('messages');
      return Promise.reject();
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('messages');
        dispatch(unselecteAll());
      },
    },
  );
};

export const useCreateNomresMessages = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const { selected } = useAppSelector((state) => state.messageAckReducer);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  return useMutation(
    (matching: boolean) => {
      if (token.length && selected.length > 0) {
        selected.forEach((sendMessageId) => {
          return fetch(
            `${HOST_NAME()}/response/nomres?sentDocumentIdentifier=${
              sendMessageId.documentIdentifier
            }&sentDocumentVersion=${
              sendMessageId.documentVersion
            }&executeImport=true&matching=${matching.toString()}`,
            {
              method: 'GET',
              headers: new Headers({
                Authorization: token,
                ...createDefaultHeaders(),
              }),
            },
          );
        });
      }
      dispatch(unselecteAll());
      queryClient.invalidateQueries('messages');
      return Promise.reject();
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('messages');
        dispatch(unselecteAll());
      },
    },
  );
};

export const useFakeAcknowledge = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const queryClient = useQueryClient();
  return useMutation(
    (id: number) => {
      if (token.length && id) {
        return fetch(`${HOST_NAME()}/response/ack/${id}?executeImport=false`, {
          method: 'GET',
          headers: new Headers({
            Authorization: token,
            'Content-Type': 'application/json',
            ...createDefaultHeaders(),
          }),
        });
      }
      return Promise.reject();
    },
    {
      onSuccess: async (response) => {
        queryClient.invalidateQueries('messages');

        // This is workaround for fetching xml file directly from backend
        const parsedData = await response.text();
        const blob = new Blob([parsedData], { type: 'text/xml' });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');

        const headers = await response.headers;
        const filename = headers.get('content-disposition')?.split('filename=')[1];

        link.href = blobUrl;
        link.download = `${filename}`;
        // Append link to the body
        document.body.appendChild(link);

        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        );

        // Remove link from body
        document.body.removeChild(link);
      },
    },
  );
};

export const useFakeAlocat = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const queryClient = useQueryClient();
  return useMutation(
    (id: number) => {
      if (token.length && id) {
        return fetch(`${HOST_NAME()}/response/alocat/${id}?executeImport=false`, {
          method: 'GET',
          headers: new Headers({
            Authorization: token,
            'Content-Type': 'application/json',
            ...createDefaultHeaders(),
          }),
        });
      }
      return Promise.reject();
    },
    {
      onSuccess: async (response) => {
        queryClient.invalidateQueries('messages');

        // This is workaround for fetching xml file directly from backend
        const parsedData = await response.text();
        const blob = new Blob([parsedData], { type: 'text/xml' });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');

        const headers = await response.headers;
        const filename = headers.get('content-disposition')?.split('filename=')[1];

        link.href = blobUrl;
        link.download = `${filename}`;
        // Append link to the body
        document.body.appendChild(link);

        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        );

        // Remove link from body
        document.body.removeChild(link);
      },
    },
  );
};

export const useFileUpload = (setError: UseFormSetError<FieldValues>) => {
  const { token } = useAppSelector((state) => state.authReducer);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    (data: DataFile) => {
      const formData = new FormData();
      formData.append('file', data.data[0]);

      if (token.length) {
        return API.UPLOAD_FILE('/response/upload', {
          body: formData,
          headers: {
            Authorization: token,
          },
        });
      }
      return Promise.reject();
    },

    {
      onError: (error: Error) => {
        setError('masterData', { message: error.message });
      },
      onSuccess: async () => {
        dispatch(closeModal({ modalId: 'fileUploadModal' }));
        dispatch(
          pushNotification({
            type: 'ACCEPTED',
            copy: 'File uploaded successfully!',
          }),
        );
        queryClient.invalidateQueries('messages');
      },
    },
  );
};

interface ReponseMessage {
  documentVersion: string;
  documentIdentifier: string;
  matching: boolean;
}

export const useFakeReponse = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const queryClient = useQueryClient();
  return useMutation(
    (responseMessage: ReponseMessage) => {
      if (token.length && responseMessage) {
        return fetch(
          `${HOST_NAME()}/response/nomres?sentDocumentIdentifier=${
            responseMessage.documentIdentifier
          }&sentDocumentVersion=${responseMessage.documentVersion}&executeImport=false&matching=${
            responseMessage.matching
          }`,
          {
            method: 'GET',
            headers: new Headers({
              Authorization: token,
              Accept: 'application/json',
              ...createDefaultHeaders(),
            }),
          },
        );
      }
      return Promise.reject();
    },
    {
      onSuccess: async (response) => {
        queryClient.invalidateQueries('messages');

        // This is workaround for fetching xml file directly from backend
        const parsedData = await response.text();
        const blob = new Blob([parsedData], { type: 'text/xml' });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');

        const headers = await response.headers;
        const filename = headers.get('content-disposition')?.split('filename=')[1];

        link.href = blobUrl;
        link.download = `${filename}`;
        // Append link to the body
        document.body.appendChild(link);

        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        );

        // Remove link from body
        document.body.removeChild(link);
      },
    },
  );
};

interface UseXMLDocumentProps {
  id: number;
  direction: string;
  name: string;
}
export const useXmlDocument = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  return useMutation(
    ({ id, direction }: UseXMLDocumentProps) => {
      if (token.length && id && direction) {
        return API.GET(`/message/${id}/xml`, {
          headers: {
            Authorization: token,
          },
        });
      }
      return Promise.reject();
    },
    {
      onError: (error: Error) => {
        dispatch(setError({ errorMessage: error.toString() }));
      },
      onSuccess: async (data, { name }) => {
        // This is workaround for fetching xml file directly from backend
        const parsedData = await data.text();
        const blob = new Blob([parsedData], { type: 'text/xml' });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.href = blobUrl;
        link.download = `${name}`;
        // Append link to the body
        document.body.appendChild(link);

        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        );

        // Remove link from body
        document.body.removeChild(link);
      },
    },
  );
};
