import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { NavigationItemProps } from './types';
import { BaseButton } from 'components/Buttons/ClassicButton';
import { MenuArrow } from 'assets/icons';

export const StyledNav = styled.nav<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 5.5rem;
  min-width: 5.5rem;
  transition: 0.2s;
  z-index: 10;
  height: calc(100vh - 3.5rem);

  ${({ theme }) => css`
    background-color: ${theme.white};
    box-shadow: ${theme.navigationShadow};
  `}

  padding: 2.5rem 0 1.5rem 0;
  position: relative;
  justify-content: space-between;
  ${({ isVisible }) =>
    isVisible &&
    css`
      min-width: 14.375rem;
      max-width: 14.375rem;
      margin-left: 0;
    `}
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  top: 1.25rem;
  right: 3.125rem;
  ::after,
  ::before {
    position: absolute;
    left: 0.9375rem;
    content: ' ';
    height: 2.0625rem;
    width: 0.125rem;
    background-color: ${({ theme }) => theme.white};
    opacity: 0.4;
  }

  ::after {
    transform: rotate(-45deg);
  }

  ::before {
    transform: rotate(45deg);
  }
`;

export const NavigationItem = styled(Link)<NavigationItemProps>`
  ${({ theme, $isMenuExpanded, $isSelected }) => css`
    width: 100%;
    color: ${theme.darkGrey};
    display: flex;
    align-items: center;
    justify-content: ${$isMenuExpanded ? 'flex-start' : 'center'};
    min-height: 3.5rem;
    max-height: 4.3125rem;
    text-decoration: none;
    padding: 0 1.5625rem;

    & svg {
      min-width: 2rem;
      path {
        fill: ${theme.darkGrey};
      }
    }

    ${$isSelected &&
    css`
      background-color: ${theme.milkGrey};
      color: ${theme.primary};
      & svg {
        path {
          fill: ${theme.primary};
        }
      }
    `}

    &:hover {
      background-color: ${theme.milkGrey};
    }
  `}
`;

export const ExpandedMenuItemLabel = styled.span<{ isVisible: boolean }>`
  width: ${({ isVisible }) => (isVisible ? 'auto' : '0')};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  margin-left: ${({ isVisible }) => (isVisible ? '1.25rem' : '0')};
  margin-left: ${({ isVisible }) => (isVisible ? '1.25rem' : '0')};
  min-width: ${({ isVisible }) => (isVisible ? '8.75rem' : '0')};
`;

export const DesktopNavCloseButton = styled(BaseButton)`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.lightGrey};
  top: 0.5rem;
  right: -0.75rem;
`;

export const StyledMenuOpenArrow = styled(MenuArrow)`
  ${({ theme }) => css`
    path {
      fill: ${theme.white};
    }
    :hover {
      path {
        fill: ${theme.baseBlack};
      }
    }
  `}
`;

export const StyledMenuCloseArrow = styled(MenuArrow)`
  transform: rotate(180deg);
  path {
    fill: ${({ theme }) => theme.baseBlack};
  }
  :hover {
    path {
      fill: ${({ theme }) => theme.darkGrey};
    }
  }
`;
