import styled from 'styled-components';

export const TopControlRowContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.dividerLineColor};
  justify-content: space-between;
  padding-bottom: 1.5rem;
`;
