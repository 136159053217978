import { FC, useEffect, useState } from 'react';
import {
  Modal,
  ClassicButton,
  VARIANT,
  ControlledDatePicker,
  FieldContainerWithLabel,
  ButtonWithLoader,
  InputError,
} from 'components';
import styled from 'styled-components';
import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/modalSlice';
import { useCopyFromTo, useCopyToPossibleDate } from '../../nominationsApi';
import { FieldValues, useForm } from 'react-hook-form';
import { MessageDefinitionValue } from '../../nominationsApi';
import { MessageDefinitionPicker } from './MessageDefinitionPicker';
import { addDays } from 'date-fns';

const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 1.5625rem;
`;

const Flex = styled.div`
  flex: 1;
`;

const StyledErrorLabelContainer = styled.div`
  margin-bottom: 1rem;
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  height: calc(100% - 4rem);
`;

const ButtonsConainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: space-between;
`;

const DatePickerRangeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export interface MessagesState {
  selectedMessages: Array<MessageDefinitionValue>;
  notSelectedMessages: Array<MessageDefinitionValue>;
}

interface CopyModalProps {
  currentDate: Date;
}

const externalContentStyles = {
  height: '63rem',
  width: '35rem',
  padding: '0',
};

const CopyModalHeading = (
  <StyledModalHeader>Copy nomination data to time period:</StyledModalHeader>
);

export const CopyToModal: FC<CopyModalProps> = ({ currentDate }) => {
  const [messages, setMessages] = useState<MessagesState>({
    selectedMessages: [],
    notSelectedMessages: [],
  });
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const closeFileUploadModal = () => dispatch(closeModal({ modalId: 'copyToModal' }));
  const nextDayDate = addDays(currentDate, 1);
  const { control, getValues, watch, reset } = useForm({
    defaultValues: {
      source: new Date(currentDate),
      from: new Date(nextDayDate),
      to: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
    } as FieldValues,
  });
  const { mutate: copy, isLoading: isCopying } = useCopyFromTo();

  const fromDate = watch('from');
  const { data: copyToProperties } = useCopyToPossibleDate(fromDate);

  useEffect(() => {
    reset({
      ...getValues(),
      source: new Date(currentDate),
      from: new Date(nextDayDate),
    });
  }, [currentDate]);

  useEffect(() => {
    if (copyToProperties && copyToProperties.toDate) {
      reset({
        ...getValues(),
        to: new Date(copyToProperties.toDate),
      });
    }
  }, [copyToProperties?.toDate]);

  const copyFromTo = () => {
    const { source, from, to } = getValues();
    const { selectedMessages } = messages;
    copy({
      sourceDate: source,
      firstDestinationDate: from,
      lastDestinationDate: to,
      listOfMessageDefinitions: selectedMessages,
    });
  };
  const { source, from, to } = watch();

  useEffect(() => {
    const sourceDate = new Date(source);
    const fromDate = new Date(from);
    const toDate = new Date(to);

    if (sourceDate.getTime() >= fromDate.getTime()) {
      setError('The time period must start after the day you copy from.');
    } else if (fromDate.getTime() > toDate.getTime()) {
      setError(
        'I wish, I could turn back time too. For this to work, please set the Enddate after the Startdate though.',
      );
    } else {
      if (error !== null) setError(null);
    }
  }, [source, from, to]);

  return (
    <Modal
      modalId="copyToModal"
      externalContentStyles={externalContentStyles}
      headerContentComponent={CopyModalHeading}
    >
      <ModalContentContainer>
        <Flex>
          <FieldContainerWithLabel>
            <p>Copy values from gas day</p>
            <ControlledDatePicker control={control} name="source" />
          </FieldContainerWithLabel>
          <FieldContainerWithLabel>
            <p>Paste to all days between</p>
            <DatePickerRangeContainer>
              <ControlledDatePicker control={control} name="from" />
              <ControlledDatePicker control={control} name="to" />
            </DatePickerRangeContainer>
          </FieldContainerWithLabel>
          {error && (
            <StyledErrorLabelContainer>
              <InputError>{error}</InputError>
            </StyledErrorLabelContainer>
          )}
          <FieldContainerWithLabel>
            <p>Copy data for the following messages</p>
            <MessageDefinitionPicker
              selectedDate={source}
              messages={messages}
              setMessages={(messages: MessagesState) => setMessages(messages)}
            />
          </FieldContainerWithLabel>
        </Flex>
        <ButtonsConainer>
          <ClassicButton
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={closeFileUploadModal}
            disabled={isCopying}
          >
            Cancel
          </ClassicButton>
          <ButtonWithLoader
            isLoading={isCopying}
            width="10rem"
            onClick={copyFromTo}
            disabled={error !== null}
          >
            Copy
          </ButtonWithLoader>
        </ButtonsConainer>
      </ModalContentContainer>
    </Modal>
  );
};
