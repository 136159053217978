import { FC } from 'react';
import styled, { css } from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Triangle, CalendarIcon } from 'assets/icons';
import { Control, Controller } from 'react-hook-form';

const DatePickerContainer = styled.div<{ isSlim?: boolean; withFrame?: boolean; width?: string }>`
  display: flex;
  position: relative;
  width: ${({ withFrame }) => (withFrame ? '10rem' : '15rem')};

  input {
    position: relative;
    width: 10rem;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    outline: none;
    padding-left: 1rem;

    ${({ isSlim, theme, withFrame, width }) => css`
      ${!isSlim && `border: ${theme.datePickerInputBorder}; font-weight: 600; height: 2.5rem;`}
      ${isSlim &&
      withFrame &&
      `border: 1px solid ${theme.inputBorderColor}; border-radius 0.25rem; height: 2.5rem;`}
      background-color: ${theme.white};
      ${width && `width: ${width};`}
    `}
  }

  .react-datepicker-popper {
    z-index: 3;
  }

  ${({ theme }) => css`
    .react-datepicker {
      background-color: ${theme.datePickerBgColor};
      border-color: ${theme.datePickerHeaderBgColor};
    }

    .react-datepicker__header {
      background-color: ${theme.datePickerHeaderBgColor};
      border: none;
    }

    .react-datepicker__current-month {
      color: ${theme.defaultFontColor};
    }
    .react-datepicker__day-name,
    .react-datepicker__day {
      color: ${theme.defaultFontColor};
    }

    .react-datepicker__navigation--previous {
      border-right-color: ${theme.datePickerNavigationButtonColor};
    }
    .react-datepicker__navigation--next {
      border-left-color: ${theme.datePickerNavigationButtonColor};
    }

    .react-datepicker__triangle::before {
      border-bottom-color: ${theme.datePickerTriangleColor}!important;
    }

    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
      border-bottom-color: ${theme.datePickerTriangleColor}!important;
    }

    .react-datepicker__day--today {
      background-color: ${theme.datePickerSelectedDateBgColor};
      color: ${theme.datePickerSelectedDateTextColor};
    }

    .react-datepicker__day:hover {
      background-color: ${theme.datePickerHeaderBgColor};
    }
  `}
`;

const DateChangeButtonPrev = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  outline: none;
  background: none;
  background-color: ${({ theme }) => theme.datePickerButtonBgColor};
  cursor: pointer;
  border-radius: 0.25rem 0px 0px 0.25rem;
  border: ${({ theme }) => theme.datePickerButtonBorder};
  border-right: none;
  svg {
    path {
      fill: ${({ theme }) => theme.datePickerSelectedDateTextColor};
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.datePickerButtonBgColorHover};
    svg {
      path {
        fill: ${({ theme }) => theme.white};
      }
    }
  }
`;

const DateChangeButtonNext = styled(DateChangeButtonPrev)`
  transform: rotate(180deg);
`;

const Arrow = styled(Triangle)`
  width: 0.5625rem;
`;

const StyledIconContainer = styled.div<{ withFrame?: boolean }>`
  position: absolute;
  cursor: pointer;
  width: 1.5rem;

  ${({ withFrame }) =>
    withFrame ? 'right: 0.5rem; top: 0.4375rem;' : 'right: 3.125rem; top: 0.4375rem;'}

  svg {
    path {
      fill: ${({ theme }) => theme.primary};
    }
  }
`;

interface DatePickerProps {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  isSlim?: boolean;
  withFrame?: boolean;
  width?: string;
  timeOnly?: boolean;
}

export const StyledDatePicker: FC<DatePickerProps> = ({
  selectedDate,
  setSelectedDate,
  isSlim,
  withFrame = false,
  width,
  timeOnly = false,
}) => {
  const changeDate = (direction = 'future') => {
    if (direction === 'past') {
      setSelectedDate(new Date(selectedDate.getTime() - 1000 * 60 * 60 * 24));
    } else {
      setSelectedDate(new Date(selectedDate.getTime() + 1000 * 60 * 60 * 24));
    }
  };

  const additionalProps = {
    ...(timeOnly && {
      timeIntervals: 1,
      showTimeSelect: true,
      showTimeSelectOnly: true,
      dateFormat: 'HH:mm',
      timeCaption: '',
      timeFormat: 'HH:mm',
    }),
  };
  return isSlim ? (
    <DatePickerContainer isSlim={isSlim} withFrame={withFrame} width={width}>
      <DatePicker
        selected={selectedDate}
        onChange={(date: Date) => setSelectedDate(date)}
        dateFormat="dd.MM.yyyy"
        {...additionalProps}
      />
      {withFrame && !timeOnly && (
        <StyledIconContainer withFrame>
          <CalendarIcon />
        </StyledIconContainer>
      )}
    </DatePickerContainer>
  ) : (
    <DatePickerContainer>
      <DateChangeButtonPrev type="button" onClick={() => changeDate('past')}>
        <Arrow />
      </DateChangeButtonPrev>
      <label>
        <DatePicker
          selected={selectedDate}
          onChange={(date: Date) => setSelectedDate(date)}
          dateFormat="dd.MM.yyyy"
        />
        <StyledIconContainer>
          <CalendarIcon />
        </StyledIconContainer>
      </label>
      <DateChangeButtonNext type="button" onClick={() => changeDate()}>
        <Arrow />
      </DateChangeButtonNext>
    </DatePickerContainer>
  );
};

interface ControlledDatePickerProps {
  isSlim?: boolean;
  control: Control;
  name: string;
  required?: boolean;
  withFrame?: boolean;
  width?: string;
  timeOnly?: boolean;
}
export const ControlledDatePicker: FC<ControlledDatePickerProps> = ({
  isSlim,
  control,
  name,
  required,
  width,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      defaultValue={() => {
        const date = new Date();
        date.setDate(date.getDate() + 2);
        return date;
      }}
      render={({ field: { onChange, value } }) => (
        <StyledDatePicker
          isSlim={isSlim}
          selectedDate={value}
          setSelectedDate={(date: Date) => onChange(date)}
          width={width}
          {...rest}
        />
      )}
    />
  );
};
