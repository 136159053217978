import {
  BookWithQuestionMark,
  Failure,
  Nominated,
  Pending,
  Success,
  SystemMasterdataIcon,
  UserDefinedMasterdataIcon,
  MessageCreated,
  SavedIcon,
  NominatedAcknowledged,
  AcknowError,
  TransmissionFailed,
  MappingError,
  MatchedIcon,
} from 'assets/icons';
import { BaseButton } from './Buttons/ClassicButton';
import { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { useClickOutside } from 'utils/hooks';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
`;

const StyledHeading = styled.h3`
  font-weight: 600;
  font-size: 1.5rem;
`;

const StyledSectionHeading = styled.h4`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`;

const SectionPosition = styled.p`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  svg {
    margin-right: 0.5rem;
    height: 1.25rem;
  }

  span {
    margin-right: 0.5rem;
  }
`;

const PreviewLegendButton = styled(BaseButton)<{ isSelected: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme, isSelected }) => css`
    :hover {
      background-color: ${theme.white};
      svg {
        path {
          fill: ${theme.primary};
        }
      }
    }

    ${isSelected &&
    css`
      background-color: ${theme.white};
      svg {
        path {
          fill: ${theme.primary};
        }
      }
    `}
  `}
`;

const LegendContainer = styled.div`
  width: 19.6rem;
  height: 42rem;
  position: absolute;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 0 0.75rem rgba(144, 164, 174, 0.4);
  border-radius: 0.25rem;
  padding: 1.5rem;
  top: -42rem;
  right: -17.5rem;
`;

interface LegendSectionProps {
  sectionName: string;
  sectionOptions: Array<{ icon: JSX.Element; name: string }>;
}
const LegendSection: FC<LegendSectionProps> = ({ sectionName, sectionOptions }) => (
  <>
    <StyledSectionHeading>{sectionName}</StyledSectionHeading>
    {sectionOptions.map((option) => (
      <SectionPosition key={option.name}>
        {option.icon} <span>{option.name}</span>
      </SectionPosition>
    ))}
  </>
);

const statusOptions = [
  { name: 'Unsent', icon: <MessageCreated /> },
  { name: 'Saved', icon: <SavedIcon /> },
  { name: 'Pending', icon: <Pending /> },
  { name: 'Sent', icon: <Nominated /> },
  { name: 'Acknowledged', icon: <NominatedAcknowledged /> },
  { name: 'Acknowledged with error', icon: <AcknowError /> },
  { name: 'Mismatched', icon: <Failure /> },
  { name: 'Partial mismatch', icon: <Failure /> },
  { name: 'Nominated', icon: <MatchedIcon /> },
  { name: 'Success', icon: <Success /> },
  { name: 'Failure', icon: <TransmissionFailed /> },
  { name: 'Received', icon: <Success /> },
  {
    name: 'Received and processed with error',
    icon: <MappingError />,
  },
];

const addedByOptions = [
  {
    name: 'created during incorporation',
    icon: <SystemMasterdataIcon />,
  },
  {
    name: 'added by user',
    icon: <UserDefinedMasterdataIcon />,
  },
];

const nominationShortcutsOptions = [
  {
    name: 'Fill right',
    icon: <span>CTRL + ALT + Arrow Right </span>,
  },
  {
    name: 'Fill left',
    icon: <span>CTRL + ALT + Arrow Left </span>,
  },
  {
    name: 'Escape cell',
    icon: <span>ESC </span>,
  },
];

export const LegendPeek: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useClickOutside(() => setIsOpen(false));
  return (
    <ButtonContainer ref={ref}>
      <PreviewLegendButton onClick={() => setIsOpen(!isOpen)} isSelected={isOpen}>
        <BookWithQuestionMark />
      </PreviewLegendButton>
      {isOpen && (
        <LegendContainer>
          <StyledHeading>Legend</StyledHeading>
          <LegendSection sectionName="Status" sectionOptions={statusOptions} />
          <LegendSection sectionName="Added by" sectionOptions={addedByOptions} />
          <LegendSection
            sectionName="Nomination sceen shortcuts"
            sectionOptions={nominationShortcutsOptions}
          />
        </LegendContainer>
      )}
    </ButtonContainer>
  );
};
