import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { SearchInput } from 'components';
import styled from 'styled-components';

const SearchForm = styled.form`
  flex: 1;
`;

interface OptionsSearchProps {
  setSearchKeyword: (keyword: string) => void;
}

export const OptionsSearch: FC<OptionsSearchProps> = ({ setSearchKeyword }) => {
  const { register, watch, reset, getValues } = useForm({
    defaultValues: { optionsSearchKeyword: '' },
  });
  const optionsSearchKeyword = watch('optionsSearchKeyword');

  const handleUpdate = useCallback(() => {
    const { optionsSearchKeyword } = getValues();
    setSearchKeyword(optionsSearchKeyword);
  }, [optionsSearchKeyword]);

  const resetSearch = () => {
    reset();
  };
  return (
    <SearchForm>
      <SearchInput
        register={register('optionsSearchKeyword')}
        placeholder="Search"
        handleUpdate={handleUpdate}
        value={optionsSearchKeyword}
        reset={resetSearch}
      />
    </SearchForm>
  );
};
