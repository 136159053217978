import styled, { css } from 'styled-components';

export const ComboFilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const FiltersContainer = styled.div<{ isExpanded: boolean }>`
  height: 2.5rem;
  display: flex;
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  border-radius: 0.25rem;
  margin-right: 0.5rem;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      flex: 1;

      & > div {
        border-left: 1px solid ${({ theme }) => theme.inputBorderColor};
      }
    `}
`;
