import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserTimezoneSettings } from 'features/SettingsModal/settingsApi';
import { TimeZone } from 'timezones-list';

interface GlobalState {
  selectedDate: string;
  timezone: UserTimezoneSettings | null;
}
// As date is not serializable we are using string as univeral format
const initialState = {
  selectedDate: new Date().toString(),
  timezone: null,
} as GlobalState;

const globalSlice = createSlice({
  name: 'globalState',
  initialState,
  reducers: {
    setDate(state, action: PayloadAction<{ date: string }>) {
      state.selectedDate = action.payload.date;
    },
    setTimezone(state, action: PayloadAction<{ timezone: UserTimezoneSettings }>) {
      state.timezone = action.payload.timezone;
    },
  },
});

export const { setDate, setTimezone } = globalSlice.actions;

export default globalSlice.reducer;
