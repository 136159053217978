import { FC } from 'react';
import styled from 'styled-components';
import { Identifier } from 'utils/types';
import { PreviewBoxComponent } from './PreviewBox';

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0.625rem;
`;

const TableHeadingCell = styled.th`
  background-color: ${({ theme }) => theme.tableHeadingBg};
  height: 2.5rem;
  padding: 0 1rem;
`;

const TableCell = styled.td`
  height: 2.5rem;
  padding: 0 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
  color: ${({ theme }) => theme.darkGrey};
`;

interface IdentifierPreviewBoxProps {
  identifiers: Array<Identifier>;
}

export const IdentifierPreviewBox: FC<IdentifierPreviewBoxProps> = ({ identifiers }) => {
  return (
    <PreviewBoxComponent amountOfElements={identifiers.length}>
      <StyledTable>
        <thead>
          <tr>
            <TableHeadingCell>Name</TableHeadingCell>
            <TableHeadingCell>Coding schema</TableHeadingCell>
            <TableHeadingCell>Identifier</TableHeadingCell>
          </tr>
        </thead>
        <tbody>
          {identifiers.map(({ identifier, type }) => (
            <tr key={identifier}>
              <TableCell>{type.name}</TableCell>
              <TableCell>{type.scheme}</TableCell>
              <TableCell>{identifier}</TableCell>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </PreviewBoxComponent>
  );
};
