import { FC } from 'react';
import { ClassicButton, TopControlRowContainer, VARIANT } from 'components';
import { useHistory } from 'react-router';
import { useAppSelector } from 'store/hooks';

export const TopControlRow: FC = () => {
  const history = useHistory();
  const { authorities } = useAppSelector((state) => state.authReducer);
  const isAdmin = authorities.includes('ADMIN') || authorities.includes('SYSADMIN');

  return (
    <TopControlRowContainer>
      <ClassicButton
        width="14.375rem"
        variant={VARIANT.PRIMARY}
        onClick={() => history.push('/app/masterdata/counterparty/create')}
        disabled={!isAdmin}
      >
        Add new counterparty
      </ClassicButton>
    </TopControlRowContainer>
  );
};
