import { FC } from 'react';
import { Modal } from 'components';
import styled from 'styled-components';
import { useAppSelector } from 'store/hooks';
import { VersionTable } from './VersionTable';

const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 1.5625rem;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.875rem;

  strong {
    display: inline-block;
    margin-right: 0.5rem;
  }
`;

interface VersionModalProps {
  selectedDate: Date;
}

const externalContentStyles = {
  height: 'auto',
  width: '90%',
  padding: '0',
};

export const VersionModal: FC<VersionModalProps> = ({ selectedDate }) => {
  const { props } = useAppSelector((state) => state.modalReducer['versionModal']);
  const VersionModalHeader = (
    <StyledModalHeader>
      <HeadingContainer>
        <strong>History for: </strong>
        {props?.balanceGroupName}
      </HeadingContainer>
      <HeadingContainer>
        <strong>Connection point: </strong>
        {props?.connectionPointName}
      </HeadingContainer>
    </StyledModalHeader>
  );
  return (
    <Modal
      modalId="versionModal"
      externalContentStyles={externalContentStyles}
      headerContentComponent={VersionModalHeader}
    >
      <VersionTable selectedDate={selectedDate} nominationTrackId={props?.nominationTrackId} />
    </Modal>
  );
};
