import { FC } from 'react';
import { ButtonWithLoader, Modal } from 'components';
import styled from 'styled-components';
import { ClassicButton, VARIANT } from 'components/Buttons/ClassicButton';
import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/modalSlice';
import { useSelectedNominationSubmit } from '../nominationsApi';

const SubmitModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 3.125rem;
`;

const ModalHeading = styled.h2`
  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const ModalParagraph = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 35rem;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const externalContentStyles = {
  height: '22.75rem',
  width: '45rem',
  padding: '0',
};

interface SubmitModalProps {
  selectedDate: Date;
  numberOfDays: number;
}

export const SubmitModal: FC<SubmitModalProps> = ({ selectedDate, numberOfDays }) => {
  const dispatch = useAppDispatch();
  const { mutate: selectedNominationSubmit, isLoading } = useSelectedNominationSubmit();

  return (
    <Modal modalId="submitModal" externalContentStyles={externalContentStyles}>
      <SubmitModalContainer>
        <ModalHeading>Confirmation</ModalHeading>
        <ModalParagraph>
          Do you want to submit all selected messages or only those messages, which must be
          (re)nominated?
        </ModalParagraph>
        <ButtonContainer>
          <ClassicButton
            onClick={() => dispatch(closeModal({ modalId: 'submitModal' }))}
            width="10rem"
            variant={VARIANT.SECONDARY}
            disabled={isLoading}
          >
            Cancel
          </ClassicButton>
          <ButtonWithLoader
            isLoading={isLoading}
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={() => selectedNominationSubmit({ selectedDate, numberOfDays, sendAll: true })}
          >
            Submit
          </ButtonWithLoader>
          <ButtonWithLoader
            isLoading={isLoading}
            width="12rem"
            variant={VARIANT.PRIMARY}
            onClick={() => selectedNominationSubmit({ selectedDate, numberOfDays, sendAll: false })}
          >
            Submit (re)nominations
          </ButtonWithLoader>
        </ButtonContainer>
      </SubmitModalContainer>
    </Modal>
  );
};
