import { FC } from 'react';
import styled from 'styled-components';
import { BalanceGroupRow } from './BalanceGroupRow';
import { BalanceGroup } from 'utils/types';

const BalanceGroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-left: 2.5rem;
`;

interface BalanceGroupsProps {
  connectionPointSelected: boolean;
  balanceGroups: Array<BalanceGroup>;
  connectionId: number;
  areaId: number;
  connectionPointName: string;
  connectionPointIndetifier: string;
}

export const BalanceGroups: FC<BalanceGroupsProps> = ({
  balanceGroups,
  connectionId,
  areaId,
  connectionPointSelected,
  connectionPointName,
  connectionPointIndetifier,
}) => {
  return (
    <BalanceGroupsContainer>
      {balanceGroups.map((balanceGroup, key) => {
        return (
          <BalanceGroupRow
            key={`${balanceGroup.destinationName}_${key}_${balanceGroup.destinationId}_${balanceGroup.sourceId}`}
            balanceGroup={balanceGroup}
            connectionId={connectionId}
            areaId={areaId}
            connectionPointSelected={connectionPointSelected}
            connectionPointName={connectionPointName}
            connectionPointIndetifier={connectionPointIndetifier}
          />
        );
      })}
    </BalanceGroupsContainer>
  );
};
