import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { SearchInput } from 'components';
import styled from 'styled-components';

const SearchForm = styled.form`
  max-width: 18.75rem;
  width: 100%;
`;

interface AreasSearchProps {
  setSearchKeyword: (keyword: string) => void;
}

export const AreasSearch: FC<AreasSearchProps> = ({ setSearchKeyword }) => {
  const { register, watch, reset, getValues } = useForm({
    defaultValues: { areasSearchKeyword: '' },
  });
  const areasSearchKeyword = watch('areasSearchKeyword');

  const handleUpdate = useCallback(() => {
    const { areasSearchKeyword } = getValues();
    setSearchKeyword(areasSearchKeyword);
  }, [areasSearchKeyword]);

  const resetSearch = () => {
    setSearchKeyword('');
    reset();
  };
  return (
    <SearchForm>
      <SearchInput
        register={register('areasSearchKeyword')}
        placeholder="Search"
        handleUpdate={handleUpdate}
        value={areasSearchKeyword}
        reset={resetSearch}
      />
    </SearchForm>
  );
};
