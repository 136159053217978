import { BaseButton } from 'components/Buttons/ClassicButton';
import { FC, ReactChild, useState } from 'react';
import styled from 'styled-components';
import { Triangle } from 'assets/icons';
import { useClickOutside } from 'utils/hooks';

const MenuContainer = styled.div`
  position: relative;
`;

const DropdownButton = styled(BaseButton)`
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.baseBlack};
  padding: 0 0.625rem;
  border-radius: 0.25rem;

  svg {
    margin-left: 0.3125rem;
    width: 0.5rem;
    transform: rotate(-90deg);
    path {
      fill: ${({ theme }) => theme.baseBlack};
    }
  }
`;

const DropdownContainer = styled.div`
  background-color: ${({ theme }) => theme.mainBackgroundColor};
  padding: 1rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 15.625rem;
  min-height: 1.875rem;
  right: -1.125rem;
  top: 2.375rem;
  z-index: 12;
  box-shadow: 0px 0px 12px rgba(144, 164, 174, 0.4);

  :before {
    content: ' ';
    width: 15.625rem;
    height: 1rem;
    background-color: ${({ theme }) => theme.mainBackgroundColor};
    position: absolute;
    top: -10px;
    left: 0;
  }
`;

const DropdownOption = styled(BaseButton)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.dividerLineColor};
  padding: 1rem 0;
  text-align: left;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    path {
      fill: ${({ theme }) => theme.baseBlack};
    }
  }

  :hover {
    color: ${({ theme }) => theme.primaryButtonBgColor};

    svg {
      path {
        fill: ${({ theme }) => theme.primaryButtonBgColor};
      }
    }
  }
`;

interface Option {
  title: string;
  action: () => void;
  icon?: ReactChild;
}

interface DropdownMenuProps {
  title: string;
  dropdownOptions: Array<Option>;
}

export const DropdownMenu: FC<DropdownMenuProps> = ({ dropdownOptions, title }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useClickOutside(() => setIsExpanded(false));

  const actionWraper = (action: () => void) => () => {
    setIsExpanded(false);
    action();
  };

  return (
    <MenuContainer>
      <DropdownButton onClick={() => setIsExpanded(!isExpanded)}>
        {title}
        <Triangle />
      </DropdownButton>
      {isExpanded && (
        <DropdownContainer ref={ref}>
          {dropdownOptions.map(({ title, action, icon }) => (
            <DropdownOption key={title} onClick={actionWraper(action)}>
              {icon ? icon : <></>}
              {title}
            </DropdownOption>
          ))}
        </DropdownContainer>
      )}
    </MenuContainer>
  );
};
