import { createGlobalStyle } from 'styled-components';
import Lato from './fonts/Lato-Regular.ttf';
import LatoBold from './fonts/Lato-Bold.ttf';
import LatoThin from './fonts/Lato-Thin.ttf';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Lato;
    src: url(${Lato});
    font-weight: 400;
  }
  @font-face {
    font-family: Lato;
    src: url(${LatoBold});
    font-weight: 600;
  }
  @font-face {
    font-family: Lato;
    src: url(${LatoThin});
    font-weight: 200;
  }

  * {
    font-family: Lato;
    
    font-weight: 400;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.defaultFontColor}
  }

  strong {
    font-weight: 600;
  }

  button {
    border: none;
    background-color: #fff;
    outline: none;
  }
  html {
    overflow: hidden;
    font-size: 12px;
    /* Below can be found snippet for adaptive font size for relative sizing */
    /* font-size: 16px;
    @media (max-width: 2000px) {
      font-size: 12px;
    } */
  }

  #root {
    font-size: 12px;
    /* Below can be found snippet for adaptive font size for relative sizing */
    /* font-size: 16px;
    @media (max-width: 2000px) {
      
    } */
  }

  input[type=number] {
    -moz-appearance:textfield;
  }
`;
