import styled from 'styled-components';

export const PageContainer = styled.section`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.white};
  min-width: 0;
  max-height: calc(100vh - 3.5rem);
  overflow: auto;
`;
