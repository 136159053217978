export interface FilterType {
  filterName: string;
  options: string[];
}

export const translateFiltersToPath = (filters: Array<FilterType>): string => {
  let filterPath = '&filters=';
  if (filters.length > 0) {
    filters.forEach((filter) => {
      filter.options.forEach((option, index) => {
        if (index === filter.options.length - 1) {
          filterPath += `${filter.filterName},${option}`;
        } else {
          filterPath += `${filter.filterName},${option},`;
        }
      });
    });
    return filterPath;
  }
  return '';
};
