import { FC, ReactNode } from 'react';
import { Modal } from '../Modal';
import { ClassicButton, VARIANT } from '../Buttons/ClassicButton';
import { useAppDispatch } from 'store/hooks';
import { closeModal, ModalId } from 'store/modalSlice';
import { ButtonWithLoader } from 'components/Buttons/ButtonWithLoader';
import {
  ButtonsContainer,
  ConfirmationPopupContentContainer,
  StyledHeading,
  StyledWarningIcon,
} from './styled';

interface ConfirmationPopupProps {
  confirmationPopupId: ModalId;
  cancelButtonCopy?: string;
  confirmButtonCopy?: string;
  confirmButtonAction: () => void;
  isLoading?: boolean;
  children: ReactNode;
  variant?: 'default' | 'warning';
}

export const ConfirmationPopup: FC<ConfirmationPopupProps> = ({
  confirmationPopupId,
  cancelButtonCopy,
  confirmButtonCopy,
  confirmButtonAction,
  children,
  isLoading = false,
  variant = 'default',
}) => {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeModal({ modalId: confirmationPopupId }));
  };
  return (
    <Modal
      modalId={confirmationPopupId}
      externalContentStyles={{ height: '22.5rem', width: '37.5rem' }}
    >
      <ConfirmationPopupContentContainer>
        <div>
          {'warning' === variant ? (
            <StyledHeading>
              <StyledWarningIcon />
              Warning
            </StyledHeading>
          ) : (
            <StyledHeading>Confirmation</StyledHeading>
          )}
          {children}
        </div>
        <ButtonsContainer>
          <ClassicButton
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={onClose}
            disabled={isLoading}
          >
            {cancelButtonCopy ? cancelButtonCopy : 'Cancel'}
          </ClassicButton>
          <ButtonWithLoader isLoading={isLoading} width="10rem" onClick={confirmButtonAction}>
            {confirmButtonCopy ? confirmButtonCopy : 'Submit'}
          </ButtonWithLoader>
        </ButtonsContainer>
      </ConfirmationPopupContentContainer>
    </Modal>
  );
};
