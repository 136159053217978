import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarketAreaType, EditedNomination } from './types';
import { merge } from 'lodash';
import { searchBalanceGroups } from './utils';
import {
  BalanceGroup,
  ConnectionPointNominationType,
  StatusOptions,
  GasDayHourDefinitionType,
} from 'utils/types';

interface State {
  areas?: Array<MarketAreaType>;
  gasDayHourDefinition?: GasDayHourDefinitionType;
  preChangesAreas?: Array<MarketAreaType>;
  changedData: Array<EditedNomination>;
  isInvalidated: boolean;
  marketAreaSums: Array<MarketAreaSum>;
  zeroFilter: boolean;
  searchKeyword: string;
  filterActive: boolean;
  statusFilter: StatusOptions;
  selectFilters: Array<SelectFilterType>;
}

const initialState = {
  areas: undefined,
  gasDayHourDefinition: undefined,
  preChangesAreas: undefined,
  changedData: [],
  isInvalidated: true,
  marketAreaSums: [],
  zeroFilter: false,
  searchKeyword: '',
  filterActive: false,
  statusFilter: {
    unsent: false,
    saved: false,
    saved_renomination: false,
    pending: false,
    sent: false,
    acknowledged: false,
    acknowledged_error: false,
    mismatched: false,
    mismatched_partial: false,
    nominated: false,
    success: false,
    failure: false,
    received: false,
    received_processed: false,
    received_processed_error: false,
  },
  selectFilters: [],
} as State;

export interface SelectFilterType {
  filterName: string;
  options: string[];
}

interface AreaSelectAction {
  areaId: number;
}

interface ConnectionPointSelectAction {
  areaId: number;
  connectionId: number;
  internalAccountsName: string;
}

interface MarketAreaSum {
  areaId: number;
  connectionPointsSums: Array<ConnectionPointSum>;
}

interface ConnectionPointSum {
  connectionId: number;
  summary: number;
}

interface SetInitialValuesActionType {
  areas?: Array<MarketAreaType>;
  gasDayHourDefinition?: GasDayHourDefinitionType;
}

interface UpdateValuesActionType {
  nominationTrackId: number;
  version: number;
  areaId?: number;
  connectionId?: number;
  destination?: number;
  sourceId?: number;
  timetable: number[];
  connectionPointIndetifier: string;
}

interface SearchActionType {
  searchKeyword: string;
}

interface StatusFilterActionType {
  options: StatusOptions;
}

const nominationSlice = createSlice({
  name: 'nomination',
  initialState,
  reducers: {
    setInitialValues(state, action: PayloadAction<SetInitialValuesActionType>) {
      const { areas, gasDayHourDefinition } = action.payload;

      const filteredData =
        state.areas
          ?.filter((area) => area.isSelected || area.isIndeterminate)
          .map((area) => ({
            areaId: area.id,
            connectionPoints: area.connectionPoints
              .filter((connecitonPoint) => connecitonPoint.isSelected)
              .map((connectionPoint) => ({
                connectionPointId: connectionPoint.id,
                nominationTracks: connectionPoint.balanceGroups.map((balanceGroup) => ({
                  destinationId: balanceGroup.destinationId,
                  sourceId: balanceGroup.sourceId,
                  nominationTrackId: balanceGroup.nominationTrackId,
                })),
              })),
          })) || [];

      if (state.areas?.length && (state.changedData.length > 0 || filteredData?.length > 0)) {
        const mergedAreas = state.areas?.map((area) => {
          const isEdited = state.changedData.find(
            (changedEntry) => changedEntry.areaId === area.id,
          );

          if (isEdited) {
            return area;
          } else {
            const newArea = areas?.find((entry) => entry.id === area.id);
            if (newArea) {
              return newArea;
            }
          }

          return area;
        });
        merge(state.areas, mergedAreas);
      } else {
        const initData = areas?.map((area) => ({
          ...area,
          isSelected: false,
          isIndeterminate: false,
          shouldBeDisplayed: true,
          connectionPoints: area.connectionPoints.map((connectionPoint) => ({
            ...connectionPoint,
            isSelected: false,
            isIndeterminate: false,
            shouldBeDisplayed: true,
            balanceGroups: connectionPoint.balanceGroups.map((balanceGroup) => ({
              ...balanceGroup,
              isSelected: false,
            })),
          })),
        }));
        state.areas = initData;
        state.preChangesAreas = initData;
      }
      state.gasDayHourDefinition = gasDayHourDefinition;
    },
    updateValues(state, action: PayloadAction<UpdateValuesActionType>) {
      const {
        timetable,
        nominationTrackId,
        version,
        destination,
        areaId,
        connectionId,
        sourceId,
        connectionPointIndetifier,
      } = action.payload;
      if (!timetable) return;

      const changedArea = state.areas?.find((area: MarketAreaType) => area.id === areaId);

      const changedConnectionPoint = changedArea?.connectionPoints.find(
        (connectionPoint: ConnectionPointNominationType) =>
          connectionPoint.id === connectionId &&
          connectionPoint.internalAccounts[0].name === connectionPointIndetifier,
      );

      const changedBalanceGroup = changedConnectionPoint?.balanceGroups.find(
        (balanceGroup: BalanceGroup) =>
          balanceGroup.destinationId === destination &&
          balanceGroup.sourceId === sourceId &&
          balanceGroup.nominationTrackId === nominationTrackId,
      );

      if (changedBalanceGroup) {
        changedBalanceGroup.hourlyValues.timetable = timetable;
      }
      // Check if we already changed specific balance group in given area/conntectionpoint relation
      const existingChangedNominations =
        state.changedData?.filter(
          (editedNomination: EditedNomination) =>
            editedNomination.nominationTrackId === nominationTrackId &&
            editedNomination.destination === destination &&
            editedNomination.source === sourceId &&
            editedNomination.connectionPoint === connectionId &&
            editedNomination.areaId === areaId,
        ) || [];

      if (existingChangedNominations?.length > 0) {
        existingChangedNominations[0].version = version;
        existingChangedNominations[0].values.timetable = timetable;
      } else if (state.changedData) {
        state.changedData.push({
          nominationTrackId,
          version,
          areaId,
          connectionPoint: connectionId,
          destination,
          source: sourceId,
          values: {
            timetable,
          },
        });
      } else {
        state.changedData = [
          {
            nominationTrackId,
            version,
            areaId,
            connectionPoint: connectionId,
            destination,
            source: sourceId,
            values: {
              timetable,
            },
          },
        ];
      }
    },
    clearChangedValues(state, action: PayloadAction<{ areaId?: number }>) {
      if (action.payload.areaId) {
        const changedArea = state.areas?.find(
          (area: MarketAreaType) => area.id === action?.payload.areaId,
        );
        const baseArea = state.preChangesAreas?.find(
          (area: MarketAreaType) => area.id === action?.payload.areaId,
        );
        if (changedArea && baseArea) {
          changedArea.connectionPoints = [...baseArea.connectionPoints];
        }

        state.changedData = state.changedData.filter(
          (data) => data.areaId !== action.payload.areaId,
        );
      } else {
        state.changedData = [];
      }
    },
    unselectConnectionPoint(state, action: PayloadAction<ConnectionPointSelectAction>) {
      const { areaId, connectionId, internalAccountsName } = action.payload;
      const choosenArea = state.areas?.find((area) => area.id === areaId);
      const choosenConnectionPoint = choosenArea?.connectionPoints.find(
        (connectionPoint) =>
          connectionPoint.id === connectionId &&
          connectionPoint.internalAccounts[0].name === internalAccountsName,
      );
      if (choosenArea && choosenConnectionPoint) {
        choosenConnectionPoint.isSelected = false;
        choosenArea.isSelected = false;

        const selectedConnectionPoints = choosenArea?.connectionPoints.filter(
          (connectionPoint) => connectionPoint.isSelected,
        );
        if (selectedConnectionPoints.length > 0) {
          choosenArea.isIndeterminate = true;
        } else {
          choosenArea.isIndeterminate = false;
        }
      }
    },
    selectConnectionPoint(state, action: PayloadAction<ConnectionPointSelectAction>) {
      const { areaId, connectionId, internalAccountsName } = action.payload;
      const choosenArea = state.areas?.find((area) => area.id === areaId);
      if (choosenArea) {
        const choosenConnectionPoint = choosenArea.connectionPoints.find(
          (connectionPoint) =>
            connectionPoint.id === connectionId &&
            connectionPoint.internalAccounts[0].name === internalAccountsName,
        );

        if (choosenConnectionPoint) {
          choosenConnectionPoint.isSelected = true;
        }

        const selectedConnectionPoints = choosenArea?.connectionPoints.filter(
          (connectionPoint) => connectionPoint.isSelected,
        );
        if (selectedConnectionPoints?.length === choosenArea.connectionPoints.length) {
          choosenArea.isSelected = true;
          choosenArea.isIndeterminate = false;
        } else if (selectedConnectionPoints?.length > 0) {
          choosenArea.isIndeterminate = true;
        }
      }
    },
    selectMarkerArea(state, action: PayloadAction<AreaSelectAction>) {
      const choosenArea = state.areas?.find((area) => area.id === action.payload.areaId);
      if (choosenArea) {
        choosenArea.isSelected = true;
        choosenArea.isIndeterminate = false;
        choosenArea.connectionPoints.forEach((connectionPoint) => {
          connectionPoint.isSelected = true;
        });
      }
    },
    unselectMarkerArea(state, action: PayloadAction<AreaSelectAction>) {
      const choosenArea = state.areas?.find((area) => area.id === action.payload.areaId);
      if (choosenArea) {
        choosenArea.isSelected = false;
        choosenArea.connectionPoints.forEach((connectionPoint) => {
          connectionPoint.isSelected = false;
        });
      }
    },
    unselectAll(state) {
      state.areas?.forEach((area) => {
        area.isSelected = false;
        area.isIndeterminate = false;
        area.connectionPoints.forEach((connectionPoint) => {
          connectionPoint.isSelected = false;
        });
      });
    },
    selectAll(state) {
      state.areas?.forEach((area) => {
        area.isSelected = true;
        area.connectionPoints.forEach((connectionPoint) => {
          connectionPoint.isSelected = true;
        });
      });
    },
    selectByStatus(state, action: PayloadAction<StatusOptions>) {
      Object.entries(action.payload).forEach(([keyname, value]) => {
        state.areas?.forEach((area) => {
          area.connectionPoints.forEach((connectionPoint) => {
            if (keyname.toLocaleUpperCase() === connectionPoint.messageStatus) {
              connectionPoint.isSelected = value;
            }
          });

          const selectedConnectionPoints = area.connectionPoints.filter((cp) => cp.isSelected);

          if (selectedConnectionPoints.length === area.connectionPoints.length) {
            area.isSelected = true;
            area.isIndeterminate = false;
          } else if (selectedConnectionPoints.length > 0) {
            area.isIndeterminate = true;
            area.isSelected = false;
          } else {
            area.isSelected = false;
            area.isIndeterminate = false;
          }
        });
      });
    },
    toggleZeroFilter(state) {
      const { zeroFilter } = state;
      state.zeroFilter = !zeroFilter;
    },
    searchNominations(state, action: PayloadAction<SearchActionType>) {
      const { searchKeyword } = action.payload;
      state.searchKeyword = searchKeyword;
      if (searchKeyword.length > 0) {
        const newState = state.areas?.map((area) => {
          const connectionPoints = area.connectionPoints.map((connectionPoint) => {
            const filteredBalanceGroupsLength = searchBalanceGroups(
              searchKeyword,
              connectionPoint.balanceGroups,
            ).length;
            return {
              ...connectionPoint,
              shouldBeDisplayed: filteredBalanceGroupsLength > 0,
            };
          });
          const shouldDisplayMarketArea =
            connectionPoints.filter((connectionPoint) => connectionPoint.shouldBeDisplayed).length >
            0;

          return {
            ...area,
            connectionPoints,
            shouldBeDisplayed: shouldDisplayMarketArea,
          };
        });

        state.areas = newState;
      } else {
        state.areas = state.areas?.map((area) => ({
          ...area,
          shouldBeDisplayed: true,
          connectionPoints: area.connectionPoints.map((connectionPoint) => ({
            ...connectionPoint,
            shouldBeDisplayed: true,
          })),
        }));
      }
    },
    changeStatusFilter(state, action: PayloadAction<StatusFilterActionType>) {
      state.statusFilter = action.payload.options;
      const activeOptions = Object.values(action.payload.options).filter((option) => option).length;
      if (activeOptions > 0) {
        state.filterActive = true;
      } else {
        state.filterActive = false;
      }
    },
    setSelectFilter(state, action: PayloadAction<SelectFilterType>) {
      if (state.selectFilters.length === 0) {
        state.selectFilters.push(action.payload);
      } else {
        const existingFilterIndex = state.selectFilters.findIndex(
          (filter) => filter.filterName === action.payload.filterName,
        );
        if (existingFilterIndex !== -1) {
          if (action.payload.options.length === 0) {
            state.selectFilters.splice(existingFilterIndex, 1);
          } else {
            state.selectFilters[existingFilterIndex].options = action.payload.options;
          }
        } else {
          state.selectFilters.push(action.payload);
        }
      }
      state.filterActive = true;
    },
    clearFilters(state) {
      state.searchKeyword = '';
      state.filterActive = false;
      state.statusFilter = {
        unsent: false,
        saved: false,
        saved_renomination: false,
        pending: false,
        sent: false,
        acknowledged: false,
        acknowledged_error: false,
        mismatched: false,
        mismatched_partial: false,
        nominated: false,
        success: false,
        failure: false,
        received: false,
        received_processed: false,
        received_processed_error: false,
      };
      state.selectFilters = [];
    },
  },
});

export const {
  setInitialValues,
  updateValues,
  clearChangedValues,
  selectMarkerArea,
  unselectMarkerArea,
  selectConnectionPoint,
  unselectConnectionPoint,
  unselectAll,
  selectAll,
  toggleZeroFilter,
  searchNominations,
  changeStatusFilter,
  clearFilters,
  setSelectFilter,
  selectByStatus,
} = nominationSlice.actions;

export default nominationSlice.reducer;
