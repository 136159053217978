import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ClassicButton } from 'components';
import styled, { ThemeContext } from 'styled-components';

const ClassicButtonWithoutRightRadius = styled(ClassicButton)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${({ theme }) => theme.primary};
`;

const CustomButton = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.primary};
    border-left: 1px solid white;
    transition-duration: unset;

    :hover {
      background-color: ${({ theme }) => theme.primary};
    }

    :disabled {
      background-color: ${({ theme }) => theme.disabledButtonBgColor};
      border-left: 0;
    }
  }
`;

interface SubmitDaySplitButtonProps {
  disabled: boolean;
  label: string;
  options: Array<{ icon?: boolean; label: string; value: number }>;
  width?: string;
  markSelected?: boolean;
  clickHandler: () => void;
  setNumberOfDays: (numberOfDays: number) => void;
}

export const SubmitDaySplitButton: React.FC<SubmitDaySplitButtonProps> = ({
  disabled,
  options,
  label,
  width,
  markSelected,
  clickHandler,
  setNumberOfDays,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const theme = React.useContext(ThemeContext);
  const handleClick = () => {
    setNumberOfDays(0);
    return clickHandler();
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setNumberOfDays(options[index].value);
    setOpen(false);
    return clickHandler();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        disabled={disabled}
        sx={{ width: width, boxShadow: 'none' }}
      >
        <ClassicButtonWithoutRightRadius width="10rem" disabled={disabled} onClick={handleClick}>
          {label}
        </ClassicButtonWithoutRightRadius>

        <CustomButton
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={disabled}
        >
          <ArrowDropDownIcon />
        </CustomButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={{ backgroundColor: theme.mainBackgroundColor }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.value}
                      selected={markSelected ? index === selectedIndex : undefined}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.icon ? <CalendarMonthIcon sx={{ marginRight: '0.2rem' }} /> : ''}
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
