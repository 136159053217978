import { ControlledSelectInput, FieldContainerWithLabel } from 'components';
import { FC, useContext } from 'react';
import { Control } from 'react-hook-form';
import { SelectOptionType } from 'utils/types';
import { useDocumentType } from '../messageDefinitionFormApi';
import { SetErrorContext } from '../index';

interface DocumentTypeSelectFieldProps {
  control: Control;
  isDisabled: boolean;
  edigasVersion: string;
}

export const DocumentTypeSelectField: FC<DocumentTypeSelectFieldProps> = ({
  control,
  isDisabled,
  edigasVersion,
}) => {
  const { data: documentTypes } = useDocumentType(edigasVersion);
  const setError = useContext(SetErrorContext)?.setError;

  return (
    <FieldContainerWithLabel isDisabled={isDisabled}>
      <p>Document type</p>
      <ControlledSelectInput
        required
        control={control}
        width="19.5rem"
        placeholder="Choose"
        options={documentTypes as unknown as Array<SelectOptionType>}
        name="documentType"
        isDisabled={isDisabled}
        setError={setError}
      />
    </FieldContainerWithLabel>
  );
};
