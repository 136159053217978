import { EditIcon, TrashIcon } from 'assets/icons';
import {
  BaseButton,
  BaseButtonWithLoader,
  ButtonWithLoader,
  ClassicButton,
  ControlledToggleInput,
  TextInput,
  ToggleSwitch,
  VARIANT,
} from 'components';
import {
  CellWithPadding,
  CenteredNameCell,
  NameCellWithPadding,
  SettingContainer,
  UserSettingsRow,
} from './styled';
import { FC, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  PhoneNumber,
  usePhoneNumberAdd,
  usePhoneNumberEdit,
  usePhoneNumberRemove,
} from './settingsApi';
import { ErrorBox } from 'components';
import { MainCustomErrorType } from 'utils/types';

const AddButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.mainBackgroundColor};
`;

const EditButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
interface PhoneNumberFormProps {
  phoneNumbers: Array<PhoneNumber>;
}

const validatePhoneNumber = (phoneNumber: string) => {
  const phoneNumberPattern =
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

  if (phoneNumberPattern.test(phoneNumber)) {
    return true;
  }

  return 'Phone number is not valid';
};

export const PhoneNumberForm: FC<PhoneNumberFormProps> = ({ phoneNumbers }) => {
  const [addFormActive, setAddFormActive] = useState(false);
  const {
    register,
    control,
    formState: { isDirty, isValid, errors: formError },
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      phoneNumber: '',
      usedForCorrespondence: false,
    } as FieldValues,
    mode: 'all',
  });

  const { mutateAsync: addPhoneNumber, error: addPhoneNumberError } = usePhoneNumberAdd();
  const phoneNumberError = addPhoneNumberError as MainCustomErrorType;

  const closeForm = () => {
    setAddFormActive(false);
    reset();
    phoneNumberError.errors = [];
  };

  const addNewNumber = async () => {
    const { phoneNumber, usedForCorrespondence } = getValues();

    try {
      await addPhoneNumber({ phoneNumber, usedForCorrespondence });
      closeForm();
    } catch (error) {}
  };

  return (
    <SettingContainer>
      <UserSettingsRow>
        <NameCellWithPadding>Number</NameCellWithPadding>
        <NameCellWithPadding>Send Notifications</NameCellWithPadding>
        <CenteredNameCell>Action</CenteredNameCell>
      </UserSettingsRow>
      {phoneNumbers.map((phoneNumber) => (
        <PhoneNumberEditRow key={phoneNumber.id} {...phoneNumber} />
      ))}
      {addFormActive ? (
        <UserSettingsRow>
          <CellWithPadding>
            <TextInput
              register={register('phoneNumber', { required: true, validate: validatePhoneNumber })}
              placeholder=""
              type="text"
              isSlim
              error={formError && 'phoneNumber' in formError ? formError.phoneNumber : ''}
            />
          </CellWithPadding>
          <CellWithPadding>
            <ControlledToggleInput control={control} name="usedForCorrespondence" />
          </CellWithPadding>
          <CellWithPadding>
            <EditButtonsContainer>
              <ClassicButton width="6rem" onClick={closeForm} variant={VARIANT.SECONDARY}>
                Cancel
              </ClassicButton>
              <ClassicButton
                width="6rem"
                onClick={addNewNumber}
                variant={VARIANT.PRIMARY}
                disabled={!(isDirty && isValid)}
              >
                Save
              </ClassicButton>
            </EditButtonsContainer>
          </CellWithPadding>
        </UserSettingsRow>
      ) : (
        <AddButtonRow>
          <CellWithPadding>
            <ClassicButton
              width="6rem"
              onClick={() => setAddFormActive(true)}
              variant={VARIANT.PRIMARY}
            >
              Add
            </ClassicButton>
          </CellWithPadding>
        </AddButtonRow>
      )}

      {phoneNumberError && phoneNumberError.errors.length > 0 ? (
        <CenteredNameCell>
          <ErrorBox errors={phoneNumberError.errors.map((error) => error.message)} />
        </CenteredNameCell>
      ) : (
        ''
      )}
    </SettingContainer>
  );
};

const PhoneNumberEditRow: FC<PhoneNumber> = ({ phoneNumber, usedForCorrespondence, ...props }) => {
  const {
    register,
    control,
    formState: { isDirty, isValid, errors: formError },
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      phoneNumber,
      usedForCorrespondence,
    } as FieldValues,
    mode: 'all',
  });

  const {
    mutateAsync: editPhoneNumber,
    isLoading: isSaving,
    error: editPhoneNumberError,
  } = usePhoneNumberEdit();

  const phoneNumberError = editPhoneNumberError as MainCustomErrorType;

  const { mutate: removePhoneNumber, isLoading: isRemoving } = usePhoneNumberRemove();

  const [editActive, setEditActive] = useState(false);

  const updatePhoneNumber = async () => {
    try {
      const { phoneNumber, usedForCorrespondence } = getValues();

      await editPhoneNumber({ phoneNumber, usedForCorrespondence, ...props });
      setEditActive(false);
    } catch (error) {}
  };

  const closeForm = () => {
    setEditActive(false);
    reset();
    phoneNumberError.errors = [];
  };

  useEffect(() => {
    reset({
      phoneNumber,
      usedForCorrespondence,
    });
  }, [phoneNumber, usedForCorrespondence]);

  return editActive ? (
    <>
      <UserSettingsRow>
        <CellWithPadding>
          <TextInput
            register={register('phoneNumber', { required: true, validate: validatePhoneNumber })}
            placeholder=""
            type="text"
            isSlim
            isDisabled={!props.editable}
            error={formError && 'phoneNumber' in formError ? formError.phoneNumber : ''}
          />
        </CellWithPadding>
        <CellWithPadding>
          <ControlledToggleInput control={control} name="usedForCorrespondence" />
        </CellWithPadding>
        <CellWithPadding>
          <EditButtonsContainer>
            <ButtonWithLoader
              width="6rem"
              onClick={closeForm}
              variant={VARIANT.SECONDARY}
              isLoading={isSaving}
            >
              Cancel
            </ButtonWithLoader>
            <ClassicButton
              width="6rem"
              onClick={updatePhoneNumber}
              variant={VARIANT.PRIMARY}
              disabled={!(isDirty && isValid)}
            >
              Save
            </ClassicButton>
          </EditButtonsContainer>
        </CellWithPadding>
      </UserSettingsRow>
      {phoneNumberError && phoneNumberError.errors.length > 0 ? (
        <CenteredNameCell>
          <ErrorBox errors={phoneNumberError.errors.map((error) => error.message)} />
        </CenteredNameCell>
      ) : (
        ''
      )}
    </>
  ) : (
    <UserSettingsRow>
      <CellWithPadding>{phoneNumber}</CellWithPadding>
      <CellWithPadding>
        <ToggleSwitch
          isChecked={usedForCorrespondence}
          onChange={() => {
            return;
          }}
          disabled
        />
      </CellWithPadding>
      <CellWithPadding>
        <BaseButton width="6rem" onClick={() => setEditActive(true)} variant={VARIANT.PRIMARY}>
          <EditIcon />
        </BaseButton>
        <BaseButtonWithLoader
          width="6rem"
          onClick={() => removePhoneNumber({ phoneNumber, usedForCorrespondence, ...props })}
          variant={VARIANT.PRIMARY}
          disabled={!props.editable}
          isLoading={isRemoving}
        >
          <TrashIcon />
        </BaseButtonWithLoader>
      </CellWithPadding>
    </UserSettingsRow>
  );
};
