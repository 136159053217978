import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { SearchInput } from 'components';
import styled from 'styled-components';
import { setSearchPhrase } from '../messageDefinitionSlice';
import { useAppDispatch } from 'store/hooks';

const SearchForm = styled.form`
  flex: 1;
`;

export const MessageDefinitionSearch: FC = () => {
  const { register, watch, reset, getValues } = useForm({
    defaultValues: { messageDefinitionSearchKeyword: '' },
  });
  const messageDefinitionSearchKeyword = watch('messageDefinitionSearchKeyword');
  const dispatch = useAppDispatch();

  const handleUpdate = useCallback(() => {
    const { messageDefinitionSearchKeyword } = getValues();
    dispatch(setSearchPhrase({ searchPhrase: messageDefinitionSearchKeyword }));
  }, [messageDefinitionSearchKeyword]);

  const resetSearch = () => {
    reset();
  };
  return (
    <SearchForm>
      <SearchInput
        register={register('messageDefinitionSearchKeyword')}
        placeholder="Search"
        handleUpdate={handleUpdate}
        value={messageDefinitionSearchKeyword}
        reset={resetSearch}
        isFrameless
      />
    </SearchForm>
  );
};
