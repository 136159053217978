import { TitleLabel } from './components/StyledComponents';
import { MessageCompareView } from 'features/MessageDetails/MessageCompareView';
import { FC } from 'react';
import styled from 'styled-components';
import { GasDayHourDefinitionType } from 'utils/types';
import { MessageDetailsGroupType } from './types';

const MessageDetailsGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2.5rem;
`;

const GroupInformationContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  ${({ theme }) => `background-color: ${theme.messageDetailsGroupHeading};`}
  border-radius: 4px;
`;

const GroupInformationCell = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 0 1rem;
`;

interface MessageDetailsGroupProps {
  messageDetailsGroup: MessageDetailsGroupType;
  gasDayHourDefinition: GasDayHourDefinitionType;
}

export const MessageDetailsGroup: FC<MessageDetailsGroupProps> = ({
  messageDetailsGroup,
  gasDayHourDefinition,
}) => {
  const { timeSeriesMessageDtos } = messageDetailsGroup;
  return (
    <MessageDetailsGroupContainer>
      <GroupInformationContainer>
        <GroupInformationCell>
          <TitleLabel>Connection point: </TitleLabel>
          {messageDetailsGroup.connectionPointName}
        </GroupInformationCell>
        <GroupInformationCell>
          <TitleLabel>Internal account: </TitleLabel>
          {messageDetailsGroup.internalAccount}
        </GroupInformationCell>
      </GroupInformationContainer>
      {timeSeriesMessageDtos?.map((timeSeriesValues) => (
        <MessageCompareView
          key={`${timeSeriesValues.sourceId}-${timeSeriesValues.destinationId}`}
          value={timeSeriesValues}
          gasDayHourDefinition={gasDayHourDefinition}
        />
      ))}
    </MessageDetailsGroupContainer>
  );
};
