import { FC, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { CarouselStyledCell } from 'components';
import { Values } from 'utils/types/generalTypes';
import { StatusTooltip } from './components/StatusTooltip';

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  min-width: 0;
  max-width: 116.625rem;
`;

const TableRow = styled.div`
  display: flex;
  height: 2.5rem;
  max-height: 2.5rem;
`;

const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
`;

const ValueStyledCell = styled(CarouselStyledCell)<{ isMatching: boolean }>`
  background-color: ${({ isMatching }) => (isMatching ? 'rgba(163, 186, 198, 0.1)' : '#FFA590')};
  min-height: 2.5rem;
`;

const RelativeContainer = styled.div`
  position: relative;
`;

interface ValuesInternalCarouselProps {
  scrollPosition: number;
  values: Values;
  borderInvisible?: boolean;
}

export const ValuesInternalCarousel: FC<ValuesInternalCarouselProps> = ({
  scrollPosition,
  values,
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const { hourlyValues, hourlyStatus, replyMessageHourlyStatusDto } = values;

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = scrollPosition;
    }
  }, [scrollPosition]);

  return (
    <ContentContainer>
      <CarouselContainer ref={carouselRef}>
        <TableRow>
          {hourlyValues.timetable.map((value, key) => (
            <RelativeContainer key={key}>
              {replyMessageHourlyStatusDto &&
                replyMessageHourlyStatusDto.timetable[key].length > 0 && (
                  <StatusTooltip
                    listOfStatuses={replyMessageHourlyStatusDto.timetable[key]}
                    tooltipId={`${key}_${values.originTimeSeriesType.type}`}
                  />
                )}
              <ValueStyledCell
                isMatching={hourlyStatus.timetable[key] !== 'MISMATCHED'}
                title={hourlyStatus.timetable[key]}
              >
                {value}
              </ValueStyledCell>
            </RelativeContainer>
          ))}
        </TableRow>
      </CarouselContainer>
    </ContentContainer>
  );
};
