import styled from 'styled-components';

export const StyledHeader = styled.header<{ masterData?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 3.5rem;
  width: 100%;
  padding: 0rem 1.25rem;
  box-shadow: ${({ theme }) => theme.globalHeaderShadow};
  z-index: 10;
  justify-content: space-between;
  z-index: 12;
  background-color: ${({ theme, masterData }) => {
    return masterData ? theme.transparentRed : theme.white;
  }};
`;

export const StyledHeaderButton = styled.button`
  width: 2.8125rem;
  height: 2.8125rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #ccc;
    transition: 0.4s;
  }
`;

export const RightSideFunctionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ThemeSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  label {
    margin: 0 0.5rem;
  }
  span {
    font-weight: 600;
  }
`;
