import styled from 'styled-components';

export const TopInformationCell = styled.div<{ flexProperty?: string }>`
  min-width: 20rem;
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 0 1.5rem;

  margin-right: 0.5rem;

  :last-child {
    margin-right: 0;
  }

  ${({ flexProperty }) => flexProperty && `flex: ${flexProperty}; margin-right: 0.5rem;`}

  svg {
    margin: 0 0.5rem;
    border-radius: 0.25rem;
  }
`;

export const TitleLabel = styled.span`
  display: inline-block;
  font-weight: 600;
  margin-right: 0.3125rem;
`;
