import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  ClassicButton,
  VARIANT,
  HeadingBar,
  FormTitle,
  ContentContainer,
  FormContainer,
  DatePickerContainer,
  ActionBar,
  MainContainer,
  StyledTextInput,
  FormSection,
  FieldContainerWithLabel,
  ControlledToggleInputWithLabel,
  ControlledDatePicker,
  FormSectionTitle,
  IdentifiersTable,
  ConfirmationPopup,
  ConfirmationPopupText,
  FormMetadataBox,
  GlobalErrorModal,
  RadioGroup,
} from 'components';
import { FieldValues, useForm } from 'react-hook-form';
import { getInitialDate } from 'utils/getInitialDate';
import { AreaConnectionPoint, Identifier, IdentifierType } from 'utils/types';
import { useIdentifier } from 'utils/hooks/useIdentifier';
import { ConterpartySelectField } from './ConterpartySelectField';
import { useConnectionPoint, useConnectionPointSave, useArea } from './connectionPointFormApi';
import { PointTypeSelectField, pointTypes } from './PointTypeSelectField';
import { useAppDispatch } from 'store/hooks';
import { openModal } from 'store/modalSlice';
import styled from 'styled-components';
import { formatDateForInput } from 'utils/dateFormating';
import { AreaSlimType } from 'utils/types/areaTypes';
import { LoadingScreen } from 'components/LoadingScreen';

const createIdentifierArray = (identifierTypes: Array<IdentifierType>): Array<Identifier> => {
  return identifierTypes.map((identifierType) => ({
    identifier: '',
    type: identifierType,
    validFrom: formatDateForInput(getInitialDate()),
    validTo: formatDateForInput(new Date('2099-09-30')),
  }));
};

const granularityOptions = ['hourly', 'daily'];

interface ConnectionPointFormProps {
  option: 'edit' | 'create';
  connectionPointId?: string;
  areaSortOrder: string;
  area: AreaSlimType;
}

const FlexContainerWithSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`; //TODO REFACTOR THIS LAYOUT TO SOMETHING MORE TOUGHTFULL

export const ConnectionPointForm: FC<ConnectionPointFormProps> = ({
  option,
  connectionPointId,
  areaSortOrder,
  area,
}) => {
  const {
    register,
    reset,
    getValues,
    formState: { isDirty, isValid },
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      code: '',
      active: true,
      granularity: granularityOptions[0],
      validFrom: getInitialDate(),
      validTo: new Date('2099-09-30'),
    } as FieldValues,
  });
  const [localState, setLocalState] = useState({
    identifiers: [] as Array<Identifier>,
    hasChanged: false,
  });

  const { identifiers, hasChanged } = localState;

  const dispatch = useAppDispatch();
  const { data: connectionPoint, isLoading: isConnectionPointLoading } =
    useConnectionPoint(connectionPointId);
  const history = useHistory();
  const cleanIdentifiers = useIdentifier(option === 'create' && identifiers.length === 0);
  const { mutate: connectionPointSave, isLoading } = useConnectionPointSave();
  const { data: currentArea, isLoading: isAreaLoading } = useArea(area.id);

  useEffect(() => {
    if (option === 'edit' && connectionPoint) {
      const {
        name,
        code,
        pointType,
        counterparty,
        identifiers,
        validFrom,
        validTo,
        active,
        sortOrder,
        nominationLeadTimeMin,
        granularity,
      } = connectionPoint;

      reset({
        name,
        code,
        active,
        validFrom: new Date(validFrom),
        validTo: new Date(validTo),
        pointType: pointTypes.find((type) => type.name === pointType),
        counterparty,
        sortOrder,
        granularity: granularity.toLowerCase(),
        nominationLeadTimeMin,
      });
      setLocalState({ ...localState, identifiers: identifiers });
    }

    if (option === 'create' && cleanIdentifiers.data) {
      const newIdentifiers = createIdentifierArray(cleanIdentifiers.data.content).filter(
        (identifier) => identifier.type.name === 'EIC',
      );
      setLocalState({ ...localState, identifiers: newIdentifiers });
    }
  }, [option, cleanIdentifiers.data, connectionPoint]);

  const updateIdentifiers = (identifiers: Array<Identifier>) => {
    setLocalState({
      ...localState,
      identifiers,
      hasChanged: true,
    });
  };

  const addNewIdentifier = (identifier: Identifier) => {
    setLocalState({
      ...localState,
      identifiers: [...localState.identifiers, identifier],
      hasChanged: true,
    });
  };

  const onSubmit = () => {
    const {
      validFrom,
      validTo,
      active,
      name,
      code,
      pointType,
      granularity,
      counterparty,
      sortOrder,
      nominationLeadTimeMin,
    } = getValues();
    if (currentArea) {
      const formValues = {
        area: currentArea,
        name,
        code,
        validFrom,
        validTo,
        active,
        pointType: pointType.name,
        granularity: granularity.toUpperCase(),
        identifiers,
        counterparty,
        sortOrder,
        nominationLeadTimeMin,
      } as AreaConnectionPoint;

      if (option === 'edit' && connectionPoint) {
        connectionPointSave({
          ...connectionPoint,
          ...formValues,
        });
      } else {
        connectionPointSave(formValues);
      }
    }
  };

  const shouldDisableSubmit = () => {
    if (option === 'edit') {
      return !hasChanged && (!isDirty || !isValid);
    } else {
      return !isDirty || !isValid || !hasChanged;
    }
  };

  return !isAreaLoading && !isConnectionPointLoading ? (
    <MainContainer>
      <GlobalErrorModal />
      <ContentContainer width="65%">
        <HeadingBar>
          <FormTitle>
            {option === 'create' ? 'Add new connection point' : 'Edit connection point'}
          </FormTitle>
        </HeadingBar>
        <FormContainer>
          <FlexContainerWithSpaceBetween>
            <FormSection>
              <FormSectionTitle>Master Data</FormSectionTitle>
              <StyledTextInput
                type="text"
                label="Connection point name"
                placeholder="e.g. VTP Trading Hub Europe"
                register={register('name', {
                  required: true,
                  minLength: 2,
                })}
                width="20rem"
                isLabelBold
              />
              <StyledTextInput
                type="text"
                label="Connection point short name"
                placeholder="e.g. VTP THE"
                register={register('code', {
                  required: true,
                  minLength: 2,
                })}
                width="20rem"
                isLabelBold
              />
            </FormSection>
            {option === 'edit' && connectionPoint && (
              <FormMetadataBox
                fipDatCreated={connectionPoint.fipDatCreated}
                fipDate={connectionPoint.fipDate}
                fipUser={connectionPoint.fipUser}
                fipUserCreated={connectionPoint.fipUserCreated}
              />
            )}
          </FlexContainerWithSpaceBetween>
          <FormSection>
            <FormSectionTitle>Area</FormSectionTitle>
            <FieldContainerWithLabel labelSpace="0.625rem">
              <p>Area name</p>
              {area.name}
            </FieldContainerWithLabel>
            <ConterpartySelectField control={control} />
            <PointTypeSelectField control={control} />
            <FieldContainerWithLabel labelSpace="1rem">
              <p>Granularity</p>
              <RadioGroup
                options={granularityOptions}
                register={register('granularity', { required: true })}
              />
            </FieldContainerWithLabel>
            <StyledTextInput
              type="text"
              label="Nomination lead time in minutes"
              placeholder="e.g. 30"
              register={register('nominationLeadTimeMin', {
                required: true,
                minLength: 2,
              })}
              width="20rem"
              isLabelBold
            />
          </FormSection>
          <FormSection>
            <FieldContainerWithLabel>
              <FormSectionTitle>Valid</FormSectionTitle>
              <DatePickerContainer>
                <label>From:</label>
                <ControlledDatePicker name="validFrom" control={control} required />
              </DatePickerContainer>
              <DatePickerContainer>
                <label>To:</label>
                <ControlledDatePicker name="validTo" control={control} />
              </DatePickerContainer>
            </FieldContainerWithLabel>
            <FieldContainerWithLabel>
              <FormSectionTitle>Status</FormSectionTitle>
              <ControlledToggleInputWithLabel control={control} name="active" />
            </FieldContainerWithLabel>
            <FieldContainerWithLabel>
              <FormSectionTitle>Sort order</FormSectionTitle>

              <StyledTextInput
                type="number"
                placeholder="e.g. 1"
                register={register('sortOrder', {
                  required: true,
                  minLength: 1,
                })}
                width="20rem"
                isLabelBold
                prefix={`${areaSortOrder}.`}
              />
            </FieldContainerWithLabel>
            <FormSectionTitle>Identifier</FormSectionTitle>
            <IdentifiersTable
              identifiers={identifiers}
              updateIdentifiers={updateIdentifiers}
              addIdentifier={addNewIdentifier}
            />
          </FormSection>
        </FormContainer>

        <ActionBar>
          <ClassicButton
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={() => history.push('/app/areas/')}
          >
            Cancel
          </ClassicButton>
          <ClassicButton
            width="10rem"
            variant={VARIANT.PURPLE}
            onClick={() => dispatch(openModal({ modalId: 'connectionPointConfirmationModal' }))}
            disabled={shouldDisableSubmit()}
          >
            {option === 'edit' ? 'Save' : 'Add'}
          </ClassicButton>
        </ActionBar>
        <ConfirmationPopup
          isLoading={isLoading}
          confirmationPopupId="connectionPointConfirmationModal"
          confirmButtonAction={() => onSubmit()}
        >
          <ConfirmationPopupText>
            {option === 'create' ? (
              <>
                Shall we go ahead and save <span>{getValues().name}</span> as a new connection
                point?{' '}
              </>
            ) : (
              <>
                Shall we go ahead and update the Masterdata for Connection Point{' '}
                <span>{getValues().name}</span>{' '}
              </>
            )}
          </ConfirmationPopupText>
        </ConfirmationPopup>
      </ContentContainer>
    </MainContainer>
  ) : (
    <LoadingScreen />
  );
};
