import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageFormContainer } from 'components';
import { ConnectionPointForm } from 'features/ConnectionPointForm';

interface ParamTypes {
  areaName: string;
  areaId: string;
  option: 'create' | 'edit';
  id?: string;
  areaSortOrder: string;
}

export const ConnectionPointFormPage: FC = () => {
  const { option, id, areaSortOrder, areaName, areaId } = useParams<ParamTypes>();

  return (
    <PageFormContainer>
      <ConnectionPointForm
        option={option}
        connectionPointId={id}
        areaSortOrder={areaSortOrder}
        area={{ id: parseInt(areaId), name: areaName }}
      />
    </PageFormContainer>
  );
};
