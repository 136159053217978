import { FC } from 'react';
import { SortTypeToggle } from './SortTypeToggle';
import { ClassicButton, StyledDatePicker, TopControlRowContainer, VARIANT } from 'components';
import { FileUploadModal } from '../components/FileUploadModal';
import { openModal } from '../../../../store/modalSlice';
import { useAppDispatch } from '../../../../store/hooks';

export interface TopControlRowProps {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
}

export const TopControlRow: FC<TopControlRowProps> = ({ selectedDate, setSelectedDate }) => {
  const dispatch = useAppDispatch();
  return (
    <TopControlRowContainer>
      <SortTypeToggle />

      <ClassicButton
        width="12rem"
        title="Open a dialog for selecting any file to be uploaded and imported as incoming message."
        variant={VARIANT.SECONDARY}
        onClick={() => dispatch(openModal({ modalId: 'fileUploadModal' }))}
      >
        Upload response data
      </ClassicButton>

      <FileUploadModal />
      <StyledDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
    </TopControlRowContainer>
  );
};
