import { NominationTrack } from '../types';
import { FC } from 'react';
import { NominationTrackRow } from './NominationTrackRow';
import styled from 'styled-components';
import { EdigasVersionType } from 'utils/types';

const GroupContainer = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.lightGrey};
  margin-bottom: 1rem;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

interface NominationTrackGroupProps {
  nominationGroup: Array<NominationTrack>;
  // color: string;
  messageDefId: number;
  edigasVersion: EdigasVersionType;
}

export const NominationTrackGroup: FC<NominationTrackGroupProps> = ({
  nominationGroup,
  messageDefId,
  edigasVersion,
}) => {
  return (
    <GroupContainer>
      {nominationGroup.map((nominationTrack, key) => (
        <NominationTrackRow
          key={nominationTrack.id}
          nominationTrack={nominationTrack}
          // color={color}
          isOdd={key % 2 !== 0}
          messageDefID={messageDefId}
          edigasVersion={edigasVersion}
        />
      ))}
    </GroupContainer>
  );
};
