import { Identifier, IdentifierType } from 'utils/types';
import { FC, useState } from 'react';
import { BaseButton, EditButton } from 'components';
import { IdentifierForm } from './IdentifierForm';
import styled from 'styled-components';
import { TableCell, HeadingCell, ContentCell, NewEntryButtonContainer } from './styled';
import { BluePlusIcon, EditIcon, TrashIcon } from 'assets/icons';
import { useIdentifier } from 'utils/hooks/useIdentifier';
import { formatDateForInput, standardDateFormat } from 'utils/dateFormating';

const IdentifierTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const IdentifierRow = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0.5rem;
  :last-child {
    margin-bottom: 0;
  }
  padding: 1rem;
`;

interface IdentifiersTableProps {
  identifiers: Array<Identifier>;
  addIdentifier: (identifier: Identifier) => void;
  updateIdentifiers: (identifiers: Array<Identifier>) => void;
}

export const IdentifiersTable: FC<IdentifiersTableProps> = ({
  identifiers,
  addIdentifier,
  updateIdentifiers,
}) => {
  const { data: identifierTypes } = useIdentifier();
  const [newIdentifierFormActive, setNewIdentifierFormActive] = useState(false);
  const [editIdentifierFormActive, setEditIdentifierFormActive] = useState(-1);

  const addNewIdentifierAndCloseForm = (identifier: Identifier) => {
    addIdentifier(identifier);
    setNewIdentifierFormActive(false);
  };

  const editIdentifierAndCloseForm = (identifier: Identifier, key: number) => {
    identifiers[key] = {
      ...identifiers[key],
      ...identifier,
    };

    setEditIdentifierFormActive(-1);

    updateIdentifiers([...identifiers]);
  };

  const removeIdentifier = (identifierToRemove: Identifier, indexOfIdentifierToRemove: number) => {
    const identifiersCopy = [...identifiers];
    identifiersCopy.splice(indexOfIdentifierToRemove, 1);
    updateIdentifiers(identifiersCopy);
  };

  return (
    <IdentifierTable>
      <IdentifierRow>
        <HeadingCell>Name</HeadingCell>
        <HeadingCell>Coding Scheme</HeadingCell>
        <HeadingCell>Identifier</HeadingCell>
        <HeadingCell width="10rem">Valid from</HeadingCell>
        <HeadingCell width="10rem">Valid to</HeadingCell>
        <TableCell width="3rem"></TableCell>
      </IdentifierRow>
      {identifiers.length !== 0 &&
        identifiers.map((element, key) => {
          return editIdentifierFormActive === key && identifierTypes ? (
            <IdentifierForm
              identifierTypesList={identifierTypes.content}
              onCancel={() => setEditIdentifierFormActive(-1)}
              identifier={element}
              submitIdentifier={(identifier: Identifier) =>
                editIdentifierAndCloseForm(identifier, key)
              }
            />
          ) : (
            <IdentifierRow key={`${element.type.scheme}_${key}`}>
              <ContentCell>{element.type.name}</ContentCell>
              <ContentCell isGrey>{element.type.scheme}</ContentCell>
              <ContentCell isGrey>{element.identifier}</ContentCell>
              <ContentCell width="10rem">
                {standardDateFormat(new Date(element.validFrom))}
              </ContentCell>
              <ContentCell width="10rem">
                {standardDateFormat(new Date(element.validTo))}
              </ContentCell>
              <TableCell width="3rem">
                <BaseButton onClick={() => setEditIdentifierFormActive(key)}>
                  <EditIcon />
                </BaseButton>
                {identifiers.length > 1 && (
                  <BaseButton onClick={() => removeIdentifier(element, key)} margin="0 0 0 0.5rem">
                    <TrashIcon />
                  </BaseButton>
                )}
              </TableCell>
            </IdentifierRow>
          );
        })}
      {identifierTypes && newIdentifierFormActive ? (
        <IdentifierForm
          identifierTypesList={identifierTypes.content}
          onCancel={() => setNewIdentifierFormActive(false)}
          submitIdentifier={(identifier: Identifier) => addNewIdentifierAndCloseForm(identifier)}
        />
      ) : (
        <NewEntryButtonContainer>
          <EditButton onClick={() => setNewIdentifierFormActive(true)}>
            <BluePlusIcon />
            Add new
          </EditButton>
        </NewEntryButtonContainer>
      )}
    </IdentifierTable>
  );
};

export const createIdentifierArray = (
  identifierTypes: Array<IdentifierType>,
): Array<Identifier> => {
  const currentDate = new Date();
  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
  return identifierTypes.map((identifierType) => ({
    identifier: '',
    type: identifierType,
    validFrom: formatDateForInput(startDate),
    validTo: formatDateForInput(new Date('2099-09-30')),
  }));
};
