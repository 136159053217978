import { FC } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { Control, Controller } from 'react-hook-form';

interface ControlledControlledTimePickerProps {
  control: Control;
  name: string;
  label?: string;
  required: boolean;
}

export const ControlledTimePicker: FC<ControlledControlledTimePickerProps> = ({
  control,
  name,
  required,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { onChange, value } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            value={value}
            onChange={onChange}
            ampm={false}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      )}
    />
  );
};
