import { FC } from 'react';
import styled from 'styled-components';
import { MasterdataSearch } from './MasterdataSearch';

const ControlPanelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
  height: 4.5rem;
`;
interface ControlRowProps {
  setSearch: (keyword: string) => void;
}

export const ControlRow: FC<ControlRowProps> = ({ setSearch }) => {
  return (
    <ControlPanelContainer>
      <MasterdataSearch setSearchKeyword={setSearch} />
    </ControlPanelContainer>
  );
};
