import { ReactElement, useContext, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import {
  EventType,
  IPublicClientApplication,
  EventMessage,
  AuthenticationResult,
  InteractionStatus,
  PublicClientApplication,
} from '@azure/msal-browser';
import { TenantInfoContext } from 'App';
import { LoadingScreen } from 'components/LoadingScreen';

export const LoginForm = (): ReactElement => {
  const { instance, inProgress } = useMsal();

  const {
    login: { clientIdentifier },
  } = useContext(TenantInfoContext);

  const signInHandler = async (instance: IPublicClientApplication) => {
    const config = {
      scopes: [`api://${clientIdentifier}/default`, 'email', 'profile'],
      redirectStartPage: `${window.location.origin}/app/`,
    };

    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
    }
    // Implemented based on suggestion from: https://stackoverflow.com/a/66405987
    instance.addEventCallback((event: EventMessage) => {
      // set active account after redirect
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload !== null) {
        const castedPayload = event.payload as AuthenticationResult;
        instance.setActiveAccount(castedPayload.account);
      }
    });
    try {
      if (inProgress === InteractionStatus.None) {
        const msalInstance: PublicClientApplication = instance as PublicClientApplication;
        msalInstance['browserStorage'].clear();
        await instance.handleRedirectPromise();

        const account = instance.getActiveAccount();
        if (!account) {
          // redirect anonymous user to login page

          instance.loginRedirect(config);
        }
      }
    } catch (error) {
      console.error({ error });
    }
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      signInHandler(instance);
    }
  }, [inProgress]);

  return <>{inProgress && <LoadingScreen />}</>;
};
