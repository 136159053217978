import { FormErrorIcon } from 'assets/icons';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const ErrorLine = styled.div`
  display: flex;
  color: ${({ theme }) => theme.extraRed};
  align-items: center;
  padding-top: 0.5rem;
  font-size: 0.875rem;
  svg {
    margin-right: 0.5rem;
  }
`;

export const InputError: FC<{ children: ReactNode }> = ({ children }) => (
  <ErrorLine>
    <FormErrorIcon />
    {children}
  </ErrorLine>
);
