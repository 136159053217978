import { Checkbox } from 'components';
import { FC, useEffect, useState } from 'react';
import {
  Pending,
  Failure,
  Nominated,
  Success,
  SavedIcon,
  MessageCreated,
  NominatedAcknowledged,
  AcknowError,
  TransmissionFailed,
  MappingError,
} from 'assets/icons';
import {
  FilterButton,
  ClearButton,
  FilterContainer,
  OptionsContainer,
  FilterOption,
  FilterCount,
  Icon,
  OptionLabel,
} from './styled';
import { useClickOutside } from 'utils/hooks';
import { StatusOptions } from 'utils/types';

interface StatusFilterProps {
  updateFilter: (options: StatusOptions) => void;
  isFrameless?: boolean;
  filtersCleared?: boolean;
}
// TODO: refactor status filter to be based on one single source of truth to avoid copying StatusOptions
export const StatusFilter: FC<StatusFilterProps> = ({
  updateFilter,
  isFrameless,
  filtersCleared,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<StatusOptions>({
    unsent: false,
    saved: false,
    saved_renomination: false,
    pending: false,
    sent: false,
    acknowledged: false,
    acknowledged_error: false,
    mismatched: false,
    mismatched_partial: false,
    nominated: false,
    success: false,
    failure: false,
    received: false,
    received_processed: false,
    received_processed_error: false,
  });

  const closeOptionsContainer = () => setIsExpanded(false);
  const ref = useClickOutside(closeOptionsContainer);

  const setOptions = (option: string) => {
    const currentOptionValue = selectedOptions[option as keyof StatusOptions];
    const currentOptions = {
      ...selectedOptions,
      [option]: !currentOptionValue,
    };
    setSelectedOptions(currentOptions);
    updateFilter(currentOptions);
  };

  const clearAll = () => {
    const initialValues = {
      unsent: false,
      saved: false,
      saved_renomination: false,
      pending: false,
      sent: false,
      acknowledged: false,
      acknowledged_error: false,
      mismatched: false,
      mismatched_partial: false,
      nominated: false,
      success: false,
      failure: false,
      received: false,
      received_processed: false,
      received_processed_error: false,
    };
    setSelectedOptions(initialValues);
    updateFilter(initialValues);
  };

  const options = [
    { name: 'Unsent', icon: <MessageCreated />, id: 'unsent' },
    { name: 'Saved', icon: <SavedIcon />, id: 'saved' },
    { name: 'Saved renomination', icon: <SavedIcon />, id: 'saved_renomination' },
    { name: 'Pending', icon: <Pending />, id: 'pending' },
    { name: 'Sent', icon: <Nominated />, id: 'sent' },
    { name: 'Acknowledged', icon: <NominatedAcknowledged />, id: 'acknowledged' },
    { name: 'Acknowledged with error', icon: <AcknowError />, id: 'acknowledged_error' },
    { name: 'Mismatched', icon: <Failure />, id: 'mismatched' },
    { name: 'Partial mismatch', icon: <Failure />, id: 'mismatched_partial' },
    { name: 'Nominated', icon: <Success />, id: 'nominated' },
    { name: 'Success', icon: <Success />, id: 'success' },
    { name: 'Failure', icon: <TransmissionFailed />, id: 'failure' },
    { name: 'Received', icon: <Success />, id: 'received' },
    {
      name: 'Received and processed with error',
      icon: <MappingError />,
      id: 'received_processed_error',
    },
  ];

  useEffect(() => {
    if (filtersCleared) {
      clearAll();
    }
  }, [filtersCleared]);

  const filtersActive = Object.entries(selectedOptions).filter((option) => option[1]).length;
  return (
    <FilterContainer ref={ref}>
      <FilterButton
        onClick={() => setIsExpanded(!isExpanded)}
        isFrameless={isFrameless}
        isExpanded={isExpanded}
      >
        Filter by status
        {filtersActive > 0 && <FilterCount>{filtersActive}</FilterCount>}
        <Icon $isOpen={isExpanded} />
      </FilterButton>
      {isExpanded && (
        <OptionsContainer isFrameless={isFrameless}>
          <form>
            {options.map((option) => (
              <FilterOption key={option.name}>
                <Checkbox
                  checked={selectedOptions[option.id as keyof StatusOptions]}
                  onChange={() => setOptions(option.id)}
                />
                <OptionLabel>
                  {option.icon}
                  {option.name}
                </OptionLabel>
              </FilterOption>
            ))}
          </form>
          {filtersActive > 0 && <ClearButton onClick={clearAll}>Clear all filters</ClearButton>}
        </OptionsContainer>
      )}
    </FilterContainer>
  );
};
