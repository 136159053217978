import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { DashboardHeadingBar } from './components/DashboardHeadingBar';
import { MessageStatusSection } from './components/MessageStatusSection';
import { LatestUpdateSection } from './components/LatestUpdateSection';

const DashboardContainer = styled.div`
  padding: 0.5rem 2.5rem 2.5rem 2.5rem;
  /* display: grid;
  grid-template-rows: 5rem 4fr minmax(0, 1fr); */
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Dashboard: FC = () => {
  const { control, watch } = useForm({
    defaultValues: {
      selectedDate: new Date(),
    } as FieldValues,
  });

  const selectedDate = watch('selectedDate');
  return (
    <DashboardContainer>
      <DashboardHeadingBar dateControl={control} />
      <MessageStatusSection date={selectedDate} />
      <LatestUpdateSection date={selectedDate} />
    </DashboardContainer>
  );
};
