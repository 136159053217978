import { Failure } from 'assets/icons';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { MainCustomErrorType } from 'utils/types';

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;

  ${({ theme }) => css`
    background-color: ${theme.transparentRed};
    border: 1px solid ${theme.extraRed};
  `}
`;

const ErrorHeading = styled.h3`
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 1rem;
  svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
`;

const ErrorsList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: disc;

  li {
    margin: 0 2rem;
  }
`;

interface FormErrorBoxProps {
  error: MainCustomErrorType;
}

export const FormErrorBox: FC<FormErrorBoxProps> = ({ error }) => (
  <ErrorContainer>
    <ErrorHeading>
      <Failure />
      <p>{error.message}</p>
    </ErrorHeading>
    <ErrorsList>
      {error.errors.map((error, key) => (
        <li key={`${error.code}_${key}`}>{error.message}</li>
      ))}
    </ErrorsList>
  </ErrorContainer>
);
