import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ErrorMessage {
  errorCode?: number;
  errorMessage: string;
}

interface State {
  isVisible: boolean;
  error?: ErrorMessage;
}

const initialState = {
  isVisible: false,
  error: {},
} as State;

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<ErrorMessage>) {
      state.error = action.payload;
      state.isVisible = true;
    },
    clearError(state) {
      state.error = {} as ErrorMessage;
      state.isVisible = false;
    },
  },
});

export const { setError, clearError } = errorSlice.actions;

export default errorSlice.reducer;
