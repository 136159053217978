import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  BalancegroupInfo,
  IdentifierType,
  MainCustomErrorType,
  MessageDefinitionSlim,
} from 'utils/types';
import { useHistory } from 'react-router-dom';
import { useApiGet, API } from 'utils/hooks';
import { setError } from 'features/ErrorScreen/errorSlice';
import { closeModal } from 'store/modalSlice';

export const useBalancegroup = (balancegroupId?: string) => {
  const fetchBalancegroup = useApiGet(`/balance-group/${balancegroupId}`, !!balancegroupId);
  return useQuery<BalancegroupInfo, Error>(['balanceGroup'], fetchBalancegroup);
};

export const useBalanceGropuSave = (
  option: 'edit' | 'create',
  updateErrors: (error: MainCustomErrorType) => void,
) => {
  const { token } = useAppSelector((state) => state.authReducer);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  return useMutation(
    (data: BalancegroupInfo) => {
      if (option === 'edit') {
        return API.PUT('/balance-group', {
          headers: {
            Authorization: token,
          },
          body: JSON.stringify(data),
        });
      }
      return API.POST('/balance-group', {
        headers: {
          Authorization: token,
        },
        body: JSON.stringify(data),
      });
    },
    {
      onError: (error: MainCustomErrorType) => {
        if (error.code) {
          updateErrors(error);
        } else {
          dispatch(setError({ errorMessage: error.toString() }));
        }

        dispatch(closeModal({ modalId: 'balancegroupConfirmationModal' }));
      },
      onSuccess: async () => {
        dispatch(closeModal({ modalId: 'balancegroupConfirmationModal' }));
        queryClient.invalidateQueries('counterparty');
        history.push('/app/masterdata');
      },
    },
  );
};

interface SlimAreaInfo {
  id: number;
  name: string;
}

export const useAreasSlimList = () => {
  const fetchAreaSlimList = useApiGet('/less/area');
  return useQuery<Array<SlimAreaInfo>, Error>(['areaList'], fetchAreaSlimList);
};

export interface IndetifierTypes {
  content: Array<IdentifierType>;
}

interface MessageDefinitionSlimListType {
  content: Array<MessageDefinitionSlim>;
}

export const useMessageDefinition = () => {
  const fetchMessageDefinitionList = useApiGet('/less/message-definition?&size=100');
  return useQuery<MessageDefinitionSlimListType, Error>(
    ['messageDefinitionsSlim'],
    fetchMessageDefinitionList,
  );
};
