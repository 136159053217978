import { ControlledSelectInput, FieldContainerWithLabel } from 'components';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { SelectOptionType } from 'utils/types';

import { useCounterpartiesLov } from 'features/AdminPanel/adminPanelApi';

interface CounterpartySelectFieldProps {
  control: Control;
}

export const CounterpartySelectField: FC<CounterpartySelectFieldProps> = ({ control }) => {
  const { data: counterparties } = useCounterpartiesLov();
  return (
    <FieldContainerWithLabel>
      <p>Counterparty</p>
      <ControlledSelectInput
        required
        control={control}
        width="22rem"
        placeholder="Choose"
        options={counterparties?.content as unknown as Array<SelectOptionType>}
        name="counterparty"
      />
    </FieldContainerWithLabel>
  );
};
