import styled from 'styled-components';

const CarouselStyledCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.375rem;
  flex-shrink: 0;
  margin-right: 0.5rem;
  max-width: 4.375rem;
  overflow: hidden;
`;

const StyledHourHeadingCell = styled(CarouselStyledCell)<{ isAdditionalHour?: boolean }>`
  font-size: 0.875rem;
  background-color: ${({ theme, isAdditionalHour }) =>
    isAdditionalHour ? theme.additionalHourHeading : theme.white};
  text-align: center;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
`;

const CarouselStyledValueCell = styled(CarouselStyledCell)<{ bgColor?: string }>`
  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.connectionPointInfoCellColor};
  font-size: 0.875rem;
  text-overflow: ellipsis;
`;

const CarouselButton = styled.button<{ themeColor?: string }>`
  width: 2.625rem;
  height: 2.625rem;
  background-color: ${({ themeColor, theme }) => (themeColor ? themeColor : theme.primary)};
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2.5rem;
  border: 1px solid ${({ theme }) => theme.white};
  z-index: 2; // Make shadow come over the neighbour element

  /* box-shadow: 0 0 0.75rem rgba(144, 164, 174, 0.4); */
  box-shadow: ${({ theme }) => theme.carouselButtonShadow};

  :disabled {
    background-color: ${({ theme }) => theme.milkGrey};
  }
  :hover {
    opacity: 0.4;
  }

  svg {
    height: 1.125rem;
    path {
      stroke: ${({ theme }) => theme.mainBackgroundColor};
    }
  }
`;
// This spacer is replacing button to keep layout intact when there is no header displayed
const Spacer = styled.div`
  width: 2.625rem;
  min-width: 2.625rem;
`;

const LeftButton = styled(CarouselButton)`
  svg {
    margin-right: 0.1875rem;
  }
`;

const RightButton = styled(CarouselButton)`
  svg {
    margin-left: 0.1875rem;
  }
`;

export {
  CarouselStyledCell,
  StyledHourHeadingCell,
  CarouselStyledValueCell,
  LeftButton,
  RightButton,
  Spacer,
};
