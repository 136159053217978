import { FC, useContext, useEffect } from 'react';
import { RightSideFunctionsContainer, StyledHeader, ThemeSwitchContainer } from './styled';
import {
  Logo,
  LogoDarkMode,
  LogoutIcon,
  NotificationSettingsIcon,
  SettingsIcon,
} from 'assets/icons';
import { DropdownMenu, ToggleSwitch } from 'components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { logOut, setUserData } from 'store/authSlice';
import { ThemeFunctionsContext } from 'App';
import styled from 'styled-components';
import { openModal } from 'store/modalSlice';
import { useMsal } from '@azure/msal-react';
import { useQuery } from 'react-query';
import { AccountType } from 'utils/types';
import { useApiGet } from 'utils/hooks';
import { CourierProvider, Inbox } from '@trycourier/react-inbox';
import { courierInboxDarkTheme, courierInboxLightTheme } from 'styles/themes';
import { Toast } from '@trycourier/react-toast';
import { COURIER_ID } from 'utils/constants';
import { Link } from 'react-router-dom';
import { ASTraficLight } from 'features/ASTraficLight';
import { TenantInfoContext } from 'App';
import { useTimezoneSetting } from 'features/SettingsModal/settingsApi';
import { setTimezone } from 'store/globalSlice';

const StyledLogoContainer = styled.div`
  svg {
    width: 11rem;
  }
`;

const StyledTenantName = styled.div`
  margin-right: 10px;
  font-weight: 600;
`;

export const Header: FC = () => {
  const dispatch = useAppDispatch();
  const { mail } = useAppSelector((state) => state.authReducer);
  const { themeSwitch, themeName } = useContext(ThemeFunctionsContext) || {};
  const { instance } = useMsal();

  const accountDataFetch = useApiGet('/account/me');
  const { data } = useQuery<AccountType, Error>(['account-info'], accountDataFetch);
  const { data: userTimezone } = useTimezoneSetting();

  const {
    info: { masterData, description },
  } = useContext(TenantInfoContext);
  useEffect(() => {
    if (data) {
      dispatch(
        setUserData({
          firstName: data.firstName,
          lastName: data.lastName,
          authorities: data.authorities,
        }),
      );
    }
  }, [data]);

  useEffect(() => {
    if (userTimezone) {
      dispatch(
        setTimezone({
          timezone: userTimezone,
        }),
      );
    }
  }, [userTimezone]);

  const azureLogOut = () => {
    instance
      .logoutRedirect()
      .then(() => {
        dispatch(logOut());
      })
      .catch((error) => console.error({ error }));
  };

  const dropDownOption = [
    {
      icon: <SettingsIcon />,
      title: 'Account settings',
      action: () =>
        dispatch(openModal({ modalId: 'settingsModal', props: { settingWindow: 'account' } })),
    },
    {
      icon: <NotificationSettingsIcon />,
      title: 'Notification settings',
      action: () =>
        dispatch(openModal({ modalId: 'settingsModal', props: { settingWindow: 'notification' } })),
    },
    {
      icon: <LogoutIcon />,
      title: 'Log out',
      action: azureLogOut,
    },
  ];

  return (
    <CourierProvider clientKey={COURIER_ID()} userId={data?.uuid}>
      <Toast />
      <StyledHeader masterData={masterData}>
        <StyledLogoContainer>
          <Link to="/app/">{themeName === 'lightTheme' ? <Logo /> : <LogoDarkMode />}</Link>
        </StyledLogoContainer>
        <RightSideFunctionsContainer>
          {description ? <StyledTenantName>Tenant: {description}</StyledTenantName> : ''}
          {data?.authorities.includes('SYSADMIN') && <ASTraficLight />}
          {themeSwitch && (
            <ThemeSwitchContainer>
              <span>Dark mode:</span>
              <ToggleSwitch isChecked={themeName === 'darkTheme'} onChange={themeSwitch} />
              {themeName === 'lightTheme' ? 'Off' : 'On'}
            </ThemeSwitchContainer>
          )}
          <Inbox
            theme={themeName === 'lightTheme' ? courierInboxLightTheme : courierInboxDarkTheme}
          />
          <DropdownMenu title={mail} dropdownOptions={dropDownOption} />
        </RightSideFunctionsContainer>
      </StyledHeader>
    </CourierProvider>
  );
};
