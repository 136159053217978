import { FC } from 'react';
import { useAppSelector } from 'store/hooks';
import { GridTableHeadingCell as TableHeadingCell, TableHeadingWithSort } from 'components';
import {
  GasDayIconActive,
  MessageDayIconActive,
  GasDayIconInactive,
  MessageDayIconInactive,
} from 'assets/icons';
import { SendDirectionFilterButton, MergedCell, Row } from './StyledComponents';
import { MessagesTableRow } from './MesssageTableRow';
import { SortDirection } from 'utils/types';
import { Message, SortType } from 'utils/types/generalTypes';
import styled from 'styled-components';

interface MessageTableProps {
  updateSort: (name: string, direction: SortDirection) => void;
  activeSort: SortType;
  messages: Array<Message>;
}

const TableHeadingSortContainer = styled(TableHeadingCell)`
  padding: 0 0.625rem;
`;

const Table = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const MessagesTable: FC<MessageTableProps> = ({ updateSort, activeSort, messages }) => {
  const { sortType } = useAppSelector((state) => state.messageReducer);

  const { name: sortName, direction: sortDirection } = activeSort;

  return (
    <Table>
      <Row>
        <TableHeadingCell />
        <TableHeadingCell />
        <TableHeadingCell padding="0" align="center">
          Status
        </TableHeadingCell>
        <TableHeadingCell>Message definition</TableHeadingCell>
        <TableHeadingCell align="center">Version</TableHeadingCell>
        <TableHeadingCell>Document type</TableHeadingCell>
        <TableHeadingSortContainer>
          <TableHeadingWithSort
            name="creationTime"
            direction={sortName === 'creationDate' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            {sortType === 'creationDate' ? <MessageDayIconActive /> : <MessageDayIconInactive />}
            Creation date
          </TableHeadingWithSort>
        </TableHeadingSortContainer>
        <TableHeadingSortContainer>
          <TableHeadingWithSort
            name="sendTime"
            direction={sortName === 'sendTime' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            Process date
          </TableHeadingWithSort>
        </TableHeadingSortContainer>
        <TableHeadingSortContainer>
          <TableHeadingWithSort
            name="gasDate"
            direction={sortName === 'gasDate' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            {sortType === 'gasDay' ? <GasDayIconActive /> : <GasDayIconInactive />}
            Gas Day
          </TableHeadingWithSort>
        </TableHeadingSortContainer>
        <TableHeadingCell padding="0">
          <MergedCell>
            <SendDirectionFilterButton
              name="sender"
              direction={sortName === 'sender' ? sortDirection : 'none'}
              setFilter={updateSort}
            >
              Sender
            </SendDirectionFilterButton>
            <SendDirectionFilterButton
              name="recipient"
              direction={sortName === 'recipient' ? sortDirection : 'none'}
              setFilter={updateSort}
            >
              Recipient
            </SendDirectionFilterButton>
          </MergedCell>
        </TableHeadingCell>
        <TableHeadingCell>Contract reference / Internal Account </TableHeadingCell>
        <TableHeadingCell>Identification</TableHeadingCell>
        <TableHeadingCell align="center" padding="0">
          XML
        </TableHeadingCell>
        <TableHeadingCell align="center">Details</TableHeadingCell>
      </Row>

      {messages.map((message, key) => (
        <MessagesTableRow
          message={message}
          key={`${message.documentIdentifier}_${key}`}
          isGray={key % 2 !== 0}
        />
      ))}
    </Table>
  );
};
