import { FC, useState, useEffect } from 'react';
import { Modal } from 'components';
import styled from 'styled-components';
import { ClassicButton, VARIANT } from 'components/Buttons/ClassicButton';
import { Checkbox, SearchInput, QuantityIndicator } from 'components';
import { useForm } from 'react-hook-form';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/modalSlice';
import { useMessageDefinition } from './balancegroupApi';
import { MessageDefinitionSlim } from 'utils/types';

const FilterBar = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem 1.5rem;
`;

const InfoHeading = styled.div`
  display: flex;
  justify-content: space-between;
  height: 3rem;
  width: 100%;
  background-color: ${({ theme }) => theme.milkGrey};
  align-items: center;
  padding: 0 1.5rem;
`;

const MessageDefinitionsContainer = styled.div`
  width: 100%;
  height: calc(100% - 18.375rem);
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ButtonsContainer = styled.div`
  width: 100%;
  height: 5.625rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  box-shadow: 0 -0.25rem 0.25rem rgba(198, 213, 221, 0.3);
`;

const StyledMessageDefinitionRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 2.5rem;
  max-height: 2.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
`;

const SectionLabel = styled.span`
  color: ${({ theme }) => theme.darkGrey};
  margin-bottom: 0.625rem;
  display: inline-block;
`;

const Section = styled.div`
  margin-bottom: 1.25rem;
`;

const SearchContainer = styled.form`
  width: 12.5rem;
`;

const ModalHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
`;

const externalContentStyles = {
  height: '37.5rem',
  width: '41.5rem',
  padding: '0',
  overflow: 'hidden',
};

interface State {
  selectedMessages: Array<MessageDefinitionSlim>;
  notSelectedMessages: Array<MessageDefinitionSlim>;
}

interface MessageDefinitionSelectionProps {
  currentMessageDefinitions: Array<MessageDefinitionSlim>;
}

export const MessageDefinitionSelection: FC<MessageDefinitionSelectionProps> = ({
  currentMessageDefinitions,
}) => {
  const [messages, setMessages] = useState<State>({
    selectedMessages: currentMessageDefinitions || [],
    notSelectedMessages: [],
  });
  const { data } = useMessageDefinition();
  const { selectedMessages, notSelectedMessages } = messages;

  useEffect(() => {
    if (currentMessageDefinitions.length > 0) {
      setMessages({
        selectedMessages: currentMessageDefinitions,
        notSelectedMessages,
      });
    }
  }, [currentMessageDefinitions]);

  useEffect(() => {
    if (data && data.content.length > 0) {
      const filteredSelectedMessages = [];
      // This solution has O(n^2) complexity which might be to slow when there is higher amount of data
      for (const notAssignedMessage of data.content) {
        const alreadySelected = selectedMessages.findIndex(
          (selectMessage) => selectMessage.id === notAssignedMessage.id,
        );
        if (alreadySelected === -1) {
          filteredSelectedMessages.push(notAssignedMessage);
        }
      }

      setMessages({
        selectedMessages: [...selectedMessages],
        notSelectedMessages: filteredSelectedMessages,
      });
    }
  }, [data]);
  const { register, watch, reset } = useForm({
    defaultValues: { nominationSearchKeyword: '' },
  });
  const nominationSearchKeyword = watch('nominationSearchKeyword');
  const { props } = useAppSelector((state) => state.modalReducer['messageDefinitionModal']);
  const dispatch = useAppDispatch();

  const handleUpdate = () => {
    console.log('search: ' + nominationSearchKeyword);
  };

  const resetSearch = () => {
    reset();
  };
  // TODO: extract everything in modal to enable normal component updates
  return (
    <>
      <InfoHeading>
        <div>
          <strong>Counterparty: </strong>
          {props?.counterpartyName}
        </div>
        <QuantityIndicator quantity={selectedMessages.length} />
      </InfoHeading>
      <FilterBar>
        <SearchContainer>
          <SearchInput
            register={register('nominationSearchKeyword')}
            placeholder="Search"
            handleUpdate={handleUpdate}
            value={nominationSearchKeyword}
            reset={resetSearch}
          />
        </SearchContainer>
      </FilterBar>

      <MessageDefinitionsContainer>
        {selectedMessages.length > 0 && (
          <Section>
            <SectionLabel>Selected</SectionLabel>
            {selectedMessages.map((messageDefinition, key) => (
              <StyledMessageDefinitionRow key={`${messageDefinition.contractReference}_${key}`}>
                <Checkbox
                  checked={true}
                  onChange={() => {
                    return;
                  }}
                  isDisabled
                />
                {messageDefinition.description}
              </StyledMessageDefinitionRow>
            ))}
          </Section>
        )}
        {notSelectedMessages.length > 0 && (
          <Section>
            <SectionLabel>New</SectionLabel>
            {notSelectedMessages.map((messageDefinition, key) => (
              <StyledMessageDefinitionRow key={`${messageDefinition.contractReference}_${key}`}>
                <Checkbox
                  checked={false}
                  onChange={() => {
                    return;
                  }}
                  isDisabled
                />
                {messageDefinition.description}
              </StyledMessageDefinitionRow>
            ))}
          </Section>
        )}
      </MessageDefinitionsContainer>

      <ButtonsContainer>
        <ClassicButton
          width="10rem"
          variant={VARIANT.SECONDARY}
          onClick={() => dispatch(closeModal({ modalId: 'messageDefinitionModal' }))}
        >
          Close
        </ClassicButton>
      </ButtonsContainer>
    </>
  );
};

interface MessageDefinitionModalProps extends MessageDefinitionSelectionProps {
  balanceGroupName: string;
}

export const MessageDefinitionModal: FC<MessageDefinitionModalProps> = ({
  currentMessageDefinitions,
  balanceGroupName,
}) => {
  const ModalTitleComponent = (
    <ModalHeading>Select message(s) for Balance Group {balanceGroupName}</ModalHeading>
  );

  // TODO: extract everything in modal to enable normal component updates
  return (
    <Modal
      modalId="messageDefinitionModal"
      externalContentStyles={externalContentStyles}
      headerContentComponent={ModalTitleComponent}
    >
      <MessageDefinitionSelection currentMessageDefinitions={currentMessageDefinitions} />
    </Modal>
  );
};
