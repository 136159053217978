import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ExpandSectionState {
  masterdata: number[];
  messageDefinitions: number[];
  areas: number[];
}

const initialState = {
  masterdata: [],
  messageDefinitions: [],
  areas: [],
} as ExpandSectionState;

const expandedSectionsSlice = createSlice({
  name: 'expandedSections',
  initialState,
  reducers: {
    toggleSection(
      state,
      action: PayloadAction<{ section: keyof ExpandSectionState; sectionId: number }>,
    ) {
      const currentSection = state[action.payload.section];
      const { sectionId } = action.payload;

      if (currentSection.includes(sectionId)) {
        currentSection.splice(currentSection.indexOf(sectionId), 1);
      } else {
        currentSection.push(sectionId);
      }
    },
  },
});

export const { toggleSection } = expandedSectionsSlice.actions;

export default expandedSectionsSlice.reducer;
