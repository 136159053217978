import { FC } from 'react';
import { Checkmark, Xmark } from 'assets/icons';

interface ActiveIndicatorProps {
  isActive: boolean;
}

export const ActiveIndicator: FC<ActiveIndicatorProps> = ({ isActive }) => {
  return isActive ? <Checkmark /> : <Xmark />;
};
