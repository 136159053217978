import { FC } from 'react';
import { MessageDefinitionSlim, SortDirection } from 'utils/types';
import styled from 'styled-components';
import { MessageDefinitionRow } from './MessageDefinitionRow';
import { GridMergedCell, GridTableHeadingCell, TableHeadingWithSort } from 'components';
import { SortType } from 'utils/types/generalTypes';
import { useAppSelector } from 'store/hooks';

const MasterdataTableContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns:
    minmax(12rem, 1fr) minmax(12rem, 1fr) minmax(12rem, 1fr) minmax(12rem, 1fr) minmax(12rem, 1.5fr)
    minmax(12rem, 1fr) minmax(10rem, 1fr) minmax(10rem, 1fr) 4.5rem 4.5rem 3.5rem;
  grid-column-gap: 0.3125rem; // TODO: make this value in theme so it's shared
`;

const BlankCell = styled.div`
  width: 2.5rem;
  display: flex;
`;

const TableHeadingSortContainer = styled(GridTableHeadingCell)`
  padding: 0 0.625rem;
`;

interface MasterdataCounterpartiesProps {
  messageDefinitions: Array<MessageDefinitionSlim>;
  updateSort: (name: string, direction: SortDirection) => void;
  activeSort: SortType;
}

export const MessageDefinitionsTable: FC<MasterdataCounterpartiesProps> = ({
  messageDefinitions,
  updateSort,
  activeSort,
}) => {
  const { name: sortName, direction: sortDirection } = activeSort;
  const expandedSections = useAppSelector((state) => state.expandedSectionsSlice);

  return (
    <MasterdataTableContainer>
      <Row>
        <TableHeadingSortContainer>
          <TableHeadingWithSort
            name="area.name"
            direction={sortName === 'area.name' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            Area
          </TableHeadingWithSort>
        </TableHeadingSortContainer>
        <TableHeadingSortContainer>
          {' '}
          <TableHeadingWithSort
            name="description"
            direction={sortName === 'description' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            Description
          </TableHeadingWithSort>
        </TableHeadingSortContainer>
        <TableHeadingSortContainer>
          {' '}
          <TableHeadingWithSort
            name="messageType.description"
            direction={sortName === 'messageType.description' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            Message Type
          </TableHeadingWithSort>
        </TableHeadingSortContainer>
        <TableHeadingSortContainer>
          {' '}
          <TableHeadingWithSort
            name="recipientMarketParticipant.name"
            direction={sortName === 'recipientMarketParticipant.name' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            Recipient
          </TableHeadingWithSort>
        </TableHeadingSortContainer>
        <TableHeadingSortContainer>
          {' '}
          <TableHeadingWithSort
            name="contractReference"
            direction={sortName === 'contractReference' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            Contract reference
          </TableHeadingWithSort>
        </TableHeadingSortContainer>
        <TableHeadingSortContainer>
          {' '}
          <TableHeadingWithSort
            name="documentType.identification"
            direction={sortName === 'documentType.identification' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            Document Type
          </TableHeadingWithSort>
        </TableHeadingSortContainer>
        <GridTableHeadingCell>Version</GridTableHeadingCell>
        <GridMergedCell>
          <GridTableHeadingCell>Valid from</GridTableHeadingCell>
          <GridTableHeadingCell>Valid to</GridTableHeadingCell>
        </GridMergedCell>
        <GridTableHeadingCell>Active</GridTableHeadingCell>
        <GridTableHeadingCell>Action</GridTableHeadingCell>
        <BlankCell />
      </Row>
      {messageDefinitions.map((messageDefinition) => (
        <MessageDefinitionRow
          key={messageDefinition.id}
          messageDefinition={messageDefinition}
          isExpanded={expandedSections.messageDefinitions.includes(messageDefinition.id)}
        />
      ))}
    </MasterdataTableContainer>
  );
};
