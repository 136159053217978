import { ContentContainer, MainContainer } from 'components';
import { ConnectionTestForm } from './ConnectionTestForm';
import { MasterDataExportImport } from './MasterDataExportImport';
import { ResetGasday } from './ResetGasday';
import { PropertiesTable } from './PropertiesTable';
import { ResponseErrorModal } from './ResponseErrorModal';
import { AdminErrorModal } from './AdminErrorModal';
import { FC } from 'react';

export const AdminPanel: FC = () => {
  return (
    <MainContainer>
      <ContentContainer width="80%">
        <MasterDataExportImport />
        <ResetGasday />
        <PropertiesTable />
        <ConnectionTestForm />
      </ContentContainer>
      <ResponseErrorModal />
      <AdminErrorModal />
    </MainContainer>
  );
};
