import { DivTableCell as TableCell } from 'components';
import styled from 'styled-components';

const DashboardSectionTitle = styled.h2`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

const CustomDateContainer = styled.div<{ isDark?: boolean }>`
  padding: 0 0.625rem;
  display: flex;
  align-items: center;
  height: 2.5rem;
  ${({ isDark, theme }) => isDark && `background-color: ${theme.milkGrey}`};
  width: 100%;
`;

// TODO: Rething approach to tables with not expanding stucture
const TableContainer = styled.section`
  flex: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  * {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Row = styled.div<{ isFailure?: boolean }>`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.tableDividingLine};
  align-items: center;

  ${({ isFailure, theme }) => isFailure && `background-color: ${theme.transparentRed};`}
`;

const HeadingRow = styled(Row)`
  border: none;
`;

const AutoSizerContainer = styled.div`
  flex: 1 1 auto;
`;

const WhiteTableCells = styled(TableCell)`
  background-color: rgba(0, 0, 0, 0);
  font-size: 1rem;
  /* padding: 0; */
  height: 2.5rem;
`;

export {
  DashboardSectionTitle,
  CustomDateContainer,
  TableContainer,
  Row,
  AutoSizerContainer,
  WhiteTableCells,
  HeadingRow,
};
