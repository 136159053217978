import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  ClassicButton,
  VARIANT,
  ConfirmationPopup,
  MainContainer,
  ContentContainer,
  HeadingBar,
  FormTitle,
  FormContainer,
  FormSection,
  FormSectionTitle,
  StyledTextInput,
  ActionBar,
  ConfirmationPopupText,
  FormMetadataBox,
  CheckboxCotainer,
  ControlledCheckbox,
  ControlledToggleInputWithLabel,
  ErrorBox,
} from 'components';
import { FieldValues, useForm } from 'react-hook-form';
import { useCounterparty, useCounterpartySave } from './counterpartyApi';
import { IdentifiersTable } from 'components';
import { RolesTable } from './RolesTable';
import { BalancegroupInfo, Identifier, MainCustomErrorType, Role, RoleType } from 'utils/types';
import { openModal } from 'store/modalSlice';
import { useAppDispatch } from 'store/hooks';
import { LoadingScreen } from 'components/LoadingScreen';
import { getInitialDate } from 'utils/getInitialDate';
import { useRoles } from 'utils/hooks/useRoleType';
import { useIdentifier } from 'utils/hooks/useIdentifier';
import { createIdentifierArray } from 'components/IdentifierComboForm';
import styled from 'styled-components';
import { formatDateForInput } from 'utils/dateFormating';
interface CounterpartyFormProps {
  option: 'create' | 'edit';
  id?: string;
}

const FlexContainerWithSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`; //TODO REFACTOR THIS LAYOUT TO SOMETHING MORE TOUGHTFULL, MAYBE GRID

const createRoleArray = (roleTypes: Array<RoleType>): Array<Role> => {
  return roleTypes.map((roleType) => ({
    role: roleType,
    validFrom: formatDateForInput(getInitialDate(true)),
    validTo: formatDateForInput(new Date('2099-09-30')),
  }));
};

export const CounterpartyForm: FC<CounterpartyFormProps> = ({ option, id }) => {
  const {
    register,
    reset,
    getValues,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      code: '',
      ownCounterparty: false,
      active: true,
    } as FieldValues,
  });
  const [localState, setLocalState] = useState({
    identifiers: [] as Array<Identifier>,
    isUserDefined: true,
    localBalanceGroups: ([] as Array<BalancegroupInfo>) || undefined,
    roles: [] as Array<Role>,
    hasChanged: false,
  });

  const [errors, setErrors] = useState<MainCustomErrorType>();
  const updateErrors = (error: MainCustomErrorType) => {
    setErrors(error);
  };

  const history = useHistory();
  const { mutate: saveCounterparty, isLoading: counterpartyIsSaving } = useCounterpartySave(
    option,
    updateErrors,
  );
  const dispatch = useAppDispatch();

  const { identifiers, isUserDefined, localBalanceGroups, roles, hasChanged } = localState;
  const { data, isLoading } = useCounterparty(id);
  const cleanIdentifiers = useIdentifier(option === 'create' && identifiers.length === 0);
  const cleanRoles = useRoles();

  useEffect(() => {
    if (option === 'edit' && data) {
      const {
        name,
        code,
        identifiers,
        userDefined,
        balanceGroups,
        roles,
        active,
        ownCounterparty,
      } = data;

      reset({
        name,
        code,
        ownCounterparty,
        active,
      });

      setLocalState({
        ...localState,
        identifiers,
        isUserDefined: !!userDefined,
        localBalanceGroups: balanceGroups ? balanceGroups : [],
        roles,
      });
    }

    if (option === 'create' && cleanIdentifiers.data && cleanRoles.data) {
      const newIdentifiers = createIdentifierArray(cleanIdentifiers.data.content).filter(
        (identifier) => identifier.type.name === 'EIC',
      );
      const newRoles = createRoleArray(cleanRoles.data.content).filter(
        (role) => role.role.role === 'Balance Responsible Party', // TODO: figure out better naming to avoid role role role thing...
      );
      setLocalState({
        ...localState,
        identifiers: newIdentifiers,
        roles: newRoles,
      });
    }
  }, [data, option, cleanIdentifiers.data, cleanRoles.data]);

  const sendSaveRequest = () => {
    const { name, code, active, ownCounterparty } = getValues();
    const newCounterpartyObject = {
      code,
      id: (id && Number.parseInt(id)) || undefined,
      identifiers,
      name,
      active,
      userDefined: isUserDefined,
      balanceGroups: localBalanceGroups,
      roles,
      ownCounterparty,
    };

    if (option === 'edit' && data) {
      saveCounterparty({
        ...data,
        ...newCounterpartyObject,
      });
    } else {
      saveCounterparty(newCounterpartyObject);
    }
  };

  const updateIdentifiers = (identifiers: Array<Identifier>) => {
    setLocalState({
      ...localState,
      identifiers,
      hasChanged: true,
    });
  };

  const addNewIdentifier = (identifier: Identifier) => {
    setLocalState({
      ...localState,
      identifiers: [...localState.identifiers, identifier],
      hasChanged: true,
    });
  };

  const addNewRole = (role: Role) => {
    setLocalState({
      ...localState,
      roles: [...localState.roles, role],
      hasChanged: true,
    });
  };

  const updateRoles = (roles: Array<Role>) => {
    setLocalState({
      ...localState,
      roles,
      hasChanged: true,
    });
  };

  const formTitle = option === 'create' ? 'Add new counterparty' : 'Edit counterparty';
  const shouldDisableSubmit = () => {
    if (option === 'edit') {
      return !hasChanged && (!isDirty || !isValid);
    } else {
      return !isDirty || !isValid || !hasChanged;
    }
  };
  return (
    <MainContainer>
      <ContentContainer width="65%">
        <HeadingBar>
          <FormTitle>{formTitle}</FormTitle>
        </HeadingBar>
        {isLoading && option === 'edit' ? (
          <LoadingScreen />
        ) : (
          <FormContainer>
            <form>
              <FlexContainerWithSpaceBetween>
                <FormSection>
                  <FormSectionTitle>Base data</FormSectionTitle>
                  <StyledTextInput
                    type="text"
                    label="Name"
                    placeholder="e.g. RWE Supply & Trading GmbH"
                    register={register('name', {
                      required: true,
                      minLength: 2,
                    })}
                    width="17.5rem"
                  />
                  <StyledTextInput
                    type="text"
                    label="Display name"
                    placeholder="e.g. RWE"
                    register={register('code', {
                      required: true,
                      minLength: 2,
                    })}
                    width="17.5rem"
                  />
                  <CheckboxCotainer>
                    <ControlledCheckbox name="ownCounterparty" control={control} />
                    <label>Own counterparty</label>
                  </CheckboxCotainer>
                </FormSection>
                {option === 'edit' &&
                  data?.fipDatCreated &&
                  data?.fipDate &&
                  data.fipUser &&
                  data.fipUserCreated && (
                    <FormMetadataBox
                      fipDatCreated={data?.fipDatCreated}
                      fipDate={data?.fipDate}
                      fipUser={data.fipUser}
                      fipUserCreated={data.fipUserCreated}
                    />
                  )}
              </FlexContainerWithSpaceBetween>
            </form>
            <FormSection>
              <FormSectionTitle>Activation</FormSectionTitle>
              <ControlledToggleInputWithLabel control={control} name="active" />
            </FormSection>
            <FormSection>
              <FormSectionTitle>Identifier</FormSectionTitle>
              <IdentifiersTable
                identifiers={identifiers}
                updateIdentifiers={updateIdentifiers}
                addIdentifier={addNewIdentifier}
              />
            </FormSection>
            <FormSection>
              <FormSectionTitle>Roles</FormSectionTitle>
              {roles.length > 0 && (
                <RolesTable roles={roles} addRole={addNewRole} updateRoles={updateRoles} />
              )}
            </FormSection>
            {errors && errors.errors.length > 0 && (
              <ErrorBox errors={errors.errors.map((error) => error.message)} />
            )}
          </FormContainer>
        )}
        <ActionBar>
          <ClassicButton
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={() => history.push('/app/masterdata/')}
          >
            Cancel
          </ClassicButton>
          <ClassicButton
            width="10rem"
            variant={VARIANT.PURPLE}
            onClick={() => dispatch(openModal({ modalId: 'counterpartyConfirmationModal' }))}
            disabled={shouldDisableSubmit()}
          >
            {option === 'create' && 'Add'}
            {option === 'edit' && 'Save'}
          </ClassicButton>
        </ActionBar>
        <ConfirmationPopup
          isLoading={counterpartyIsSaving}
          confirmationPopupId="counterpartyConfirmationModal"
          confirmButtonAction={() => sendSaveRequest()}
        >
          <ConfirmationPopupText>
            {option === 'create' ? (
              <>
                Shall we go ahead and save <span>{getValues().companyName}</span> as a new
                counterparty?{' '}
              </>
            ) : (
              <>
                Shall we go ahead and update the Masterdata for Counterparty{' '}
                <span>{getValues().companyName}</span>{' '}
              </>
            )}
          </ConfirmationPopupText>
        </ConfirmationPopup>
      </ContentContainer>
    </MainContainer>
  );
};
