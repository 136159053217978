import { EditIcon, TrashIcon } from 'assets/icons';
import {
  BaseButton,
  BaseButtonWithLoader,
  ButtonWithLoader,
  ClassicButton,
  ControlledToggleInput,
  TextInput,
  ToggleSwitch,
  VARIANT,
} from 'components';
import {
  CellWithPadding,
  CenteredNameCell,
  NameCellWithPadding,
  SettingContainer,
  UserSettingsRow,
} from './styled';
import { FC, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Email, useEmailAdd, useEmailEdit, useEmailRemove } from './settingsApi';
import { ErrorBox } from 'components';
import { MainCustomErrorType } from 'utils/types';

const AddButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.mainBackgroundColor};
`;

const EditButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface EmailFormProps {
  emails: Array<Email>;
}

const validateEmail = (email: string) => {
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (emailPattern.test(email)) {
    return true;
  }

  return 'Email is not valid';
};

export const EmailForm: FC<EmailFormProps> = ({ emails }) => {
  const [addFormActive, setAddFormActive] = useState(false);
  const {
    register,
    control,
    formState: { isDirty, isValid, errors: formError },
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      email: '',
      usedForCorrespondence: false,
    } as FieldValues,
    mode: 'all',
  });

  const { mutateAsync: addEmail, error: addEmailError } = useEmailAdd();
  const emailError = addEmailError as MainCustomErrorType;

  const closeForm = () => {
    reset();
    setAddFormActive(false);
    emailError.errors = [];
  };

  const addNewMail = async () => {
    const { email, usedForCorrespondence } = getValues();
    try {
      await addEmail({ email, usedForCorrespondence });
      closeForm();
    } catch (error) {}
  };

  return (
    <SettingContainer>
      <UserSettingsRow>
        <NameCellWithPadding>Address</NameCellWithPadding>
        <NameCellWithPadding>Send Notifications</NameCellWithPadding>
        <CenteredNameCell>Action</CenteredNameCell>
      </UserSettingsRow>
      {emails.map((email) => (
        <EmailEditRow key={email.id} {...email} />
      ))}
      {addFormActive ? (
        <UserSettingsRow>
          <CellWithPadding>
            <TextInput
              register={register('email', {
                required: true,
                validate: validateEmail,
              })}
              placeholder=""
              type="text"
              isSlim
              error={formError && 'email' in formError ? formError.email : ''}
            />
          </CellWithPadding>
          <CellWithPadding>
            <ControlledToggleInput control={control} name="usedForCorrespondence" />
          </CellWithPadding>
          <CellWithPadding>
            <EditButtonsContainer>
              <ClassicButton width="6rem" onClick={closeForm} variant={VARIANT.SECONDARY}>
                Cancel
              </ClassicButton>
              <ClassicButton
                width="6rem"
                onClick={addNewMail}
                variant={VARIANT.PRIMARY}
                disabled={!(isDirty && isValid)}
              >
                Save
              </ClassicButton>
            </EditButtonsContainer>
          </CellWithPadding>
        </UserSettingsRow>
      ) : (
        <AddButtonRow>
          <CellWithPadding>
            <ClassicButton
              width="6rem"
              onClick={() => setAddFormActive(true)}
              variant={VARIANT.PRIMARY}
            >
              Add
            </ClassicButton>
          </CellWithPadding>
        </AddButtonRow>
      )}

      {emailError && emailError.errors.length > 0 ? (
        <CenteredNameCell>
          <ErrorBox errors={emailError.errors.map((error) => error.message)} />
        </CenteredNameCell>
      ) : (
        ''
      )}
    </SettingContainer>
  );
};

const EmailEditRow: FC<Email> = ({ email: currentEmail, usedForCorrespondence, ...props }) => {
  const {
    register,
    control,
    formState: { isDirty, isValid, errors: formError },
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      email: currentEmail,
      usedForCorrespondence,
    } as FieldValues,
    mode: 'all',
  });

  const { mutateAsync: editEmail, isLoading: isSaving, error: updateEmailError } = useEmailEdit();
  const { mutate: removeEmail, isLoading: isRemoving } = useEmailRemove();
  const [editActive, setEditActive] = useState(false);
  const emailError = updateEmailError as MainCustomErrorType;

  const updateEmail = async () => {
    try {
      const { email, usedForCorrespondence } = getValues();
      if (email) {
        await editEmail({ email, usedForCorrespondence, ...props });
      } else {
        await editEmail({ email: currentEmail, usedForCorrespondence, ...props });
      }
      closeForm();
    } catch (error) {}
  };

  const closeForm = () => {
    reset();
    setEditActive(false);
    emailError.errors = [];
  };

  useEffect(() => {
    reset({
      email: currentEmail,
      usedForCorrespondence,
    });
  }, [currentEmail, usedForCorrespondence]);

  return editActive ? (
    <>
      <UserSettingsRow>
        <CellWithPadding>
          <TextInput
            register={register('email', { required: true, validate: validateEmail })}
            placeholder=""
            type="text"
            isSlim
            isDisabled={!props.editable}
            error={formError && 'email' in formError ? formError.email : ''}
          />
        </CellWithPadding>
        <CellWithPadding>
          <ControlledToggleInput control={control} name="usedForCorrespondence" />
        </CellWithPadding>
        <CellWithPadding>
          <EditButtonsContainer>
            <ClassicButton width="6rem" onClick={closeForm} variant={VARIANT.SECONDARY}>
              Cancel
            </ClassicButton>
            <ButtonWithLoader
              width="6rem"
              onClick={updateEmail}
              variant={VARIANT.PRIMARY}
              isLoading={isSaving}
              disabled={!(isDirty && isValid)}
            >
              Save
            </ButtonWithLoader>
          </EditButtonsContainer>
        </CellWithPadding>
      </UserSettingsRow>
      {emailError && emailError.errors.length > 0 ? (
        <CenteredNameCell>
          <ErrorBox errors={emailError.errors.map((error) => error.message)} />
        </CenteredNameCell>
      ) : (
        ''
      )}
    </>
  ) : (
    <UserSettingsRow>
      <CellWithPadding>{currentEmail}</CellWithPadding>
      <CellWithPadding>
        <ToggleSwitch
          isChecked={usedForCorrespondence}
          onChange={() => {
            return;
          }}
          disabled
        />
      </CellWithPadding>
      <CellWithPadding>
        <BaseButton
          width="6rem"
          onClick={() => setEditActive(true)}
          variant={VARIANT.PRIMARY}
          // disabled={!props.editable}
        >
          <EditIcon />
        </BaseButton>
        <BaseButtonWithLoader
          width="6rem"
          onClick={() => removeEmail({ email: currentEmail, usedForCorrespondence, ...props })}
          variant={VARIANT.PRIMARY}
          disabled={!props.editable}
          isLoading={isRemoving}
        >
          <TrashIcon />
        </BaseButtonWithLoader>
      </CellWithPadding>
    </UserSettingsRow>
  );
};
