import { FC } from 'react';
import styled from 'styled-components';
import { NotFound } from 'assets/icons';
import { ClassicButton } from 'components';
import { useHistory } from 'react-router';

const ErrorPageContainer = styled.div`
  padding: 1.5rem;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.milkGrey};

  svg {
    margin-left: 10rem;
  }
`;

const ErrorHeading = styled.h1`
  font-size: 2.25rem;
  font-weight: 600;
  margin: 4rem 0 2.5rem 0;
`;

export const NotFoundPage: FC = () => {
  const history = useHistory();
  return (
    <ErrorPageContainer>
      <NotFound />
      <ErrorHeading>Page not found</ErrorHeading>
      <ClassicButton width="10rem" onClick={() => history.push('/')}>
        Go home
      </ClassicButton>
    </ErrorPageContainer>
  );
};
