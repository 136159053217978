import { ButtonWithLoader, ControlledToggleInput } from 'components';
import { NotificationTypeSetings } from './settingsApi';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  CenteredCell,
  CenteredNameCell,
  NameCell,
  SettingContainer,
  SettingsContainerRow,
} from './styled';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;

interface NotificationSettingFormProps {
  notificationSettings: NotificationTypeSetings;
  saveAction: (settings: NotificationTypeSetings) => void;
  isLoading: boolean;
}

export const NotificationSettingsForm: FC<NotificationSettingFormProps> = ({
  notificationSettings,
  saveAction,
  isLoading,
}) => {
  const {
    control,
    getValues,
    reset,
    formState: { isDirty, isValid },
  } = useForm();

  useEffect(() => {
    if (notificationSettings) {
      reset(notificationSettings);
    }
  }, [notificationSettings]);

  const submitChanges = () => {
    const values = getValues();
    saveAction(values as NotificationTypeSetings);
  };

  return (
    <>
      <SettingsContainerRow>
        <NameCell></NameCell>
        <CenteredNameCell>Send info</CenteredNameCell>
        <CenteredNameCell>Send warning</CenteredNameCell>
        <CenteredNameCell>Send error</CenteredNameCell>
        <CenteredNameCell>Action</CenteredNameCell>
      </SettingsContainerRow>
      <SettingContainer>
        {notificationSettings &&
          Object.entries(notificationSettings).map(([key, { name }]) => (
            <SettingsContainerRow key={name}>
              <NameCell>{name}</NameCell>
              <CenteredCell>
                <ControlledToggleInput control={control} name={`${key}.infos`} />
              </CenteredCell>
              <CenteredCell>
                <ControlledToggleInput control={control} name={`${key}.warnings`} />
              </CenteredCell>
              <CenteredCell>
                <ControlledToggleInput control={control} name={`${key}.errors`} />
              </CenteredCell>
              <CenteredCell></CenteredCell>
            </SettingsContainerRow>
          ))}
      </SettingContainer>
      <ButtonContainer>
        <ButtonWithLoader
          onClick={submitChanges}
          isLoading={isLoading}
          width="10rem"
          disabled={!(isDirty && isValid)}
        >
          Submit changes
        </ButtonWithLoader>
      </ButtonContainer>
    </>
  );
};
