import { setError } from 'features/ErrorScreen/errorSlice';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import timezones, { TimeZone } from 'timezones-list';
import { API, useApiGet } from 'utils/hooks';

import { MainCustomErrorType } from 'utils/types';

interface LevelPreferencesType {
  errors: boolean;
  infos: boolean;
  warnings: boolean;
  id: number;
}

interface NotificationSetting {
  enabled: boolean;
  id: number;
  levelPreferences: LevelPreferencesType;
  name: string;
}

interface NotificationSettingWithWebhook extends NotificationSetting {
  webhookAddress: string;
}

export interface NotificationTypeSetings {
  emailNotificationChannel: NotificationSetting;
  pushNotificationChannel: NotificationSetting;
  textNotificationChannel: NotificationSetting;
  teamsNotificationChannel: NotificationSettingWithWebhook;
}

interface UserNotificationSettings {
  enabled: boolean;
  errors: boolean;
  fipDate: string;
  fipUser: string;
  fipVersion: string;
  id: undefined | number;
  infos: boolean;
  name: string;
  warnings: boolean;
}

export interface UserNotificationTypeSettings {
  fipDate: string;
  fipUser: string;
  fipVersion: string;
  id: undefined | number;
  emailNotificationPreferences: UserNotificationSettings;
  internalNotificationPreferences: UserNotificationSettings;
  pushNotificationPreferences: UserNotificationSettings;
  slackNotificationPreferences: UserNotificationSettings;
  teamsNotificationPreferences: UserNotificationSettings;
  textNotificationPreferences: UserNotificationSettings;
}

export interface UserTimezoneSettings {
  fipDate: string;
  fipUser: string;
  fipVersion: number;
  id: number;
  timeZone: string;
  utc?: string;
}

export const useGlobalNotifcationSetting = () => {
  const fetchNotifiactionSettings = useApiGet('/notification-channel');
  return useQuery<NotificationTypeSetings, Error>(
    ['globalNotificationSettings'],
    fetchNotifiactionSettings,
  );
};

export const useUserNotificationSettings = () => {
  const fetchNotifiactionSettings = useApiGet('/account/me/notification-preferences');
  return useQuery<UserNotificationTypeSettings, Error>(
    ['notificationSettings'],
    fetchNotifiactionSettings,
  );
};

export const useGlobalNotificationSettingsSave = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (data: NotificationTypeSetings) => {
      return API.PUT('/notification-channel', {
        headers: {
          Authorization: token,
        },
        body: JSON.stringify(data),
      });
    },
    {
      onError: (error: Error) => {
        dispatch(setError({ errorMessage: error.toString() }));
      },
      onSuccess: () => {
        queryClient.invalidateQueries('globalNotificationSettings');
      },
    },
  );
};
export const useUserNotificationSettingsSave = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (data: UserNotificationTypeSettings) => {
      const { id } = data;

      const method = null === id ? 'POST' : 'PUT';

      return API[method]('/account/me/notification-preferences', {
        headers: {
          Authorization: token,
        },
        body: JSON.stringify(data),
      });
    },
    {
      onError: (error: Error) => {
        dispatch(setError({ errorMessage: error.toString() }));
      },
      onSuccess: () => {
        queryClient.invalidateQueries('notificationSettings');
      },
    },
  );
};

export const useTimezoneSetting = () => {
  const fetchTimezoneSettings = useApiGet('/account/me/interface-preferences');

  return useQuery<UserTimezoneSettings, Error>({
    queryKey: ['timezoneSetting'],
    queryFn: async () => {
      const userTimezoneSettings: UserTimezoneSettings = await fetchTimezoneSettings();
      const record = timezones.find((e) => e.tzCode === userTimezoneSettings.timeZone);
      userTimezoneSettings.utc = record?.utc;
      return userTimezoneSettings;
    },
  });
};

export const useTimezoneSettingSave = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const { timezone } = useAppSelector((state) => state.globalSlice);
  const queryClient = useQueryClient();

  return useMutation(
    (data: TimeZone) => {
      return null === timezone?.id
        ? API.POST('/account/me/interface-preferences', {
            headers: {
              Authorization: token,
            },
            body: JSON.stringify({ timeZone: data.tzCode }),
          })
        : API.PUT('/account/me/interface-preferences', {
            headers: {
              Authorization: token,
            },
            body: JSON.stringify({
              id: timezone?.id,
              fipVersion: timezone?.fipVersion,
              timeZone: data.tzCode,
            }),
          });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('timezoneSetting');
      },
    },
  );
};

export interface PhoneNumber {
  editable: boolean;
  fipDatCreated: string;
  fipDate: string;
  fipUser: string;
  fipUserCreated: string;
  fipVersion: number;
  id: number;
  phoneNumber: string;
  usedForCorrespondence: boolean;
}

interface PhoneNumberList {
  content: Array<PhoneNumber>;
}

export const usePhoneNumbers = () => {
  const fetchPhoneNumbers = useApiGet('/account/me/phone-number');
  return useQuery<PhoneNumberList, Error>(['phoneNumbers'], fetchPhoneNumbers);
};

interface NewPhoneNumber {
  phoneNumber: string;
  usedForCorrespondence: boolean;
}

export const usePhoneNumberAdd = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (data: NewPhoneNumber) => {
      return API.POST('/account/me/phone-number', {
        headers: {
          Authorization: token,
        },
        body: JSON.stringify(data),
      });
    },
    {
      onError: (error: MainCustomErrorType) => {
        if ('500' == error.code) {
          dispatch(setError({ errorMessage: error.toString() }));
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries('phoneNumbers');
      },
    },
  );
};

export const usePhoneNumberRemove = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (data: PhoneNumber) => {
      return API.DELETE(`/account/me/phone-number/${data.id}`, {
        headers: {
          Authorization: token,
        },
      });
    },
    {
      onError: (error: Error) => {
        dispatch(setError({ errorMessage: error.toString() }));
      },
      onSuccess: () => {
        queryClient.invalidateQueries('phoneNumbers');
      },
    },
  );
};

export const usePhoneNumberEdit = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (data: PhoneNumber) => {
      return API.PUT('/account/me/phone-number', {
        headers: {
          Authorization: token,
        },
        body: JSON.stringify(data),
      });
    },
    {
      onError: (error: MainCustomErrorType) => {
        if ('500' == error.code) {
          dispatch(setError({ errorMessage: error.toString() }));
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries('phoneNumbers');
      },
    },
  );
};

export interface Email {
  editable: boolean;
  fipDatCreated: string;
  fipDate: string;
  fipUser: string;
  fipUserCreated: string;
  fipVersion: number;
  id: number;
  email: string;
  usedForCorrespondence: boolean;
}

interface EmailList {
  content: Array<Email>;
}

export const useEmails = () => {
  const fetchEmails = useApiGet('/account/me/email');
  return useQuery<EmailList, Error>(['emails'], fetchEmails);
};

interface NewEmail {
  email: string;
  usedForCorrespondence: boolean;
}

export const useEmailAdd = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (data: NewEmail) => {
      return API.POST('/account/me/email', {
        headers: {
          Authorization: token,
        },
        body: JSON.stringify(data),
      });
    },
    {
      onError: (error: MainCustomErrorType) => {
        if ('500' == error.code) {
          dispatch(setError({ errorMessage: error.toString() }));
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries('emails');
      },
    },
  );
};

export const useEmailEdit = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (data: Email) => {
      return API.PUT('/account/me/email', {
        headers: {
          Authorization: token,
        },
        body: JSON.stringify(data),
      });
    },
    {
      onError: (error: MainCustomErrorType) => {
        if ('500' == error.code) {
          dispatch(setError({ errorMessage: error.toString() }));
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries('emails');
      },
    },
  );
};

export const useEmailRemove = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (data: Email) => {
      return API.DELETE(`/account/me/email/${data.id}`, {
        headers: {
          Authorization: token,
        },
      });
    },
    {
      onError: (error: Error) => {
        dispatch(setError({ errorMessage: error.toString() }));
      },
      onSuccess: () => {
        queryClient.invalidateQueries('emails');
      },
    },
  );
};
