import { FC } from 'react';
import styled from 'styled-components';
import { useAppSelector } from 'store/hooks';
import { useResendMessages } from '../messageApi';
import { ComboFilter } from './ComboFilter';
import { ButtonWithLoader } from 'components';

const ControlPanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
`;

const LeftSideContainer = styled.div`
  display: flex;

  > * {
    margin-right: 0.5rem;
  }
`;
const RightSideContainer = styled.div`
  display: flex;
  flex: 1;
`;

interface ControlPanelProps {
  setSearchKeyword: (keyword: string) => void;
  selectedDate: Date;
}

export const ControlPanel: FC<ControlPanelProps> = ({ setSearchKeyword, selectedDate }) => {
  const { selected } = useAppSelector((state) => state.messageReducer);
  const { mutate: messagesResend, isLoading } = useResendMessages();

  return (
    <ControlPanelContainer>
      <LeftSideContainer>
        <ButtonWithLoader
          isLoading={isLoading}
          width="10rem"
          disabled={selected.length === 0}
          onClick={() => messagesResend(false)}
        >
          Resend message
        </ButtonWithLoader>
        <ButtonWithLoader
          isLoading={isLoading}
          width="18rem"
          disabled={selected.length === 0}
          onClick={() => messagesResend(true)}
        >
          Resend message with new version
        </ButtonWithLoader>
      </LeftSideContainer>
      <RightSideContainer>
        <ComboFilter setSearchKeyword={setSearchKeyword} selectedDate={selectedDate} />
      </RightSideContainer>
    </ControlPanelContainer>
  );
};
