import { PageContainer } from '../../../components';
import { createContext, FC, ReactNode, UIEvent, useState, useRef, useEffect } from 'react';

interface PageContainerScrollTrackingProps {
  children: ReactNode;
}

interface PageContainerScrollTrackingContextProps {
  scrollValue: number;
  widthValue: number;
}
export const PageContainerScrollTrackingContext =
  createContext<PageContainerScrollTrackingContextProps>({
    scrollValue: 0,
    widthValue: 0,
  });

export const PageContainerScrollTracking: FC<PageContainerScrollTrackingProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [scrollValue, setScrollValue] = useState(0);
  const [widthValue, setWidthValue] = useState(0);

  const handleOnScroll = (event: UIEvent<HTMLElement>) => {
    const { scrollTop, clientWidth } = event.currentTarget;
    setScrollValue(scrollTop);
    setWidthValue(clientWidth);
  };

  const handleResize = () => {
    if (containerRef.current) {
      setWidthValue(containerRef.current.clientWidth);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      setWidthValue(containerRef.current.clientWidth);
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <PageContainer onScroll={handleOnScroll} ref={containerRef}>
      <PageContainerScrollTrackingContext.Provider value={{ scrollValue, widthValue }}>
        {children}
      </PageContainerScrollTrackingContext.Provider>
    </PageContainer>
  );
};
