import { FC } from 'react';
import styled from 'styled-components';
import { MultiOptionCheckbox } from 'components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAll, unselectAll } from '../nominationReduxSlice';
import { ComboFilter } from './ComboFilter';

const ControlPanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
`;

const LeftSideContainer = styled.div`
  display: flex;

  > * {
    margin-right: 0.5rem;
  }
`;
const RightSideContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const ControlPanel: FC = () => {
  const dispatch = useAppDispatch();
  const { areas } = useAppSelector((state) => state.nominationReducer);

  const isSelected = areas?.filter((area) => area.isSelected).length === areas?.length;
  let isSomeSelected = false;
  if (!isSelected) {
    isSomeSelected = !!areas?.some(
      (area) =>
        area.isSelected ||
        area.connectionPoints.some((connectionPoint) => connectionPoint.isSelected),
    );
  }

  return (
    <ControlPanelContainer>
      <LeftSideContainer>
        <MultiOptionCheckbox
          isIndeterminate={isSomeSelected}
          isSelected={isSelected}
          selectAction={() => dispatch(selectAll())}
          unselectAction={() => dispatch(unselectAll())}
        />
      </LeftSideContainer>
      <RightSideContainer>
        <ComboFilter />
      </RightSideContainer>
    </ControlPanelContainer>
  );
};
