import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterType } from 'utils/translateFiltersToPath';
import { Message } from 'utils/types';

interface SelectedMessage {
  id: number;
  documentIdentifier: string;
  documentVersion: string;
}

interface State {
  messages: Array<Message>;
  sortType: 'gasDay' | 'creationDate' | 'sendDate';
  selected: Array<SelectedMessage>;
  filters: Array<FilterType>;
  filterActive: boolean;
  selectedPage: number;
  amountOutItemsPerPage: number;
}

const initialState = {
  messages: [],
  sortType: 'creationDate',
  selected: [],
  filters: [],
  filterActive: false,
  selectedPage: 1,
  amountOutItemsPerPage: 10,
} as State;

interface SetMessagesActionType {
  messages: Array<Message>;
}

const messageSlice = createSlice({
  name: 'messageAck',
  initialState,
  reducers: {
    setInitialValues(state, action: PayloadAction<SetMessagesActionType>) {
      state.messages = action.payload.messages;
    },
    toggleSortType(state) {
      if (state.sortType === 'creationDate') {
        state.sortType = 'gasDay';
      } else {
        state.sortType = 'creationDate';
      }
    },
    toggleSelectMessage(state, action: PayloadAction<SelectedMessage>) {
      const { id, documentIdentifier, documentVersion } = action.payload;
      if (state.selected.length > 0) {
        const selectedMessageIndex = state.selected.findIndex(
          (selectedMessage) => selectedMessage.id === id,
        );
        if (selectedMessageIndex !== -1) {
          state.selected.splice(selectedMessageIndex, 1);
        } else {
          state.selected.push({ id, documentIdentifier, documentVersion });
        }
      } else {
        state.selected.push({ id, documentIdentifier, documentVersion });
      }
    },
    unselecteAll(state) {
      state.selected = [];
    },
    setFilters(state, action: PayloadAction<FilterType>) {
      if (state.filters.length === 0) {
        state.filters.push(action.payload);
      } else {
        const existingFilterIndex = state.filters.findIndex(
          (filter) => filter.filterName === action.payload.filterName,
        );
        if (existingFilterIndex !== -1) {
          if (action.payload.options.length === 0) {
            state.filters.splice(existingFilterIndex, 1);
          } else {
            state.filters[existingFilterIndex].options = action.payload.options;
          }
        } else {
          state.filters.push(action.payload);
        }
      }
      state.filterActive = true;
    },
    clearFilters(state) {
      state.filters = [];
      state.filterActive = false;
      state.selectedPage = 1;
    },
    clearFiltersWithoutResetingPage(state) {
      state.filters = [];
      state.filterActive = false;
    },
    setPage(state, action: PayloadAction<number>) {
      state.selectedPage = action.payload;
    },
    setAmountOutItemsPerPage(state, action: PayloadAction<number>) {
      state.amountOutItemsPerPage = action.payload;
      state.selectedPage = 1;
    },
  },
});

export const {
  setInitialValues,
  toggleSortType,
  toggleSelectMessage,
  unselecteAll,
  setFilters,
  clearFilters,
  clearFiltersWithoutResetingPage,
  setPage,
  setAmountOutItemsPerPage,
} = messageSlice.actions;

export default messageSlice.reducer;
