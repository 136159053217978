import { createContext, FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BreadcrumbsContainer } from './styled';
import { MessageDefinitionDetailsForm } from './components/MessageDefinitionDetailsForm';
import { DocumentAttributesForm } from './components/DocumentAttributesForm';
import { AccountAttributesForm } from './components/AccountAttributesForm';
import { Breadcrumbs } from './components/Breadcrumbs';
import {
  ActionBar,
  ClassicButton,
  ConfirmationPopup,
  ConfirmationPopupText,
  ContentContainer,
  MainContainer,
  VARIANT,
  GlobalErrorModal,
} from 'components';
import {
  EdigasVersionType,
  FormOption,
  MessageDefinitionSlim,
  MessageType,
  RoleType,
  SelectOptionType,
} from 'utils/types';
import { useMessageDefinition, useMessageDefinitionSave } from './messageDefinitionFormApi';
import { FieldValues, useForm, UseFormSetError } from 'react-hook-form';
import { isObjEmpty } from 'utils/isObjEmpty';
import { useRoles } from 'utils/hooks/useRoleType';
import { getInitialDate } from 'utils/getInitialDate';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/modalSlice';
import { formatDateForInput, formatTimeFromUtc, formatTimeForInput } from 'utils/dateFormating';

interface MessageDefinitionFormProps {
  option: FormOption;
  id?: string;
}

export type ValidStateType = 'VALID' | 'ERROR' | 'NOTREADY';

const isFormValid = (isValid: boolean, isError: boolean): ValidStateType => {
  if (isValid) {
    return 'VALID';
  } else if (!isValid && isError) {
    return 'ERROR';
  } else {
    return 'NOTREADY';
  }
};

export interface ValidStatesType {
  messageDefinitionDetails: ValidStateType;
  documentAttributes: ValidStateType;
  accountAttributes: ValidStateType;
}

export type SetFormValuesInSectionFunctionType = (sectionValues: {
  [key: string]: string | SelectOptionType;
}) => void;
export const SetErrorContext = createContext<
  { setError: UseFormSetError<FieldValues> } | undefined
>(undefined);
const getRoleTypeByCode = (code: string, roles?: Array<RoleType>) =>
  roles?.find((roleType) => roleType.code === code);

export const MessageDefinitionForm: FC<MessageDefinitionFormProps> = ({ option, id }) => {
  const history = useHistory();
  const { mutate: saveMessageDefinition, isLoading: isMessageDefinitionSaving } =
    useMessageDefinitionSave();
  const { data: roleTypes } = useRoles();
  const dispatch = useAppDispatch();

  const { timezone: userTimezone } = useAppSelector((store) => store.globalSlice);

  const {
    register: messageDefinitionDetailsRegister,
    control: messageDefinitionDetailsControl,
    reset: messageDefinitionDetailsReset,
    getValues: messageDefinitionDetailsGetValues,
    formState: {
      isValid: isMessageDefinitionDetailsValid,
      errors: isMessageDefinitionDetailsError,
      isDirty: isMessageDefinitionDetailsDirty,
    },
    watch: messageDefinitionDetailsWatch,
  } = useForm({
    mode: 'all',
    defaultValues: {
      active: true,
      validFrom: getInitialDate(),
      validTo: new Date('2099-09-30'),
    } as FieldValues,
  });

  const {
    register: documentAttributesRegister,
    control: documentAttributesControl,
    reset: documentAttributesReset,
    getValues: documentAttributesGetValues,
    formState: {
      isValid: isDocumentAttributesValid,
      errors: isDocumentAttributesError,
      isDirty: isDocumentAttributesDirty,
    },
    watch: documentAttributesWatch,
    setError: documentAttributesSetError,
  } = useForm({ mode: 'all' });

  const {
    register: accountAttributesRegister,
    control: accountAttributesControl,
    reset: accountAttributesReset,
    getValues: accountAttributesGetValues,
    formState: {
      isValid: isAccountAttributesValid,
      errors: isAccountAttributesError,
      isDirty: isAccountAttributesDirty,
    },
    setError: accountAttributesSetError,
    watch: messageDefinitionAccountAtributesWatch,
  } = useForm({
    mode: 'all',
    defaultValues: {
      repeatConnectionPointClass: false,
      condensatePeriod: false,
      netting: false,
      includeAllZeroNominationTracks: false,
      ignoreUnknownNomresAccountPairs: false,
      mismatchErrorCutoffTime: new Date('1970-01-01T20:00:00.000'),
      delay: 0,
    } as FieldValues,
  });

  const messageDefinitionDetails = messageDefinitionDetailsGetValues();
  const documentAttributes = documentAttributesGetValues();
  const accountAttributes = accountAttributesGetValues();
  const edigasVersion = documentAttributesWatch('edigasVersion');
  const { data: messageDef } = useMessageDefinition(id);
  const fipValues = () => {
    if (messageDef) {
      return {
        fipDatCreated: messageDef.fipDatCreated,
        fipDate: messageDef.fipDate,
        fipUser: messageDef.fipUser,
        fipUserCreated: messageDef.fipUserCreated,
      };
    }
    return undefined;
  };

  const submitMessageDefinition = () => {
    // Solution preventing submit being out of sync with selected date
    const validFrom = messageDefinitionDetailsWatch('validFrom');
    const validTo = messageDefinitionDetailsWatch('validTo');
    const mismatchErrorCutoffTime =
      messageDefinitionAccountAtributesWatch('mismatchErrorCutoffTime');

    const messageDefinitonSubmitModel = {
      ...(messageDef && messageDef),
      ...messageDefinitionDetails,
      ...documentAttributes,
      ...accountAttributes,
      validFrom: formatDateForInput(validFrom),
      validTo: formatDateForInput(validTo),
      mismatchErrorCutoffTime: formatTimeForInput(
        new Date(
          `1970-01-01 ${mismatchErrorCutoffTime.getHours()}:${mismatchErrorCutoffTime.getMinutes()} GMT${
            userTimezone?.utc
          }`,
        ),
      ),
    } as unknown as MessageDefinitionSlim;
    // TODO make this submit more typesafe

    saveMessageDefinition(messageDefinitonSubmitModel);
  };

  const messageType = messageDefinitionDetails.messageType as MessageType;
  const getRoleBaseOnMessageType = () => {
    switch (messageType?.description) {
      case 'Interconnection Point':
        return getRoleTypeByCode('SO', roleTypes?.content);
      case 'Virtual Trading Point':
        return getRoleTypeByCode('AC', roleTypes?.content);
      case 'Counterparty':
        return getRoleTypeByCode('BRP', roleTypes?.content);
      case 'Storage':
        return getRoleTypeByCode('SSO', roleTypes?.content);
      default:
        return undefined;
    }
  };

  const checkIfEnabled = () => {
    return (
      Object.values(validState).every((validStateValues) => validStateValues === 'VALID') &&
      (isMessageDefinitionDetailsDirty || isDocumentAttributesDirty || isAccountAttributesDirty)
    );
  };

  useEffect(() => {
    if (messageDef) {
      const {
        area,
        description,
        messageType,
        validFrom,
        validTo,
        connectionPointIdentifierType,
        externalAccountIdentifierType,
        internalAccountIdentifierType,
        repeatConnectionPointClass,
        condensatePeriod,
        netting,
        unitOfMeasureType,
        gasDirectionType,
        documentType,
        contractReference,
        recipientMarketParticipantIdentifierType,
        recipientMarketParticipant,
        recipientMarketParticipantMarketRole,
        issuerMarketParticipantIdentifierType,
        issuerMarketParticipant,
        issuerMarketParticipantMarketRole,
        edigasVersion,
        referenceType,
        active,
        includeAllZeroNominationTracks,
        ignoreUnknownNomresAccountPairs,
        mismatchErrorCutoffTime,
        messageOutputSetting,
        delay,
      } = messageDef;

      messageDefinitionDetailsReset({
        area,
        description,
        messageType,
        validFrom: new Date(validFrom),
        validTo: new Date(validTo),
        active,
        messageOutputSetting,
      });

      documentAttributesReset({
        documentType,
        contractReference,
        recipientMarketParticipantIdentifierType,
        recipientMarketParticipant,
        recipientMarketParticipantMarketRole,
        issuerMarketParticipantIdentifierType,
        issuerMarketParticipant,
        issuerMarketParticipantMarketRole,
        edigasVersion,
        referenceType,
      });

      accountAttributesReset({
        connectionPointIdentifierType,
        externalAccountIdentifierType,
        internalAccountIdentifierType,
        repeatConnectionPointClass,
        condensatePeriod,
        netting,
        unitOfMeasureType,
        gasDirectionType,
        includeAllZeroNominationTracks,
        ignoreUnknownNomresAccountPairs,
        mismatchErrorCutoffTime: new Date(
          `1970-01-01T${formatTimeFromUtc(
            new Date('1970-01-01T' + mismatchErrorCutoffTime + 'Z'),
            userTimezone?.timeZone,
          )}`,
        ),
        delay,
      });
    }
  }, [messageDef]);

  const validState = {
    messageDefinitionDetails: isFormValid(
      isMessageDefinitionDetailsValid,
      isObjEmpty(isMessageDefinitionDetailsError),
    ),
    documentAttributes: isFormValid(
      isDocumentAttributesValid,
      isObjEmpty(isDocumentAttributesError),
    ),
    accountAttributes: isFormValid(isAccountAttributesValid, isObjEmpty(isAccountAttributesError)),
  } as ValidStatesType;

  return (
    <MainContainer>
      <GlobalErrorModal />
      <BreadcrumbsContainer>
        <Breadcrumbs validStates={validState} option={option} />
      </BreadcrumbsContainer>
      <ContentContainer width="55%">
        <MessageDefinitionDetailsForm
          register={messageDefinitionDetailsRegister}
          control={messageDefinitionDetailsControl}
          fipValues={fipValues()}
        />
        <SetErrorContext.Provider value={{ setError: documentAttributesSetError }}>
          <DocumentAttributesForm
            isDisabled={!isMessageDefinitionDetailsValid}
            register={documentAttributesRegister}
            control={documentAttributesControl}
            roleType={getRoleBaseOnMessageType()}
            edigasVersion={edigasVersion}
          />
        </SetErrorContext.Provider>
        <SetErrorContext.Provider value={{ setError: accountAttributesSetError }}>
          <AccountAttributesForm
            register={accountAttributesRegister}
            errors={isAccountAttributesError}
            isDisabled={!isDocumentAttributesValid}
            edigasVersion={edigasVersion as EdigasVersionType}
            control={accountAttributesControl}
          />
        </SetErrorContext.Provider>

        <ActionBar>
          <ClassicButton
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={() => history.push('/app/message-definitions/')}
          >
            Cancel
          </ClassicButton>
          <ClassicButton
            width="10rem"
            variant={VARIANT.PURPLE}
            onClick={() => dispatch(openModal({ modalId: 'messageDefinitionConfirmationModal' }))}
            disabled={!checkIfEnabled()}
          >
            {option === 'create' ? 'Add' : 'Save'}
          </ClassicButton>
        </ActionBar>
        <ConfirmationPopup
          isLoading={isMessageDefinitionSaving}
          confirmationPopupId="messageDefinitionConfirmationModal"
          confirmButtonAction={() => submitMessageDefinition()}
        >
          <ConfirmationPopupText>
            {option === 'create' ? (
              <>
                Shall we go ahead and save{' '}
                <span>{messageDefinitionDetailsWatch('description')}</span> as a new message
                definition?{' '}
              </>
            ) : (
              <>
                Shall we go ahead and update the Masterdata for Message Definition{' '}
                <span>{messageDefinitionDetailsWatch('description')}</span>{' '}
              </>
            )}
          </ConfirmationPopupText>
        </ConfirmationPopup>
      </ContentContainer>
    </MainContainer>
  );
};
