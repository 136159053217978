import { ControlledSelectInput, FieldContainerWithLabel } from 'components';
import { FC } from 'react';
import { Control } from 'react-hook-form';

interface PointTypeSelectFieldProps {
  control: Control;
}

export const pointTypes = [
  {
    id: 1,
    name: 'IP',
  },
  {
    id: 2,
    name: 'VTP',
  },
  {
    id: 3,
    name: 'B2B',
  },
  {
    id: 4,
    name: 'STO',
  },
].sort((a, b) => a.name.localeCompare(b.name));

export const PointTypeSelectField: FC<PointTypeSelectFieldProps> = ({ control }) => {
  return (
    <FieldContainerWithLabel>
      <p>Point Type</p>
      <ControlledSelectInput
        required
        control={control}
        width="20rem"
        placeholder="Choose"
        options={pointTypes}
        name="pointType"
      />
    </FieldContainerWithLabel>
  );
};
