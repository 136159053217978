import { useQuery } from 'react-query';
import { formatDateForInput } from 'utils/dateFormating';
import { useApiGet } from 'utils/hooks';
import { SortType } from 'utils/types/generalTypes';
import { DashboardMessageStatus, DashboardUpdate } from './types';

interface DashboardMessageStatusesList {
  content: Array<DashboardMessageStatus>;
}

export const useDashboardMessageStatusesByDay = (day: Date, activeSort: SortType) => {
  const formattedDate = formatDateForInput(day);
  const fetchMessageStatuses = useApiGet(
    `/dashboard/day/${formattedDate}?size=50${
      activeSort.name !== '' && activeSort.direction !== 'none'
        ? `&sort=${activeSort.name},${activeSort.direction}`
        : ''
    }`,
  );
  return useQuery<DashboardMessageStatusesList, Error>(
    [`message-statuses-${formattedDate}`, activeSort, day],
    fetchMessageStatuses,
    { keepPreviousData: true, refetchInterval: 30000 },
  );
};

interface DashboardUpdateList {
  content: Array<DashboardUpdate>;
  totalPages: number;
  totalElements: number;
}

export const useDashboardUpdates = (
  day: Date,
  activeSort: SortType,
  size: number,
  currentPage: number,
  searchPhrase?: string,
) => {
  const formattedDate = formatDateForInput(day);
  const fetchDashboardUpdates = useApiGet(
    `/dashboard/updates/${formattedDate}?size=${size}&page=${currentPage - 1}${
      activeSort.name !== '' && activeSort.direction !== 'none'
        ? `&sort=${activeSort.name},${activeSort.direction}`
        : ''
    }${searchPhrase && searchPhrase?.length > 0 ? `&search=${searchPhrase}` : ''}`,
  );
  return useQuery<DashboardUpdateList, Error>(
    [`message-updates`, activeSort, size, currentPage, day, searchPhrase],
    fetchDashboardUpdates,
    { refetchInterval: 30000 },
  );
};
