import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal, closeModal } from 'store/modalSlice';
import { API, useApiGet } from 'utils/hooks';
import { Area, Counterparty, MainCustomErrorType } from 'utils/types';

export const useArea = (option: 'create' | 'edit', areadId?: string) => {
  const fetchAreaById = useApiGet(`/area/${areadId}`);
  return useQuery<Area, Error>(['area'], fetchAreaById, {
    enabled: !!areadId && option === 'edit',
  });
};

export interface AreaCounterparties {
  content: Array<Counterparty>;
}

export const useCounterparties = () => {
  const fetchCounterparties = useApiGet(`/counterparty?size=0&sort=name,asc`);
  return useQuery<AreaCounterparties, Error>(['counterparties'], fetchCounterparties);
};

export const useAreaSave = (option: 'create' | 'edit') => {
  const { token } = useAppSelector((state) => state.authReducer);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  return useMutation(
    (data: Area) => {
      if (token.length) {
        if (option === 'edit') {
          return API.PUT('/area', {
            headers: {
              Authorization: token,
            },
            body: JSON.stringify(data),
          });
        }
        return API.POST('/area', {
          headers: {
            Authorization: token,
          },
          body: JSON.stringify(data),
        });
      }
      return Promise.reject();
    },
    {
      onError: (error: MainCustomErrorType) => {
        dispatch(
          openModal({
            modalId: 'globalErrorModal',
            props: { errors: error.errors, message: error.message },
          }),
        );
      },
      onSuccess: async () => {
        dispatch(closeModal({ modalId: 'areaConfirmationModal' }));
        queryClient.invalidateQueries('areas');
        history.push('/app/areas/');
      },
    },
  );
};
