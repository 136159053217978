import { FC } from 'react';
import styled, { css } from 'styled-components';

const QuantityContainer = styled.div<{ margin?: string }>`
  display: flex;
  align-items: center;
  ${({ margin }) => margin && margin}
`;

const Quantity = styled.div<{ isShort?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 1.5rem;
  background-color: #ffa590;
  border-radius: 2rem;
  margin-left: 0.625rem;
  ${({ isShort }) =>
    isShort &&
    css`
      width: 2rem;
      margin: 0;
    `}
`;
interface QuantityProps {
  quantity: number;
  margin?: string;
  short?: boolean;
}
export const QuantityIndicator: FC<QuantityProps> = ({ quantity, margin, short }) => {
  return (
    <QuantityContainer margin={margin}>
      {!short && 'Quantity:'}
      <Quantity isShort={short}>{quantity}</Quantity>
    </QuantityContainer>
  );
};
