import { EditedNomination, NominationPayload, MasterDataFile, VersionsHistory } from './types';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearChangedValues, unselectAll } from './nominationReduxSlice';
import { closeModal, openModal } from 'store/modalSlice';
import { pushNotification } from 'features/NotificationPopup/notificationSlice';
import { useApiGet, API } from 'utils/hooks';
import { setError } from 'features/ErrorScreen/errorSlice';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { EditMode, MainCustomErrorType } from 'utils/types';
import { formatDateForInput } from 'utils/dateFormating';

export const useNominations = (selectedDate: Date) => {
  const formattedDate = formatDateForInput(selectedDate);
  const nominationsFetch = useApiGet(`/day/${formattedDate}`);
  return useQuery<NominationPayload, Error>(['nominations', selectedDate], nominationsFetch, {
    refetchInterval: 30000,
  });
};

export interface FileUploadResponseModel {
  sheetNames: string;
  workbookName: string;
  results: Array<FileUploadResults>;
}

interface FileUploadResults {
  successful: string[];
  nominationTracksNotFound: string[];
  nominationTrackValidationErrors: { [index: string]: string[] };
  processedRows: number;
  processedValues: number;
  sheetName: string;
  sheetValid: boolean;
  sheetIgnored: boolean;
  validationMessage: string;
}

interface UploadData {
  file?: MasterDataFile;
  currentDate: Date;
  ignoreNominationLeadTime?: boolean | null;
  importAllDates?: boolean | null;
}

export const useFileUpload = (setError?: UseFormSetError<FieldValues>) => {
  const { token } = useAppSelector((state) => state.authReducer);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    (data: UploadData) => {
      const formData = new FormData();
      const formattedDate = formatDateForInput(data.currentDate);

      const queryParams = {
        ...(data.ignoreNominationLeadTime !== null && {
          ignoreNominationLeadTime: `${data.ignoreNominationLeadTime || false}`,
        }),
        ...(data.currentDate !== null && {
          allowedDate: `${formattedDate}`,
        }),
        ...(data.importAllDates !== undefined && {
          importAllDates: `${data.importAllDates || false}`,
        }),
      };

      if (data.file) {
        formData.append('file', data.file.masterData[0]);

        if (token.length) {
          return API.UPLOAD_FILE(
            `/upload/day/xlsx?${new URLSearchParams(queryParams).toString()}`,
            {
              body: formData,
              headers: {
                Authorization: token,
              },
            },
          );
        }
      }
      if (setError) {
        setError('masterData', { message: 'Select file one again' });
      }
      return Promise.reject();
    },

    {
      onError: (error: MainCustomErrorType, data: UploadData) => {
        if (
          // In case values are filled in greyed field
          error.code === 'MESSAGE_NOMINATION_LEAD_TIME_VIOLATION' ||
          // Meanwhile file is imported
          error.code === 'IMPORT_GASDAY_DATA_VIOLATION'
        ) {
          dispatch(
            openModal({
              modalId: 'uploadTimeValidationModal',
              props: { error, fileToUpload: data.file },
            }),
          );
        } else {
          if (setError) {
            setError('masterData', { message: error.errors[0].message });
          }
        }
      },
      onSuccess: async (data: Response) => {
        const parsedData = (await data.json()) as Array<FileUploadResponseModel>;

        dispatch(closeModal({ modalId: 'fileUploadModal' }));

        dispatch(
          openModal({
            modalId: 'uploadFileWarningModal',
            props: {
              fileUploadResponse: parsedData[0],
            },
          }),
        );

        queryClient.invalidateQueries('nominations');
      },
    },
  );
};

interface FileUploadCongif {
  key: string;
  type: string;
  value: string;
  defaultValue: string;
  description: string;
}

export const useFileUploadConfig = () => {
  const fileUploadConfigFetch = useApiGet(
    `/configproperty/fileUpload.enforceWithinDayLeadTimeValidation.enabled`,
  );
  return useQuery<FileUploadCongif, Error>(['fileUploadConfig'], fileUploadConfigFetch);
};

export const useNominationHistory = (selectedDate: Date, nominationTrackId?: number) => {
  const formattedDate = formatDateForInput(selectedDate);
  const nominationHistoryFetch = useApiGet(
    `/day/${formattedDate}/value?nominationTrackId=${nominationTrackId}`,
  );
  return useQuery<VersionsHistory, Error>(
    ['nominationVersion', selectedDate],
    nominationHistoryFetch,
  );
};
interface ChangedNominationData {
  changedData: Array<EditedNomination>;
  selectedDate: Date;
  numberOfDays: number;
  sideEffect?: () => void;
  areaId?: number;
  ignoreNominationLeadTime?: boolean;
  editMode?: EditMode;
}

export const useNominationSave = (redirectToSubmit?: boolean) => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.authReducer);

  const queryClient = useQueryClient();
  return useMutation(
    (data: ChangedNominationData) => {
      const { selectedDate, changedData, ignoreNominationLeadTime, editMode, numberOfDays } = data;
      const formattedDate = formatDateForInput(selectedDate);

      const queryParams = {
        ...(ignoreNominationLeadTime && {
          ignoreNominationLeadTime: `${ignoreNominationLeadTime}`,
        }),
        ...(editMode && { editMode: `${editMode}` }),
        ...(numberOfDays && { numberOfDays: `${numberOfDays}` }),
      };
      if (token.length) {
        return API.PUT(
          `/day/${formattedDate}/values?${new URLSearchParams(queryParams).toString()}`,
          {
            headers: {
              Authorization: token,
            },
            body: JSON.stringify(changedData),
          },
        );
      }
      return Promise.reject();
    },
    {
      onError: (error: MainCustomErrorType, data: ChangedNominationData) => {
        if (
          // In case values are filled in greyed field
          error.code === 'MESSAGE_NOMINATION_LEAD_TIME_VIOLATION' ||
          // Meanwhile file is imported
          error.code === 'IMPORT_GASDAY_DATA_VIOLATION'
        ) {
          dispatch(closeModal({ modalId: 'saveModal' }));
          dispatch(
            openModal({
              modalId: 'nominationTimeLeadValidationSaveModal',
              props: {
                error: error,
                errors: error.errors,
                message: error.message,
                editMode: data.editMode,
                redirectToSubmit,
              },
            }),
          );
        } else if (error.code === 'OPTIMISTIC_LOCKING_ERROR') {
          dispatch(closeModal({ modalId: 'saveModal' }));
          dispatch(
            openModal({
              modalId: 'nominationOptimisticLockingErrorModal',
              props: {
                message: error.message,
                error,
                redirectToSubmit,
              },
            }),
          );
        } else if ('500' !== error.code) {
          dispatch(openModal({ modalId: 'submitErrorModal', props: { error } }));
        } else {
          dispatch(setError({ errorMessage: error.message || 'Something went wrong' }));
        }
      },
      onSuccess: async (data, variables) => {
        const { sideEffect, areaId } = variables;
        if (sideEffect) {
          sideEffect();
        }
        queryClient.invalidateQueries('nominations').then(() => {
          dispatch(clearChangedValues({ areaId }));
        });
      },
    },
  );
};

interface NominationSubmitData {
  selectedDate: Date;
  numberOfDays: number;
  sendAll: boolean;
  ignoreNominationLeadTime?: boolean;
}

export const useSelectedNominationSubmit = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const { areas } = useAppSelector((state) => state.nominationReducer);

  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (data: NominationSubmitData) => {
      const { selectedDate, ignoreNominationLeadTime, sendAll, numberOfDays } = data;

      const filteredData = areas
        ?.filter((area) => area.isSelected || area.isIndeterminate)
        .map((area) => ({
          areaId: area.id,
          connectionPoints: area.connectionPoints
            .filter((connecitonPoint) => connecitonPoint.isSelected)
            .map((connectionPoint) => ({
              connectionPointId: connectionPoint.id,
              nominationTracks: connectionPoint.balanceGroups.map((balanceGroup) => ({
                destinationId: balanceGroup.destinationId,
                sourceId: balanceGroup.sourceId,
                nominationTrackId: balanceGroup.nominationTrackId,
              })),
            })),
        }));
      const formattedDate = formatDateForInput(selectedDate);
      if (token.length) {
        return API.PUT(
          `/day/${formattedDate}/xml?sendAll=${sendAll}${
            ignoreNominationLeadTime ? `&ignoreNominationLeadTime=${ignoreNominationLeadTime}` : ''
          }${numberOfDays ? `&numberOfDays=${numberOfDays}` : ''}`,
          {
            headers: {
              Authorization: token,
            },
            body: JSON.stringify({ areas: filteredData }),
          },
        );
      }
      return Promise.reject();
    },
    {
      onError: (error: MainCustomErrorType, variables) => {
        if (
          // In case values are filled in greyed field
          error.code === 'MESSAGE_NOMINATION_LEAD_TIME_VIOLATION' ||
          // Meanwhile file is imported
          error.code === 'IMPORT_GASDAY_DATA_VIOLATION'
        ) {
          dispatch(closeModal({ modalId: 'submitModal' }));

          dispatch(
            openModal({
              modalId: 'nominationTimeLeadValidationSubmitModal',
              props: {
                error: error,
                errors: error.errors,
                message: error.message,
                sendAll: variables.sendAll,
              },
            }),
          );
        } else if ('500' !== error.code) {
          dispatch(openModal({ modalId: 'submitErrorModal', props: { error } }));
        } else {
          dispatch(setError({ errorMessage: error.message || 'Something went wrong' }));
        }
      },
      onSuccess: async () => {
        dispatch(
          pushNotification({
            type: 'ACCEPTED',
            copy: 'Submitted successfully!',
          }),
        );
        dispatch(closeModal({ modalId: 'submitModal' }));
        dispatch(closeModal({ modalId: 'nominationTimeLeadValidationSubmitModal' }));
        dispatch(unselectAll());

        queryClient.invalidateQueries('nominations');
      },
    },
  );
};

interface CopyDayData {
  sourceDate: Date;
  selectedDate: Date;
}

export const useCopyGasDay = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  return useMutation(
    (data: CopyDayData) => {
      const { selectedDate, sourceDate } = data;
      const formattedDestinationDate = formatDateForInput(selectedDate);
      const formatedSourceDate = formatDateForInput(sourceDate);
      if (token.length) {
        return API.PUT(`/day/${formattedDestinationDate}/copy/${formatedSourceDate}`, {
          headers: {
            Authorization: token,
          },
        });
      }
      return Promise.reject();
    },

    {
      onError: (error: Error) => {
        dispatch(setError({ errorMessage: error.toString() }));
      },
      onSuccess: async () => {
        dispatch(clearChangedValues({}));
        dispatch(closeModal({ modalId: 'copyFromModal' }));
        queryClient.invalidateQueries('nominations');
      },
    },
  );
};

export interface MessageDefinitionValue {
  areaName: string;
  description: string;
  id: number;
  messageTypeShortCode: string;
}

interface MessageDefinitionListOfValuesType {
  content: Array<MessageDefinitionValue>;
}

export const useMessageDefinitionListOfValues = (date: Date) => {
  const formattedDate = formatDateForInput(date);
  const fetchMessageDefinitionListOfValues = useApiGet(
    `/lov/message-definition?size=0&date=${formattedDate}`,
  );
  return useQuery<MessageDefinitionListOfValuesType, Error>(
    ['messageDefinitionsLov', date],
    fetchMessageDefinitionListOfValues,
  );
};

interface CopyFromToData {
  sourceDate: Date;
  firstDestinationDate: Date;
  lastDestinationDate: Date;
  listOfMessageDefinitions: Array<MessageDefinitionValue>;
}

export const useCopyFromTo = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  return useMutation(
    (data: CopyFromToData) => {
      const { sourceDate, firstDestinationDate, lastDestinationDate, listOfMessageDefinitions } =
        data;

      const formattedSourceDate = formatDateForInput(sourceDate);
      const firstFormatedDestination = formatDateForInput(firstDestinationDate);
      const lastFormatedDestination = formatDateForInput(lastDestinationDate);

      if (token.length) {
        return API.PUT(
          `/day/${formattedSourceDate}/copy/from/${firstFormatedDestination}/to/${lastFormatedDestination}`,
          {
            headers: {
              Authorization: token,
            },
            body: JSON.stringify(listOfMessageDefinitions),
          },
        );
      }
      return Promise.reject();
    },

    {
      onError: (error: MainCustomErrorType) => {
        if (error.code === 'MULTI_USER_EXCEPTION') {
          dispatch(openModal({ modalId: 'copyToErrorModal', props: { error } }));
        } else {
          dispatch(setError({ errorMessage: error.toString() }));
        }
      },
      onSuccess: async () => {
        dispatch(clearChangedValues({}));
        dispatch(closeModal({ modalId: 'copyToModal' }));
        queryClient.invalidateQueries('nominations');
      },
    },
  );
};

export const useCSVDocument = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  return useMutation(
    (day: string) => {
      if (token.length && day) {
        return API.GET(`/day/${day}/csv?asAttachment=true`, {
          headers: {
            Authorization: token,
          },
        });
      }
      return Promise.reject();
    },
    {
      onError: (error: Error) => {
        dispatch(setError({ errorMessage: error.toString() }));
      },
      onSuccess: async (data) => {
        // This is workaround for fetching csv file directly from backend
        const parsedData = await data.text();
        const header = data.headers.get('Content-Disposition');
        const parts = header ? header.split(';') : [];
        const filename = parts[1].split('=')[1];
        const blob = new Blob([parsedData], { type: 'text/xml' });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.href = blobUrl;

        link.download = filename;
        // Append link to the body
        document.body.appendChild(link);

        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        );

        // Remove link from body
        document.body.removeChild(link);
      },
    },
  );
};

export const useXLSXDocument = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  return useMutation(
    (day: string) => {
      if (token.length && day) {
        return API.GET(`/day/${day}/csv?asCsv=false&asAttachment=true`, {
          headers: {
            Authorization: token,
          },
        });
      }
      return Promise.reject();
    },
    {
      onError: (error: Error) => {
        dispatch(setError({ errorMessage: error.toString() }));
      },
      onSuccess: async (data) => {
        // This is workaround for fetching csv file directly from backend
        const parsedData = await data.blob();
        const header = data.headers.get('Content-Disposition');
        const parts = header ? header.split(';') : [];
        const filename = parts[1].split('=')[1];
        const blob = new Blob([parsedData], { type: 'text/xml' });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.href = blobUrl;

        link.download = filename;
        // Append link to the body
        document.body.appendChild(link);

        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        );

        // Remove link from body
        document.body.removeChild(link);
      },
    },
  );
};

interface CopyToProperties {
  configPropertyCopyGasDayMaxDays: {
    description: string;
    key: string;
    value: string;
  };
  configPropertyEndOfMonthOffsetDays: {
    description: string;
    key: string;
    value: string;
  };
  fromDate: string;
  toDate: string;
  usedOffsetDays: number;
}

export const useCopyToPossibleDate = (currentDate: Date) => {
  const formattedDate = formatDateForInput(currentDate);
  const fetchPossibleDates = useApiGet(`/day/${formattedDate}/copy/to`);
  return useQuery<CopyToProperties, Error>(['possbileDates', currentDate], fetchPossibleDates, {
    refetchInterval: 30000,
  });
};
