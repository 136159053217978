import { BaseButton } from 'components';
import { UserNotificationSettings } from './UserNotificationSettings';
import { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { SettingsWindowType } from 'utils/types';
import { useCheckAuthority } from 'utils/hooks/useCheckAuthority';
import { AdminNotificationSettings } from './AdminNotificationSettings';
import { AccountSetting } from './AccountSettings';

const TabsRow = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.comboFormBgColor};
`;

const Tab = styled(BaseButton)<{ isSelected: boolean }>`
  padding: 1rem;
  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      border-bottom: 2px solid ${theme.extraViolet};
      color: ${theme.extraViolet};
    `}
`;

const OptionContainer = styled.div`
  padding: 2.5rem;
`;

interface SettingsTabsProps {
  preSelectedTab: SettingsWindowType;
}

export const SettingsTabs: FC<SettingsTabsProps> = ({ preSelectedTab }) => {
  const [selectedTab, setSelectedTab] = useState(preSelectedTab);
  const adminNotificationAuthorities = useCheckAuthority(['ADMIN', 'SYSADMIN']);

  const renderTab = () => {
    switch (selectedTab) {
      case 'account':
        return <AccountSetting />;
      case 'notification':
        return <UserNotificationSettings />;
      case 'adminNotification':
        return <AdminNotificationSettings />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <TabsRow>
        <Tab onClick={() => setSelectedTab('account')} isSelected={selectedTab === 'account'}>
          Account settings
        </Tab>
        {adminNotificationAuthorities && (
          <Tab
            onClick={() => setSelectedTab('adminNotification')}
            isSelected={selectedTab === 'adminNotification'}
          >
            Admin notification settings
          </Tab>
        )}
        <Tab
          onClick={() => setSelectedTab('notification')}
          isSelected={selectedTab === 'notification'}
        >
          Notification settings
        </Tab>
      </TabsRow>
      <OptionContainer>{renderTab()}</OptionContainer>
    </>
  );
};
