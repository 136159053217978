import { ControlledSelectInput, FieldContainerWithLabel } from 'components';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { SelectOptionType } from 'utils/types';
import { useMessageType } from '../messageDefinitionFormApi';

interface MessageTypeSelectFieldProps {
  control: Control;
  isDisabled?: boolean;
}

export const MessageTypeSelectField: FC<MessageTypeSelectFieldProps> = ({
  control,
  isDisabled,
}) => {
  const { data } = useMessageType();
  return (
    <>
      <FieldContainerWithLabel isDisabled={isDisabled}>
        <p>Message Type</p>
        <ControlledSelectInput
          required
          control={control}
          width="19.5rem"
          placeholder="Choose"
          options={data ? (data as unknown as Array<SelectOptionType>) : [{ id: 0, name: '' }]}
          name="messageType"
          isDisabled={isDisabled}
        />
      </FieldContainerWithLabel>
    </>
  );
};
