import styled, { css } from 'styled-components';
import { ChangeEventHandler, FC } from 'react';
import { BlueCheckmark, IndeterminateLine } from 'assets/icons';
import { Control, Controller } from 'react-hook-form';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 0.0625rem;
  margin: -0.0625rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0.0625rem;
  display: none;
`;
const CheckboxContainer = styled.label`
  height: 1.5rem;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
`;

const StyledCheckbox = styled.div<{
  checked?: boolean;
  indeterminate: boolean;
  isDisabled?: boolean;
}>`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${({ checked, theme }) =>
    checked ? theme.checkboxSelectedBgColor : theme.white};
  border: 1px solid ${({ checked, theme }) => (checked ? theme.primary : theme.darkGrey)};
  border-radius: 4px;
  transition: all 150ms;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  svg {
    width: 1rem;
    margin: 0 !important;
    path {
      fill: ${({ theme }) => theme.checkboxSelectedTickColor};
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.milkGrey};
  }

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      border: 1px solid ${theme.lightGrey};

      svg {
        path {
          fill: ${theme.lightGrey};
        }
      }

      :hover {
        background-color: none;
      }
    `}
`;

interface CheckboxProps {
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  indeterminate?: boolean;
  isDisabled?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
  className,
  checked,
  indeterminate = false,
  isDisabled,
  ...props
}) => {
  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} disabled={isDisabled} />
      <StyledCheckbox checked={checked} indeterminate={indeterminate} isDisabled={isDisabled}>
        {checked && <BlueCheckmark />}
        {indeterminate && <IndeterminateLine />}
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

interface ControlledCheckboxProps extends CheckboxProps {
  name: string;
  required?: boolean;
  control?: Control;
}

export const ControlledCheckbox: FC<ControlledCheckboxProps> = ({
  name,
  control,
  required,
  checked = false,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, value } }) => (
        <Checkbox onChange={onChange} {...props} checked={value || checked} />
      )}
    />
  );
};
