import { ComboFilterContainer, FilterButton, FiltersContainer, MultiSelectInput } from 'components';
import { FC, useState } from 'react';
import { MessageHistorySearch } from './MessageHistorySearch';
import { useAppDispatch } from 'store/hooks';
import { setFilters } from '../messagesSlice';
import { useRecipients, useSenders } from '../messageApi';

interface ComboFilterProps {
  setSearchKeyword: (keyword: string) => void;
  selectedDate: Date;
}

const parseFilterOptions = (
  options?: Array<{ sender?: string; recipient?: string }>,
): Array<string> => {
  return options?.map((option) => option.recipient || option.sender || '') || [];
};

export const ComboFilter: FC<ComboFilterProps> = ({ setSearchKeyword, selectedDate }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useAppDispatch();
  const { data: recipients } = useRecipients(selectedDate);
  const { data: senders } = useSenders(selectedDate);

  const updateFilter = (name: 'recipient' | 'sender' | 'contract') => (options: string[]) => {
    dispatch(setFilters({ filterName: name, options }));
  };

  return (
    <ComboFilterContainer>
      <FiltersContainer isExpanded={isExpanded}>
        <MessageHistorySearch setSearchKeyword={setSearchKeyword} />
        {isExpanded && (
          <>
            <MultiSelectInput
              placeholder="Sender"
              options={parseFilterOptions(senders)}
              onOptionSelect={updateFilter('sender')}
              width={250}
              isFrameless
            />
            <MultiSelectInput
              placeholder="Recipient"
              options={parseFilterOptions(recipients)}
              onOptionSelect={updateFilter('recipient')}
              width={250}
              isFrameless
            />
          </>
        )}
      </FiltersContainer>
      <FilterButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
    </ComboFilterContainer>
  );
};
