import { LoadingScreen } from 'components/LoadingScreen';
import { useMessageDefinitions, useNominationTrackRemoval } from './messageDefinitionApi';
import { FC, useEffect, useState } from 'react';
import { ControlRow } from './components/ControlRow';
import { MessageDefinitionsTable } from './components/MessageDefinitionsTable';
import { FilterRow } from './components/FilterRow';
import { SortDirection } from 'utils/types';
import { SortType } from 'utils/types/generalTypes';
import { useAppDispatch } from 'store/hooks';
import { clearFilters } from './messageDefinitionSlice';
import { ConfirmationPopupText, DeletionConfirmationPopup } from 'components';

export const MessageDefinitions: FC = () => {
  const [activeSort, setActiveSort] = useState<SortType>({
    name: 'area',
    direction: 'asc',
  });

  const updateSort = (name: string, direction: SortDirection) => {
    setActiveSort({ name, direction });
  };

  const { data, isLoading } = useMessageDefinitions(activeSort);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearFilters());
    };
  }, []);
  return (
    <>
      <ControlRow />
      <FilterRow />
      {data && !isLoading && data.content && (
        <MessageDefinitionsTable
          messageDefinitions={data.content}
          updateSort={updateSort}
          activeSort={activeSort}
        />
      )}
      {isLoading && <LoadingScreen />}
      <DeletionConfirmationPopup
        confirmationPopupId="nominationTrackRemovalConfirmationModal"
        deleteQuery={useNominationTrackRemoval}
      >
        <ConfirmationPopupText>Are you sure you want to remove this entry?</ConfirmationPopupText>
      </DeletionConfirmationPopup>
    </>
  );
};
