import {
  ButtonWithLoader,
  CheckboxCotainer,
  ControlledCheckbox,
  FieldContainerWithLabel,
  FormContainer,
  FormSection,
  FormTitle,
  HeadingBar,
  StyledTextInput,
} from 'components';
import { useMasterDataExport, useMasterdataImport } from './adminPanelApi';

import { FC, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { format } from 'date-fns';

const StyledFileInput = styled.input`
  min-width: 35rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
`;

const InputCover = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: 0.075rem solid #ddd;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.2rem 0.4rem rgb(0 0 0 / 5%);
  user-select: none;

  ::before {
    position: absolute;
    top: -0.075rem;
    right: -0.075rem;
    bottom: -0.075rem;
    z-index: 6;
    display: block;
    content: 'Browse';
    padding: 0.4375rem 1.5625rem;
    line-height: 1.5;
    color: #555;
    background-color: #eee;
    border: 0.075rem solid #ddd;
    border-radius: 0 0.25rem 0.25rem 0;
  }
`;

const StyledFileLabel = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
  margin-right: 1.25rem;
  flex: 1;
  margin-bottom: 2rem;
`;

const Checkboxes = styled.div`
  margin-bottom: 2rem;
`;

export const MasterDataExportImport: FC = () => {
  const [fileName, setFileName] = useState('Choose file...');
  const { mutate: fileUpload, isLoading } = useMasterdataImport();
  const { mutate: fileDownload, isLoading: isDownloadingRequested } = useMasterDataExport();
  const {
    register,
    getValues,
    formState: { isDirty, isValid },
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      truncateTables: false,
      resetTenant: false,
      resetConfigProperties: false,
      resetHibernateSequence: false,
      resetAudit: false,
      ignoreMissingSheets: false,
      ignoreMissingEntities: false,
      ignoreMasterDataTables: true,
      importExpiredOrNotActive: false,
    } as FieldValues,
  });

  const submitFile = () => {
    const {
      masterdataFile,
      truncateTables,
      resetTenant,
      resetConfigProperties,
      resetHibernateSequence,
      resetAudit,
      secureString,
      ignoreMissingSheets,
      ignoreMissingEntities,
      ignoreMasterDataTables,
      importExpiredOrNotActive,
    } = getValues();

    fileUpload({
      file: masterdataFile,
      truncateTables,
      resetTenant,
      resetConfigProperties,
      resetHibernateSequence,
      resetAudit,
      secureString,
      ignoreMissingSheets,
      ignoreMissingEntities,
      ignoreMasterDataTables,
      importExpiredOrNotActive,
    });
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget.files?.item(0) || { name: '' };
    if (name.length) {
      setFileName(name);
    }
  };

  return (
    <>
      <HeadingBar>
        <FormTitle>Master data</FormTitle>
      </HeadingBar>
      <FormContainer>
        <FormSection>
          <FieldContainerWithLabel>
            <p>Download current masterdata</p>
            <ButtonWithLoader
              width="10rem"
              isLoading={isDownloadingRequested}
              onClick={() => fileDownload()}
            >
              Download
            </ButtonWithLoader>
          </FieldContainerWithLabel>
        </FormSection>
        <FormSection>
          <FieldContainerWithLabel>
            <p>Masterdata for upload</p>
            <StyledFileLabel>
              <StyledFileInput
                {...register('masterdataFile', { required: true })}
                type="file"
                onChange={onChange}
                required
                accept=".xlsx,.xls"
              />
              <InputCover>{fileName}</InputCover>
            </StyledFileLabel>
            <Checkboxes>
              <CheckboxCotainer>
                <ControlledCheckbox name="truncateTables" control={control} />
                <label>Truncate tables</label>
              </CheckboxCotainer>
              <CheckboxCotainer>
                <ControlledCheckbox name="resetTenant" control={control} />
                <label>Reset tenant</label>
              </CheckboxCotainer>
              <CheckboxCotainer>
                <ControlledCheckbox name="resetConfigProperties" control={control} />
                <label>Reset Config Properties</label>
              </CheckboxCotainer>
              <CheckboxCotainer>
                <ControlledCheckbox name="resetHibernateSequence" control={control} />
                <label>Reset Hibernate Sequence</label>
              </CheckboxCotainer>
              <CheckboxCotainer>
                <ControlledCheckbox name="resetAudit" control={control} />
                <label>Reset audit</label>
              </CheckboxCotainer>
              <CheckboxCotainer>
                <ControlledCheckbox name="ignoreMissingSheets" control={control} />
                <label>Ignore missing sheets</label>
              </CheckboxCotainer>
              <CheckboxCotainer>
                <ControlledCheckbox name="ignoreMissingEntities" control={control} />
                <label>Ignore missing entities</label>
              </CheckboxCotainer>
              <CheckboxCotainer>
                <ControlledCheckbox name="ignoreMasterDataTables" control={control} />
                <label>
                  Ignore MasterData sheets (As2Configuration, As4Configuration, Partnership,
                  CertificateConfiguration, KeyConfiguration, Partner2KeyConfiguration,
                  Partner2CertificateConfig)
                </label>
              </CheckboxCotainer>
              <CheckboxCotainer>
                <ControlledCheckbox name="importExpiredOrNotActive" control={control} />
                <label>
                  Import expired or not active entities (currently only affects
                  NominationTrack-children validation)
                </label>
              </CheckboxCotainer>
            </Checkboxes>
            <StyledTextInput
              type="text"
              label={`Secure string. To confirm type in TRUNCATE_ALL_${format(
                new Date(),
                'yyyy-MM-dd',
              )}`}
              placeholder="e.g. TRUNCATE_ALL_YYYY-MM-DD"
              register={register('secureString', { required: true })}
              width="28rem"
              isLabelBold
            />
          </FieldContainerWithLabel>
          <ButtonWithLoader
            width="10rem"
            isLoading={isLoading}
            onClick={() => submitFile()}
            disabled={!isDirty || !isValid}
          >
            Upload
          </ButtonWithLoader>
        </FormSection>
      </FormContainer>
    </>
  );
};
