import { FC } from 'react';
import { Control, FieldValues, UseFormRegister } from 'react-hook-form';
import { HeadingBar, StepLabel } from '../styled';
import {
  ControlledSelectInput,
  ControlledDatePicker,
  FormContainer,
  FormSection,
  FieldContainerWithLabel,
  StyledTextInput,
  DatePickerContainer,
  FormTitle,
  ControlledToggleInputWithLabel,
  FormMetadataBox,
} from 'components';
import { useAreasSlimList } from '../messageDefinitionFormApi';
import { SelectOptionType } from 'utils/types';
import { MessageTypeSelectField } from './MessageTypeSelectField';
import styled from 'styled-components';
import { OutputSettingSelectField } from './OutputSettingSelectField';

const FlexContainerWithSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`; //TODO REFACTOR THIS LAYOUT TO SOMETHING MORE TOUGHTFULL

interface MessageDefinitionDetailsFormProps {
  register: UseFormRegister<FieldValues>;
  control: Control;
  fipValues?: {
    fipDatCreated: string;
    fipDate: string;
    fipUser: string;
    fipUserCreated: string;
  };
}

export const MessageDefinitionDetailsForm: FC<MessageDefinitionDetailsFormProps> = ({
  register,
  control,
  fipValues,
}) => {
  const areaList = useAreasSlimList();

  return (
    <section id="message-definition">
      <HeadingBar>
        <FormTitle>Message Definition</FormTitle>
        <StepLabel>Step 1</StepLabel>
      </HeadingBar>
      <FormContainer>
        <FlexContainerWithSpaceBetween>
          <FormSection>
            <form>
              <FieldContainerWithLabel>
                <p>Area</p>
                <ControlledSelectInput
                  required
                  control={control}
                  width="19.5rem"
                  placeholder="Choose"
                  options={areaList.data as Array<SelectOptionType>}
                  name="area"
                />
              </FieldContainerWithLabel>
              <StyledTextInput
                type="text"
                label="Description"
                placeholder="e.g. THE_VTP_H"
                register={register('description')}
                width="19.5rem"
                isLabelBold
              />
              <MessageTypeSelectField control={control} />
              <FieldContainerWithLabel>
                <p>Status</p>
                <ControlledToggleInputWithLabel control={control} name="active" />
              </FieldContainerWithLabel>
              <FieldContainerWithLabel>
                <p>Valid</p>
                <DatePickerContainer>
                  <label>From:</label>
                  <ControlledDatePicker name="validFrom" control={control} required />
                </DatePickerContainer>
                <DatePickerContainer>
                  <label>To:</label>
                  <ControlledDatePicker name="validTo" control={control} />
                </DatePickerContainer>
              </FieldContainerWithLabel>
              <OutputSettingSelectField control={control} />
            </form>
          </FormSection>
          {fipValues && (
            <FormMetadataBox
              fipDatCreated={fipValues.fipDatCreated}
              fipDate={fipValues.fipDate}
              fipUser={fipValues.fipUser}
              fipUserCreated={fipValues.fipUserCreated}
            />
          )}
        </FlexContainerWithSpaceBetween>
      </FormContainer>
    </section>
  );
};
