import { ReactElement, useState } from 'react';
import { useLocation } from 'react-router';
import Modal from 'react-modal';
import {
  StyledNav,
  ModalCloseButton,
  DesktopNavCloseButton,
  StyledMenuCloseArrow,
  StyledMenuOpenArrow,
} from './styled';
import { useMobileDetection } from 'utils/hooks';
import { NavigationItems } from './NavigationItems';
import { LegendPeek } from '../LegendPeek';

const modalStyles = {
  content: {
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#1a202e',
    border: 'none',
  },
  overlay: {
    backgroundColor: '#1a202e',
    zIndex: 1000,
  },
};

const pathBlackist = ['/counterparty', '/balancegroup', '/edit', '/create'];

export const Navigation = (): ReactElement => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const isMobile = useMobileDetection();
  const { pathname } = useLocation();
  const isEnabled = pathBlackist.every((path) => pathname.indexOf(path) === -1);
  const toggleMenuExpand = () => setIsMenuExpanded(!isMenuExpanded);
  const closeMenu = () => setIsMenuExpanded(false);

  return isEnabled ? (
    isMobile ? (
      <Modal isOpen={isMenuExpanded} style={modalStyles}>
        <ModalCloseButton onClick={closeMenu} />
        <NavigationItems isMenuExpanded closeModal={closeMenu} isMobile={isMobile} />
      </Modal>
    ) : (
      <StyledNav isVisible={isMenuExpanded}>
        <DesktopNavCloseButton onClick={toggleMenuExpand}>
          {!isMenuExpanded ? <StyledMenuOpenArrow /> : <StyledMenuCloseArrow />}
        </DesktopNavCloseButton>
        <NavigationItems isMenuExpanded={isMenuExpanded} closeModal={closeMenu} />
        <LegendPeek />
      </StyledNav>
    )
  ) : (
    <></>
  );
};
