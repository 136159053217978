import styled, { css } from 'styled-components';
import { Triangle } from 'assets/icons';
import { BaseButton } from 'components/Buttons/ClassicButton';

const FilterButton = styled(BaseButton)<{
  isFrameless?: boolean;
  isExpanded: boolean;
  string?: number;
}>`
  ${({ theme, isFrameless, isExpanded, width }) => css`
    ${!isFrameless && `border: 1px solid ${theme.lightGrey};`}
    ${!isFrameless && 'border-radius: 0.25rem;'}
    height: ${isFrameless ? '100%;' : '2.4375rem'};
    width: ${width ? width : '13.125rem'};
    :hover {
      background-color: ${!isExpanded && theme.milkGrey};
    }
  `}
  display: flex;
  align-items: center;
  padding: 0rem 1rem;
  justify-content: space-between;
`;

const ClearButton = styled(BaseButton)`
  color: ${({ theme }) => theme.primary};
  text-decoration: underline;
  margin-top: 1.25rem;
  text-align: right;
  font-size: 0.875rem;
`;

const FilterContainer = styled.div`
  position: relative;
`;

const OptionsContainer = styled.div<{ isFrameless?: boolean; width?: number }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  border-top: none;
  top: 2.3125rem;
  right: 0%;
  padding: 1.25rem;
  z-index: 4;
  max-height: calc(9 * 2.5rem);
  overflow: auto;

  ${({ isFrameless, width, theme }) => css`
    width: ${width ? `${width}px` : '13.125rem'};
    border: 1px solid ${theme.lightGrey};
    border-top: none;
    background-color: ${theme.mainBackgroundColor};

    ${isFrameless &&
    css`
      left: -0.0625rem;
      width: ${width ? `${width + 2}px` : '13.25rem'};
    `};
  `}
`;

const FilterOption = styled.label`
  display: flex;
  align-items: center;
  min-height: 2.5rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
`;

const OptionLabel = styled.span`
  display: flex;
  svg {
    margin-right: 0.3125rem;
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const FilterCount = styled.span`
  background-color: ${({ theme }) => theme.baseBlack};
  display: flex;
  width: 0.9375rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  align-items: center;
  justify-content: center;
  color: white;
`;

const Icon = styled(Triangle)<{ $isOpen: boolean }>`
  transform: rotate(${({ $isOpen }) => ($isOpen ? '90deg' : '-90deg')});
  width: 0.5625rem;
  path {
    fill: ${({ theme }) => theme.baseBlack};
  }
`;
export {
  FilterButton,
  ClearButton,
  FilterContainer,
  OptionsContainer,
  FilterOption,
  FilterCount,
  Icon,
  OptionLabel,
};
