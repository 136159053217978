import { BaseButton } from 'components';
import { FC } from 'react';
import styled from 'styled-components';
import { MessageIcon, GasIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleSortType } from '../messagesSlice';

const SortToggleContainer = styled(BaseButton)`
  display: flex;
  width: 19.375rem;
  height: 2.5rem;
  border-radius: 1.75rem;
  border: 1px solid ${({ theme }) => theme.lightGrey};
  background-color: ${({ theme }) => theme.milkGrey};
  position: relative;
  justify-content: space-between;
  align-items: center;
`;

const SelectedPill = styled.div<{ isToggled: boolean }>`
  position: absolute;
  width: 10rem;
  height: 2.375rem;
  border-radius: 1.75rem;
  background-color: ${({ theme }) => theme.primary};
  transition: 0.3s;
  ${({ isToggled }) => isToggled && 'transform: translateX(100%)'}
`;

const OptionLabel = styled.div<{ isWhite: boolean }>`
  z-index: 10;
  width: 9.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.625rem;
  svg {
    border-radius: 0.25rem;
    margin-right: 0.3125rem;
  }
  ${({ isWhite }) => isWhite && 'color: white'}
`;

export const SortTypeToggle: FC = () => {
  const dispatch = useAppDispatch();
  const { sortType } = useAppSelector((state) => state.messageAckReducer);
  const isToggled = sortType === 'gasDay';

  const changeSortType = () => {
    dispatch(toggleSortType());
  };

  return (
    <SortToggleContainer onClick={changeSortType}>
      <SelectedPill isToggled={isToggled} />
      <OptionLabel isWhite={!isToggled}>
        <MessageIcon /> Creation date
      </OptionLabel>
      <OptionLabel isWhite={isToggled}>
        <GasIcon /> Gas Day
      </OptionLabel>
    </SortToggleContainer>
  );
};
