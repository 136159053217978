import styled from 'styled-components';
import { SentIcon } from 'assets/icons';
import { BaseButton, TableHeadingWithSort } from 'components';

const StyledThead = styled.thead`
  border-bottom: 0;
`;

const StyledTableRow = styled.tr`
  border-bottom: 0;
`;

const SendDirectionFilterButton = styled(TableHeadingWithSort)`
  min-width: 8rem;
  padding: 0 0.5rem 0 0.5rem;
`;

const MergedCell = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
`;

const MergedContentCell = styled(MergedCell)`
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.625rem;
  }
`;

const CustomContainer = styled.div<{ isDark?: boolean }>`
  padding: 0 0.625rem;
  display: flex;
  align-items: center;
  height: 2.5rem;
  ${({ isDark, theme }) => isDark && `background-color: ${theme.milkGrey}`};
  width: 100%;
`;

const PartyNameContainer = styled(CustomContainer)`
  min-width: 9rem;
`;

const OutgoingStyledIcon = styled(SentIcon)`
  transform: rotate(-135deg) translateY(15%);
  path {
    fill: ${({ theme }) => theme.extraOrange};
  }
`;

const Row = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns:
    2rem 4.5rem 4.375rem minmax(9rem, 1fr) 4.5rem minmax(9rem, 11rem) 11.25rem 11.25rem 8.75rem minmax(
      15rem,
      1fr
    )
    minmax(12rem, 1fr) 11.25rem 2.5rem 4.375rem 5rem 5rem 5.5rem 5.5rem;
  grid-column-gap: 0.3125rem;
`;

export const NoResultsBar = styled.div`
  width: 100%;
  display: flex;
  font-size: 1.125rem;
  font-weight: 600;
  align-items: center;
  margin-top: 3.125rem;
  justify-content: center;
`;

const CheckboxCellContainer = styled.div<{ noChecbox?: boolean }>`
  display: flex;
  justify-content: ${({ noChecbox }) => (noChecbox ? 'flex-end' : 'space-between')};
  align-items: center;
  width: 100%;
`;

const LinkButton = styled(BaseButton)`
  color: ${({ theme }) => theme.primary};
  text-decoration: underline;
`;

export {
  StyledThead,
  StyledTableRow,
  SendDirectionFilterButton,
  MergedCell,
  CustomContainer,
  PartyNameContainer,
  OutgoingStyledIcon,
  CheckboxCellContainer,
  MergedContentCell,
  Row,
  LinkButton,
};
