import { FC } from 'react';
import { ButtonWithLoader, ErrorBox, Modal } from 'components';
import styled from 'styled-components';
import { ClassicButton, VARIANT } from 'components/Buttons/ClassicButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, openModal } from 'store/modalSlice';
import { useNominationSave } from '../nominationsApi';
import { WarningIcon } from 'assets/icons';

const SubmitModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 3.125rem;
  height: calc(100% - 9rem);
`;

const ModalHeading = styled.h2`
  display: flex;

  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  align-items: center;
  svg {
    margin-left: 0.5rem;
    height: 100%;
  }
`;

const ModalParagraph = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 21.875rem;
  display: flex;
  justify-content: space-between;
`;

const ErrorsContainer = styled.div`
  flex: 1;
  margin-bottom: 0.5rem;
  overflow-y: auto;
`;

const externalContentStyles = {
  height: '50%',
  width: '60%',
  padding: '0',
};

interface NominationTimeLeadValidationSaveModalProps {
  selectedDate: Date;
  numberOfDays: number;
}

export const NominationTimeLeadValidationSaveModal: FC<
  NominationTimeLeadValidationSaveModalProps
> = ({ selectedDate, numberOfDays }) => {
  const dispatch = useAppDispatch();
  const { changedData } = useAppSelector((state) => state.nominationReducer);
  const { props } = useAppSelector(
    (state) => state.modalReducer.nominationTimeLeadValidationSaveModal,
  );
  const { mutate: nominationSave, isLoading } = useNominationSave();

  const goToSubmitModal = () => {
    dispatch(openModal({ modalId: 'submitModal' }));
  };

  return (
    <Modal
      modalId="nominationTimeLeadValidationSaveModal"
      externalContentStyles={externalContentStyles}
      headerType="warning"
    >
      <SubmitModalContainer>
        <ModalHeading>
          Confirmation
          <WarningIcon />
        </ModalHeading>
        <ModalParagraph>{props?.message}</ModalParagraph>
        {props?.error && (
          <ErrorsContainer>
            <ErrorBox errors={props.error.errors.map((errorDetail) => errorDetail.message)} />
          </ErrorsContainer>
        )}
        <ButtonContainer>
          <ButtonWithLoader
            isLoading={isLoading}
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={() =>
              nominationSave({
                changedData,
                selectedDate,
                numberOfDays,
                ...(props?.redirectToSubmit
                  ? { sideEffect: goToSubmitModal }
                  : {
                      sideEffect: () =>
                        dispatch(closeModal({ modalId: 'nominationTimeLeadValidationSaveModal' })),
                    }),
                ignoreNominationLeadTime: true,
                editMode: props?.editMode,
              })
            }
          >
            Yes
          </ButtonWithLoader>
          <ClassicButton
            onClick={() =>
              dispatch(closeModal({ modalId: 'nominationTimeLeadValidationSaveModal' }))
            }
            width="10rem"
            variant={VARIANT.PRIMARY}
            disabled={isLoading}
          >
            No
          </ClassicButton>
        </ButtonContainer>
      </SubmitModalContainer>
    </Modal>
  );
};
