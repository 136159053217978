import styled, { css } from 'styled-components';

const TableCell = styled.div<{ width?: string; isBold?: boolean; isGrey?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding-left: 0.5rem;
  margin-right: 0.5rem;
  ${({ isBold }) => isBold && 'font-weight: 600;'}
  ${({ isGrey, theme }) => isGrey && `color: ${theme.darkGrey};`}
  ${({ width }) =>
    width &&
    css`
      min-width: ${width};
      max-width: ${width};
    `}

  :last-child {
    margin-right: 0;
  }
`;

const HeadingCell = styled(TableCell)`
  background-color: ${({ theme }) => theme.milkGrey};
  font-size: 0.875rem;
`;

const ContentCell = styled(TableCell)`
  border-bottom: 1px solid ${({ theme }) => theme.comboFormContentCellBorderColor};
`;

const NewEntryButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 2.5rem;
`;

export { TableCell, HeadingCell, ContentCell, NewEntryButtonContainer };
