import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  ClassicButton,
  VARIANT,
  HeadingBar,
  FormTitle,
  ContentContainer,
  FormContainer,
  DatePickerContainer,
  ActionBar,
  MainContainer,
  StyledTextInput,
  FormSection,
  FieldContainerWithLabel,
  ControlledToggleInputWithLabel,
  ControlledDatePicker,
  ControlledCountrySelectInput,
  FormSectionTitle,
  IdentifiersTable,
  ConfirmationPopup,
  ConfirmationPopupText,
  FormMetadataBox,
  GlobalErrorModal,
} from 'components';
import { FieldValues, useForm } from 'react-hook-form';
import { LoadingScreen } from 'components/LoadingScreen';
import { getInitialDate } from 'utils/getInitialDate';
import { Area, Identifier, IdentifierType } from 'utils/types';
import { ConterpartySelectField } from './CounterpartySelectField';
import { useArea, useAreaSave } from './areaFormApi';
import { useIdentifier } from 'utils/hooks/useIdentifier';
import { useAppDispatch } from 'store/hooks';
import { openModal } from 'store/modalSlice';
import styled from 'styled-components';
import { formatDateForInput } from 'utils/dateFormating';

const createIdentifierArray = (identifierTypes: Array<IdentifierType>): Array<Identifier> => {
  return identifierTypes.map((identifierType) => ({
    identifier: '',
    type: identifierType,
    validFrom: formatDateForInput(getInitialDate()),
    validTo: formatDateForInput(new Date('2099-09-30')),
  }));
};

const FlexContainerWithSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`; //TODO REFACTOR THIS LAYOUT TO SOMETHING MORE TOUGHTFULL

interface AreaFromProps {
  option: 'edit' | 'create';
  areaId?: string;
}

export const AreaForm: FC<AreaFromProps> = ({ option, areaId }) => {
  const {
    register,
    reset,
    getValues,
    formState: { isDirty, isValid },
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      code: '',
      active: true,
      validFrom: getInitialDate(),
      validTo: new Date('2099-09-30'),
    } as FieldValues,
  });

  const [localState, setLocalState] = useState({
    identifiers: [] as Array<Identifier>,
    hasChanged: false,
  });
  const { identifiers, hasChanged } = localState;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { mutate: areaSave, isLoading: areaIsSaving } = useAreaSave(option);
  const { data: areaData, isLoading } = useArea(option, areaId);
  const cleanIdentifiers = useIdentifier(option === 'create' && identifiers.length === 0);

  useEffect(() => {
    if (option === 'edit' && areaData) {
      const {
        name,
        code,
        active,
        validFrom,
        validTo,
        country,
        identifiers,
        counterparty,
        sortOrder,
      } = areaData;
      reset({
        name,
        code,
        active,
        validFrom: new Date(validFrom),
        validTo: new Date(validTo),
        country,
        counterparty,
        sortOrder,
      });
      setLocalState({ ...localState, identifiers });
    }

    if (option === 'create' && cleanIdentifiers.data) {
      const newIdentifiers = createIdentifierArray(cleanIdentifiers.data.content).filter(
        (identifier) => identifier.type.name === 'EIC',
      );
      setLocalState({ ...localState, identifiers: newIdentifiers });
    }
  }, [option, cleanIdentifiers.data, areaData]);

  const updateIdentifiers = (identifiers: Array<Identifier>) => {
    setLocalState({
      ...localState,
      identifiers,
      hasChanged: true,
    });
  };

  const addNewIdentifier = (identifier: Identifier) => {
    setLocalState({
      ...localState,
      identifiers: [...localState.identifiers, identifier],
      hasChanged: true,
    });
  };

  const onSubmit = () => {
    const { validFrom, validTo, isActive, ...rest } = getValues();

    const areaObj = {
      identifiers,
      validFrom: formatDateForInput(validFrom),
      validTo: formatDateForInput(validTo),
      userDefined: true, // TODO: change this for editing
      active: isActive,
      ...rest,
    };

    if (option === 'edit' && areaData) {
      areaSave({
        ...areaData,
        ...areaObj,
      } as Area);
    } else {
      areaSave(areaObj as Area);
    }
  };

  const shouldDisableSubmit = () => {
    if (option === 'edit') {
      return !hasChanged && (!isDirty || !isValid);
    } else {
      return !isDirty || !isValid || !hasChanged;
    }
  };

  return (
    <MainContainer>
      <GlobalErrorModal />
      <ContentContainer width="65%">
        <HeadingBar>
          <FormTitle>{option === 'create' ? 'Add new area' : 'Edit area'}</FormTitle>
        </HeadingBar>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <FormContainer>
            <FlexContainerWithSpaceBetween>
              <FormSection>
                <FormSectionTitle>Master Data</FormSectionTitle>
                <StyledTextInput
                  type="text"
                  label="Area name"
                  placeholder="e.g. Trading Hub Europe"
                  register={register('name', {
                    required: true,
                    minLength: 2,
                  })}
                  width="20rem"
                  isLabelBold
                />
                <StyledTextInput
                  type="text"
                  label="Short name"
                  placeholder="e.g. THE"
                  register={register('code', {
                    required: true,
                    minLength: 2,
                  })}
                  width="20rem"
                  isLabelBold
                />
              </FormSection>
              {option === 'edit' && areaData && (
                <FormMetadataBox
                  fipDatCreated={areaData.fipDatCreated}
                  fipDate={areaData.fipDate}
                  fipUser={areaData.fipUser}
                  fipUserCreated={areaData.fipUserCreated}
                />
              )}
            </FlexContainerWithSpaceBetween>
            <FormSection>
              <FormSectionTitle>Area Details</FormSectionTitle>
              <ConterpartySelectField control={control} />
              <FieldContainerWithLabel>
                <p>Country</p>
                <ControlledCountrySelectInput
                  name="country"
                  control={control}
                  width="20rem"
                  required
                />
              </FieldContainerWithLabel>
            </FormSection>
            <FormSection>
              <FieldContainerWithLabel>
                <FormSectionTitle>Valid</FormSectionTitle>
                <DatePickerContainer>
                  <label>From:</label>
                  <ControlledDatePicker name="validFrom" control={control} required />
                </DatePickerContainer>
                <DatePickerContainer>
                  <label>To:</label>
                  <ControlledDatePicker name="validTo" control={control} />
                </DatePickerContainer>
              </FieldContainerWithLabel>
              <FieldContainerWithLabel>
                <FormSectionTitle>Status</FormSectionTitle>
                <ControlledToggleInputWithLabel control={control} name="active" />
              </FieldContainerWithLabel>
              <FieldContainerWithLabel>
                <FormSectionTitle>Sort order</FormSectionTitle>
                <StyledTextInput
                  type="number"
                  placeholder="e.g. 1"
                  register={register('sortOrder', {
                    required: true,
                    minLength: 1,
                  })}
                  width="20rem"
                  isLabelBold
                />
              </FieldContainerWithLabel>
              <FormSectionTitle>Identifier</FormSectionTitle>
              <IdentifiersTable
                identifiers={identifiers}
                updateIdentifiers={updateIdentifiers}
                addIdentifier={addNewIdentifier}
              />
            </FormSection>
          </FormContainer>
        )}
        <ActionBar>
          <ClassicButton
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={() => history.push('/app/areas/')}
          >
            Cancel
          </ClassicButton>
          <ClassicButton
            width="10rem"
            variant={VARIANT.PURPLE}
            onClick={() => dispatch(openModal({ modalId: 'areaConfirmationModal' }))}
            disabled={shouldDisableSubmit()}
          >
            {option === 'edit' ? 'Save' : 'Add'}
          </ClassicButton>
        </ActionBar>
        <ConfirmationPopup
          isLoading={areaIsSaving}
          confirmationPopupId="areaConfirmationModal"
          confirmButtonAction={() => onSubmit()}
        >
          <ConfirmationPopupText>
            {option === 'create' ? (
              <>
                Shall we go ahead and save <span>{getValues().name}</span> as a new area?{' '}
              </>
            ) : (
              <>
                Shall we go ahead and update the Masterdata for Area <span>{getValues().name}</span>{' '}
              </>
            )}
          </ConfirmationPopupText>
        </ConfirmationPopup>
      </ContentContainer>
    </MainContainer>
  );
};
