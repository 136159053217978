import {
  CountryFlag,
  DivTableHeadingCell,
  DivTableHeadingCell as TableHeadingCell,
  LinkToMessage,
  MergedCell,
  StatusIndicator,
  TableHeadingWithSort,
} from 'components';
import { FixedSizeList as List } from 'react-window';
import { FC, useState } from 'react';
import { MessageDayIconActive } from 'assets/icons';
import { SortDirection } from 'utils/types';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled, { CSSProperties } from 'styled-components';
import { CountryCode, MessageStatus, SortType } from 'utils/types/generalTypes';
import {
  CustomDateContainer,
  TableContainer,
  Row,
  AutoSizerContainer,
  WhiteTableCells,
  HeadingRow,
} from './styled';
import { useDashboardMessageStatusesByDay } from '../dashboardApi';
import { DashboardMessageStatus } from '../types';
import { formatFromUtc } from 'utils/dateFormating';
import { useAppSelector } from 'store/hooks';

const TableHeadingSortContainer = styled(DivTableHeadingCell)`
  padding: 0 0.625rem;
`;

interface RowProps {
  index: number;
  style: CSSProperties;
  data: Array<DashboardMessageStatus>;
}

const RowRender: FC<RowProps> = ({ data, index, style }) => {
  const { area, lastChange, messageDefinitionDescription, status, messageId } = data[index];
  const { timezone: userTimezone } = useAppSelector((store) => store.globalSlice);
  const [date, hour] =
    lastChange !== null
      ? formatFromUtc(new Date(`${lastChange}Z`), userTimezone?.timeZone).split(' ')
      : [null, null];
  return (
    <Row style={style} isFailure={status === 'FAILURE'}>
      <WhiteTableCells width="2.5rem" align="center" padding="0">
        <CountryFlag countryCode={area.country.isoAlpha2Code as CountryCode} />
      </WhiteTableCells>
      <WhiteTableCells>{messageDefinitionDescription}</WhiteTableCells>
      <WhiteTableCells width="3.5rem" align="center">
        <StatusIndicator status={status as MessageStatus} />
      </WhiteTableCells>
      <WhiteTableCells width="12.1875rem" padding="0">
        <MergedCell>
          {date !== null && hour !== null ? (
            <>
              <CustomDateContainer>{date}</CustomDateContainer>
              <CustomDateContainer>{hour}</CustomDateContainer>
            </>
          ) : (
            <>
              <WhiteTableCells align="center">-</WhiteTableCells>
            </>
          )}
        </MergedCell>
      </WhiteTableCells>
      <WhiteTableCells width="3.5rem" align="center">
        {messageId && <LinkToMessage link={`/app/history/message/${messageId}`} />}
      </WhiteTableCells>
    </Row>
  );
};

interface MessageStatusTableProps {
  date: Date;
}

export const MessageStatusTable: FC<MessageStatusTableProps> = ({ date }) => {
  const [activeSort, setActiveSort] = useState<SortType>({
    name: '',
    direction: 'none',
  });

  const updateSort = (name: string, direction: SortDirection) => {
    setActiveSort({ name, direction });
  };
  const { name: sortName, direction: sortDirection } = activeSort;

  const { data } = useDashboardMessageStatusesByDay(date, activeSort);

  return (
    <TableContainer>
      <HeadingRow>
        <TableHeadingCell width="2.5rem" align="center" padding="0">
          Area
        </TableHeadingCell>
        <TableHeadingCell>Message</TableHeadingCell>
        <TableHeadingCell width="3.5rem" align="center" padding="0">
          Status
        </TableHeadingCell>
        <TableHeadingSortContainer width="12.1875rem">
          <TableHeadingWithSort
            name="lastChange"
            direction={sortName === 'lastChange' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            <MessageDayIconActive />
            Last change
          </TableHeadingWithSort>
        </TableHeadingSortContainer>
        <TableHeadingCell width="3.5rem" align="center" padding="0">
          Action
        </TableHeadingCell>
      </HeadingRow>
      {data?.content && (
        <AutoSizerContainer>
          <AutoSizer>
            {({ height, width }) => (
              <List
                width={width}
                height={height}
                itemCount={data.content.length}
                itemSize={32}
                itemData={data.content}
              >
                {RowRender}
              </List>
            )}
          </AutoSizer>
        </AutoSizerContainer>
      )}
    </TableContainer>
  );
};
