import styled, { css } from 'styled-components';
import { BaseButton } from 'components';

const MultiOptionCheckboxContainer = styled.div<{ isExpanded: boolean }>`
  position: relative;
  height: 2.5rem;
  width: 2.75rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.lightGrey};
  padding: 0.5rem;
  z-index: 3;
  ${({ isExpanded, theme }) =>
    isExpanded &&
    css`
      box-shadow: ${theme.baseShadow};
    `}/* :hover {
    ${({ isExpanded, theme }) => !isExpanded && `border-color: ${theme.primary};`}
  } */
`;

const OptionExpandButton = styled(BaseButton)<{ isExpanded: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4375rem;
  svg {
    transform: ${({ isExpanded }) => (isExpanded ? 'rotate(90deg)' : 'rotate(-90deg)')};
    width: 0.5625rem;
    path {
      fill: ${({ theme }) => theme.primary};
    }
  }
`;

const StyledMenuContainer = styled.div`
  position: absolute;
  padding: 0.625rem 0.9375rem;
  width: 15.5rem;
  box-shadow: 0rem 0.25rem 0.25rem rgba(144, 164, 174, 0.2);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 2.5rem;
  left: -1px;
  box-shadow: 0px 0px 8px rgba(144, 164, 174, 0.4);

  ${({ theme }) => css`
    background-color: ${theme.mainBackgroundColor};
    border: 1px solid ${theme.lightGrey};
    :before {
      content: '';
      position: absolute;
      width: 4.25rem;
      height: 0.5rem;
      top: -0.5rem;
      left: -0.0625rem;
      background: ${theme.mainBackgroundColor};
      border-left: 1px solid ${theme.lightGrey};
      border-right: 1px solid ${theme.lightGrey};
      z-index: 4;
    }
  `}
  z-index: 2;
`;

const ExpandedMenuButton = styled(BaseButton)`
  font-size: 0.875rem;
  height: 2.5rem;
  :hover {
    text-decoration: underline;
  }
`;

const StatusOption = styled.label`
  display: flex;
  align-items: center;
  height: 2.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
`;

const OptionLabel = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.3125rem;
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
  }
`;

const ClearButton = styled(BaseButton)`
  color: ${({ theme }) => theme.primary};
  text-decoration: underline;
  margin-bottom: 1rem;
  text-align: right;
  font-size: 0.875rem;
`;

const FlexEndAligned = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledStatusSelectForm = styled.form`
  width: 100%;
`;

export {
  MultiOptionCheckboxContainer,
  OptionExpandButton,
  StyledMenuContainer,
  ExpandedMenuButton,
  StatusOption,
  OptionLabel,
  ClearButton,
  StyledStatusSelectForm,
  FlexEndAligned,
};
