import { FC, useState } from 'react';
import { Modal, ClassicButton, MediumLoaderWithBlackBg, InputError } from 'components';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useFileUpload } from '../messageApi';
import { ErrorMessage } from '@hookform/error-message';

export interface DataFile {
  data: FileList;
}

const StyledFileInput = styled.input`
  min-width: 90rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
`;

const InputCover = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: 0.075rem solid #ddd;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.2rem 0.4rem rgb(0 0 0 / 5%);
  user-select: none;

  ::before {
    position: absolute;
    top: -0.075rem;
    right: -0.075rem;
    bottom: -0.075rem;
    z-index: 6;
    display: block;
    content: 'Browse';
    padding: 0.4375rem 1.5625rem;
    line-height: 1.5;
    color: #555;
    background-color: #eee;
    border: 0.075rem solid #ddd;
    border-radius: 0 0.25rem 0.25rem 0;
  }
`;

const StyledFileLabel = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
  margin-right: 1.25rem;
`;

const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 1.5625rem;
`;

const UploadForm = styled.form`
  display: flex;
`;

const ButtonLoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadFormContianer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
`;

const FileModalHeading = <StyledModalHeader>Upload response data file</StyledModalHeader>;
export const FileUploadModal: FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [fileName, setFileName] = useState('Choose file...');
  const { mutate: fileUpload, isLoading } = useFileUpload(setError);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    fileUpload(data as DataFile);
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget.files?.item(0) || { name: '' };
    if (name.length) {
      setFileName(name);
    }
  };
  return (
    <Modal
      modalId="fileUploadModal"
      externalContentStyles={{ height: '12rem', width: '100rem' }}
      headerContentComponent={FileModalHeading}
    >
      <UploadFormContianer>
        <UploadForm onSubmit={handleSubmit(onSubmit)}>
          <StyledFileLabel>
            <StyledFileInput {...register('data')} type="file" onChange={onChange} />
            <InputCover>{fileName}</InputCover>
          </StyledFileLabel>
          <ClassicButton width="6.25rem" type="submit" disabled={isLoading}>
            {isLoading ? (
              <ButtonLoaderContainer>
                <MediumLoaderWithBlackBg />
              </ButtonLoaderContainer>
            ) : (
              'Submit'
            )}
          </ClassicButton>
        </UploadForm>
        <ErrorMessage
          errors={errors}
          name="masterData"
          render={({ message }) => <InputError>{message}</InputError>}
        />
      </UploadFormContianer>
    </Modal>
  );
};
