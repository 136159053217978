import { FC } from 'react';
import styled from 'styled-components';
import { PreviewBoxComponent } from 'components';
import { Role } from 'utils/types';
import { standardDateFormat } from 'utils/dateFormating';

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0.625rem;
`;

const TableHeadingCell = styled.th`
  background-color: ${({ theme }) => theme.tableHeadingBg};
  height: 2.5rem;
  padding: 0 1rem;
`;

const TableCell = styled.td`
  height: 2.5rem;
  padding: 0 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
  color: ${({ theme }) => theme.darkGrey};
`;

interface RolePreviewBoxProps {
  roles: Array<Role>;
}

export const RolePreviewBox: FC<RolePreviewBoxProps> = ({ roles }) => {
  return (
    <PreviewBoxComponent amountOfElements={roles.length}>
      <StyledTable>
        <thead>
          <tr>
            <TableHeadingCell>Role</TableHeadingCell>
            <TableHeadingCell>Code</TableHeadingCell>
            <TableHeadingCell>Valid from</TableHeadingCell>
            <TableHeadingCell>Valid to</TableHeadingCell>
          </tr>
        </thead>
        <tbody>
          {roles.map(({ id, role, validFrom, validTo }) => (
            <tr key={id}>
              <TableCell>{role.role}</TableCell>
              <TableCell>{role.code}</TableCell>
              <TableCell>{standardDateFormat(new Date(validFrom))}</TableCell>
              <TableCell>{standardDateFormat(new Date(validTo))}</TableCell>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </PreviewBoxComponent>
  );
};
