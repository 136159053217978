import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { SearchInput } from 'components';
import styled from 'styled-components';

const SearchForm = styled.form`
  width: 100%;
  max-width: 20.25rem;
`;

interface LatestUpdatesSearchProps {
  setSearchKeyword: (keyword: string) => void;
}

export const LatestUpdatesSearch: FC<LatestUpdatesSearchProps> = ({ setSearchKeyword }) => {
  const { register, watch, reset, getValues } = useForm({
    defaultValues: { latestUpdatesSearch: '' },
  });
  const latestUpdatesSearch = watch('latestUpdatesSearch');

  const handleUpdate = useCallback(() => {
    const { latestUpdatesSearch } = getValues();
    setSearchKeyword(latestUpdatesSearch);
  }, [setSearchKeyword]);

  const resetSearch = () => {
    reset();
  };

  return (
    <SearchForm>
      <SearchInput
        register={register('latestUpdatesSearch')}
        placeholder="Search"
        handleUpdate={handleUpdate}
        value={latestUpdatesSearch}
        reset={resetSearch}
      />
    </SearchForm>
  );
};
