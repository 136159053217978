import { ControlledSelectInput, FieldContainerWithLabel } from 'components';
import { FC, useContext } from 'react';
import { Control } from 'react-hook-form';
import { SelectOptionType } from 'utils/types';
import { useContractType } from '../messageDefinitionFormApi';
import { SetErrorContext } from '../index';

interface ContractTypeSelectFieldProps {
  control: Control;
  isDisabled: boolean;
  edigasVersion?: string;
}

export const ContractTypeSelectField: FC<ContractTypeSelectFieldProps> = ({
  control,
  isDisabled,
  edigasVersion,
}) => {
  const { data: contractTypes } = useContractType(edigasVersion);
  const setError = useContext(SetErrorContext)?.setError;
  return (
    <FieldContainerWithLabel isDisabled={isDisabled}>
      <p>Contract type</p>
      <ControlledSelectInput
        required
        control={control}
        width="19.5rem"
        placeholder="Choose"
        options={contractTypes as unknown as Array<SelectOptionType>}
        name="referenceType"
        isDisabled={isDisabled}
        setError={setError}
      />
    </FieldContainerWithLabel>
  );
};
