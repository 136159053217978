import { FC, ReactNode } from 'react';
import { BaseButton } from 'components';
import styled from 'styled-components';
import { SmallTriangle } from 'assets/icons';
import { SortDirection } from 'utils/types';

const SortDownArrow = styled(SmallTriangle)<{ $isActive: boolean }>`
  path {
    fill: ${({ $isActive, theme }) => ($isActive ? theme.primary : '#90A4AE')};
  }
`;

const SortUpArrow = styled(SortDownArrow)`
  transform: rotate(180deg);
`;

const FilterHeadingButton = styled(BaseButton)`
  display: flex;
  align-items: center;
  text-align: initial;
  width: 100%;
  position: relative;
  height: 100%;
  font-size: 0.875rem;
  justify-content: space-between;

  svg {
    border-radius: 0.25rem;
  }

  :hover {
    ${SortDownArrow} {
      path {
        fill: ${({ theme }) => theme.primary};
      }
    }
  }
`;

const SortIndicatorContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
`;

interface TableHeadingWithSortProps {
  name: string;
  direction: SortDirection;
  setFilter: (name: string, direction: SortDirection) => void;
  children: ReactNode;
}

export const TableHeadingWithSort: FC<TableHeadingWithSortProps> = ({
  children,
  name,
  direction,
  setFilter,
  ...props
}) => {
  const onSortClick = () => {
    switch (direction) {
      case 'none':
        setFilter(name, 'asc');
        break;
      case 'asc':
        setFilter(name, 'desc');
        break;
      case 'desc':
        setFilter(name, 'none');
        break;
      default:
        break;
    }
  };

  return (
    <FilterHeadingButton {...props} onClick={onSortClick}>
      {children}
      <SortIndicatorContainer>
        <SortUpArrow $isActive={direction === 'asc'} />
        <SortDownArrow $isActive={direction === 'desc'} />
      </SortIndicatorContainer>
    </FilterHeadingButton>
  );
};
