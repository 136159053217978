import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NotificationType = 'ACCEPTED' | 'FAILED' | 'NEUTRAL';

interface Notification {
  isVisible: boolean;
  type: NotificationType;
  copy: string;
}

// TODO: possible bigger ammount of notifications?
interface NotificationState {
  notification: Notification;
}

const initialState = {
  notification: {
    isVisible: false,
    type: 'NEUTRAL',
    copy: '',
  },
} as NotificationState;

interface NotificationActionType {
  type: NotificationType;
  copy: string;
}

const notificationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    pushNotification(state, actions: PayloadAction<NotificationActionType>) {
      const { type, copy } = actions.payload;
      state.notification = {
        type,
        copy,
        isVisible: true,
      };
    },
    closeNotification(state) {
      state.notification = {
        isVisible: false,
        copy: '',
        type: 'NEUTRAL',
      };
    },
  },
});

export const { pushNotification, closeNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
