import { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const HeadingRow = styled.div<{ isWhite: boolean }>`
  width: 100%;
  display: flex;
  padding: 1.5625rem 2.5rem;
  max-height: 8rem;
  min-height: 8rem;
  background-color: ${({ theme, isWhite }) => (isWhite ? theme.white : theme.pageSectionTitleBg)};
`;

const Heading = styled.h1`
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 0.625rem;
`;

const Paragraph = styled.p`
  b {
    font-weight: 600;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface Props {
  section: string;
  isWhite?: boolean;
}

export const SectionTitle = ({ section, isWhite = false }: Props): ReactElement => {
  const { t } = useTranslation(section);
  return (
    <HeadingRow isWhite={isWhite}>
      <ColumnContainer>
        <Heading>
          <>{t('title')}</>
        </Heading>
        <Paragraph dangerouslySetInnerHTML={{ __html: t('subtitle') }} />
      </ColumnContainer>
    </HeadingRow>
  );
};
