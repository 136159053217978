import { FC } from 'react';
import styled from 'styled-components';
import { useAppSelector } from 'store/hooks';
import { useCreateNomresMessages, useImportAcknowMessages, useSendMessages } from '../messageApi';
import { ComboFilter } from './ComboFilter';
import { ButtonWithLoader } from 'components';

const ControlPanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
`;

const LeftSideContainer = styled.div`
  display: flex;

  > * {
    margin-right: 0.5rem;
  }
`;
const RightSideContainer = styled.div`
  display: flex;
  flex: 1;
`;

interface ControlPanelProps {
  setSearchKeyword: (keyword: string) => void;
  selectedDate: Date;
}

export const ControlPanel: FC<ControlPanelProps> = ({ setSearchKeyword, selectedDate }) => {
  const { selected } = useAppSelector((state) => state.messageAckReducer);
  const { mutate: messagesSend, isLoading } = useSendMessages();
  const { mutate: acknowCreate } = useImportAcknowMessages();
  const { mutate: messagesCreate } = useCreateNomresMessages();

  return (
    <ControlPanelContainer>
      <LeftSideContainer>
        <ButtonWithLoader
          isLoading={isLoading}
          title="Execute sending of our generated NOMINT messages which are UNSENT or PENDING.&#10;Status-flow is: UNSENT -> PENDING -> SENT"
          width="14rem"
          disabled={selected.length === 0}
          onClick={() => messagesSend()}
        >
          Send NOMINT message
        </ButtonWithLoader>
        <ButtonWithLoader
          isLoading={isLoading}
          title="Create and then import an ACKNOW-message."
          width="24rem"
          disabled={selected.length === 0}
          onClick={() => acknowCreate()}
        >
          Import ACKNOW message
        </ButtonWithLoader>
        <ButtonWithLoader
          isLoading={isLoading}
          title="Create and then import an matching NOMRES-message."
          width="24rem"
          disabled={selected.length === 0}
          onClick={() => messagesCreate(true)}
        >
          Import matching NOMRES message
        </ButtonWithLoader>
        <ButtonWithLoader
          isLoading={isLoading}
          title="Create and then import an non-matching NOMRES-message."
          width="24rem"
          disabled={selected.length === 0}
          onClick={() => messagesCreate(false)}
        >
          Import non-matching NOMRES message
        </ButtonWithLoader>
      </LeftSideContainer>
      <RightSideContainer>
        <ComboFilter setSearchKeyword={setSearchKeyword} selectedDate={selectedDate} />
      </RightSideContainer>
    </ControlPanelContainer>
  );
};
