import React, { FC, useState } from 'react';
import {
  SelectButton,
  SelectContainer,
  OptionsContainer,
  SelectOption,
  Icon,
  PlaceholderText,
  SelectedText,
} from './SelectInput/styled';
import { useApiGet, useClickOutside } from 'utils/hooks';
import { Controller, Control } from 'react-hook-form';
import {
  France,
  Germany,
  Italy,
  Switzerland,
  Netherlands,
  Austria,
  Denmark,
  Belgium,
  CompanyIcon,
  GreatBritain,
  Bulgaria,
  Estonia,
  Finland,
  Hungary,
  Latvia,
  Lithuania,
  Norway,
  Poland,
  Portugal,
  Romania,
  Slovakia,
  Slovenia,
  Spain,
  CzechRepublic,
} from 'assets/icons/countryIcons';
import { useQuery } from 'react-query';
import { Country } from 'utils/types';

const countryIcons = {
  DE: <Germany />,
  IT: <Italy />,
  CH: <Switzerland />,
  FR: <France />,
  NL: <Netherlands />,
  AT: <Austria />,
  DK: <Denmark />,
  BE: <Belgium />,
  DMY: <CompanyIcon />,
  GB: <GreatBritain />,
  BG: <Bulgaria />,
  EE: <Estonia />,
  FI: <Finland />,
  HU: <Hungary />,
  LV: <Latvia />,
  LT: <Lithuania />,
  NO: <Norway />,
  PL: <Poland />,
  PT: <Portugal />,
  RO: <Romania />,
  SK: <Slovakia />,
  SI: <Slovenia />,
  ES: <Spain />,
  CZ: <CzechRepublic />,
};

interface CountryList {
  content: Array<Country>;
}

export const useCountries = () => {
  const fetchCountries = useApiGet(`/country?size=0&sort=name,asc`);
  return useQuery<CountryList, Error>(['countryList'], fetchCountries);
};

interface CountrySelectInputProps {
  width?: string;
  isFrameless?: boolean;
  filtersCleared?: boolean;
  isDisabled?: boolean;
  fieldName?: string;
  selectOption?: (country: Country) => void;
  value?: Country;
}

export const CountrySelectInput: FC<CountrySelectInputProps> = ({
  value,
  selectOption,
  width,
  isFrameless,
  isDisabled = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const closeOptionsContainer = () => setIsExpanded(false);
  const ref = useClickOutside(closeOptionsContainer);
  const { data: countryList } = useCountries();

  const selectedCountry = countryList?.content.find(
    (countryOption) => countryOption.isoAlpha2Code === value?.isoAlpha2Code,
  );

  const selectCountry = (option: Country) => {
    selectOption && selectOption(option);
    setIsExpanded(false);
  };

  return (
    <SelectContainer ref={ref} width={width}>
      <SelectButton
        type="button"
        onClick={() => setIsExpanded(!isExpanded)}
        isFrameless={isFrameless}
        disabled={isDisabled}
        isOpen={isExpanded}
      >
        {!selectedCountry ? (
          <PlaceholderText>Select country</PlaceholderText>
        ) : (
          <SelectedText>
            {countryIcons[selectedCountry.isoAlpha2Code as keyof typeof countryIcons]}
            {selectedCountry.name}
          </SelectedText>
        )}
        <Icon $isOpen={isExpanded} />
      </SelectButton>
      {isExpanded && (
        <OptionsContainer>
          {countryList?.content.map((option) => (
            <SelectOption onClick={() => selectCountry(option)} key={option.isoAlpha2Code}>
              {countryIcons[option.isoAlpha2Code as keyof typeof countryIcons]}
              {option.name}
            </SelectOption>
          ))}
        </OptionsContainer>
      )}
    </SelectContainer>
  );
};

interface ControlledCountrySelectInputProps extends CountrySelectInputProps {
  name: string;
  control: Control;
  required?: boolean;
}

export const ControlledCountrySelectInput: FC<ControlledCountrySelectInputProps> = ({
  name,
  width,
  control,
  required,
  isDisabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, value } }) => (
        <CountrySelectInput
          selectOption={(country: Country) => onChange(country)}
          value={value}
          width={width}
          isDisabled={isDisabled}
          fieldName={name}
        />
      )}
    />
  );
};
