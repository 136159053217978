import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Triangle, SentIcon } from 'assets/icons';
import { MessageDirection } from 'utils/types';

const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 1.5625rem 2.5rem;
  max-height: 7rem;
  min-height: 7rem;
  height: 7rem;
  background-color: ${({ theme }) => theme.pageSectionTitleBg};
`;

const Heading = styled.h1`
  font-weight: 500;
  font-size: 1.5rem;
`;

const MessageName = styled.h2`
  font-weight: 500;
  font-size: 1.5rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledBackLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.primary};
  font-size: 1.125rem;

  svg {
    width: 0.5625rem;
    margin-right: 0.3125rem;
    path {
      fill: ${({ theme }) => theme.primary};
    }
  }
`;

const DirectionIcon = styled(SentIcon)`
  margin-right: 0.3125rem;
`;

const OutgoingStyledIcon = styled(DirectionIcon)`
  transform: rotate(-135deg) translateY(15%);
  path {
    fill: ${({ theme }) => theme.extraOrange};
  }
`;

interface Props {
  messageName: string;
  messageDirection: MessageDirection;
}

export const MessageDetailSectionHeader: FC<Props> = ({ messageName, messageDirection }) => {
  const { t } = useTranslation('messageDetails');
  return (
    <HeadingContainer>
      <ColumnContainer>
        <StyledBackLink to="/app/history">
          <Triangle />
          Back to messages
        </StyledBackLink>
        <HeadingRow>
          <Heading>
            <>
              {messageDirection === 'SENT' ? <OutgoingStyledIcon /> : <DirectionIcon />}
              {t('title')}
            </>
          </Heading>
          <MessageName>{messageName}</MessageName>
        </HeadingRow>
      </ColumnContainer>
    </HeadingContainer>
  );
};
