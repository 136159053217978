import { FC, useState } from 'react';
import styled from 'styled-components';
import { DashboardSectionTitle } from './styled';
import { LatestUpdatesSearch } from './LatestUpdatesSearch';
import { LatestUpdatesTable } from './LatestUpdatesTable';

const LatestUpdateContainer = styled.div`
  width: 100%;
  padding: 1.5rem;
  border: 2px solid ${({ theme }) => theme.milkGrey};
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  overflow-y: auto;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const LastUpdateSectionContainer = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
`;

const LastUpdateSectionTitle = styled(DashboardSectionTitle)`
  margin: 0;
`;

const LastUpdateBlock = styled.div`
  width: 100%;
  flex: 1;
`;

interface LatestUpdateSectionProps {
  date: Date;
}

export const LatestUpdateSection: FC<LatestUpdateSectionProps> = ({ date }) => {
  const [searchKeyword, setSearchKeyword] = useState('');

  return (
    <LastUpdateSectionContainer>
      <TopRow>
        <LastUpdateSectionTitle>Latest update</LastUpdateSectionTitle>
        <LatestUpdatesSearch setSearchKeyword={(keyword: string) => setSearchKeyword(keyword)} />
      </TopRow>

      <LatestUpdateContainer>
        <LastUpdateBlock>
          <LatestUpdatesTable date={date} searchKeyword={searchKeyword} />
        </LastUpdateBlock>
      </LatestUpdateContainer>
    </LastUpdateSectionContainer>
  );
};
