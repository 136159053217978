import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  selectedPage: number;
  amountOutItemsPerPage: number;
}

const initialState = {
  selectedPage: 1,
  amountOutItemsPerPage: 10,
} as State;

const dashboardSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.selectedPage = action.payload;
    },
    setAmountOutItemsPerPage(state, action: PayloadAction<number>) {
      state.amountOutItemsPerPage = action.payload;
      state.selectedPage = 1;
    },
  },
});

export const { setPage, setAmountOutItemsPerPage } = dashboardSlice.actions;

export default dashboardSlice.reducer;
