import { FC } from 'react';
import styled from 'styled-components';
import { ClassicButton, BaseButtonProps, BaseButton } from './ClassicButton';
import { MediumLoaderWithBlackBg } from '../Loaders';

const ButtonLoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ButtonWithLoaderProps extends BaseButtonProps {
  isLoading: boolean;
  onClick: () => void;
}

export const ButtonWithLoader: FC<ButtonWithLoaderProps> = ({
  isLoading,
  children,
  onClick,
  width,
  variant,
  ...classicButtonProps
}) => (
  <ClassicButton
    width={width}
    variant={variant}
    onClick={onClick}
    {...classicButtonProps}
    disabled={isLoading || classicButtonProps.disabled}
  >
    {isLoading ? (
      <ButtonLoaderContainer>
        <MediumLoaderWithBlackBg />
      </ButtonLoaderContainer>
    ) : (
      children
    )}
  </ClassicButton>
);

export const BaseButtonWithLoader: FC<ButtonWithLoaderProps> = ({
  isLoading,
  children,
  onClick,
  ...classicButtonProps
}) => (
  <BaseButton
    onClick={onClick}
    {...classicButtonProps}
    disabled={isLoading || classicButtonProps.disabled}
  >
    {isLoading ? (
      <ButtonLoaderContainer>
        <MediumLoaderWithBlackBg />
      </ButtonLoaderContainer>
    ) : (
      children
    )}
  </BaseButton>
);
