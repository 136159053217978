import { FC } from 'react';
import { TimePeriodType } from 'utils/types';
import { StyledHourHeadingCell } from './styled';

interface CarouselHourHeadingProps {
  hoursDefinition?: Array<TimePeriodType>;
}

const removeLeadingZero = (hour: string) => {
  return hour.replace(/(?!00:00)(^0)/, '');
};

export const CarouselHourHeading: FC<CarouselHourHeadingProps> = ({ hoursDefinition }) => {
  return hoursDefinition ? (
    <>
      {hoursDefinition.map(({ from, to }, key) => (
        <StyledHourHeadingCell
          key={`${from}_${to}`}
          isAdditionalHour={hoursDefinition.length === 25 && key === 20}
        >
          {removeLeadingZero(from)}
          <br />
          {removeLeadingZero(to)}
        </StyledHourHeadingCell>
      ))}
    </>
  ) : (
    <>
      <StyledHourHeadingCell>
        6:00
        <br />
        7:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        7:00
        <br />
        8:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        8:00
        <br />
        9:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        9:00
        <br />
        10:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        10:00
        <br />
        11:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        11:00
        <br />
        12:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        12:00
        <br />
        13:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        13:00
        <br />
        14:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        14:00
        <br />
        15:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        15:00
        <br />
        16:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        16:00
        <br />
        17:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        17:00
        <br />
        18:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        18:00
        <br />
        19:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        19:00
        <br />
        20:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        20:00
        <br />
        21:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        21:00
        <br />
        22:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        22:00
        <br />
        23:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        23:00
        <br />
        00:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        00:00
        <br />
        1:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        1:00
        <br />
        2:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        2:00
        <br />
        3:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        3:00
        <br />
        4:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        4:00
        <br />
        5:00
      </StyledHourHeadingCell>
      <StyledHourHeadingCell>
        5:00
        <br />
        6:00
      </StyledHourHeadingCell>
    </>
  );
};
