import { FC, useContext } from 'react';

import {
  useEmails,
  useGlobalNotificationSettingsSave,
  usePhoneNumbers,
  useTimezoneSetting,
  useTimezoneSettingSave,
} from './settingsApi';
import styled from 'styled-components';
import { useAppSelector } from 'store/hooks';
import { TenantInfoContext } from 'App';
import { PhoneNumberForm } from './PhoneNumberForm';
import { EmailForm } from './EmailForm';
import { ControlledSelectInput } from 'components';
import { FieldValues, useForm } from 'react-hook-form';
import timezonesList from 'timezones-list';
import { TimezoneSettingsForm } from './TimezoneSettingsForm';

export const TitleLabel = styled.span`
  display: inline-block;
  font-weight: 600;
  margin-right: 0.3125rem;
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const AccountSetting: FC = () => {
  const { data: phoneNumbers, isLoading: phoneNumbersFetchingInProgress } = usePhoneNumbers();
  const { data: emails, isLoading: emailFetchingInProgress } = useEmails();

  const { data: timezoneSettings, isLoading: timezoneSettingsFetchingInProgress } =
    useTimezoneSetting();

  const { firstName, lastName } = useAppSelector((state) => state.authReducer);
  const {
    login: { organisation },
  } = useContext(TenantInfoContext);

  const {
    register,
    control,
    formState: { isDirty, isValid, errors: formError },
    getValues,
    reset,
  } = useForm({
    defaultValues: {} as FieldValues,
    mode: 'all',
  });

  return (
    <>
      <InfoRow>
        <TitleLabel>Organisation: </TitleLabel>
        {organisation}
      </InfoRow>
      <InfoRow>
        <TitleLabel>First name: </TitleLabel>
        {firstName}
      </InfoRow>
      <InfoRow>
        <TitleLabel>Last name: </TitleLabel>
        {lastName}
      </InfoRow>
      {phoneNumbers && <PhoneNumberForm phoneNumbers={phoneNumbers.content} />}
      {emails && <EmailForm emails={emails.content} />}

      {timezoneSettings && <TimezoneSettingsForm timezoneSettings={timezoneSettings} />}
    </>
  );
};
