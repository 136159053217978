import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Counterparty, MainCustomErrorType } from 'utils/types';
import { useHistory } from 'react-router-dom';
import { useApiGet, API } from 'utils/hooks';
import { setError } from 'features/ErrorScreen/errorSlice';
import { closeModal } from 'store/modalSlice';

export const useCounterparty = (counterpartyId?: string) => {
  const fetchCounterparty = useApiGet(`/counterparty/${counterpartyId}`, !!counterpartyId);
  return useQuery<Counterparty, Error>(['counterparty'], fetchCounterparty);
};

export const useCounterpartySave = (
  option: 'edit' | 'create',
  updateErrors: (error: MainCustomErrorType) => void,
) => {
  const { token } = useAppSelector((state) => state.authReducer);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  return useMutation(
    (data: Counterparty) => {
      if (token.length > 0) {
        if (option === 'edit') {
          return API.PUT('/counterparty', {
            headers: {
              Authorization: token,
            },
            body: JSON.stringify(data),
          });
        }
        return API.POST('/counterparty', {
          headers: {
            Authorization: token,
          },
          body: JSON.stringify(data),
        });
      }
      return Promise.reject();
    },
    {
      onError: (error: MainCustomErrorType) => {
        if (error.code) {
          updateErrors(error);
        } else {
          dispatch(setError({ errorMessage: error.toString() }));
        }

        dispatch(closeModal({ modalId: 'counterpartyConfirmationModal' }));
      },
      onSuccess: async () => {
        dispatch(closeModal({ modalId: 'counterpartyConfirmationModal' }));
        queryClient.invalidateQueries('counterparty');
        history.push('/app/masterdata');
      },
    },
  );
};
