import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageFormContainer } from 'components';
import { BalancegroupForm } from 'features/BalancegroupForm';

interface ParamTypes {
  option: 'create' | 'edit';
  counterpartyName: string;
  id?: string;
  counterpartyId: string;
}

export const BalancegroupFormPage: FC = () => {
  const { option, counterpartyName, id, counterpartyId } = useParams<ParamTypes>();

  return (
    <PageFormContainer>
      <BalancegroupForm
        option={option}
        counterpartyName={counterpartyName}
        balanceGroupId={id}
        counterpartyId={counterpartyId}
      />
    </PageFormContainer>
  );
};
