import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Area, AreaConnectionPoint } from 'utils/types';

import { API, useApiGet } from 'utils/hooks';
import { CustomErrorType, SortType } from 'utils/types/generalTypes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, openModal } from 'store/modalSlice';

interface Areas {
  content: Array<Area>;
}

interface ConnectionPoints {
  content: Array<AreaConnectionPoint>;
}

export const useAreas = (activeSort: SortType, searchPhrase: string) => {
  const fetchAreas = useApiGet(
    `/area?size=50${
      activeSort.name !== '' && activeSort.direction !== 'none'
        ? `&sort=${activeSort.name},${activeSort.direction}`
        : ''
    }${searchPhrase.length > 0 ? `&search=${searchPhrase}` : ''}`,
  );
  return useQuery<Areas, Error>(['areas', activeSort, searchPhrase], fetchAreas);
};

export const useConnectionpointsByAreaId = (areaId: number) => {
  const fetchConnectionpints = useApiGet(`/connection-point/area/${areaId}?size=0`);
  return useQuery<ConnectionPoints, Error>(
    [`connectionPoint_${areaId}`, areaId],
    fetchConnectionpints,
    {
      enabled: !!areaId,
    },
  );
};

export const useConnectionPointRemoval = (areaId?: number) => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  return useMutation(
    (connectionPointId: number) => {
      if (token.length) {
        return API.DELETE(`/connection-point/${connectionPointId}`, {
          headers: {
            Authorization: token,
          },
        });
      }
      return Promise.reject();
    },
    {
      onError: (error: CustomErrorType) => {
        dispatch(
          openModal({
            modalId: 'deletionErrorModal',
            props: { errors: error?.errors },
          }),
        );
      },
      onSuccess: async () => {
        dispatch(closeModal({ modalId: 'connectionPointRemovalConfirmationModal' }));
        queryClient.invalidateQueries(`connectionPoint_${areaId}`);
      },
    },
  );
};
