import { FC } from 'react';
import { ButtonWithLoader, Modal } from 'components';
import styled from 'styled-components';
import { VARIANT } from 'components/Buttons/ClassicButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, openModal } from 'store/modalSlice';
import { useNominationSave } from '../nominationsApi';
import { WarningIcon } from 'assets/icons';

const SubmitModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 3.125rem;
`;

const ModalHeading = styled.h2`
  display: flex;

  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  align-items: center;
  svg {
    margin-left: 0.5rem;
    height: 100%;
  }
`;

const ModalParagraph = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 32rem;
  display: flex;
  justify-content: space-between;
`;

const externalContentStyles = {
  height: '25rem',
  width: '60rem',
  padding: '0',
};

interface NominationOptimisticLockingErrorModalProps {
  selectedDate: Date;
  numberOfDays: number;
}

export const NominationOptimisticLockingErrorModal: FC<
  NominationOptimisticLockingErrorModalProps
> = ({ selectedDate, numberOfDays }) => {
  const dispatch = useAppDispatch();
  const { changedData } = useAppSelector((state) => state.nominationReducer);
  const { props } = useAppSelector(
    (state) => state.modalReducer.nominationOptimisticLockingErrorModal,
  );
  const { mutate: nominationSave, isLoading } = useNominationSave(props?.redirectToSubmit);

  const goToSubmitModal = () => {
    dispatch(openModal({ modalId: 'submitModal' }));
  };

  return (
    <Modal
      modalId="nominationOptimisticLockingErrorModal"
      externalContentStyles={externalContentStyles}
      headerType="warning"
    >
      <SubmitModalContainer>
        <ModalHeading>
          Warning
          <WarningIcon />
        </ModalHeading>
        <ModalParagraph>{props?.message}</ModalParagraph>
        <ButtonContainer>
          <ButtonWithLoader
            isLoading={isLoading}
            onClick={() =>
              nominationSave({
                changedData,
                selectedDate,
                numberOfDays,
                ...(props?.redirectToSubmit
                  ? { sideEffect: goToSubmitModal }
                  : {
                      sideEffect: () =>
                        dispatch(closeModal({ modalId: 'nominationOptimisticLockingErrorModal' })),
                    }),
                editMode: 'OVERWRITE',
              })
            }
            width="10rem"
            variant={VARIANT.SECONDARY}
          >
            Yes
          </ButtonWithLoader>
          <ButtonWithLoader
            isLoading={isLoading}
            width="10rem"
            variant={VARIANT.PRIMARY}
            onClick={() =>
              nominationSave({
                changedData,
                selectedDate,
                numberOfDays,
                ...(props?.redirectToSubmit
                  ? { sideEffect: goToSubmitModal }
                  : {
                      sideEffect: () =>
                        dispatch(closeModal({ modalId: 'nominationOptimisticLockingErrorModal' })),
                    }),
                editMode: 'DONT_OVERWRITE',
              })
            }
          >
            No
          </ButtonWithLoader>
          <ButtonWithLoader
            isLoading={isLoading}
            width="10rem"
            variant={VARIANT.PRIMARY}
            onClick={() =>
              nominationSave({
                changedData,
                selectedDate,
                numberOfDays,
                editMode: 'DISCARD_CHANGES',
                ...(props?.redirectToSubmit
                  ? { sideEffect: goToSubmitModal }
                  : {
                      sideEffect: () =>
                        dispatch(closeModal({ modalId: 'nominationOptimisticLockingErrorModal' })),
                    }),
              })
            }
          >
            Discard changes
          </ButtonWithLoader>
        </ButtonContainer>
      </SubmitModalContainer>
    </Modal>
  );
};
