import { FC } from 'react';
import { ClassicButton, ErrorBox, Modal } from 'components';
import styled from 'styled-components';
import { VARIANT } from 'components/Buttons/ClassicButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'store/modalSlice';
import { ErrorIcon } from 'assets/icons';

const SubmitModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 3.125rem;
  height: calc(100% - 9rem);
`;

const ModalHeading = styled.h2`
  display: flex;

  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  align-items: center;
  svg {
    margin-left: 0.5rem;
    height: 100%;
  }
`;

const ModalParagraph = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 32rem;
  display: flex;
  justify-content: center;
`;

const ErrorsContainer = styled.div`
  flex: 1;
  margin-bottom: 0.5rem;
  overflow-y: auto;
`;

const externalContentStyles = {
  height: '50%',
  width: '70%',
  padding: '0',
};

export const SubmitErrorModal: FC = () => {
  const dispatch = useAppDispatch();
  const { props } = useAppSelector((state) => state.modalReducer.submitErrorModal);

  return (
    <Modal
      modalId="submitErrorModal"
      externalContentStyles={externalContentStyles}
      headerType="error"
    >
      <SubmitModalContainer>
        <ModalHeading>
          Error
          <ErrorIcon />
        </ModalHeading>
        <ModalParagraph>{props?.error?.message}</ModalParagraph>
        <ErrorsContainer>
          {props?.error && <ErrorBox errors={props.error.errors.map((error) => error.message)} />}
        </ErrorsContainer>
        <ButtonContainer>
          <ClassicButton
            width="10rem"
            variant={VARIANT.PRIMARY}
            onClick={() => dispatch(closeModal({ modalId: 'submitErrorModal' }))}
          >
            Close
          </ClassicButton>
        </ButtonContainer>
      </SubmitModalContainer>
    </Modal>
  );
};
