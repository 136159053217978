import { FC } from 'react';
import { Modal } from 'components';
import styled from 'styled-components';
import { SettingsTabs } from './SettingsTabs';
import { useAppSelector } from 'store/hooks';

const ModalHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
`;

const externalContentStyles = {
  height: '80%',
  width: '50%',
  padding: '0',
  overflow: 'hidden',
  overflowY: 'scroll',
};

export const SettingsModal: FC = () => {
  const ModalTitleComponent = <ModalHeading>Settings</ModalHeading>;
  const { props } = useAppSelector(({ modalReducer }) => modalReducer.settingsModal);

  // TODO: extract everything in modal to enable normal component updates
  return (
    <Modal
      modalId="settingsModal"
      externalContentStyles={externalContentStyles}
      headerContentComponent={ModalTitleComponent}
    >
      <SettingsTabs preSelectedTab={(props && props.settingWindow) || 'account'} />
    </Modal>
  );
};
