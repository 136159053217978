import { Role } from 'utils/types';
import { FC, useState } from 'react';
import { BaseButton, EditButton } from 'components';
import { RoleForm } from './RoleForm';
import styled from 'styled-components';
import { HeadingCell, ContentCell, RoleTableBaseCell, NewRoleButtonContainer } from './styled';
import { BluePlusIcon, EditIcon, TrashIcon } from 'assets/icons';
import { useRoles } from 'utils/hooks/useRoleType';
import { standardDateFormat } from 'utils/dateFormating';

const RoleTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const RoleRow = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0.5rem;
  :last-child {
    margin-bottom: 0;
  }
  padding: 1rem;
`;

interface RoleTableProps {
  roles: Array<Role>;
  addRole: (role: Role) => void;
  updateRoles: (roles: Array<Role>) => void;
}

export const RolesTable: FC<RoleTableProps> = ({ roles, addRole, updateRoles }) => {
  const { data: roleTypes } = useRoles();
  const [newRoleFormActive, setNewRoleFormActive] = useState(false);
  const [editRoleFormActive, setEditRoleFormActive] = useState(-1);

  const addNewRoleAndCloseForm = (role: Role) => {
    addRole(role);
    setNewRoleFormActive(false);
  };

  const editRoleAndCloseForm = (role: Role, key: number) => {
    roles[key] = {
      ...roles[key],
      ...role,
    };

    setEditRoleFormActive(-1);

    updateRoles([...roles]);
  };

  const removeRole = (roleIndex: number) => {
    const filteredRoles = roles.filter((role, key) => key !== roleIndex);
    updateRoles(filteredRoles);
  };

  return (
    <RoleTable>
      <RoleRow>
        <HeadingCell>Role</HeadingCell>
        <HeadingCell>Code</HeadingCell>
        <HeadingCell width="10rem">Valid from</HeadingCell>
        <HeadingCell width="10rem">Valid to</HeadingCell>
        <RoleTableBaseCell width="3rem"></RoleTableBaseCell>
      </RoleRow>
      {roles.length !== 0 &&
        roles.map((element, key) => {
          return editRoleFormActive === key && roleTypes ? (
            <RoleForm
              roleList={roleTypes.content}
              onCancel={() => setEditRoleFormActive(-1)}
              role={element}
              submitRole={(role: Role) => editRoleAndCloseForm(role, key)}
            />
          ) : (
            <RoleRow key={`${element.role.code}_${key}`}>
              <ContentCell>{element.role.role}</ContentCell>
              <ContentCell isGrey>{element.role.code}</ContentCell>
              <ContentCell width="10rem">
                {standardDateFormat(new Date(element.validFrom))}
              </ContentCell>
              <ContentCell width="10rem">
                {standardDateFormat(new Date(element.validTo))}
              </ContentCell>
              <RoleTableBaseCell width="3rem">
                <BaseButton onClick={() => setEditRoleFormActive(key)}>
                  <EditIcon />
                </BaseButton>
                {roles.length > 1 && (
                  <BaseButton onClick={() => removeRole(key)} margin="0 0 0 0.5rem">
                    <TrashIcon />
                  </BaseButton>
                )}
              </RoleTableBaseCell>
            </RoleRow>
          );
        })}
      {roleTypes && newRoleFormActive ? (
        <RoleForm
          roleList={roleTypes.content}
          onCancel={() => setNewRoleFormActive(false)}
          submitRole={addNewRoleAndCloseForm}
        />
      ) : (
        <NewRoleButtonContainer>
          <EditButton onClick={() => setNewRoleFormActive(true)}>
            <BluePlusIcon />
            Add new
          </EditButton>
        </NewRoleButtonContainer>
      )}
    </RoleTable>
  );
};
