import { FC } from 'react';
import { PageFormContainer } from 'components';
import { AdminPanel } from '../../features/AdminPanel';
import { ResponseModal } from '../../features/AdminPanel/ResponseModal';

export const AdminPage: FC = () => {
  return (
    <PageFormContainer>
      <AdminPanel />
      <ResponseModal />
    </PageFormContainer>
  );
};
