import { ControlledSelectInput, FieldContainerWithLabel } from 'components';
import { FC, useContext } from 'react';
import { Control } from 'react-hook-form';
import styled from 'styled-components';
import { SelectOptionType } from 'utils/types';
import { useOutputSetting } from '../messageDefinitionFormApi';
import { SetErrorContext } from '../index';
import { SmallLoader } from 'components';
interface OutputSettingSelectFieldProps {
  control: Control;
}

const StyledFormWithLoaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LoaderContainer = styled(SmallLoader)`
  margin-left: 0.5rem;
`;

export const OutputSettingSelectField: FC<OutputSettingSelectFieldProps> = ({ control }) => {
  const { data: outputSettings, isLoading } = useOutputSetting();
  const setError = useContext(SetErrorContext)?.setError;

  return (
    <FieldContainerWithLabel>
      <p>Output Channel</p>
      <StyledFormWithLoaderContainer>
        <ControlledSelectInput
          required
          control={control}
          width="19.5rem"
          placeholder="Choose"
          options={outputSettings as unknown as Array<SelectOptionType>}
          name="messageOutputSetting"
          setError={setError}
          isDisabled={isLoading}
        />
        {isLoading && <LoaderContainer />}
      </StyledFormWithLoaderContainer>
    </FieldContainerWithLabel>
  );
};
