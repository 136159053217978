import { FC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { RadioButton } from './RadioButton';

interface RadioGroupProps {
  options: string[];
  register: UseFormRegisterReturn;
}

export const RadioGroup: FC<RadioGroupProps> = ({ options, register }) => {
  return (
    <>
      {options.length > 0 &&
        options.map((option) => (
          <RadioButton key={option} label={option} register={register} value={option} />
        ))}
    </>
  );
};
