import { useState, useEffect } from 'react';

interface InnerWindowSize {
  innerHeight: number;
  innerWidth: number;
}

const getSize = () => {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
  };
};

export const useInnerWindowSize = (): InnerWindowSize => {
  const [innerWindowSize, setInnerWindowSize] = useState(getSize());

  const handleResize = () => {
    setInnerWindowSize(getSize());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return innerWindowSize;
};

export const useMobileDetection = (): boolean => {
  const [isMobile, setIsMobile] = useState(false);
  const { innerWidth } = useInnerWindowSize();

  useEffect(() => {
    if (innerWidth <= 425) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  return isMobile;
};
