import { FC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styled from 'styled-components';

const RadioButtonSelectIndicator = styled.span`
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid ${({ theme }) => theme.darkGrey};
  border-radius: 50%;
  margin-right: 0.5rem;

  :after {
    content: '';
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.primary};
  }
`;

const RadioButtonContainer = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 1rem;
  text-transform: capitalize;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ ${RadioButtonSelectIndicator} {
      border-color: ${({ theme }) => theme.primary};
      :after {
        display: block;
      }
    }
  }
`;

interface RadioButtonProps {
  label: string;
  register: UseFormRegisterReturn;
  value: string;
}

export const RadioButton: FC<RadioButtonProps> = ({ label, register, ...props }) => {
  return (
    <RadioButtonContainer>
      <input type="radio" {...register} {...props} />
      <RadioButtonSelectIndicator />
      {label}
    </RadioButtonContainer>
  );
};
