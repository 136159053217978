import { InfoIconWithoutBg } from 'assets/icons';
import { FC } from 'react';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { formatFromUtc } from 'utils/dateFormating';

const MetadataContainer = styled.div`
  align-self: start;
  max-width: 30rem;
  min-height: 8rem;
  max-height: 10rem;
  background-color: ${({ theme }) => theme.milkGrey};
  padding: 0.5rem 1.5rem 1.5rem;
  border-radius: 0.25rem;
  margin-left: 2rem;
`;

const BoldLabel = styled.span`
  font-size: 1rem;
  font-weight: 600;
  margin-right: 0.5rem;
`;

const IconIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const InfoContianerRow = styled.div`
  display: grid;
  grid-template-columns: 5rem 11rem 1fr;
  margin-bottom: 0.5rem;
`;

interface FormMetadataBoxProps {
  fipDatCreated: string;
  fipDate: string;
  fipUser: string;
  fipUserCreated: string;
}

export const FormMetadataBox: FC<FormMetadataBoxProps> = ({
  fipDatCreated,
  fipDate,
  fipUser,
  fipUserCreated,
}) => {
  const { timezone: userTimezone } = useAppSelector((store) => store.globalSlice);
  return (
    <MetadataContainer>
      <IconIconContainer>
        <InfoIconWithoutBg />
      </IconIconContainer>
      <InfoContianerRow>
        <BoldLabel>Created: </BoldLabel>
        {formatFromUtc(new Date(`${fipDatCreated}Z`), userTimezone?.timeZone)}
        <span>by {fipUserCreated}</span>
      </InfoContianerRow>
      <InfoContianerRow>
        <BoldLabel>Last edit: </BoldLabel>
        {formatFromUtc(new Date(`${fipDate}Z`), userTimezone?.timeZone)}
        <span>by {fipUser}</span>
      </InfoContianerRow>
    </MetadataContainer>
  );
};
