import { FC, useContext } from 'react';
import { CarouselControl, CarouselStyledValueCell } from 'components';
import { rgba } from 'polished';
import { MarketAreaThemeContext } from '../index';
import styled from 'styled-components';
import { useAppSelector } from 'store/hooks';

interface SummaryTableProps {
  timetableValues: number[];
  marketName: string;
  setScrollPosition: (scrollPos: number) => void;
  scrollPosition: number;
  dailyGranularity: boolean;
}

const SummaryCarouselContainer = styled.div<{ themeColor: string }>`
  width: 100%;
  padding-bottom: 1.5rem;
  border-bottom: 0.125rem solid ${({ themeColor }) => themeColor};
  display: flex;
  min-width: 0;
  max-width: 121.875rem;
`;

const timeTableValuesSum = (timetableValues: number[]): [number] => {
  const sum = timetableValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return [sum];
};

export const SummaryTable: FC<SummaryTableProps> = ({
  timetableValues,
  marketName,
  setScrollPosition,
  scrollPosition,
  dailyGranularity,
}) => {
  const themeColor = useContext(MarketAreaThemeContext);
  const lightThemeColor = rgba(themeColor, 0.2);
  const { gasDayHourDefinition } = useAppSelector((state) => state.nominationReducer);
  const timeTableSum = dailyGranularity ? timeTableValuesSum(timetableValues) : [0];

  return (
    <SummaryCarouselContainer themeColor={themeColor}>
      <CarouselControl
        gasDayHourDefinition={gasDayHourDefinition}
        dailyGranularity={dailyGranularity}
        scrollControl={setScrollPosition}
        scrollPosition={scrollPosition}
      >
        {!dailyGranularity
          ? timetableValues.map((value, key) => (
              <CarouselStyledValueCell key={`${marketName}_${key}`} bgColor={lightThemeColor}>
                {parseFloat(value.toFixed(3))}
              </CarouselStyledValueCell>
            ))
          : timeTableSum.map((value, key) => (
              <CarouselStyledValueCell key={`${marketName}_${key}`} bgColor={lightThemeColor}>
                {parseFloat(value.toFixed(3))}
              </CarouselStyledValueCell>
            ))}
      </CarouselControl>
    </SummaryCarouselContainer>
  );
};
