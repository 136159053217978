import { ControlledSelectInput, FieldContainerWithLabel } from 'components';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { SelectOptionType } from 'utils/types';
import { useMeasureUnit } from '../messageDefinitionFormApi';

interface MeasureUnitSelectFieldProps {
  control: Control;
  isDisabled: boolean;
  edigasVersion?: string;
}

export const MeasureUnitSelectField: FC<MeasureUnitSelectFieldProps> = ({
  control,
  isDisabled,
  edigasVersion,
}) => {
  const { data } = useMeasureUnit(edigasVersion);
  return (
    <>
      <FieldContainerWithLabel isDisabled={isDisabled}>
        <p>Measure Unit Code </p>
        <ControlledSelectInput
          required
          control={control}
          width="19.5rem"
          placeholder="Choose"
          options={
            data
              ? (data.sort((a, b) =>
                  a.description.localeCompare(b.description),
                ) as unknown as Array<SelectOptionType>)
              : [{ id: 0, name: '' }]
          }
          name="unitOfMeasureType"
          isDisabled={isDisabled}
        />
      </FieldContainerWithLabel>
    </>
  );
};
