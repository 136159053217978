import { ChangeEvent, ChangeEventHandler, FC } from 'react';
import styled, { css } from 'styled-components';
import { Control, Controller } from 'react-hook-form';
import { ActivationStyledLabel, ActivationSwitchContainer } from 'components';

interface Props {
  isChecked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

const StyledSwitchInput = styled.input.attrs({ type: 'checkbox' })`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const StyleSwitchLabel = styled.label<{ isChecked: boolean }>`
  ${({ theme, isChecked }) => css`
    cursor: pointer;
    text-indent: -9999px;
    width: 2.5rem;
    height: 1.625rem;
    background-color: ${theme.white};
    border: 1px solid ${theme.darkGrey};
    display: block;
    border-radius: 2.75rem;
    position: relative;

    :hover {
      background-color: ${theme.milkGrey};
    }

    :after {
      content: '';
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      width: 1rem;
      height: 1rem;
      background: ${theme.lightGrey};
      border-radius: 0.625rem;
      transition: 0.3s;
    }

    ${isChecked &&
    css`
      border-color: ${theme.extraGreen};
      :after {
        left: calc(100% - 0.25rem);
        transform: translateX(-100%);
        background-color: ${theme.extraGreen};
      }
    `}
  `}
`;

export const ToggleSwitch: FC<Props> = ({ isChecked, onChange, disabled }) => {
  return (
    <StyleSwitchLabel isChecked={isChecked}>
      <StyledSwitchInput checked={isChecked} onChange={onChange} disabled={disabled} />
    </StyleSwitchLabel>
  );
};

interface ControlledToggleInputProps {
  name: string;
  control: Control;
  required?: boolean;
  customOnChange?: () => void;
}

export const ControlledToggleInput: FC<ControlledToggleInputProps> = ({
  name,
  control,
  required,
  customOnChange,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <ToggleSwitch
          isChecked={value}
          onChange={
            ((e: ChangeEvent<HTMLInputElement>) => {
              onChange(e);
              if (customOnChange) {
                customOnChange();
              }
            }) as ChangeEventHandler<HTMLInputElement>
          }
        />
      )}
    />
  );
};

export const ControlledToggleInputWithLabel: FC<ControlledToggleInputProps> = ({
  name,
  control,
  required,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, value } }) => (
        <ActivationSwitchContainer>
          <ToggleSwitch isChecked={value} onChange={onChange} />
          <ActivationStyledLabel>{value ? 'Active' : 'Inactive'}</ActivationStyledLabel>
        </ActivationSwitchContainer>
      )}
    />
  );
};
