import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageFormContainer } from 'components';
import { NominationTrackForm } from 'features/NominationTrackForm';

interface ParamTypes {
  option: 'create' | 'edit';
  id?: string;
  messageDefId: string;
  edigasBaseVersion: string;
}

export const NominationTrackFormPage: FC = () => {
  const { option, id, messageDefId, edigasBaseVersion } = useParams<ParamTypes>();

  return (
    <PageFormContainer>
      <NominationTrackForm
        option={option}
        nominationTrackId={id}
        messageDefinitionId={messageDefId}
        edigasBaseVerion={edigasBaseVersion}
      />
    </PageFormContainer>
  );
};
