import { FC } from 'react';
import styled from 'styled-components';
import {
  SystemMasterdataIcon,
  UserDefinedMasterdataIcon,
  EditIcon,
  RemoveIcon,
} from 'assets/icons';
import {
  ActiveIndicator,
  BaseButton,
  IdentifierPreviewBox,
  StandardNestedGridTableCell,
} from 'components';
import { useHistory } from 'react-router-dom';
import { AreaConnectionPoint } from 'utils/types';
import { standardDateFormat } from 'utils/dateFormating';
import { useAppDispatch } from 'store/hooks';
import { openModal } from 'store/modalSlice';

const StyledConnectionPointRow = styled.div<{ isOdd?: boolean }>`
  width: 100%;
  display: grid;
  border-bottom: 1px solid ${({ theme }) => theme.nestedDataRowBorderColor};
  height: 4rem;
  align-items: center;

  grid-template-columns:
    3.125rem minmax(13rem, 1fr) minmax(13rem, 1fr) minmax(13rem, 1fr) minmax(9rem, 1fr)
    6rem
    13rem 13rem 7rem 7rem 4rem 3.75rem 6.25rem;
  grid-column-gap: 0.3125rem;
  ${({ theme, isOdd }) => !isOdd && `background-color: ${theme.evenRowColor};`}

  :first-of-type {
    height: 3.25rem;
    padding-bottom: 0.75rem;
  }
`;

interface ConnectionPointInfoRowProps {
  connectionPoint: AreaConnectionPoint;
  isAdmin: boolean;
  isOdd: boolean;
  areaSortOrder: number;
}

export const ConnectionPointInfoRow: FC<ConnectionPointInfoRowProps> = ({
  connectionPoint,
  isAdmin,
  isOdd,
  areaSortOrder,
}) => {
  const {
    id,
    name,
    code,
    pointType,
    userDefined,
    granularity,
    identifiers,
    sortOrder,
    validFrom,
    validTo,
    counterparty,
    active,
    nominationLeadTimeMin,
    area,
  } = connectionPoint;

  const history = useHistory();
  const dispatch = useAppDispatch();

  const editConnectionPoint = () => {
    history.push(`/app/areas/connection-point/${area.name}/${area.id}/edit/${areaSortOrder}/${id}`);
  };

  return (
    <StyledConnectionPointRow isOdd={isOdd}>
      <StandardNestedGridTableCell>
        {userDefined ? <UserDefinedMasterdataIcon /> : <SystemMasterdataIcon />}
      </StandardNestedGridTableCell>
      <StandardNestedGridTableCell>{name}</StandardNestedGridTableCell>
      <StandardNestedGridTableCell>{code}</StandardNestedGridTableCell>
      <StandardNestedGridTableCell>{counterparty.name}</StandardNestedGridTableCell>
      <StandardNestedGridTableCell align="center">{pointType}</StandardNestedGridTableCell>
      <StandardNestedGridTableCell align="center">{granularity}</StandardNestedGridTableCell>
      <StandardNestedGridTableCell align="center">
        {nominationLeadTimeMin}
      </StandardNestedGridTableCell>
      <StandardNestedGridTableCell align="space-between" overflowEnabled>
        {identifiers.length > 0 && (
          <>
            {identifiers[0].identifier}
            <IdentifierPreviewBox identifiers={identifiers} />
          </>
        )}
      </StandardNestedGridTableCell>
      <StandardNestedGridTableCell>
        {validFrom !== null ? standardDateFormat(new Date(validFrom)) : ''}
      </StandardNestedGridTableCell>
      <StandardNestedGridTableCell>
        {validTo !== null ? standardDateFormat(new Date(validTo)) : ''}
      </StandardNestedGridTableCell>
      <StandardNestedGridTableCell>{`${areaSortOrder}.${sortOrder}`}</StandardNestedGridTableCell>
      <StandardNestedGridTableCell align="center">
        <ActiveIndicator isActive={active} />
      </StandardNestedGridTableCell>
      <StandardNestedGridTableCell>
        <BaseButton width="2.5rem" disabled={!isAdmin} onClick={editConnectionPoint}>
          <EditIcon />
        </BaseButton>
        {id && (
          <BaseButton
            width="2.5rem"
            disabled={!isAdmin}
            onClick={() =>
              dispatch(
                openModal({
                  modalId: 'connectionPointRemovalConfirmationModal',
                  props: {
                    dependencyId: area.id,
                    propertyId: id,
                  },
                }),
              )
            }
          >
            <RemoveIcon />
          </BaseButton>
        )}
      </StandardNestedGridTableCell>
    </StyledConnectionPointRow>
  );
};
