import { useAppDispatch, useAppSelector } from 'store/hooks';
import { HOST_NAME, TENANT_NAME } from 'utils/constants';
import { setError } from 'features/ErrorScreen/errorSlice';

type Method = 'GET' | 'PUT' | 'POST' | 'DELETE';

export const createDefaultHeaders = () => {
  return {
    'X-Tenant-ID': TENANT_NAME(),
  };
};

const apiLogHeader = (): Record<string, boolean> => {
  const isDev = ['localhost', 'dev', 'staging', 'test'].some((env) => {
    return window.location.host.toLowerCase().includes(env);
  });
  if (isDev) {
    try {
      const logHeader = localStorage.getItem('apiLogHeader');
      if (logHeader === 'true') {
        return {
          'x-api-stacktrace': true,
        };
      } else {
        return {};
      }
    } catch (e) {
      return {};
    }
  }
  return {};
};

const createBasicRequest = async (path: string, method: Method, init?: RequestInit) => {
  const url = new URL(`${HOST_NAME()}${path}`);

  const response = await fetch(url.toString(), {
    ...init,
    method,
    headers: new Headers({
      'Content-Type': 'application/json',
      ...init?.headers,
      ...apiLogHeader(),
      ...createDefaultHeaders(),
    }),
  });

  if (!response.ok) {
    if (response.status === 401) {
      if (!process.env.REACT_APP_LOCAL_TOKEN || process.env.REACT_APP_LOCAL_TOKEN === '') {
        location.reload();
      }
    } else {
      const responseError = await response.json();
      if (responseError.errors.length > 0) {
        throw responseError;
      } else {
        throw new Error(responseError.message);
      }
    }
  }
  return response;
};

const createUploadRequest = async (path: string, init?: RequestInit) => {
  const url = new URL(`${HOST_NAME()}${path}`);

  const response = await fetch(url.toString(), {
    ...init,
    method: 'POST',
    headers: new Headers({
      ...init?.headers,
      ...createDefaultHeaders(),
    }),
  });

  if (!response.ok) {
    if (response.status === 401) {
      if (!process.env.REACT_APP_LOCAL_TOKEN || process.env.REACT_APP_LOCAL_TOKEN === '') {
        location.reload();
      }
    } else {
      const responseError = await response.json();
      if (responseError.errors.length > 0) {
        throw responseError;
      } else {
        throw new Error(responseError.message);
      }
    }
  }
  return response;
};

export const API = {
  PUT: async (path: string, init?: RequestInit): Promise<Response> => {
    return createBasicRequest(path, 'PUT', init);
  },
  POST: async (path: string, init?: RequestInit): Promise<Response> => {
    return createBasicRequest(path, 'POST', init);
  },
  GET: async (path: string, init?: RequestInit): Promise<Response> => {
    return createBasicRequest(path, 'GET', init);
  },
  DELETE: async (path: string, init?: RequestInit): Promise<Response> => {
    return createBasicRequest(path, 'DELETE', init);
  },
  UPLOAD_FILE: (path: string, init?: RequestInit): Promise<Response> => {
    return createUploadRequest(path, init);
  },
};

export const useApiGet = (path: string, dependency = true, init?: RequestInit) => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();

  return async () => {
    try {
      if (dependency) {
        const response = await API.GET(path, {
          ...init,
          headers: {
            ...init?.headers,
            Authorization: token,
          },
        });

        return response.json();
      }
      return Promise.reject();
    } catch (error) {
      // TODO: SET ERROR MESSAGE
      dispatch(setError({ errorMessage: 'Something went wrong' }));
      console.error(error);
    }
  };
};

export const useApiGetNonAuthorized = (path: string, dependency = true, init?: RequestInit) => {
  return async () => {
    try {
      if (dependency) {
        const response = await API.GET(path, {
          ...init,
          headers: {
            ...init?.headers,
          },
        });

        return response.json();
      }
      return Promise.reject();
    } catch (error) {
      // TODO: SET ERROR MESSAGE
      throw new Error('Wrong tenant info');
    }
  };
};
