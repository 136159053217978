import { useConfigProperties, useSaveConfigProperty } from './adminPanelApi';
import { FC, useEffect } from 'react';
import styled from 'styled-components';
import {
  ButtonWithLoader,
  ControlledToggleInput,
  FormContainer,
  FormTitle,
  HeadingBar,
  Loader,
  StyledTextInput,
  GridTableHeadingCell,
} from 'components';
import { FieldValues, useForm } from 'react-hook-form';
import { ConfigProperty } from './types';
import { GridWhiteTableCell } from 'components/StyledDivTableComponents';

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.25rem;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 7rem;
  grid-gap: 0.2rem;
  margin-bottom: 0.5rem;
  height: 3.5rem;
`;

export const PropertiesTable: FC = () => {
  const { data: properties, isLoading } = useConfigProperties();
  return !isLoading ? (
    <>
      <HeadingBar>
        <FormTitle>Properties configuration</FormTitle>
      </HeadingBar>
      <FormContainer>
        <TableContainer>
          <Row>
            <GridTableHeadingCell>Property Name</GridTableHeadingCell>
            <GridTableHeadingCell>Property Description</GridTableHeadingCell>
            <GridTableHeadingCell>Property Value</GridTableHeadingCell>
            <GridTableHeadingCell></GridTableHeadingCell>
          </Row>
          {properties?.map((property) => (
            <PropertiesRow key={property.key} property={property} />
          ))}
        </TableContainer>
      </FormContainer>
    </>
  ) : (
    <Loader />
  );
};

const PropertiesRow: FC<{ property: ConfigProperty }> = ({ property }) => {
  const {
    register,
    getValues,
    control,
    reset,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: {
      value:
        property.value === 'true' || property.value === 'false'
          ? JSON.parse(property.value)
          : property.value,
    } as FieldValues,
  });
  const { mutate: updateProperty, isLoading } = useSaveConfigProperty();
  const onSubmit = () => {
    updateProperty({ key: property.key, value: getValues().value });
  };

  useEffect(() => {
    reset({
      value:
        property.value === 'true' || property.value === 'false'
          ? JSON.parse(property.value)
          : property.value,
    });
  }, [property]);

  const componentToRender =
    property.value === 'true' || property.value === 'false' ? (
      <ControlledToggleInput name="value" control={control} />
    ) : (
      <StyledTextInput
        placeholder=""
        type="text"
        register={register('value', {
          required: true,
        })}
        isSlim
      />
    );

  return (
    <Row>
      <GridWhiteTableCell>{property.key}</GridWhiteTableCell>
      <GridWhiteTableCell>{property.description}</GridWhiteTableCell>
      <GridWhiteTableCell padding="0">
        <form>{componentToRender}</form>
      </GridWhiteTableCell>
      <GridWhiteTableCell align="center">
        <ButtonWithLoader
          width="5rem"
          isLoading={isLoading}
          onClick={onSubmit}
          disabled={!isDirty || !isValid}
        >
          Save
        </ButtonWithLoader>
      </GridWhiteTableCell>
    </Row>
  );
};
