import { ComboFilterContainer, FilterButton, FiltersContainer, MultiSelectInput } from 'components';
import { FC, useState } from 'react';
import { MessageDefinitionSearch } from './MessageDefinitionSearch';
import { useAppDispatch } from 'store/hooks';
import { useAreasSlimList, useMessageType, useMarketParticipant } from '../messageDefinitionApi';
import { SelectOptionType } from 'utils/types';
import { setFilters } from '../messageDefinitionSlice';

const parseFilterOptions = (options?: Array<SelectOptionType>): Array<string> => {
  return options?.map((option) => option.name || option.description || '') || [];
};

export const ComboFilter: FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useAppDispatch();
  const { data: areas } = useAreasSlimList();
  const { data: participants } = useMarketParticipant();
  const { data: messageTypes } = useMessageType();

  const updateFilter =
    (name: 'area.name' | 'recipientMarketParticipant.name' | 'messageType.description') =>
    (options: string[]) => {
      dispatch(setFilters({ filterName: name, options }));
    };

  return (
    <ComboFilterContainer>
      <FiltersContainer isExpanded={isExpanded}>
        <MessageDefinitionSearch />
        {isExpanded && (
          <>
            <MultiSelectInput
              placeholder="Area"
              options={parseFilterOptions(areas)}
              onOptionSelect={updateFilter('area.name')}
              width={250}
              isFrameless
            />
            <MultiSelectInput
              placeholder="Participant"
              options={parseFilterOptions(participants as unknown as Array<SelectOptionType>)}
              onOptionSelect={updateFilter('recipientMarketParticipant.name')}
              width={250}
              isFrameless
            />
            <MultiSelectInput
              placeholder="Message Type"
              options={parseFilterOptions(messageTypes as unknown as Array<SelectOptionType>)}
              onOptionSelect={updateFilter('messageType.description')}
              width={250}
              isFrameless
            />
          </>
        )}
      </FiltersContainer>
      <FilterButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
    </ComboFilterContainer>
  );
};
