import { useMessageDefinitionListOfValues, MessageDefinitionValue } from '../../nominationsApi';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Checkbox, SearchInput } from 'components';
import { MessagesState } from './index';

const FilterBar = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem 0;
`;

const MessageDefinitionsContainer = styled.div`
  width: 100%;
  height: 30rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledMessageDefinitionRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 2.5rem;
  max-height: 2.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
`;

const SectionLabel = styled.span`
  color: ${({ theme }) => theme.darkGrey};
  margin-bottom: 0.625rem;
  display: inline-block;
`;

const Section = styled.div`
  margin-bottom: 1.25rem;
`;

const SearchContainer = styled.form`
  width: 12.5rem;
`;

interface MessageDefinitionPickerProps {
  selectedDate: Date;
  messages: MessagesState;
  setMessages: (messages: MessagesState) => void;
}

export const MessageDefinitionPicker: FC<MessageDefinitionPickerProps> = ({
  selectedDate,
  messages,
  setMessages,
}) => {
  const { data } = useMessageDefinitionListOfValues(selectedDate);
  const { selectedMessages, notSelectedMessages } = messages;

  useEffect(() => {
    if (data && data.content.length > 0) {
      setMessages({
        selectedMessages: data.content,
        notSelectedMessages: [],
      });
    }
  }, [data]);
  const { register, watch, reset } = useForm({
    defaultValues: { nominationSearchKeyword: '' },
  });
  const nominationSearchKeyword = watch('nominationSearchKeyword');

  const handleUpdate = () => {
    console.log('search: ' + nominationSearchKeyword);
  };

  const resetSearch = () => {
    reset();
  };

  const selectMessage = (messageDefinition: MessageDefinitionValue) => {
    const newNotSelectedMessages = notSelectedMessages.filter(
      (messageDef) => messageDef.id !== messageDefinition.id,
    );
    setMessages({
      selectedMessages: [...selectedMessages, messageDefinition],
      notSelectedMessages: newNotSelectedMessages,
    });
  };

  const unselectMessage = (messageDefinition: MessageDefinitionValue) => {
    setMessages({
      selectedMessages: selectedMessages.filter(
        (messageDef) => messageDef.id !== messageDefinition.id,
      ),
      notSelectedMessages: [...notSelectedMessages, messageDefinition],
    });
  };

  // TODO: extract everything in modal to enable normal component updates
  return (
    <>
      <FilterBar>
        <SearchContainer>
          <SearchInput
            register={register('nominationSearchKeyword')}
            placeholder="Search"
            handleUpdate={handleUpdate}
            value={nominationSearchKeyword}
            reset={resetSearch}
          />
        </SearchContainer>
      </FilterBar>

      <MessageDefinitionsContainer>
        {selectedMessages.length > 0 && (
          <Section>
            <SectionLabel>Selected</SectionLabel>
            {selectedMessages.map((messageDefinition, key) => (
              <StyledMessageDefinitionRow key={`${messageDefinition.description}_${key}`}>
                <Checkbox checked={true} onChange={() => unselectMessage(messageDefinition)} />
                {messageDefinition.description}
              </StyledMessageDefinitionRow>
            ))}
          </Section>
        )}
        {notSelectedMessages.length > 0 && (
          <Section>
            <SectionLabel>Not selected</SectionLabel>
            {notSelectedMessages.map((messageDefinition, key) => (
              <StyledMessageDefinitionRow key={`${messageDefinition.description}_${key}`}>
                <Checkbox checked={false} onChange={() => selectMessage(messageDefinition)} />
                {messageDefinition.description}
              </StyledMessageDefinitionRow>
            ))}
          </Section>
        )}
      </MessageDefinitionsContainer>
    </>
  );
};
