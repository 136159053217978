import { FC } from 'react';
import styled from 'styled-components';
import { ClassicButton, VARIANT } from 'components';
import { useHistory } from 'react-router';
import { useAppSelector } from 'store/hooks';

const ControlPanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  height: 4.5rem;
`;

export const ControlRow: FC = () => {
  const history = useHistory();
  const { authorities } = useAppSelector((state) => state.authReducer);
  const isAdmin = authorities.includes('ADMIN') || authorities.includes('SYSADMIN');
  return (
    <ControlPanelContainer>
      <ClassicButton
        width="14.375rem"
        variant={VARIANT.PRIMARY}
        onClick={() => history.push('/app/message-definitions/create')}
        disabled={!isAdmin}
      >
        Add Message definition
      </ClassicButton>
    </ControlPanelContainer>
  );
};
