import styled, { css } from 'styled-components';
import { InfoIcon } from 'assets/icons';
import { BaseButton } from 'components';

const StyledInfoBox = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(87, 150, 185, 0.2);
  border-radius: 4px;
  height: 2.5rem;
  padding: 0.625rem;
  margin-top: 1.25rem;
  max-width: 35rem;
`;

const InfoIconWithSpacing = styled(InfoIcon)`
  margin-right: 0.5rem;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(70px, 1fr)) 2.5rem;
  column-gap: 0.625rem;
  row-gap: 0.625rem;
  width: 100%;
`;

const TableHeadingCell = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.milkGrey};
  height: 2.5rem;
  padding: 0 1rem;
`;

const TableCell = styled.div<{ isGrey?: boolean; isBold?: boolean }>`
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding: 0 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};

  ${({ isGrey, theme }) => isGrey && `color: ${theme.darkGrey}`}
  ${({ isBold }) => isBold && 'font-weigth: 600'}
`;

const CopyButton = styled(BaseButton)`
  width: 2.5rem;
`;

const FadedLabel = styled.label`
  color: ${({ theme }) => theme.lightGrey};
`;

const RoleTableBaseCell = styled.div<{ width?: string; isBold?: boolean; isGrey?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding-left: 0.5rem;
  margin-right: 0.5rem;
  ${({ isBold }) => isBold && 'font-weight: 600;'}
  ${({ isGrey, theme }) => isGrey && `color: ${theme.darkGrey};`}
  ${({ width }) =>
    width &&
    css`
      min-width: ${width};
      max-width: ${width};
    `}

  :last-child {
    margin-right: 0;
  }
`;

const HeadingCell = styled(RoleTableBaseCell)`
  background-color: ${({ theme }) => theme.milkGrey};
  font-size: 0.875rem;
`;

const ContentCell = styled(RoleTableBaseCell)`
  border-bottom: 1px solid ${({ theme }) => theme.comboFormContentCellBorderColor};
`;

const NewRoleButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 2.5rem;
`;

export {
  StyledInfoBox,
  InfoIconWithSpacing,
  GridContainer,
  TableHeadingCell,
  TableCell,
  CopyButton,
  FadedLabel,
  HeadingCell,
  ContentCell,
  RoleTableBaseCell,
  NewRoleButtonContainer,
};
