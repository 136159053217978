import { EditIcon, InfoIcon, RemoveIcon } from 'assets/icons';
import { ActiveIndicator, BaseButton } from 'components';
import { NominationTrack } from 'features/MessageDefinitions/types';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { standardDateFormat } from 'utils/dateFormating';
import { useAppDispatch } from 'store/hooks';
import { openModal } from 'store/modalSlice';
import { EdigasVersionType } from 'utils/types';

// TODO: try refactoring this with other simmilar row odd/even components
const StyledRow = styled.div<{ isOdd?: boolean }>`
  display: flex;
  width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.nestedDataRowBorderColor};
  ${({ isOdd, theme }) => !isOdd && `background-color: ${theme.evenRowColor};`}
  align-items: center;

  svg {
    margin: 0 0.5rem;
  }
`;

const MainCell = styled.div<{ width?: string; alignText?: 'center' }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  ${({ width }) => css`
    width: ${width};
    max-width: ${width};
  `};
  max-height: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  font-size: 0.875rem;
  margin-right: 0.3125rem;
  padding: 0.5625rem 0.625rem;
  ${({ alignText }) => alignText && `align-items: ${alignText};`}

  :last-child {
    margin-right: 0;
  }
`;

const SubCell = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  ${({ color }) => color && `color: ${color};`}

  :last-child {
    margin-bottom: 0;
  }
`;

const SpacingReplacingIcon = styled.div`
  width: 1.5rem;
  margin: 0 0.5rem;
`;

const PropertyDescription = styled.span`
  color: ${({ theme }) => theme.darkGrey};
  font-weight: 600;
`;

interface NominationTrackRowProps {
  nominationTrack: NominationTrack;
  messageDefID: number;
  // color: string;
  isOdd: boolean;
  edigasVersion: EdigasVersionType;
}

export const NominationTrackRow: FC<NominationTrackRowProps> = ({
  nominationTrack,
  isOdd,
  messageDefID,
  edigasVersion,
}) => {
  const {
    connectionPoint,
    internalAccount,
    externalAccount,
    inactive,
    id,
    externalReference,
    validFrom,
    validTo,
    excludeInOutgoingMessage,
  } = nominationTrack;
  const history = useHistory();

  const dispatch = useAppDispatch();

  return (
    <StyledRow isOdd={isOdd}>
      {excludeInOutgoingMessage ? (
        <InfoIcon title="Nomination track excluded in outgoing message" />
      ) : (
        <SpacingReplacingIcon />
      )}

      <MainCell width="6.5rem">
        <SubCell>
          <PropertyDescription>Name</PropertyDescription>
        </SubCell>
        <SubCell>
          <PropertyDescription>Identifier</PropertyDescription>
        </SubCell>
      </MainCell>
      <MainCell>
        <SubCell>{connectionPoint.name}</SubCell>
        <SubCell>{connectionPoint.identifiers[0].identifier}</SubCell>
      </MainCell>
      <MainCell>
        <SubCell>{internalAccount.name}</SubCell>
        <SubCell>{internalAccount.identifiers[0].identifier}</SubCell>
      </MainCell>
      <MainCell>
        <SubCell>{externalAccount.name}</SubCell>
        <SubCell>{externalAccount.identifiers[0].identifier}</SubCell>
      </MainCell>
      <MainCell>
        <SubCell>{externalReference}</SubCell>
      </MainCell>
      <MainCell width="7rem">
        <SubCell>{standardDateFormat(new Date(validFrom))}</SubCell>
      </MainCell>
      <MainCell width="7rem">
        <SubCell>{standardDateFormat(new Date(validTo))}</SubCell>
      </MainCell>
      <MainCell width="6rem" alignText="center">
        <SubCell>
          <ActiveIndicator isActive={!inactive} />
        </SubCell>
      </MainCell>
      <MainCell width="6rem">
        <SubCell>
          {' '}
          <BaseButton
            width="2.5rem"
            onClick={() =>
              history.push(
                `/app/message-definitions/nomination-track/edit/${messageDefID}/${edigasVersion.version.baseVersion}/${id}`,
              )
            }
          >
            <EditIcon />
          </BaseButton>
          <BaseButton
            width="2.5rem"
            onClick={() =>
              dispatch(
                openModal({
                  modalId: 'nominationTrackRemovalConfirmationModal',
                  props: {
                    propertyId: id,
                    dependencyId: messageDefID,
                  },
                }),
              )
            }
          >
            <RemoveIcon />
          </BaseButton>
        </SubCell>
      </MainCell>
    </StyledRow>
  );
};
