import { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { PreviewIcon } from 'assets/icons';
import { QuantityIndicator } from './QuantityIndicator';

const PreviewContainer = styled.div`
  position: relative;
`;

const PreviewArea = styled.div`
  /* width: 4rem; */
  cursor: pointer;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const PreviewBox = styled.div`
  min-height: 5rem;
  width: 37.5rem;
  background: ${({ theme }) => theme.white};
  box-shadow: ${({ theme }) => theme.identifierPreviewShadow};
  border-radius: 0 0 0.25rem 0.25rem;
  position: absolute;
  padding: 1rem;
  z-index: 100;
  top: 1.5625rem;
  transform: translateX(-50%);
`;

const StyledIcon = styled(PreviewIcon)`
  margin-left: 0.5rem;
`;

interface PreviewBoxProps {
  amountOfElements?: number;
  countFromOne?: boolean;
  children: ReactNode;
}

export const PreviewBoxComponent: FC<PreviewBoxProps> = ({
  amountOfElements,
  countFromOne,
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <PreviewContainer>
      <PreviewArea
        onMouseEnter={() => {
          setIsVisible(true);
        }}
        onMouseLeave={() => {
          setIsVisible(false);
        }}
      >
        {amountOfElements && !countFromOne && amountOfElements > 1 && (
          <QuantityIndicator quantity={amountOfElements} short />
        )}
        {amountOfElements && countFromOne && (
          <QuantityIndicator quantity={amountOfElements} short />
        )}
        <StyledIcon />
      </PreviewArea>
      {isVisible && <PreviewBox>{children}</PreviewBox>}
    </PreviewContainer>
  );
};
