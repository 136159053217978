import { MessageDefinitionSlim } from './messageDefinitionTypes';
import {
  HourlyStatus,
  HourlyValues,
  Identifier,
  MessageStatus,
  Role,
  GasDayHourDefinitionType,
} from './generalTypes';
import { AreaSlimType } from './areaTypes';

export interface BalanceGroup {
  nominationTrackId: number;
  excludeInOutgoingMessage: boolean;
  missConfiguration: boolean;
  destinationId: number;
  hourlyValues: HourlyValues;
  hourlyValuesConfirmed: HourlyValues;
  latestNomintValues: HourlyValues;
  hourlyStatus: HourlyStatus;
  messageStatus: MessageStatus;
  sourceId: number;
  firstNonLeadTimeIndex: number;
  counterparty: string;
  version: number;
  destinationName: string;
  sourceName: string;
}

interface InternalAccount {
  name: string;
}

export interface ConnectionPointType {
  balanceGroups: Array<BalanceGroup>;
  code: string;
  id: number;
  messageStatus: MessageStatus;
  name: string;
  internalAccounts: Array<InternalAccount>;
}

export enum GRANULARITY {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
}

export interface ConnectionPointNominationType extends ConnectionPointType {
  isSelected: boolean;
  shouldBeDisplayed: boolean;
  gasDayHourDefinition: GasDayHourDefinitionType;
  granularity: GRANULARITY;
}

export interface Counterparty {
  code: string;
  id?: number;
  identifiers: Array<Identifier>;
  name: string;
  balanceGroups?: Array<BalancegroupInfo>;
  active: boolean;
  userDefined?: boolean;
  roles: Array<Role>;
  fipDatCreated?: string;
  fipDate?: string;
  fipUser?: string;
  fipUserCreated?: string;
  ownCounterparty: boolean;
}

export interface BalancegroupInfo {
  area: AreaSlimType;
  name: string;
  identifiers: Array<Identifier>;
  active: boolean;
  validFrom: string;
  validTo: string;
  id?: number;
  userDefined?: boolean;
  counterparty?: {
    id?: number;
    name?: string;
    ownCounterparty?: boolean;
  };
  messageDefinitions: Array<MessageDefinitionSlim>;
  fipDatCreated?: string;
  fipDate?: string;
  fipUser?: string;
  fipUserCreated?: string;
}
