import { FC } from 'react';
import styled from 'styled-components';
import {
  SystemMasterdataIcon,
  UserDefinedMasterdataIcon,
  EditIcon,
  RemoveIcon,
} from 'assets/icons';
import {
  ActiveIndicator,
  BaseButton,
  IdentifierPreviewBox,
  StandardNestedGridTableCell,
} from 'components';
import { useHistory } from 'react-router-dom';
import { BalancegroupInfo } from 'utils/types';
import { MessageDefinitionPreviewBox } from './MessageDefinitionPreviewBox';
import { standardDateFormat } from 'utils/dateFormating';
import { useAppDispatch } from 'store/hooks';
import { openModal } from 'store/modalSlice';

const StyledBalancegroupRow = styled.div<{ isOdd?: boolean }>`
  display: grid;
  border-bottom: 1px solid ${({ theme }) => theme.nestedDataRowBorderColor};
  height: 4rem;
  align-items: center;
  grid-template-columns:
    3.125rem minmax(13rem, 1fr) minmax(13rem, 1fr) minmax(13rem, 1fr) minmax(9rem, 1fr)
    minmax(9rem, 1fr) minmax(9rem, 1fr) 3.75rem 6.25rem;
  grid-column-gap: 0.3125rem;
  ${({ theme, isOdd }) => !isOdd && `background-color: ${theme.evenRowColor};`}

  :first-of-type {
    height: 3.25rem;
    padding-bottom: 0.75rem;
  }
`;

interface BalancegroupInfoRowProps {
  balancegroup: BalancegroupInfo;
  counterpartyName: string;
  isAdmin: boolean;
  counterpartyId: number;
  isOdd: boolean;
}

export const BalancegroupInfoRow: FC<BalancegroupInfoRowProps> = ({
  balancegroup,
  isAdmin,
  counterpartyName,
  counterpartyId,
  isOdd,
}) => {
  const {
    area,
    name,
    identifiers,
    active,
    validFrom,
    validTo,
    id,
    userDefined,
    messageDefinitions,
  } = balancegroup;

  const history = useHistory();
  const dispatch = useAppDispatch();

  const editBalancegroup = () => {
    const encodedCounterpartyName = encodeURIComponent(counterpartyName);
    history.push(
      `/app/masterdata/${encodedCounterpartyName}/${counterpartyId}/balancegroup/edit/${id}`,
    );
  };

  return (
    <StyledBalancegroupRow isOdd={isOdd}>
      <StandardNestedGridTableCell>
        {userDefined ? <UserDefinedMasterdataIcon /> : <SystemMasterdataIcon />}
      </StandardNestedGridTableCell>
      <StandardNestedGridTableCell>{area.name}</StandardNestedGridTableCell>
      <StandardNestedGridTableCell>{name}</StandardNestedGridTableCell>
      <StandardNestedGridTableCell overflowEnabled align="space-between">
        {identifiers.length > 0 ? (
          <>
            {`${identifiers[0].type.scheme}: ${identifiers[0].identifier}`}
            <IdentifierPreviewBox identifiers={identifiers} />
          </>
        ) : (
          <></>
        )}
      </StandardNestedGridTableCell>

      <StandardNestedGridTableCell overflowEnabled>
        Quantity: <MessageDefinitionPreviewBox messageDefinitions={messageDefinitions} />
      </StandardNestedGridTableCell>
      <StandardNestedGridTableCell>
        {validFrom !== null ? standardDateFormat(new Date(validFrom)) : ''}
      </StandardNestedGridTableCell>
      <StandardNestedGridTableCell>
        {validTo !== null ? standardDateFormat(new Date(validTo)) : ''}
      </StandardNestedGridTableCell>
      <StandardNestedGridTableCell align="center">
        <ActiveIndicator isActive={active} />
      </StandardNestedGridTableCell>
      <StandardNestedGridTableCell>
        <BaseButton width="2.5rem" disabled={!isAdmin} onClick={editBalancegroup}>
          <EditIcon />
        </BaseButton>
        {id && (
          <BaseButton
            width="2.5rem"
            disabled={!isAdmin}
            onClick={() =>
              dispatch(
                openModal({
                  modalId: 'balanceGroupRemovalConfirmationModal',
                  props: {
                    propertyId: id,
                  },
                }),
              )
            }
          >
            <RemoveIcon />
          </BaseButton>
        )}
      </StandardNestedGridTableCell>
    </StyledBalancegroupRow>
  );
};
