import { StatusFilter } from 'components';
import { FC } from 'react';
import styled from 'styled-components';
import { StatusOptions } from 'utils/types';
import { MessageDetailsSearch } from './MessageDetailsSearch';

const FilterRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.5rem;
`;

interface FilterRowProps {
  searchKeyword: string;
  setSearchKeyword: (keyword: string) => void;
  updateStatusFilter: (options: StatusOptions) => void;
}

export const FilterRow: FC<FilterRowProps> = ({
  searchKeyword,
  setSearchKeyword,
  updateStatusFilter,
}) => {
  return (
    <FilterRowContainer>
      <MessageDetailsSearch searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
      <StatusFilter updateFilter={updateStatusFilter} />
    </FilterRowContainer>
  );
};
