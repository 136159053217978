import { FC, useState, useRef, useEffect } from 'react';
import { TopControlRow } from './components/TopControlRow';
import { ControlPanel } from './components/ControlPanel';
import { useMessages } from './messageApi';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { MessagesTable } from './components/MessagesTable';
import { NoResultsBar } from './components/StyledComponents';
import { LoadingScreen } from 'components/LoadingScreen';
import { SortDirection } from 'utils/types';
import { translateFiltersToPath } from 'utils/translateFiltersToPath';
import styled from 'styled-components';
import { clearFiltersWithourResetingPage } from './messagesSlice';
import { setDate } from 'store/globalSlice';
import { PaginationRow } from './components/PaginationRow';

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 2.5rem;
`;

export const History: FC = () => {
  const [activeSort, setActiveSort] = useState<{ name: string; direction: SortDirection }>({
    name: '',
    direction: 'none',
  });
  const [searchKeyword, setSearchKeyword] = useState('');
  const updateSort = (name: string, direction: SortDirection) => {
    setActiveSort({ name, direction });
  };

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { filters, selectedPage } = useAppSelector((state) => state.messageReducer);
  const { selectedDate } = useAppSelector((state) => state.globalSlice);
  const currentDate = new Date(selectedDate);
  const { data, isLoading } = useMessages(
    currentDate,
    activeSort,
    searchKeyword,
    translateFiltersToPath(filters),
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearFiltersWithourResetingPage());
    };
  }, []);

  const setSelectedDate = (date: Date) => {
    dispatch(setDate({ date: date.toString() }));
  };

  const setSearch = (keyword: string) => setSearchKeyword(keyword);
  return (
    <MessagesContainer>
      <TopControlRow selectedDate={currentDate} setSelectedDate={setSelectedDate} />
      <ControlPanel setSearchKeyword={setSearch} selectedDate={currentDate} />
      <div ref={tableContainerRef}>
        {isLoading ? (
          <LoadingScreen />
        ) : data?.content && data.content.length > 0 ? (
          <>
            <MessagesTable
              updateSort={updateSort}
              activeSort={activeSort}
              messages={data.content}
            />
          </>
        ) : (
          <NoResultsBar>No results for this date</NoResultsBar>
        )}
      </div>
      {data && data.content.length > 0 && (
        <PaginationRow
          currentPage={selectedPage}
          totalPages={data.totalPages}
          totalElements={data.totalElements}
        />
      )}
    </MessagesContainer>
  );
};
