import { addDays } from 'date-fns';
import { FC } from 'react';
import styled from 'styled-components';
import { MessageStatusBlock } from './MessageStatusBlock';
import { DashboardSectionTitle } from './styled';

const MessageStatusSectionContainer = styled.div`
  padding-bottom: 1.5rem;
  min-height: 0;
  min-width: 0;
`;

const MessageStatusGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
  height: calc(20 * 2rem);
`;

interface MessageStatusSectionProps {
  date: Date;
}
export const MessageStatusSection: FC<MessageStatusSectionProps> = ({ date }) => {
  const nextDayDate = addDays(date, 1);
  return (
    <MessageStatusSectionContainer>
      <DashboardSectionTitle>Message Status</DashboardSectionTitle>
      <MessageStatusGrid>
        <MessageStatusBlock date={date} />
        <MessageStatusBlock date={nextDayDate} />
      </MessageStatusGrid>
    </MessageStatusSectionContainer>
  );
};
