import {
  ButtonWithLoader,
  ControlledSelectInput,
  FieldContainerWithLabel,
  FormContainer,
  FormSection,
  FormTitle,
  HeadingBar,
} from 'components';
import { useFileUpload } from './adminPanelApi';
import { CounterpartySelectField } from './CounterpartySelectField';
import { PartnershipSelectField } from './PartnershipSelectField';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

const StyledFileInput = styled.input`
  min-width: 35rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
`;

const InputCover = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: 0.075rem solid #ddd;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.2rem 0.4rem rgb(0 0 0 / 5%);
  user-select: none;

  ::before {
    position: absolute;
    top: -0.075rem;
    right: -0.075rem;
    bottom: -0.075rem;
    z-index: 6;
    display: block;
    content: 'Browse';
    padding: 0.4375rem 1.5625rem;
    line-height: 1.5;
    color: #555;
    background-color: #eee;
    border: 0.075rem solid #ddd;
    border-radius: 0 0.25rem 0.25rem 0;
  }
`;

const StyledFileLabel = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
  margin-right: 1.25rem;
  flex: 1;
`;

export const ConnectionTestForm: FC = () => {
  const [fileName, setFileName] = useState('Choose file...');
  const { mutate: fileUpload, isLoading } = useFileUpload();
  const {
    register,
    getValues,
    formState: { isDirty, isValid },
    control,
    watch,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const submitFile = () => {
    const { connectionTestFile, partnership } = getValues();

    fileUpload({
      file: connectionTestFile,
      partnershipId: partnership.id,
    });
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget.files?.item(0) || { name: '' };
    if (name.length) {
      setFileName(name);
      trigger();
    }
  };

  const counterparty = watch('counterparty');
  const connectionType = watch('type');
  return (
    <>
      <HeadingBar>
        <FormTitle>Test connection</FormTitle>
      </HeadingBar>
      <FormContainer>
        <FormSection>
          <CounterpartySelectField control={control} />
          <FieldContainerWithLabel>
            <p>Type</p>
            <ControlledSelectInput
              width="22rem"
              control={control}
              placeholder="Choose"
              options={[
                { id: 1, name: 'AS2' },
                { id: 2, name: 'AS4' },
              ]}
              name="type"
            />
          </FieldContainerWithLabel>
          <PartnershipSelectField
            control={control}
            isDisabled={!counterparty && !connectionType}
            counterpartyId={counterparty?.id}
            connectionType={connectionType?.name}
          />
        </FormSection>
        <FormSection>
          <StyledFileLabel>
            <StyledFileInput
              {...register('connectionTestFile', { required: true })}
              type="file"
              onChange={onChange}
            />
            <InputCover>{fileName}</InputCover>
          </StyledFileLabel>
        </FormSection>
        <ButtonWithLoader
          width="10rem"
          isLoading={isLoading}
          onClick={submitFile}
          disabled={!isDirty || !isValid}
        >
          Send
        </ButtonWithLoader>
      </FormContainer>
    </>
  );
};
