import { ControlledSelectInput, FieldContainerWithLabel, SmallLoader } from 'components';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import styled from 'styled-components';
import { RoleType, SelectOptionType } from 'utils/types';
import { useMarketParticipant } from '../messageDefinitionFormApi';

const StyledFormWithLoaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LoaderContainer = styled(SmallLoader)`
  margin-left: 0.5rem;
`;

interface MarketParticipantSelectFieldProps {
  control: Control;
  isDisabled: boolean;
  name: string;
  roleType?: RoleType;
}

export const MarketParticipantSelectField: FC<MarketParticipantSelectFieldProps> = ({
  control,
  isDisabled,
  name,
  roleType,
}) => {
  const { data: marketParticipants, isLoading } = useMarketParticipant(roleType);

  return (
    <FieldContainerWithLabel isDisabled={isDisabled}>
      <p>Participant name</p>
      <StyledFormWithLoaderContainer>
        <ControlledSelectInput
          required
          control={control}
          width="19.5rem"
          placeholder="Choose"
          options={marketParticipants as unknown as Array<SelectOptionType>}
          name={name}
          isDisabled={isDisabled}
        />
        {isLoading && <LoaderContainer />}
      </StyledFormWithLoaderContainer>
    </FieldContainerWithLabel>
  );
};
