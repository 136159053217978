import { ControlledSelectInput, FieldContainerWithLabel } from 'components';
import { useCounterparties } from './connectionPointFormApi';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { SelectOptionType } from 'utils/types';

interface ConterpartySelectFieldProps {
  control: Control;
}

export const ConterpartySelectField: FC<ConterpartySelectFieldProps> = ({ control }) => {
  const { data: counterparties } = useCounterparties();

  return (
    <FieldContainerWithLabel>
      <p>Connection point responsible party</p>
      <ControlledSelectInput
        required
        control={control}
        width="20rem"
        placeholder="Choose"
        options={counterparties?.content as unknown as Array<SelectOptionType>}
        name="counterparty"
      />
    </FieldContainerWithLabel>
  );
};
