import { FC, useState } from 'react';
import { Checkbox } from 'components';
import { useClickOutside } from 'utils/hooks';
import { MultiOptionCheckboxContainer } from './styled';

interface MultipleOptionCheckboxProps {
  isSelected: boolean;
  isIndeterminate: boolean;
  selectAction: () => void;
  unselectAction: () => void;
}

export const MultiOptionCheckbox: FC<MultipleOptionCheckboxProps> = ({
  isSelected,
  isIndeterminate,
  selectAction,
  unselectAction,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const closeExpandedMenu = () => setIsExpanded(false);
  const toggleCheckbox = () => {
    if (isSelected || isIndeterminate) {
      unselectAction();
    } else {
      selectAction();
    }
  };
  const ref = useClickOutside(closeExpandedMenu);

  return (
    <MultiOptionCheckboxContainer isExpanded={isExpanded} ref={ref}>
      <Checkbox checked={isSelected} indeterminate={isIndeterminate} onChange={toggleCheckbox} />
    </MultiOptionCheckboxContainer>
  );
};
