import { useNominationTracks } from '../messageDefinitionApi';
import { FC } from 'react';
import styled from 'styled-components';
import { ClassicButton, DivTableHeadingCell, VARIANT } from 'components';
import { NominationTrack } from 'features/MessageDefinitions/types';
import { NominationTrackGroup } from './NominationTrackGroup';
import { useHistory } from 'react-router-dom';
import { EdigasVersionType } from 'utils/types';

// const groupColors = ['#8d8fc5', '#ad6178', '#EDCCCC', '#F3E2B6', '#D0C9D9', '#CCE8D2', '#CBE1EB', '#87678c'];

const StyledNominationTrackTable = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;

const StyledHeadingRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.darkGrey};
`;

const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 2.5rem 0;
`;

const HeadingCell = styled(DivTableHeadingCell)`
  background-color: ${({ theme }) => theme.white};
`;

interface NominationTrackTableProps {
  messageDefinitionId: number;
  edigasVersion: EdigasVersionType;
}

interface GroupedData {
  [key: number]: Array<NominationTrack>;
}

export const NominationTracksTable: FC<NominationTrackTableProps> = ({
  messageDefinitionId,
  edigasVersion,
}) => {
  const { data } = useNominationTracks(messageDefinitionId);
  const groupedData = data?.content.reduce((acc, cur) => {
    (acc[cur.connectionPoint.id] = acc[cur.connectionPoint.id] || []).push(cur);
    return acc;
  }, {} as GroupedData);
  const history = useHistory();

  return (
    <StyledNominationTrackTable>
      {groupedData && Object.entries(groupedData).length > 0 && (
        <>
          <StyledHeadingRow>
            <HeadingCell width="9rem"></HeadingCell>
            <HeadingCell>Connection point</HeadingCell>
            <HeadingCell>Internal account</HeadingCell>
            <HeadingCell>External account</HeadingCell>
            <HeadingCell>External Reference*</HeadingCell>
            <HeadingCell width="7rem">Valid from</HeadingCell>
            <HeadingCell width="7rem">Valid to</HeadingCell>
            <HeadingCell width="6rem" align="center">
              Active
            </HeadingCell>
            <HeadingCell width="6rem">Action</HeadingCell>
          </StyledHeadingRow>
          <div>
            {groupedData &&
              Object.entries(groupedData).map(([key, nominationTracksGroup], index) => (
                <NominationTrackGroup
                  key={key}
                  nominationGroup={nominationTracksGroup}
                  // color={Object.entries(groupedData).length > 1 ? groupColors[index % 8] : '#000000'}
                  messageDefId={messageDefinitionId}
                  edigasVersion={edigasVersion}
                />
              ))}
          </div>
        </>
      )}
      <ButtonRow>
        <ClassicButton
          width="15rem"
          variant={VARIANT.SECONDARY}
          onClick={() =>
            history.push(
              `/app/message-definitions/nomination-track/create/${messageDefinitionId}/${edigasVersion.version.baseVersion}`,
            )
          }
        >
          Add nomination track
        </ClassicButton>
      </ButtonRow>
    </StyledNominationTrackTable>
  );
};
