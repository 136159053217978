import { WarningIcon } from 'assets/icons';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;

  ${({ theme }) => css`
    background-color: ${theme.transparentYellow};
    border: 1px solid ${theme.extraOrange};
  `}
`;

const WarningHeading = styled.h3`
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 1rem;
  svg {
    margin-right: 0.5rem;
  }
`;

const WarningList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: disc;

  li {
    margin: 0 2rem;
  }
`;

interface WarningBoxProps {
  errors: string[];
}

export const WarningBox: FC<WarningBoxProps> = ({ errors }) => (
  <WarningContainer>
    <WarningHeading>
      <WarningIcon />
      Warnings:
    </WarningHeading>
    <WarningList>
      {errors.map((error) => (
        <li key={error}>{error}</li>
      ))}
    </WarningList>
  </WarningContainer>
);
