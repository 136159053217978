import styled, { css } from 'styled-components';
// import { debounce } from 'debounce';
import { SearchIcon } from 'assets/icons';
import { BaseButton } from 'components/Buttons/ClassicButton';
import { FC, useMemo } from 'react';
import { debounce } from 'lodash';
import { UseFormRegisterReturn } from 'react-hook-form';
const StyledInput = styled.input`
  position: relative;
  border: none;
  line-height: 1.125rem;
  outline: none;
  width: 100%;
  margin-left: 0.625rem;
  cursor: pointer;

  background-color: ${({ theme }) => theme.inputBackground};

  ::placeholder {
    color: ${({ theme }) => theme.darkGrey};
  }
`;

const StyledLabel = styled.label<{ isFrameless?: boolean }>`
  ${({ isFrameless, theme }) => css`
    width: 12.1875rem;
    height: ${isFrameless ? '100%' : '2.5rem'};
    ${!isFrameless && 'border-radius: .25rem'};

    :hover {
      background-color: ${theme.milkGrey};

      input {
        background: none;
      }
    }
    ${!isFrameless &&
    css`
      border: 1px solid ${theme.inputBorderColor};
      :hover {
        border: 1px solid ${({ theme }) => theme.primary};
      }
      :active {
        border: 1px solid ${({ theme }) => theme.baseBlack};
      }
      :focus-within {
        border: 1px solid ${theme.baseBlack};
      }
    `}
    padding: 0px 0.625rem;
    display: flex;
    align-items: center;
    width: 100%;

    /* :focus-within {
      border: 1px solid ${theme.baseBlack};
    } */
  `}
`;

const CancelSearchButton = styled(BaseButton)`
  position: relative;
  width: 0.9375rem;
  height: 0.9375rem;
  ::after,
  ::before {
    position: absolute;
    content: ' ';
    height: 0.9375rem;
    top: 0;
    width: 0.125rem;
    background-color: ${({ theme }) => theme.primary};
  }

  ::after {
    transform: rotate(-45deg);
  }

  ::before {
    transform: rotate(45deg);
  }
`;

export interface SearchInputProps {
  placeholder: string;
  value: string;
  register: UseFormRegisterReturn;
  handleUpdate: () => void;
  reset: () => void;
  isFrameless?: boolean;
}

export const SearchInput: FC<SearchInputProps> = ({
  placeholder,
  register,
  handleUpdate,
  value,
  reset,
  isFrameless,
}) => {
  const debouncedChangeHandler = useMemo(() => debounce(handleUpdate, 300), []);
  const { onChange, ...rest } = register;

  return (
    <StyledLabel isFrameless={isFrameless}>
      <SearchIcon />
      <StyledInput
        type="text"
        placeholder={placeholder}
        {...rest}
        onChange={(e) => {
          onChange(e);
          debouncedChangeHandler();
        }}
      />
      {value.length > 0 && (
        <CancelSearchButton
          onClick={() => {
            reset();
          }}
        />
      )}
    </StyledLabel>
  );
};
