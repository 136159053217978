import styled, { css } from 'styled-components';

export enum VARIANT {
  PRIMARY,
  SECONDARY,
  PURPLE,
  UNDERLINE,
  BLACK,
  SECONDARY_UNDERLINE,
}

export interface BaseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  width?: string;
  variant?: VARIANT;
  margin?: string;
  tabIndex?: number;
}

export const BaseButton = styled.button<BaseButtonProps>`
  ${({ width }) => width && `width: ${width};`}
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  ${({ margin }) => margin && `margin: ${margin};`}

  :disabled {
    color: #a3bac6;
    cursor: default;

    svg {
      path {
        fill: ${({ theme }) => theme.lightGrey};
      }
    }
  }
`;

export const ClassicButton = styled(BaseButton)`
  height: 2.5rem;
  border-radius: 0.25rem;

  :hover {
    /* opacity: 0.8; */
    transition: 0.2s;
  }

  ${({ variant, theme }) => {
    switch (variant) {
      case VARIANT.PRIMARY:
        return css`
          color: ${theme.primaryButtonTextColor};
          background-color: ${theme.primaryButtonBgColor};
          :hover {
            background-color: ${theme.primaryButtonHvrColor};
          }
        `;
      case VARIANT.SECONDARY:
        return css`
          color: ${theme.secondaryButtonTextColor};
          border: 1px solid ${theme.secondaryButtonBorderColor};
          :hover {
            border-color: ${theme.primary};
            color: ${theme.primary};

            svg {
              path {
                fill: ${({ theme }) => theme.primary};
              }
            }
          }
        `;
      case VARIANT.BLACK:
        return css`
          color: ${theme.blackButtonTextColor};
          background-color: ${theme.blackButtonBgColor};
          :hover {
            background-color: ${theme.primary};
          }
        `;
      case VARIANT.PURPLE:
        return css`
          color: ${theme.white};
          background-color: ${theme.extraViolet};
          :hover {
            color: ${theme.extraViolet};
            background-color: ${theme.transparentViolet};
          }
        `;
      case VARIANT.UNDERLINE:
        return css`
          border: none;
          color: ${theme.primary};
          text-decoration: underline;
          :disabled {
            background: none;
          }

          // TODO: add hover
        `;
      case VARIANT.SECONDARY_UNDERLINE:
        return css`
          border: none;
          color: ${theme.primary};
          background-color: ${theme.secondaryUnderlineBgColor};
          color: ${theme.secondaryUnderlineTextColor};
          text-decoration: ${theme.secondaryUnderlineTextStyle};
        `;
      default:
        return css`
          color: ${theme.blackButtonTextColor};
          background-color: ${theme.blackButtonBgColor};
          :hover {
            background-color: ${theme.primary};
          }
        `;
    }
  }}
  :disabled {
    background-color: ${({ theme }) => theme.disabledButtonBgColor};
    color: ${({ theme }) => theme.disabledButtonTextColor};
    border: none;
  }
`;

export const EditButton = styled(BaseButton)`
  color: ${({ theme }) => theme.primary};
  text-decoration: underline;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.3125rem;
  }
  :hover {
    color: ${({ theme }) => theme.darkGrey};
  }
`;
