import { useAppSelector } from 'store/hooks';

export const useCheckAuthority = (listOfRequiredAuthorites: string[]): boolean => {
  const { authorities } = useAppSelector((state) => state.authReducer);

  const matchingAuthorities = authorities.filter((authority) =>
    listOfRequiredAuthorites.includes(authority),
  );
  return matchingAuthorities.length > 0;
};
