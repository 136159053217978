import { ControlledSelectInput, FieldContainerWithLabel } from 'components';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { SelectOptionType } from 'utils/types';
import { useEdigasVerion } from '../messageDefinitionFormApi';

interface EdigasSelectFieldProps {
  control: Control;
  isDisabled: boolean;
}

export const EdigasSelectField: FC<EdigasSelectFieldProps> = ({ control, isDisabled }) => {
  const { data: edigasVersionList } = useEdigasVerion();
  return (
    <FieldContainerWithLabel isDisabled={isDisabled}>
      <p>Edigas Version</p>
      <ControlledSelectInput
        required
        control={control}
        width="19.5rem"
        placeholder="Choose"
        options={
          edigasVersionList
            ? (edigasVersionList as unknown as Array<SelectOptionType>)
            : [{ id: 0, name: '' }]
        }
        name="edigasVersion"
        isDisabled={isDisabled}
      />
    </FieldContainerWithLabel>
  );
};
