import {
  ActiveIndicator,
  BaseButton,
  GridTableCell,
  NestedDataExpandButton,
  GridMergedCell,
} from 'components';
import { FC } from 'react';
import styled from 'styled-components';
import { MessageDefinitionSlim } from 'utils/types';
import { EditIcon } from 'assets/icons';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { NominationTracksTable } from './NominationTracksTable';
import { standardDateFormat } from 'utils/dateFormating';
import { toggleSection } from 'store/expandedSectionsSlice';

interface MessageDefinitionRowProps {
  messageDefinition: MessageDefinitionSlim;
  isExpanded: boolean;
}

const Row = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns:
    minmax(12rem, 1fr) minmax(12rem, 1fr) minmax(12rem, 1fr) minmax(12rem, 1fr) minmax(12rem, 1.5fr)
    minmax(12rem, 1fr) minmax(10rem, 1fr) minmax(10rem, 1fr) 4.5rem 4.5rem 3.5rem;
  grid-column-gap: 0.3125rem; // TODO: make this value in theme so it's shared
  margin-bottom: 0.3125rem;
`;

export const MessageDefinitionRow: FC<MessageDefinitionRowProps> = ({
  messageDefinition,
  isExpanded,
}) => {
  const {
    description,
    recipientMarketParticipant,
    contractReference,
    documentType,
    edigasVersion,
    validFrom,
    validTo,
    id,
    messageType,
    area,
    active,
  } = messageDefinition;
  const history = useHistory();
  const { authorities } = useAppSelector((state) => state.authReducer);
  const isAdmin = authorities.includes('ADMIN') || authorities.includes('SYSADMIN');
  const dispatch = useAppDispatch();
  const toggleExpand = () => {
    dispatch(toggleSection({ section: 'messageDefinitions', sectionId: id }));
  };
  return id ? (
    <>
      <Row>
        <GridTableCell minWidth="10.9375rem" align="space-between">
          {area.name}
        </GridTableCell>
        <GridTableCell>{description}</GridTableCell>
        <GridTableCell>{messageType !== null && messageType.description}</GridTableCell>
        <GridTableCell>{recipientMarketParticipant.name}</GridTableCell>
        <GridTableCell>{contractReference}</GridTableCell>
        <GridTableCell>{documentType.identification}</GridTableCell>
        <GridTableCell>{edigasVersion.version.version}</GridTableCell>
        <GridMergedCell>
          <GridTableCell>
            {validFrom !== null ? standardDateFormat(new Date(validFrom)) : '\u221e'}
          </GridTableCell>
          <GridTableCell>
            {validTo !== null ? standardDateFormat(new Date(validTo)) : '\u221e'}
          </GridTableCell>
        </GridMergedCell>
        <GridTableCell align="center">
          <ActiveIndicator isActive={active} />
        </GridTableCell>
        <GridTableCell align="center">
          <BaseButton
            onClick={() => history.push(`/app/message-definitions/edit/${id}`)}
            disabled={!isAdmin}
          >
            <EditIcon />
          </BaseButton>
        </GridTableCell>
        <NestedDataExpandButton isExpanded={isExpanded} setIsExpanded={toggleExpand} />
      </Row>
      {isExpanded && (
        <NominationTracksTable messageDefinitionId={id} edigasVersion={edigasVersion} />
      )}
    </>
  ) : (
    <></>
  );
};
