import { ControlledSelectInput, FieldContainerWithLabel } from 'components';
import { SetErrorContext } from '../index';
import { FC, useContext } from 'react';
import { Control } from 'react-hook-form';
import { SelectOptionType } from 'utils/types';
import { useGasDirection } from '../messageDefinitionFormApi';

interface GasDirectionSelectFieldProps {
  control: Control;
  isDisabled: boolean;
  edigasVersion?: string;
}

export const GasDirectionSelectField: FC<GasDirectionSelectFieldProps> = ({
  control,
  isDisabled,
  edigasVersion,
}) => {
  const { data: gasDirections } = useGasDirection(edigasVersion);
  const setError = useContext(SetErrorContext)?.setError;
  return (
    <FieldContainerWithLabel isDisabled={isDisabled}>
      <p>Default Direction Code</p>
      <ControlledSelectInput
        required
        control={control}
        width="19.5rem"
        placeholder="Choose"
        options={gasDirections as unknown as Array<SelectOptionType>}
        name="gasDirectionType"
        isDisabled={isDisabled}
        setError={setError}
      />
    </FieldContainerWithLabel>
  );
};
