import { setError } from 'features/ErrorScreen/errorSlice';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, openModal } from 'store/modalSlice';
import { API, useApiGet } from 'utils/hooks';
import {
  BalancegroupInfo,
  ConnectionPointType,
  MainCustomErrorType,
  NominationType,
} from 'utils/types';
import { AreaSlimType } from 'utils/types/areaTypes';

interface ConnectionPointListType {
  content: Array<ConnectionPointType>;
}

export interface NominationTrackSlimType {
  active: boolean;
  connectionPointId: number;
  doNotCopy: boolean;
  externalAccountId: number;
  id?: number;
  internalAccountId: number;
  messageDefinitionId: number;
  externalReference?: string;
  validFrom: string;
  validTo: string;
  fipDatCreated: string;
  fipDate: string;
  fipUser: string;
  fipUserCreated: string;
  excludeInOutgoingMessage: boolean;
  nominationType?: NominationType;
  nominationTypeId: number;
}

export const useNominationTrackSlim = (nominationTrackId?: string) => {
  const fetchNominationTrackSlim = useApiGet(`/less/nomination-track/${nominationTrackId}`);
  return useQuery<NominationTrackSlimType, Error>(
    ['nominationTrackById'],
    fetchNominationTrackSlim,
    {
      enabled: !!nominationTrackId,
    },
  );
};

export const useConnectionPoint = () => {
  const fetchConnectionPoints = useApiGet(
    `/connection-point?size=0&filters=active,true&sort=name,asc`,
  );
  return useQuery<ConnectionPointListType, Error>(['connectionPointList'], fetchConnectionPoints);
};

interface BalanceGroupListType {
  content: Array<BalancegroupInfo>;
}

export const useBalancegroupList = () => {
  const fetchBalancgroups = useApiGet(
    `/balance-group?size=0&filters=active,true&sort=counterparty.code,asc`,
  );
  return useQuery<BalanceGroupListType, Error>(['balanceGroupList'], fetchBalancgroups);
};

export const useInternalAccountsList = (area?: AreaSlimType) => {
  const fetchBalancgroups = useApiGet(
    `/balance-group?size=0&filters=area.id,${area?.id},counterparty.ownCounterparty,true,active,true&sort=counterparty.code,asc`,
  );
  return useQuery<BalanceGroupListType, Error>(['internalAccountsList', area], fetchBalancgroups, {
    enabled: !!area,
  });
};

interface NominationPayload {
  data: NominationTrackSlimType;
  deleteInvalidTimeSeries?: boolean;
}

export const useNominationTrackSave = (
  option: 'edit' | 'create',
  setFormError: (errorMessage: MainCustomErrorType) => void,
) => {
  const { token } = useAppSelector((state) => state.authReducer);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  return useMutation(
    (payload: NominationPayload) => {
      const reqUrl = payload.deleteInvalidTimeSeries
        ? '/less/nomination-track?deleteInvalidTimeSeries=true'
        : '/less/nomination-track';

      if (token.length) {
        if (option === 'edit') {
          return API.PUT(reqUrl, {
            headers: {
              Authorization: token,
            },
            body: JSON.stringify(payload.data),
          });
        } else {
          return API.POST(reqUrl, {
            headers: {
              Authorization: token,
            },
            body: JSON.stringify(payload.data),
          });
        }
      }
      return Promise.reject();
    },
    {
      onError: (error: MainCustomErrorType) => {
        if (error.code && 'UPDATE_VALID_RANGE_ASK_DELETE_TIMESERIES_WARNING' === error.code) {
          dispatch(
            openModal({
              modalId: 'nominationTrackDeleteInvalidGasDayConfirmationModal',
              props: { errors: error.errors, message: error.message },
            }),
          );
        } else if (error.errors[0].code === 'OVERLAPPING_VALID_FROM_VALID_TO') {
          setFormError(error);
          dispatch(closeModal({ modalId: 'nominationTrackConfirmationModal' }));
        } else {
          dispatch(
            openModal({
              modalId: 'globalErrorModal',
              props: { errors: error.errors, message: error.message },
            }),
          );
        }
      },
      onSuccess: async () => {
        queryClient.invalidateQueries('message-definitions');
        dispatch(closeModal({ modalId: 'nominationTrackConfirmationModal' }));
        dispatch(closeModal({ modalId: 'nominationTrackDeleteInvalidGasDayConfirmationModal' }));
        history.push('/app/message-definitions/');
      },
    },
  );
};

export const useNominationType = (edigasVersion?: string) => {
  const fetchNominationTypes = useApiGet(
    `/nomination-type?filters=edigasVersion.version.baseVersion,${edigasVersion}`,
  );
  return useQuery<Array<NominationType>, Error>(
    ['nominationTypes', edigasVersion],
    fetchNominationTypes,
    {
      enabled: !!edigasVersion,
    },
  );
};
