import { FC } from 'react';
import { SectionTitle, PageFeatureContainer } from 'components';
import { PageContainerScrollTracking } from 'features/Nominations/components/PageContainerScrollTracking';
import { Nominations } from 'features/Nominations';

export const Nomination: FC = () => {
  return (
    <PageContainerScrollTracking>
      <SectionTitle section="nomination" />
      <PageFeatureContainer>
        <Nominations />
      </PageFeatureContainer>
    </PageContainerScrollTracking>
  );
};
