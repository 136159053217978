import React, { ClipboardEventHandler, FC, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ChangeHandler, useForm } from 'react-hook-form';
import { MessageStatus, BalanceGroup } from 'utils/types';
import { StyledBalanceGroupValueCell } from '../StyledNominationComponents';
import { useAppDispatch } from 'store/hooks';
import { updateValues } from '../../nominationReduxSlice';

const StyledTextEditField = styled.input<{ isUnmatched: boolean; isNonLeadTimeViolation: boolean }>`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  width: 100%;
  text-align: center;
  height: 100%;
  font-size: 0.875rem;
  text-overflow: ellipsis;

  ${({ isUnmatched, isNonLeadTimeViolation }) =>
    css`
      // text-decoration: underline;
      text-underline-offset: 1px;
      background-color: ${!isUnmatched ? 'rgba(163, 186, 198, 0.1)' : 'rgba(255, 165, 144, 1.0)'};

      ${!isNonLeadTimeViolation && isUnmatched && 'filter: brightness(0.8);'}
      ${!isNonLeadTimeViolation && !isUnmatched && 'filter: brightness(0);'}
    `}

  :focus {
    border: 1px solid ${({ theme }) => theme.darkGrey};
    box-shadow: 0px 0.25rem 0.25rem rgba(144, 164, 174, 0.2);
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.balanceGroupEditCellBg};
    color: ${({ theme }) => theme.balanceGroupEditCellTextColor};
    text-overflow: initial;
    filter: brightness(1);
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const StyledForm = styled.form`
  display: flex;
  width: 100%;
`;

const checkIfNumberRegex = /^[+-]?([0-9]+([.|,][0-9]*)?|[.][0-9]+)$/;

const formatDecimalInput = (input: string) => {
  const isDecimalLimitReached = input.search(/^[0-9]+(.|,)?[0-9]{0,3}$/) === -1;

  if (!isDecimalLimitReached) {
    return input;
  } else {
    const indexOfDecimalPoint = input.search(/[,|.]/);
    const transformedValue = input.substring(0, indexOfDecimalPoint + 4).replace(',', '.');

    return transformedValue;
  }
};

interface BalanceGroupValuesProps {
  areaId: number;
  connectionId: number;
  balanceGroup: BalanceGroup;
  alignCarousel: () => void;
  connectionPointIndetifier: string;
}

export const BalanceGroupValues: FC<BalanceGroupValuesProps> = ({
  balanceGroup,
  areaId,
  connectionId,
  connectionPointIndetifier,
  alignCarousel,
}) => {
  const dispatch = useAppDispatch();

  const {
    hourlyValues: { timetable },
    hourlyValuesConfirmed,
    latestNomintValues,
    hourlyStatus,
    nominationTrackId,
    firstNonLeadTimeIndex,
    version,
    sourceId,
    destinationId,
  } = balanceGroup;

  const { register, getValues, reset } = useForm({
    defaultValues: timetable as number[],
  });

  useEffect(() => {
    reset(timetable);
  }, [timetable]);

  const handleUpdate = () => {
    const values = getValues();
    const containsNan = Object.values(values).includes(NaN);

    if (areaId && !containsNan) {
      dispatch(
        updateValues({
          nominationTrackId,
          version,
          areaId,
          connectionId,
          destination: destinationId,
          sourceId,
          timetable: Object.values(values),
          connectionPointIndetifier,
        }),
      );
    }
  };

  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.focus();

    const el = e.currentTarget;

    if (document.activeElement === el) {
      setTimeout(() => {
        el.select();
      }, 1);
    }

    alignCarousel();
  };

  const inputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (value === '' || value.length === 0) {
      const correctedValues = Object.values(getValues()).map((value) => (isNaN(value) ? 0 : value));
      reset(correctedValues);
    }

    handleUpdate();
    alignCarousel();
  };

  const handlePaste: ClipboardEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData('text/plain');
    const currentValues = Object.values(getValues());
    const pastedValueRows = clipboardData.split('\n');
    const pastedValues = pastedValueRows[0].split('\t');
    const parsedPastedValues = pastedValues.map((i) => {
      const parsedValue = parseFloat(formatDecimalInput(i));
      return !isNaN(parsedValue) ? parsedValue : 0;
    });

    const baseArray = [...currentValues];
    let i = parseInt(e.currentTarget.name);
    let j = 0;
    while (j < parsedPastedValues.length && i != baseArray.length) {
      baseArray[i] = parsedPastedValues[j];
      i += 1;
      j += 1;
    }

    e.currentTarget.blur();

    if (areaId) {
      dispatch(
        updateValues({
          nominationTrackId,
          version,
          areaId,
          connectionId,
          destination: destinationId,
          sourceId,
          timetable: baseArray,
          connectionPointIndetifier,
        }),
      );
    }
  };

  const correctInputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChangeEvent: ChangeHandler,
  ) => {
    const currentValue = e.currentTarget.value;

    if (
      currentValue.indexOf('0') === 0 &&
      currentValue.indexOf(',') === -1 &&
      currentValue.indexOf('.') === -1 &&
      currentValue.length > 1
    ) {
      e.currentTarget.value = currentValue.substring(1);
    }

    const isNumber =
      currentValue.search(checkIfNumberRegex) !== -1 || currentValue.search('-') === 0;

    if (isNumber) {
      const decimalLimitNotReached = currentValue.search(/^[+-]?([0-9]+[,|.]?[0-9]{0,3})$/) === 0;
      const isFloatingPoint = currentValue.search(/^[+-]?([0-9]+[,|.]?[0-9]+)$/) === 0;

      if (isFloatingPoint) {
        let newValue = currentValue;

        if (!decimalLimitNotReached && currentValue.slice(-1) !== '.') {
          const indexOfDecimalPoint = currentValue.search(/[,|.]/);
          newValue = currentValue.slice(0, indexOfDecimalPoint + 4);
        }
        e.currentTarget.value = newValue.replaceAll(',', '.');
      }

      onChangeEvent(e);

      // }
    } else {
      const nonNumericValues = currentValue.split('').filter((i) => checkIfNumberRegex.test(i));
      e.currentTarget.value = nonNumericValues.join('');
    }
  };

  const handleKeyShortcuts = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape' || e.keyCode === 27) {
      e.currentTarget.blur();
    }

    if (e.key === 'Enter' || e.keyCode === 13 || e.key === 'Return') {
      const { parentElement } = e.currentTarget;
      if (parentElement && parentElement !== null) {
        const nextElement = parentElement.nextElementSibling;
        if (nextElement && nextElement !== null) {
          const nextElementChild = nextElement.firstElementChild;
          if (nextElementChild?.tagName.toLowerCase() === 'input') {
            (nextElementChild as HTMLInputElement).focus();
          }
        }
      }
    }

    if (e.altKey && e.ctrlKey && (e.key === 'ArrowRight' || e.key === 'ArrowLeft')) {
      const currentValues = Object.values(getValues());
      const baseValue = e.currentTarget.value;
      const currentIndex = parseInt(e.currentTarget.name);

      if (e.key === 'ArrowRight') {
        for (let i = currentIndex; i < currentValues.length; i += 1) {
          currentValues[i] = parseFloat(baseValue);
        }
      }

      if (e.key === 'ArrowLeft') {
        for (let i = 0; i < currentIndex; i += 1) {
          currentValues[i] = parseFloat(baseValue);
        }
      }

      if (areaId) {
        dispatch(
          updateValues({
            nominationTrackId,
            version,
            areaId,
            connectionId,
            destination: destinationId,
            sourceId,
            timetable: currentValues,
            connectionPointIndetifier,
          }),
        );
      }
    }
  };

  return (
    <StyledForm>
      {timetable.map((value, key) => {
        const { onChange, ...rest } = register(`${key}`, { valueAsNumber: true });
        return (
          <StyledBalanceGroupValueCell
            key={`$${key}_${sourceId}_${destinationId}_${areaId}_${connectionId}`}
            isAdditionalHour={timetable.length === 25 && key === 20}
          >
            <StyledTextEditField
              {...rest}
              onFocus={(e) => inputOnFocus(e)}
              onBlur={(e) => inputOnBlur(e)}
              onChange={(e) => {
                correctInputOnChange(e, onChange);
              }}
              onKeyDown={handleKeyShortcuts}
              onPaste={handlePaste}
              title={[
                'Status: ',
                hourlyStatus.timetable[key],
                hourlyValuesConfirmed ? '\nConfirmed: ' + hourlyValuesConfirmed.timetable[key] : '',
                latestNomintValues ? '\nNominated: ' + latestNomintValues.timetable[key] : '',
              ].join('')}
              isUnmatched={
                hourlyStatus.timetable[key] === MessageStatus.MISMATCHED ||
                (hourlyValuesConfirmed != undefined &&
                  hourlyValuesConfirmed.timetable[key] !== timetable[key])
              }
              isNonLeadTimeViolation={firstNonLeadTimeIndex > -1 && key >= firstNonLeadTimeIndex}
              // onClick={selectInputValue}
            />
          </StyledBalanceGroupValueCell>
        );
      })}
    </StyledForm>
  );
};
