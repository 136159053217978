import { TextInput } from 'components';
import styled from 'styled-components';

const MainContainer = styled.div`
  display: flex;
  min-height: 100%;
  justify-content: center;
`;

const ContentContainer = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '30%'};
  min-width: 50rem;

  min-height: 100%;
  background-color: ${({ theme }) => theme.formBackground};
  scroll-behavior: smooth;
`;

const FormContainer = styled.div`
  padding: 1.5625rem 5rem;
  width: 100%;
  margin-bottom: 3.125rem;
`;

const ActionBar = styled.div`
  background-color: ${({ theme }) => theme.formBackground};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 5rem;
  padding: 2.5rem 0;
  border-top: 1px solid ${({ theme }) => theme.milkGrey};
`;

const HeadingBar = styled.div`
  display: flex;
  align-items: center;
  margin: 0 5rem;
  padding: 2.5rem 0 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.milkGrey};
  justify-content: space-between;
`;

const FormTitle = styled.h1`
  color: ${({ theme }) => theme.baseBlack};
  font-size: 1.5rem;
  font-weight: 600;
`;

const FormSectionTitle = styled.h2`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.extraViolet};
  margin-bottom: 1.25rem;
`;

const StyledTextInput = styled(TextInput)`
  width: 18.75rem;
`;

const FormSection = styled.div`
  margin-bottom: 2.5rem;
`;

const ActivationSwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ActivationStyledLabel = styled.label`
  margin-left: 0.625rem;
  font-size: 0.875rem;
`;

const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 18.125rem;
  justify-content: space-between;
  margin-bottom: 0.9375rem;
`;
const TimePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 19.5rem;
  align-items: left;
  margin-bottom: 0.9375rem;
  label {
    margin-bottom: 0.9375rem;
  }
`;

const ConfirmationPopupText = styled.p`
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;

  span {
    font-size: inherit;
    color: ${({ theme }) => theme.darkGrey};
  }
`;

const FieldContainerWithLabel = styled.div<{ labelSpace?: string; isDisabled?: boolean }>`
  margin-bottom: 1.5625rem;
  p {
    display: block;
    margin-bottom: ${({ labelSpace }) => (labelSpace ? labelSpace : '0.5rem')};
    font-weight: 600;
    ${({ isDisabled, theme }) => isDisabled && `color: ${theme.lightGrey}`}
  }
`;

const CheckboxCotainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
`;

export {
  MainContainer,
  ContentContainer,
  FormContainer,
  ActionBar,
  HeadingBar,
  FormTitle,
  FormSectionTitle,
  StyledTextInput,
  FormSection,
  ActivationSwitchContainer,
  ActivationStyledLabel,
  DatePickerContainer,
  ConfirmationPopupText,
  FieldContainerWithLabel,
  CheckboxCotainer,
  FlexContainer,
  TimePickerContainer,
};
