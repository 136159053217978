import styled, { keyframes } from 'styled-components';

const loadAnimation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  color: ${({ theme }) => theme.primary};
  font-size: 0.6875rem;
  text-indent: -99999em;
  margin: 3.4375rem auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  transform: translateZ(0);
  position: relative;

  &,
  &:before,
  &:after {
    border-radius: 50%;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    width: 5.2em;
    height: 10.2em;
    background-color: ${({ theme }) => theme.white};
  }

  &:before {
    border-radius: 10.2em 0 0 10.2em;
    top: -0.1em;
    left: -0.1em;
    transform-origin: 5.1em 5.1em;
    animation: ${loadAnimation} 2s infinite ease 1.5s;
  }
  &:after {
    border-radius: 0 10.2em 10.2em 0;
    top: -0.1em;
    left: 4.9em;
    transform-origin: 0.1em 5.1em;
    animation: ${loadAnimation} 2s infinite ease;
  }
`;

export const SmallLoader = styled(Loader)`
  /* width: 1.875rem;
  height: 1.875rem; */
  margin: 0;
  font-size: 2px;

  /* &:before {
    width: 1rem;
    height: 1.9375rem;
    transform-origin: 0.9375rem 0.9375rem;
  }
  &:after {
    width: 0.9375rem;
    height: 1.875rem;
    left: 0.9375rem;
    transform-origin: 0.1em 0.9375rem;
  } */
`;

export const MediumLoaderWithBlackBg = styled(Loader)`
  margin: 0;
  font-size: 2.5px;

  &:before {
    background: ${({ theme }) => theme.mediumLoaderBg};
  }

  &:after {
    background: ${({ theme }) => theme.mediumLoaderBg};
  }
`;
