import { Loader } from './Loaders';
import { FC } from 'react';
import styled from 'styled-components';

const LoadingScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LoadingScreen: FC = () => {
  return (
    <LoadingScreenContainer>
      <Loader />
    </LoadingScreenContainer>
  );
};
