import { BaseButton } from './ClassicButton';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { FilterIcon } from 'assets/icons';

const FilterExpandButton = styled(BaseButton)<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    border: 1px solid ${theme.lightGrey};
    ${isActive && `background-color: ${theme.primary};`}
    svg {
      path {
        fill: ${isActive ? theme.white : theme.primary};
      }
    }
  `}
`;

interface FilterButton {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

export const FilterButton: FC<FilterButton> = ({ isExpanded, setIsExpanded }) => {
  return (
    <FilterExpandButton isActive={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
      <FilterIcon />
    </FilterExpandButton>
  );
};
