import { BaseButton } from 'components';
import { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import greenCheckmark from 'assets/icons/greenCheckmarkIcon.svg';
import { ValidStatesType, ValidStateType } from '../index';
import { Failure } from 'assets/icons';
import { FormOption } from 'utils/types';

const BreadcrumbsContainer = styled.div`
  margin-top: 5rem;
  position: fixed;
`;

const Step = styled.li<BreadcrumbsStatusProps>`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  color: ${({ theme, activeStatus }) =>
    activeStatus === 'ACTIVE' ? theme.extraViolet : theme.darkGrey};
  ${({ validStatus, theme }) =>
    validStatus && validStatus === 'ERROR' && `color: ${theme.extraRed}`}
`;

const StepName = styled.a<BreadcrumbsStatusProps>`
  color: ${({ theme, activeStatus }) =>
    activeStatus === 'ACTIVE' ? theme.baseBlack : theme.darkGrey};
  text-decoration: none;
  margin-left: 1rem;
  cursor: pointer;
`;

const StepInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BreadcrumbsHeading = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

const ErrorContainer = styled.div`
  width: 100%;
  height: 1.75rem;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  ${({ theme }) => css`
    background-color: ${theme.transparentRed};
    color: ${theme.extraRed};
  `}
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  svg {
    margin: 0 0.5rem 0 0.125rem;
  }
`;

const Error: FC = () => (
  <ErrorContainer>
    <Failure /> Fill the data again
  </ErrorContainer>
);

// RADIO BUTTON COMPONENT

const RadioButtonContainer = styled(BaseButton)<BreadcrumbsStatusProps>`
  width: 1.5rem;
  height: 1.5rem;
  background-color: white;
  border-radius: 1.5rem;
  margin-right: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ activeStatus, validStatus, theme }) => css`
    border: 1px solid ${theme.darkGrey};

    ${activeStatus === 'ACTIVE' &&
    validStatus === 'NOTREADY' &&
    css`
      border-color: ${theme.extraViolet};
      &::after {
        content: ' ';
        width: 1rem;
        height: 1rem;
        border-radius: 1rem;
        background-color: ${theme.extraViolet};
      }
    `}
    ${validStatus === 'VALID' &&
    css`
      border-color: ${theme.extraGreen};
      background-color: ${theme.transparentGreen};

      &::after {
        content: ' ';
        width: 1rem;
        height: 1rem;
        background-image: url(${greenCheckmark});
        background-repeat: no-repeat;
        background-position: center;
      }
    `}
    ${validStatus === 'ERROR' && `border-color: ${theme.extraRed};`}
  `}
`;

interface BreadcrumbsStatusProps {
  activeStatus: 'ACTIVE' | 'INACTIVE';
  validStatus?: ValidStateType;
}

const BreadcrumbsRadioButton: FC<BreadcrumbsStatusProps> = ({ activeStatus, validStatus }) => {
  return (
    <RadioButtonContainer
      activeStatus={activeStatus}
      validStatus={validStatus}
    ></RadioButtonContainer>
  );
};

//-----------------------

const stepsList = [
  { name: 'Message Definition', id: '#message-definition', statusId: 'messageDefinitionDetails' },
  { name: 'Document Attributes', id: '#document-attributes', statusId: 'documentAttributes' },
  { name: 'Account Attributes', id: '#account-attributes', statusId: 'accountAttributes' },
];

interface BreadcrumbsProps {
  validStates: ValidStatesType;
  option: FormOption;
}
export const Breadcrumbs: FC<BreadcrumbsProps> = ({ validStates, option }) => {
  const [activeSection, setActiveSection] = useState(stepsList[0].id);
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.6) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.6, rootMargin: '200px 0px 200px 0px' }, // TODO: figure out how to make it work on more screen sizes
    );

    stepsList.forEach((step) => {
      const element = document.querySelector(step.id);
      if (element) {
        intersectionObserver.observe(element);
      }
    });
    return () => {
      stepsList.forEach((step) => {
        const element = document.querySelector(step.id);
        if (element) {
          intersectionObserver.unobserve(element);
        }
      });
    };
  }, []);

  const scrollFunction = (stepId: string) => {
    document.querySelector(stepId)?.scrollIntoView({ behavior: 'smooth' });
    setActiveSection(stepId);
  };

  const headingString = option === 'create' ? 'Add new message' : 'Edit message';

  return (
    <BreadcrumbsContainer>
      <BreadcrumbsHeading>{headingString}</BreadcrumbsHeading>
      <ul>
        {stepsList.map((step, key) => {
          const status = step.id === `#${activeSection}` ? 'ACTIVE' : 'INACTIVE';
          const validState = validStates[step.statusId as keyof ValidStatesType];
          return (
            <Step key={step.id} activeStatus={status} validStatus={validState}>
              <StepInfoContainer>
                <BreadcrumbsRadioButton activeStatus={status} validStatus={validState} />
                Step {key + 1}{' '}
                <StepName
                  onClick={() => scrollFunction(step.id)}
                  activeStatus={status}
                  validStatus={validState}
                >
                  {step.name}
                </StepName>
              </StepInfoContainer>
              {validState === 'ERROR' && <Error />}
            </Step>
          );
        })}
      </ul>
    </BreadcrumbsContainer>
  );
};
