import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { BalanceGroupCarousel } from './BalanceGroupCarousel';
import { StatusIndicator } from 'components';
import { calculateRowSum } from '../../utils';
import { InfoCell, TextOverflow } from '../StyledNominationComponents';
import { useAppSelector } from 'store/hooks';
import { BalanceGroup } from 'utils/types';
import { InfoIcon } from 'assets/icons';

const BalanceGroupRowContainer = styled.div`
  display: flex;
`;
const Row = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
  min-width: 0;
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 30.9375rem;
`;

const Spacer = styled.div`
  min-width: 2.625rem;
  max-width: 2.625rem;
`;

const VersionLabel = styled.span`
  color: ${({ theme }) => theme.darkGrey};
  font-size: 0.75rem;
  line-height: 0.875rem;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 8rem;
`;

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FlexWithSpacing = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const CounterpartyLabel = styled.span`
  color: ${({ theme }) => theme.darkGrey};
  font-size: 0.75rem;
`;

const StatusIconContainer = styled.div`
  svg {
    width: 1.0625rem;
    height: 1.0625rem;
  }
`;

const StyledInfoIcon = styled(InfoIcon)`
  width: 1.5rem;
  margin-right: 0.5rem;
`;

const SpacingReplacingIcon = styled.div`
  width: 1.5rem;
  margin-right: 0.5rem;
`;

export interface BalangeGroupRowProps {
  connectionPointSelected: boolean;
  balanceGroup: BalanceGroup;
  connectionId: number;
  areaId: number;
  connectionPointName: string;
  connectionPointIndetifier: string;
}

export const BalanceGroupRow: FC<BalangeGroupRowProps> = ({
  balanceGroup,
  connectionId,
  areaId,
  connectionPointName,
  connectionPointIndetifier,
}) => {
  const { zeroFilter } = useAppSelector((state) => state.nominationReducer);
  const {
    counterparty,
    messageStatus,
    hourlyValues,
    version,
    destinationName,
    missConfiguration,
    excludeInOutgoingMessage,
  } = balanceGroup;

  const rowSum = useMemo(() => calculateRowSum(hourlyValues.timetable), [hourlyValues]);
  return zeroFilter && rowSum === 0 ? (
    <></>
  ) : (
    <BalanceGroupRowContainer>
      <Row>
        <InfoContainer>
          <InfoCell flexValue="1" padding={'0.5rem 0.5625rem 0.5rem 0.5rem'}>
            <FlexWithSpacing>
              <FlexContainer>
                {excludeInOutgoingMessage ? (
                  <StyledInfoIcon title="Nomination track excluded in outgoing message" />
                ) : (
                  <SpacingReplacingIcon />
                )}
                <ColumnFlex>
                  <CounterpartyLabel>Counterparty</CounterpartyLabel>
                  <TextOverflow>{counterparty}</TextOverflow>
                </ColumnFlex>
              </FlexContainer>
              <VersionLabel>v.{version}</VersionLabel>
            </FlexWithSpacing>
          </InfoCell>
          <InfoCell flexValue="1" maxWidth="15rem">
            {missConfiguration && (
              <StyledInfoIcon title="Nomination identifier configuration failures" />
            )}
            <TextOverflow>{destinationName}</TextOverflow>
          </InfoCell>
          <InfoCell width="3.5625rem" align="center">
            <StatusIconContainer>
              <StatusIndicator status={messageStatus} />
            </StatusIconContainer>
          </InfoCell>
          <InfoCell width="4rem" align="center">
            {parseFloat(rowSum.toFixed(3))}
          </InfoCell>
        </InfoContainer>
        <BalanceGroupCarousel
          balanceGroup={balanceGroup}
          connectionId={connectionId}
          areaId={areaId}
          connectionPointName={connectionPointName}
          connectionPointIndetifier={connectionPointIndetifier}
        />
      </Row>
      <Spacer />
    </BalanceGroupRowContainer>
  );
};
