import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import authReducer from './authSlice';
import modalReducer from './modalSlice';
import globalSlice from './globalSlice';
import expandedSectionsSlice from './expandedSectionsSlice';
import nominationReducer from 'features/Nominations/nominationReduxSlice';
import messageReducer from 'features/Messages/messagesSlice';
import messageAckReducer from 'pages/MessageFakeResponsePage/FakeMessagesAck/messagesSlice';
import notificationSlice from 'features/NotificationPopup/notificationSlice';
import errorSlice from 'features/ErrorScreen/errorSlice';
import messageDefinitionSlice from 'features/MessageDefinitions/messageDefinitionSlice';
import dashboardSlice from 'features/Dashboard/dashboardSlice';

const rootReducer = combineReducers({
  authReducer,
  modalReducer,
  nominationReducer,
  messageReducer,
  notificationSlice,
  messageDefinitionSlice,
  errorSlice,
  globalSlice,
  expandedSectionsSlice,
  messageAckReducer,
  dashboardSlice,
});

export default function configureAppStore() {
  const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./', () => store.replaceReducer(rootReducer));
  }

  return store;
}

export const store = configureAppStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
