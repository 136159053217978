import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'components/Checkbox';
import { ButtonWithLoader, ClassicButton, CountryFlag, VARIANT } from 'components';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { useNominationSave } from '../nominationsApi';
import { clearChangedValues } from '../nominationReduxSlice';
import { CountryCode } from 'utils/types/generalTypes';

interface MarketAreaHeaderProps {
  name: string;
  isSelected: boolean;
  isIndeterminate: boolean;
  areaId: number;
  toggleSelection: () => void;
  selectedDate: Date;
  numberOfDays: number;
  bgColor: string;
  country: CountryCode;
}

const StyledHeaderContainer = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3.5rem;
  background-color: ${({ bgColor }) => bgColor};
  padding: 0 0.5rem 0 0.5rem;
  margin-bottom: 0.3125rem;
  border-radius: 0.25rem 0.25rem 0px 0px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: ${({ theme }) => theme.alwaysDarkFont};
  }
  svg {
    margin-right: 0.5rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
`;

export const MarketAreaHeader: FC<MarketAreaHeaderProps> = ({
  name,
  isSelected,
  isIndeterminate,
  areaId,
  toggleSelection,
  selectedDate,
  numberOfDays,
  bgColor,
  country,
}): ReactElement => {
  const { changedData } = useAppSelector((state) => state.nominationReducer);
  const changedMarketAreaData = changedData.filter((data) => data.areaId === areaId);
  const dispatch = useAppDispatch();

  const { mutate: nominationSave, isLoading } = useNominationSave(false);
  return (
    <StyledHeaderContainer bgColor={bgColor}>
      <HeadingContainer>
        <Checkbox checked={isSelected} indeterminate={isIndeterminate} onChange={toggleSelection} />
        <CountryFlag countryCode={country} />
        <h2>{name}</h2>
      </HeadingContainer>
      <FlexContainer>
        <ClassicButton
          disabled={changedMarketAreaData.length === 0 || isLoading}
          width="11.25rem"
          variant={VARIANT.SECONDARY_UNDERLINE}
          onClick={() => dispatch(clearChangedValues({ areaId }))}
          margin="0 0.5rem 0 0"
        >
          Cancel the changes
        </ClassicButton>
        <ButtonWithLoader
          isLoading={isLoading}
          disabled={changedMarketAreaData.length === 0}
          variant={VARIANT.PRIMARY}
          width="10rem"
          onClick={() =>
            nominationSave({
              changedData: changedMarketAreaData,
              selectedDate,
              numberOfDays,
              areaId,
            })
          }
        >
          Save changes
        </ButtonWithLoader>
      </FlexContainer>
    </StyledHeaderContainer>
  );
};
