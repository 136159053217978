import { FC } from 'react';
import styled from 'styled-components';
import { ConnectionPointInfoRow } from './ConnectionPointInfoRow';
import { ClassicButton, StandardNestedGridTableHeadingCell, VARIANT } from 'components';
import { useHistory } from 'react-router-dom';
import { useConnectionpointsByAreaId } from '../areasApi';
import { AreaSlimType } from 'utils/types/areaTypes';

const ConnectionPointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.75rem;
`;

const StyledHeading = styled.h3`
  font-size: 1rem;
  color: ${({ theme }) => theme.darkGrey};
  padding: 0 0.75rem;
  margin-bottom: 0.625rem;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns:
    3.125rem minmax(13rem, 1fr) minmax(13rem, 1fr) minmax(13rem, 1fr) minmax(9rem, 1fr)
    6rem
    13rem 13rem 7rem 7rem 4rem 3.75rem 6.25rem;
  grid-column-gap: 0.3125rem;
`;

const ConnectionPointHeadingRow = styled(Row)`
  margin-bottom: 1rem;
`;

const ButtonContaienr = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.25rem 0;
`;

interface ConnectionPointTableProps {
  isAdmin: boolean;
  areaSlim: AreaSlimType;
  areaSortOrder: number;
}

export const ConnectionPointTable: FC<ConnectionPointTableProps> = ({
  isAdmin,
  areaSlim,
  areaSortOrder,
}) => {
  const history = useHistory();
  const { data: connectionPoints } = useConnectionpointsByAreaId(areaSlim.id);
  return (
    <ConnectionPointsContainer>
      {connectionPoints && connectionPoints.content.length > 0 && (
        <>
          <StyledHeading>ConnectionPoints</StyledHeading>
          <ConnectionPointHeadingRow>
            <StandardNestedGridTableHeadingCell></StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>Display name</StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>Short name</StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>System operator</StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell align="center">
              Point type
            </StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell align="center">
              Granularity
            </StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>
              Nomination lead time in minutes
            </StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>
              Connection point identifier
            </StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>Valid from</StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>Valid to</StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>Sort order</StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell>Active</StandardNestedGridTableHeadingCell>
            <StandardNestedGridTableHeadingCell></StandardNestedGridTableHeadingCell>
          </ConnectionPointHeadingRow>
          {connectionPoints.content.map((connectionPoint, key) => (
            <ConnectionPointInfoRow
              connectionPoint={connectionPoint}
              key={connectionPoint.id}
              isAdmin={isAdmin}
              isOdd={key % 2 !== 0}
              areaSortOrder={areaSortOrder}
            />
          ))}
        </>
      )}
      <ButtonContaienr>
        <ClassicButton
          variant={VARIANT.SECONDARY}
          width="14.5rem"
          onClick={() =>
            history.push(
              `/app/areas/connection-point/${areaSlim.name}/${areaSlim.id}/create/${areaSortOrder}/`,
            )
          }
          disabled={!isAdmin}
        >
          Add new connection point
        </ClassicButton>
      </ButtonContaienr>
    </ConnectionPointsContainer>
  );
};
