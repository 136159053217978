import { FC } from 'react';
import { Modal } from 'components';
import styled from 'styled-components';
import { ClassicButton, VARIANT } from 'components/Buttons/ClassicButton';
import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/modalSlice';

const SubmitModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 3.125rem;
  height: calc(100% - 10rem);
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 21.875rem;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const ModalHeading = styled.h2`
  display: flex;
  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  align-items: center;
`;

const externalContentStyles = {
  height: '30rem',
  width: '40rem',
  padding: '0',
};

export const ResponseErrorModal: FC = () => {
  const dispatch = useAppDispatch();

  return (
    <Modal
      modalId="connectionTestErrorModal"
      externalContentStyles={externalContentStyles}
      // headerType={status}
    >
      <SubmitModalContainer>
        <ModalHeading>
          Something went wrong while uploading file, please contact support
        </ModalHeading>
        <ButtonContainer>
          <ClassicButton
            onClick={() => dispatch(closeModal({ modalId: 'connectionTestErrorModal' }))}
            width="10rem"
            variant={VARIANT.PRIMARY}
          >
            Ok
          </ClassicButton>
        </ButtonContainer>
      </SubmitModalContainer>
    </Modal>
  );
};
