import styled, { css } from 'styled-components';

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;

const TableCell = styled.td<{
  width?: string;
  align?: string;
  padding?: string;
  bgColor?: string;
}>`
  max-height: 2.5rem;
  height: 2.5rem;
  text-align: ${({ align }) => align || 'initial'};
  align-items: center;
  padding: ${({ padding }) => padding || '0 0.625rem'};
  overflow: hidden;
  background-color: ${({ bgColor, theme }) => bgColor || theme.mainBackgroundColor};
  :last-child {
    border-right: none;
  }
`;

const TableHeadingCell = styled.th<{
  width?: string;
  minWidth?: string;
  align?: string;
  padding?: string;
  fontSize?: string;
}>`
  ${({ theme, align, padding, width, minWidth, fontSize }) => css`
    max-height: 2.5rem;
    height: 2.5rem;
    text-align: ${align || 'initial'};
    align-items: center;
    background-color: ${theme.tableHeadingBg};
    padding: ${padding || '0.5625rem 0.625rem'};
    overflow: hidden;
    font-size: ${fontSize ? fontSize : '0.875rem'};
    ${width &&
    css`
      max-width: ${width};
      width: ${width};
    `}
    ${minWidth && `min-width: ${minWidth};`}

    border-right: 0.3125rem solid ${theme.mainBackgroundColor};
    border-bottom: 0.3125rem solid ${theme.mainBackgroundColor};
  `}

  :last-child {
    border-right: none;
  }
`;

export { TableCell, TableHeadingCell, Table };
