import { Triangle } from 'assets/icons';
import { BaseButton } from 'components';
import { FC } from 'react';
import styled from 'styled-components';

const ToggleButton = styled(BaseButton)<{ isExpanded?: boolean }>`
  width: 2.5rem;
  height: 3rem;
  background-color: ${({ isExpanded, theme }) =>
    isExpanded ? theme.primary : theme.nestedDataExpandButtonColor};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: ${({ isExpanded, theme }) =>
      isExpanded ? theme.transparentBlue : theme.milkGrey};
  }

  svg {
    width: 0.5625rem;
    path {
      fill: ${({ isExpanded, theme }) => (isExpanded ? 'white' : theme.baseBlack)};
    }
    transform: rotate(${({ isExpanded }) => (isExpanded ? '90deg' : '-90deg')});
  }
`;

interface NestedDataExpandButtonProps {
  isExpanded: boolean;
  setIsExpanded: () => void;
}
export const NestedDataExpandButton: FC<NestedDataExpandButtonProps> = ({
  isExpanded,
  setIsExpanded,
}) => (
  <ToggleButton isExpanded={isExpanded} onClick={setIsExpanded}>
    <Triangle />
  </ToggleButton>
);
