import { FC, useMemo, useState } from 'react';
import { useMessageDetails } from './messageDetailsApi';
import styled from 'styled-components';
import { StatusOptions } from 'utils/types';
import { LoadingScreen } from 'components/LoadingScreen';
import { FilterRow } from './components/FilterRow';
import { MessageDetailTable } from './components/MessageDetailTable';

import { MessageDetailsGroupType } from 'features/MessageDetails/types';
import { MessageDetailsGroup } from './MessageDetailsGroup';

import { ErrorBox, WarningBox, PageFeatureContainer } from 'components';

import { MessageDetailSectionHeader } from './components/MessageDetailSectionHeader';

const MessageValuesContainer = styled.section`
  margin-top: 2rem;
  width: 100%;
`;

const CustomErrorBoxContainer = styled.div`
  margin-top: 1.5rem;
`;

interface MessageDetailsProps {
  messageId: string;
}

export const MessageDetails: FC<MessageDetailsProps> = ({ messageId }) => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [statusFilter, setStatusFilter] = useState<StatusOptions>({
    unsent: false,
    saved: false,
    saved_renomination: false,
    pending: false,
    sent: false,
    acknowledged: false,
    acknowledged_error: false,
    mismatched: false,
    mismatched_partial: false,
    nominated: false,
    success: false,
    failure: false,
    received: false,
    received_processed: false,
    received_processed_error: false,
  });
  const { data, isLoading } = useMessageDetails(messageId);

  const filteredByStatus = useMemo(() => {
    const activeFilters = Object.entries(statusFilter).filter(
      ([optionName, value]) => value && optionName,
    );

    return (
      data?.valuesStructured.filter((messageValueGroup) => {
        const groupResults = messageValueGroup.timeSeriesMessageDtos.filter((messageValue) => {
          if (activeFilters.length > 0) {
            for (const index in activeFilters) {
              const [keyname] = activeFilters[index];
              if (messageValue.messageStatus === keyname.toLocaleUpperCase()) {
                return messageValue;
              }
            }
          } else {
            return messageValue;
          }
        });

        return groupResults.length > 0;
      }) || ([] as Array<MessageDetailsGroupType>)
    );
  }, [data?.valuesStructured, statusFilter]);

  const searchValues = filteredByStatus.filter(
    ({ connectionPointName, internalAccount, timeSeriesMessageDtos }) => {
      const isMatchingGroupInfo =
        connectionPointName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        internalAccount.toLowerCase().includes(searchKeyword.toLowerCase());
      if (isMatchingGroupInfo) {
        return true;
      } else {
        timeSeriesMessageDtos.filter(({ externalAccount }) =>
          externalAccount.toLowerCase().includes(searchKeyword.toLowerCase()),
        ).length > 0;
      }
    },
  );

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <>
      {data && (
        <>
          <MessageDetailSectionHeader
            messageDirection={data.direction}
            messageName={data.documentIdentifier}
          />
          <PageFeatureContainer>
            <MessageDetailTable data={data} />
            <FilterRow
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              updateStatusFilter={setStatusFilter}
            />
            {data.replyMessageWarnings.length > 0 && (
              <CustomErrorBoxContainer>
                <WarningBox errors={data.replyMessageWarnings} />
              </CustomErrorBoxContainer>
            )}
            {data.replyMessageErrors.length > 0 && (
              <CustomErrorBoxContainer>
                <ErrorBox errors={data.replyMessageErrors} />
              </CustomErrorBoxContainer>
            )}
            <MessageValuesContainer>
              {searchValues.map((messageGroup, key) => (
                <MessageDetailsGroup
                  key={key}
                  gasDayHourDefinition={data.gasDayHourDefinition}
                  messageDetailsGroup={messageGroup}
                />
              ))}
            </MessageValuesContainer>
          </PageFeatureContainer>
        </>
      )}
    </>
  );
};
