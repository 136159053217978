import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageFormContainer } from 'components';
import { AreaForm } from 'features/AreaForm';

interface ParamTypes {
  option: 'create' | 'edit';
  id?: string;
}

export const AreaFormPage: FC = () => {
  const { option, id } = useParams<ParamTypes>();

  return (
    <PageFormContainer>
      <AreaForm option={option} areaId={id} />
    </PageFormContainer>
  );
};
