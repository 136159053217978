import { BalanceGroup, ConnectionPointNominationType } from 'utils/types';

export const calculateConnectionPointHourlyValues = (
  balanceGroups: Array<BalanceGroup>,
): number[] => {
  const combinedTimetables = balanceGroups.map(
    (balanceGroup) => balanceGroup.hourlyValues.timetable,
  );
  const combinedHourlyValues = combinedTimetables.reduce((prev, next) => {
    return next.map((value, index) => {
      if (typeof value === 'string') {
        return (prev[index] || 0) + parseFloat(value);
      }
      return (prev[index] || 0) + value;
    });
  }, []);
  return combinedHourlyValues;
};

export const calculateRowSum = (timetable: number[]): number => {
  return timetable.reduce((a, b) => a + b, 0);
};

const calculateConnectionPointSums = (
  connectionPoints: Array<ConnectionPointNominationType>,
): number[][] => {
  return connectionPoints.map(({ balanceGroups }) =>
    calculateConnectionPointHourlyValues(balanceGroups),
  );
};

export const calculateColumnsSum = (
  connectionPoints: Array<ConnectionPointNominationType>,
): number[] => {
  const rowValues = calculateConnectionPointSums(connectionPoints);
  return rowValues.reduce((r: number[], a) => {
    a.forEach((b, i) => {
      r[i] = (r[i] || 0) + b;
    });
    return r;
  }, []);
};

export const calculateTimetableSumValues = (
  connectionPoints: Array<ConnectionPointNominationType>,
): number[] => {
  const connectionPointsSum = calculateConnectionPointSums(connectionPoints);
  return connectionPointsSum.map((connectionPointsSum) => calculateRowSum(connectionPointsSum));
};

export const searchBalanceGroups = (searchKeyword: string, balanceGroups: Array<BalanceGroup>) =>
  balanceGroups.filter(
    (balanceGroup) =>
      balanceGroup.counterparty.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      balanceGroup.destinationName.toLowerCase().includes(searchKeyword.toLowerCase()),
  );
