import {
  StatusFilter,
  ClassicButton,
  VARIANT,
  MultiSelectInput,
  FilterButton,
  ComboFilterContainer,
  FiltersContainer,
} from 'components';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { NominationSearch } from './NominationsSearch';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeStatusFilter, clearFilters, setSelectFilter } from '../nominationReduxSlice';
import { MarketAreaType } from 'features/Nominations/types';
import { StatusOptions } from 'utils/types';

const CleanFiltersButton = styled(ClassicButton)`
  margin-left: 0.5rem;
  margin-right: 1rem;
`;

const extractCounterparties = (areas?: Array<MarketAreaType>): string[] => {
  if (areas) {
    return areas.reduce<string[]>((acc, cur) => {
      const connectionPoints = cur.connectionPoints.map(
        (connectionPoint) => connectionPoint.name,
        [],
      );
      return [...acc, ...connectionPoints] as string[];
    }, []);
  } else {
    return [];
  }
};

const extractBalanceGroups = (areas?: Array<MarketAreaType>): string[] => {
  if (areas) {
    return areas
      .reduce<string[]>((acc, cur) => {
        const balanceGroups = cur.connectionPoints.map((connectionPoint) =>
          connectionPoint.balanceGroups.map((balanceGroup) => balanceGroup.destinationName),
        );
        return [...acc, ...balanceGroups] as string[];
      }, [])
      .flat();
  } else {
    return [];
  }
};

export const ComboFilter: FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useAppDispatch();
  const { searchKeyword, filterActive, areas, selectFilters } = useAppSelector(
    (state) => state.nominationReducer,
  );

  const selectConnectionPoints = [...new Set(extractCounterparties(areas))];
  const selectBalancegroups = [...new Set(extractBalanceGroups(areas))];

  const updateStatus = (options: StatusOptions) => {
    dispatch(changeStatusFilter({ options }));
  };

  const updateFilter = (name: 'connectionPoint' | 'balanceGroup') => (options: string[]) => {
    dispatch(setSelectFilter({ filterName: name, options }));
  };

  const anyFiltersApplied = searchKeyword.length > 0 || selectFilters.length > 0;

  return (
    <ComboFilterContainer>
      <FiltersContainer isExpanded={isExpanded}>
        <NominationSearch />
        {isExpanded && (
          <>
            <MultiSelectInput
              placeholder="Connection point"
              options={selectConnectionPoints}
              onOptionSelect={updateFilter('connectionPoint')}
              isFrameless
              filtersCleared={!filterActive}
              width={220}
            />
            <MultiSelectInput
              placeholder="Balance group"
              options={selectBalancegroups}
              onOptionSelect={updateFilter('balanceGroup')}
              isFrameless
              filtersCleared={!filterActive}
              width={220}
            />
            <StatusFilter updateFilter={updateStatus} isFrameless filtersCleared={!filterActive} />
          </>
        )}
      </FiltersContainer>
      {isExpanded && (
        <CleanFiltersButton
          variant={VARIANT.UNDERLINE}
          disabled={!anyFiltersApplied}
          onClick={() => dispatch(clearFilters())}
          width="10rem"
        >
          Clear all filters
        </CleanFiltersButton>
      )}
      <FilterButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
    </ComboFilterContainer>
  );
};
