import { FC } from 'react';
import { SortTypeToggle } from './SortTypeToggle';
import { StyledDatePicker, TopControlRowContainer } from 'components';

export interface TopControlRowProps {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
}

export const TopControlRow: FC<TopControlRowProps> = ({ selectedDate, setSelectedDate }) => {
  return (
    <TopControlRowContainer>
      <SortTypeToggle />
      <StyledDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
    </TopControlRowContainer>
  );
};
