import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { unselecteAll } from './messagesSlice';
import { useApiGet, API } from 'utils/hooks';
import { setError } from 'features/ErrorScreen/errorSlice';
import { Message, SortType } from 'utils/types/generalTypes';
import { formatDateForInput } from 'utils/dateFormating';

export interface Messages {
  content: Array<Message>;
  totalPages: number;
  totalElements: number;
}

export const useMessages = (
  selectedDate: Date,
  activeSort: SortType,
  searchKeyword: string,
  filters: string,
) => {
  const { sortType, selectedPage, amountOutItemsPerPage } = useAppSelector(
    (state) => state.messageReducer,
  );
  const formatedStartDate = formatDateForInput(selectedDate);
  const sortTypeNumber = sortType === 'creationDate' ? 'SENT' : 'GAS';

  const fetchMessages = useApiGet(
    `/message?size=${amountOutItemsPerPage}&page=${
      selectedPage - 1
    }&startDate=${formatedStartDate}&endDate=${formatedStartDate}&type=${sortTypeNumber}${filters}${
      activeSort.name !== '' && activeSort.direction !== 'none'
        ? `&sort=${activeSort.name},${activeSort.direction}`
        : ''
    }${searchKeyword.length > 0 ? `&search=${searchKeyword}` : ''}`,
  );
  return useQuery<Messages, Error>(
    [
      'messages',
      selectedDate,
      activeSort,
      searchKeyword,
      sortType,
      filters,
      selectedPage,
      amountOutItemsPerPage,
    ],
    fetchMessages,
    { keepPreviousData: true, refetchInterval: 30000 },
  );
};

export const useResendMessages = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const { selected } = useAppSelector((state) => state.messageReducer);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  return useMutation(
    (createNewVersion: boolean) => {
      if (token.length && selected.length > 0) {
        const messageIds = selected.map((selected) => selected.id);
        return API.PUT(`/messages/resend?createNewVersion=${createNewVersion}`, {
          headers: {
            Authorization: token,
          },
          body: JSON.stringify(messageIds),
        });
      }
      return Promise.reject();
    },
    {
      onError: (error: Error) => {
        dispatch(setError({ errorMessage: error.toString() }));
      },
      onSuccess: async () => {
        queryClient.invalidateQueries('messages');
        dispatch(unselecteAll());
      },
    },
  );
};

export const useSenders = (selectedDate: Date) => {
  const formatedStartDate = formatDateForInput(selectedDate);
  const fetchSenders = useApiGet(
    `/message/sender?startDate=${formatedStartDate}&endDate=${formatedStartDate}&type=1`,
  );
  return useQuery<Array<{ sender: string }>, Error>(['senders', selectedDate], fetchSenders);
};
export const useRecipients = (selectedDate: Date) => {
  const formatedStartDate = formatDateForInput(selectedDate);
  const fetchRecipients = useApiGet(
    `/message/recipient?startDate=${formatedStartDate}&endDate=${formatedStartDate}&type=0`,
  );
  return useQuery<Array<{ recipient: string }>, Error>(
    ['recipients', selectedDate],
    fetchRecipients,
  );
};
