import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  ClassicButton,
  VARIANT,
  ConfirmationPopup,
  HeadingBar,
  FormTitle,
  ContentContainer,
  FormContainer,
  ActionBar,
  ConfirmationPopupText,
  MainContainer,
  StyledTextInput,
  FieldContainerWithLabel,
  DatePickerContainer,
  ControlledDatePicker,
  ActivationSwitchContainer,
  ToggleSwitch,
  ActivationStyledLabel,
  ControlledSelectInput,
  CheckboxCotainer,
  ControlledCheckbox,
  FormMetadataBox,
  FormSection,
  FormErrorBox,
  GlobalErrorModal,
} from 'components';
import { FieldValues, useForm } from 'react-hook-form';
import { useAppDispatch } from 'store/hooks';
import { openModal } from 'store/modalSlice';
import { LoadingScreen } from 'components/LoadingScreen';
import { getInitialDate } from 'utils/getInitialDate';
import {
  useNominationTrackSave,
  useNominationTrackSlim,
  useConnectionPoint,
  useBalancegroupList,
  NominationTrackSlimType,
} from './nominationTrackApi';
import { MainCustomErrorType, SelectOptionType } from 'utils/types';
import styled from 'styled-components';
import { formatDateForInput } from 'utils/dateFormating';
import { InternalAccountSelectField } from './InternalAccountSelectField';
import { ErrorConfirmationModal } from './components/ErrorConfirmationModal';
import { NominationTypeSelectField } from './components/NominationTypeSelectField';

const FlexContainerWithSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`; //TODO REFACTOR THIS LAYOUT TO SOMETHING MORE TOUGHTFULL

interface NominationTrackFormProp {
  option: 'edit' | 'create';
  nominationTrackId?: string;
  messageDefinitionId: string;
  edigasBaseVerion: string;
}

export const nominatioTrackInputWidth = '24rem';

export const NominationTrackForm: FC<NominationTrackFormProp> = ({
  option,
  nominationTrackId,
  messageDefinitionId,
  edigasBaseVerion,
}) => {
  const dispatch = useAppDispatch();
  const [isActive, setIsActive] = useState(true);
  const [generalApiValidationError, setGeneralApiValidationError] = useState<MainCustomErrorType>();
  const { data, isLoading } = useNominationTrackSlim(nominationTrackId);
  const { data: connectionPoints } = useConnectionPoint();
  const { data: balanceGroups } = useBalancegroupList();
  const {
    register,
    reset,
    getValues,
    formState: { isDirty, isValid },
    control,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      externalReference: '',
      validFrom: getInitialDate(),
      validTo: new Date('2099-09-30'),
      doNotCopy: false,
      excludeInOutgoingMessage: false,
    } as FieldValues,
  });
  const history = useHistory();
  const setFormError = (error: MainCustomErrorType) => {
    setGeneralApiValidationError(error);
  };
  const { mutate: nominationTrackSave, isLoading: nominationTrackIsSaving } =
    useNominationTrackSave(option, setFormError);

  const submitChanges = (deleteInvalidTimeSeries = false) => {
    const {
      externalReference,
      connectionPoint,
      internalAccount,
      externalAccount,
      doNotCopy,
      validFrom,
      validTo,
      excludeInOutgoingMessage,
      nominationType,
    } = getValues();

    setGeneralApiValidationError(undefined);

    const sendObject = {
      externalReference,
      connectionPointId: connectionPoint.id,
      internalAccountId: internalAccount.id,
      externalAccountId: externalAccount.id,
      active: isActive,
      messageDefinitionId: Number.parseInt(messageDefinitionId),
      doNotCopy,
      excludeInOutgoingMessage,
      validFrom: formatDateForInput(validFrom),
      validTo: formatDateForInput(validTo),
      nominationTypeId: nominationType.id,
      ...(nominationTrackId && { id: Number.parseInt(nominationTrackId) }),
    } as NominationTrackSlimType;

    if (option === 'edit' && data) {
      nominationTrackSave({
        data: {
          ...data,
          ...sendObject,
        },
        deleteInvalidTimeSeries: deleteInvalidTimeSeries,
      });
    } else {
      nominationTrackSave({ data: sendObject, deleteInvalidTimeSeries: deleteInvalidTimeSeries });
    }
  };

  useEffect(() => {
    if (option === 'edit' && data && connectionPoints && balanceGroups) {
      const {
        doNotCopy,
        externalReference,
        validFrom,
        validTo,
        excludeInOutgoingMessage,
        nominationType,
      } = data;
      const currentConnectionPoint = connectionPoints.content.find(
        (connectionPoint) => connectionPoint.id === data.connectionPointId,
      );
      const currentIntenalAccount = balanceGroups.content.find(
        (balanceGroup) => balanceGroup.id === data.internalAccountId,
      );
      const currentExternalAccount = balanceGroups.content.find(
        (balanceGroup) => balanceGroup.id === data.externalAccountId,
      );

      reset({
        doNotCopy,
        connectionPoint: currentConnectionPoint,
        internalAccount: currentIntenalAccount,
        externalAccount: currentExternalAccount,
        externalReference: externalReference,
        validFrom: new Date(validFrom),
        validTo: new Date(validTo),
        excludeInOutgoingMessage,
        nominationType,
      });
      setIsActive(data.active);
    }
  }, [data, connectionPoints, balanceGroups]);

  const selectedConnectionPoint = watch('connectionPoint');

  return (
    <MainContainer>
      <GlobalErrorModal />
      <ContentContainer width="60%">
        <HeadingBar>
          <FormTitle>
            {option === 'create' ? 'Add new Nomination track' : 'Edit Nomination track'}
          </FormTitle>
        </HeadingBar>
        {option === 'edit' && isLoading ? (
          <LoadingScreen />
        ) : (
          <FormContainer>
            <FlexContainerWithSpaceBetween>
              <FormSection>
                <FieldContainerWithLabel>
                  <p>Connection point</p>
                  <ControlledSelectInput
                    required
                    control={control}
                    width={nominatioTrackInputWidth}
                    placeholder="Choose"
                    options={connectionPoints?.content as unknown as Array<SelectOptionType>}
                    name="connectionPoint"
                  />
                </FieldContainerWithLabel>
                <InternalAccountSelectField
                  control={control}
                  isDisabled={!selectedConnectionPoint?.area}
                  area={selectedConnectionPoint?.area}
                />
                <FieldContainerWithLabel>
                  <p>External Account</p>
                  <ControlledSelectInput
                    required
                    control={control}
                    width={nominatioTrackInputWidth}
                    placeholder="Choose"
                    options={balanceGroups?.content as unknown as Array<SelectOptionType>}
                    name="externalAccount"
                    accountSelect
                  />
                </FieldContainerWithLabel>
                <StyledTextInput
                  type="text"
                  label="External Reference"
                  placeholder="e.g. RWE@THE_VTP_H"
                  register={register('externalReference')}
                  width={nominatioTrackInputWidth}
                  isLabelBold
                />
                <NominationTypeSelectField
                  control={control}
                  isDisabled={false}
                  edigasVersion={edigasBaseVerion}
                />
                <FieldContainerWithLabel>
                  <p>Status</p>
                  <ActivationSwitchContainer>
                    <ToggleSwitch isChecked={isActive} onChange={() => setIsActive(!isActive)} />
                    <ActivationStyledLabel>
                      {isActive ? 'Active' : 'Inactive'}
                    </ActivationStyledLabel>
                  </ActivationSwitchContainer>
                </FieldContainerWithLabel>
                <FieldContainerWithLabel>
                  <p>Valid</p>
                  <DatePickerContainer>
                    <label>From:</label>
                    <ControlledDatePicker name="validFrom" control={control} required />
                  </DatePickerContainer>
                  <DatePickerContainer>
                    <label>To:</label>
                    <ControlledDatePicker name="validTo" control={control} />
                  </DatePickerContainer>
                </FieldContainerWithLabel>
                <CheckboxCotainer>
                  <ControlledCheckbox name="doNotCopy" control={control} />
                  <label>Exclude when copying values from - to</label>
                </CheckboxCotainer>
                <CheckboxCotainer>
                  <ControlledCheckbox name="excludeInOutgoingMessage" control={control} />
                  <label>Exclude in outgoing message</label>
                </CheckboxCotainer>
              </FormSection>
              {option === 'edit' && data && (
                <FormMetadataBox
                  fipDatCreated={data.fipDatCreated}
                  fipDate={data.fipDate}
                  fipUser={data.fipUser}
                  fipUserCreated={data.fipUserCreated}
                />
              )}
            </FlexContainerWithSpaceBetween>
            {generalApiValidationError && <FormErrorBox error={generalApiValidationError} />}
          </FormContainer>
        )}
        <ActionBar>
          <ClassicButton
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={() => history.push('/app/message-definitions/')}
          >
            Cancel
          </ClassicButton>
          <ClassicButton
            width="10rem"
            variant={VARIANT.PURPLE}
            onClick={() => dispatch(openModal({ modalId: 'nominationTrackConfirmationModal' }))}
            disabled={!isDirty || !isValid}
          >
            {option === 'edit' ? 'Save' : 'Add'}
          </ClassicButton>
        </ActionBar>
      </ContentContainer>
      <ConfirmationPopup
        isLoading={nominationTrackIsSaving}
        confirmationPopupId="nominationTrackConfirmationModal"
        confirmButtonAction={() => submitChanges(false)}
      >
        <ConfirmationPopupText>
          {option === 'create' ? (
            <>
              Shall we go ahead and save <span>{getValues().name}</span> as a new nomination track?{' '}
            </>
          ) : (
            <>
              Shall we go ahead and update the Nomination track <span>{getValues().name}</span>{' '}
            </>
          )}
        </ConfirmationPopupText>
      </ConfirmationPopup>
      <ErrorConfirmationModal isLoading={isLoading} submitChanges={submitChanges} />
    </MainContainer>
  );
};
