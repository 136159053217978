import { useQuery } from 'react-query';
import { IdentifierType, SelectOptionType } from 'utils/types';
import { useApiGet } from 'utils/hooks';

interface IndentifierTypes {
  content: Array<IdentifierType>;
}
export const useIdentifier = (shouldFetch = true) => {
  const fetchIdentifier = useApiGet('/identifier-type?sort=name,asc');
  return useQuery<IndentifierTypes, Error>(['identifiers', shouldFetch], fetchIdentifier, {
    enabled: shouldFetch,
  });
};

export const prepareIdentifierCodingSchemaList = (
  identifiers?: IndentifierTypes,
): Array<SelectOptionType> =>
  identifiers
    ? identifiers.content.map((identifierType) => ({
        id: identifierType.id,
        name: identifierType.scheme,
      }))
    : [{ id: 0, name: '' }];
