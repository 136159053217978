import {
  ControlledSelectInput,
  FieldContainerWithLabel,
  FormContainer,
  FormSection,
  FormSectionTitle,
  FormTitle,
  StyledTextInput,
} from 'components';
import { FC } from 'react';
import { Control, FieldValues, UseFormRegister } from 'react-hook-form';
import { useIdentifier } from 'utils/hooks/useIdentifier';
import { HeadingBar, StepLabel } from '../styled';
import { EdigasSelectField } from './EdigasSelectField';
import { MarketRoleSelectField } from './MarketRoleSelectField';
import { DocumentTypeSelectField } from './DocumentTypeSelectField';
import { ContractTypeSelectField } from './ContractTypeSelectField';
import { MarketParticipantSelectField } from './MarketParticipantSelectField';
import { EdigasVersionType, RoleType, SelectOptionType } from 'utils/types';

interface DocumentAttributesFormProps {
  isDisabled: boolean;
  register: UseFormRegister<FieldValues>;
  control: Control;
  roleType?: RoleType;
  edigasVersion: EdigasVersionType;
}
export const DocumentAttributesForm: FC<DocumentAttributesFormProps> = ({
  isDisabled,
  control,
  register,
  edigasVersion,
  roleType,
}) => {
  const isEdigasVersionSelected = edigasVersion && edigasVersion.id !== 0;
  const cleanIdentifiers = useIdentifier();
  const schemaList = cleanIdentifiers.data?.content as unknown as Array<SelectOptionType>;

  // TODO: Implement smarter field width so there is no need to change them for every field
  return (
    <section id="document-attributes">
      <HeadingBar>
        <FormTitle>Document Attributes</FormTitle>
        <StepLabel>Step 2</StepLabel>
      </HeadingBar>
      <FormContainer>
        <form>
          <FormSection>
            <EdigasSelectField control={control} isDisabled={isDisabled} />
            <DocumentTypeSelectField
              control={control}
              isDisabled={isDisabled || !isEdigasVersionSelected}
              edigasVersion={edigasVersion?.version.baseVersion}
            />

            <StyledTextInput
              type="text"
              label="Contract reference"
              placeholder="e.g. dispoGas"
              register={register('contractReference')}
              width="19.5rem"
              isLabelBold
              isDisabled={isDisabled}
            />
            <ContractTypeSelectField
              control={control}
              isDisabled={isDisabled || !isEdigasVersionSelected}
              edigasVersion={edigasVersion?.version.baseVersion}
            />
          </FormSection>
          <FormSection>
            <FormSectionTitle>Issuer Market Participant</FormSectionTitle>
            <MarketParticipantSelectField
              control={control}
              name="issuerMarketParticipant"
              isDisabled={isDisabled}
              roleType={roleType}
            />
            <FieldContainerWithLabel isDisabled={isDisabled}>
              <p>Identifier Type</p>
              <ControlledSelectInput
                required
                control={control}
                width="19.5rem"
                placeholder="Choose"
                options={schemaList}
                name="issuerMarketParticipantIdentifierType"
                isDisabled={isDisabled}
                identifierSelect
              />
            </FieldContainerWithLabel>

            <MarketRoleSelectField
              edigasVersion={edigasVersion?.version.baseVersion}
              control={control}
              isDisabled={isDisabled || !isEdigasVersionSelected}
              name="issuerMarketParticipantMarketRole"
            />
          </FormSection>
          <FormSection>
            <FormSectionTitle>Recipient Market Participant</FormSectionTitle>
            <MarketParticipantSelectField
              control={control}
              name="recipientMarketParticipant"
              isDisabled={isDisabled}
              roleType={roleType}
            />
            <FieldContainerWithLabel isDisabled={isDisabled}>
              <p>Identifier Type</p>
              <ControlledSelectInput
                required
                control={control}
                width="19.5rem"
                placeholder="Choose"
                options={schemaList}
                name="recipientMarketParticipantIdentifierType"
                isDisabled={isDisabled}
                identifierSelect
              />
            </FieldContainerWithLabel>
            <MarketRoleSelectField
              edigasVersion={edigasVersion?.version.baseVersion}
              control={control}
              isDisabled={isDisabled || !isEdigasVersionSelected}
              name="recipientMarketParticipantMarketRole"
            />
          </FormSection>
        </form>
      </FormContainer>
    </section>
  );
};
