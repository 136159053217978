import { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { SearchInput } from 'components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { searchNominations } from '../nominationReduxSlice';
import styled from 'styled-components';

const SearchForm = styled.form`
  flex: 1;
`;

export const NominationSearch: FC = () => {
  const { searchKeyword } = useAppSelector((state) => state.nominationReducer);
  const { register, watch, reset, getValues } = useForm({
    defaultValues: { nominationSearchKeyword: searchKeyword || '' },
  });
  const dispatch = useAppDispatch();
  const nominationSearchKeyword = watch('nominationSearchKeyword');

  const handleUpdate = useCallback(() => {
    const { nominationSearchKeyword } = getValues();

    dispatch(searchNominations({ searchKeyword: nominationSearchKeyword }));
  }, [searchNominations]);

  const resetSearch = () => {
    reset({
      nominationSearchKeyword: '',
    });
    handleUpdate();
  };

  useEffect(() => {
    reset({
      nominationSearchKeyword: searchKeyword,
    });
  }, [searchKeyword]);
  return (
    <SearchForm>
      <SearchInput
        register={register('nominationSearchKeyword')}
        placeholder="Search"
        handleUpdate={handleUpdate}
        value={nominationSearchKeyword}
        reset={resetSearch}
        isFrameless
      />
    </SearchForm>
  );
};
