import { FC } from 'react';
import { PageContainer, SectionTitle } from 'components';
import { MasterdataPanel } from 'features/MasterdataPanel';

export const MasterdataPage: FC = () => {
  return (
    <PageContainer>
      <SectionTitle section="masterdataPage" />

      <MasterdataPanel />
    </PageContainer>
  );
};
