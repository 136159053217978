import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  mail: string;
  token: string;
  authorities: string[];
  firstName: string;
  lastName: string;
}

const initialState = {
  mail: '',
  token: '',
  authorities: [],
  firstName: '',
  lastName: '',
} as AuthState;

interface AuthPayload {
  token: string;
  mail: string;
}

interface UserData {
  authorities: string[];
  firstName: string;
  lastName: string;
}

const authSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    authorizeUser(state, action: PayloadAction<AuthPayload>) {
      const { token, mail } = action.payload;
      state.token = token;
      state.mail = mail;
    },
    setUserData(state, action: PayloadAction<UserData>) {
      const { authorities, firstName, lastName } = action.payload;
      state.authorities = authorities;
      state.firstName = firstName;
      state.lastName = lastName;
    },
    logOut(state) {
      state.mail = '';
      state.token = '';
    },
  },
});

export const { authorizeUser, logOut, setUserData } = authSlice.actions;

export default authSlice.reducer;
