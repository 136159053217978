import { ControlledSelectInput, FieldContainerWithLabel } from 'components';
import { FC, useContext } from 'react';
import { Control } from 'react-hook-form';
import { SelectOptionType } from 'utils/types';
import { useMarketRole } from '../messageDefinitionFormApi';
import { SetErrorContext } from '../index';

interface MarketRoleSelectFieldProps {
  control: Control;
  isDisabled: boolean;
  name: string;
  edigasVersion?: string;
}

export const MarketRoleSelectField: FC<MarketRoleSelectFieldProps> = ({
  control,
  isDisabled,
  name,
  edigasVersion,
}) => {
  const { data: marketRoles } = useMarketRole(edigasVersion);
  const setError = useContext(SetErrorContext)?.setError;

  return (
    <FieldContainerWithLabel isDisabled={isDisabled}>
      <p>Market Role code</p>
      <ControlledSelectInput
        required
        control={control}
        width="19.5rem"
        placeholder="Choose"
        options={marketRoles && (marketRoles as unknown as Array<SelectOptionType>)}
        name={name}
        isDisabled={isDisabled}
        setError={setError}
      />
    </FieldContainerWithLabel>
  );
};
