import { ControlledSelectInput, FieldContainerWithLabel } from 'components';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { SelectOptionType } from 'utils/types';
import { useNominationType } from '../nominationTrackApi';

interface NominationTypeSelectFieldProps {
  control: Control;
  isDisabled: boolean;
  edigasVersion?: string;
}

export const NominationTypeSelectField: FC<NominationTypeSelectFieldProps> = ({
  control,
  isDisabled,
  edigasVersion,
}) => {
  const { data: nominationTypes } = useNominationType(edigasVersion);
  return (
    <FieldContainerWithLabel isDisabled={isDisabled}>
      <p>Nomination type</p>
      <ControlledSelectInput
        required
        control={control}
        placeholder="Choose"
        options={nominationTypes as unknown as Array<SelectOptionType>}
        name="nominationType"
        isDisabled={isDisabled}
      />
    </FieldContainerWithLabel>
  );
};
