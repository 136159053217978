import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MessageDefinitions, NominationTracks } from './types';
import { API, useApiGet } from 'utils/hooks';
import { MarketParticipantSlimType, MessageType, SelectOptionType } from 'utils/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { translateFiltersToPath } from 'utils/translateFiltersToPath';
import { CustomErrorType, SortType } from 'utils/types/generalTypes';
import { closeModal, openModal } from 'store/modalSlice';

const constructSortForMessageDefinitions = (activeSort: SortType): string => {
  if (activeSort.name !== '' && activeSort.direction !== 'none') {
    if (activeSort.name === 'area') {
      return `&sort=area,${activeSort.direction}&sort=description,${activeSort.direction}&sort=messageType,${activeSort.direction}`;
    } else {
      return `&sort=${activeSort.name},${activeSort.direction}`;
    }
  }
  return '';
};

export const useMessageDefinitions = (activeSort: SortType) => {
  const { filters, searchPhrase } = useAppSelector((state) => state.messageDefinitionSlice);
  const translatedFilters = translateFiltersToPath(filters);
  const sort = constructSortForMessageDefinitions(activeSort);

  const fetchMessageDefinitions = useApiGet(
    `/message-definition?size=0${sort}${translatedFilters}${
      searchPhrase.length > 0 ? `&search=${searchPhrase}` : ''
    }`,
  );
  return useQuery<MessageDefinitions, Error>(
    ['message-definitions', translatedFilters, searchPhrase, activeSort],
    fetchMessageDefinitions,
  );
};

export const useNominationTracks = (messageDefId: number) => {
  const fetchNominationTracks = useApiGet(
    `/nomination-track/message-definition/${messageDefId}?size=0`,
  );
  return useQuery<NominationTracks, Error>(
    [`nomination-tracks-${messageDefId}`],
    fetchNominationTracks,
  );
};

export const useAreasSlimList = () => {
  const fetchAreaSlimList = useApiGet('/less/area');
  return useQuery<Array<SelectOptionType>, Error>(['areaList'], fetchAreaSlimList);
};

export const useMessageType = () => {
  const fetchMessageTypes = useApiGet('/message-type');
  return useQuery<Array<MessageType>, Error>(['messageType'], fetchMessageTypes);
};

export const useMarketParticipant = () => {
  const fetchMarketParticipant = useApiGet(`/market-participant`);
  return useQuery<Array<MarketParticipantSlimType>, Error>(
    ['marketParticipant'],
    fetchMarketParticipant,
  );
};

export const useNominationTrackRemoval = (messageDefinitionId?: number) => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  return useMutation(
    (nominationTrackId: number) => {
      if (token.length) {
        return API.DELETE(`/nomination-track/${nominationTrackId}`, {
          headers: {
            Authorization: token,
          },
        });
      }
      return Promise.reject();
    },
    {
      onError: (error: CustomErrorType) => {
        dispatch(
          openModal({
            modalId: 'deletionErrorModal',
            props: { errors: error?.errors },
          }),
        );
      },
      onSuccess: async () => {
        dispatch(closeModal({ modalId: 'nominationTrackRemovalConfirmationModal' }));
        queryClient.invalidateQueries(`nomination-tracks-${messageDefinitionId}`);
      },
    },
  );
};
