import { Pagination, SelectInput } from 'components';
import { setAmountOutItemsPerPage, setPage } from '../messagesSlice';
import { FC, useContext } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled, { ThemeContext } from 'styled-components';
import { SelectOptionType } from 'utils/types';

const PaginationRowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${({ theme }) => theme.divTableHeadingBg};
  height: 3.5rem;
`;

const PaginationSelectContainer = styled.div`
  margin-right: 2rem;
  display: flex;
  align-items: center;
  span {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
`;

const PageSelectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;
  margin-right: 1rem;

  span {
    margin-right: 0.5rem;
  }
`;

const AmountOfItemsInfoContainer = styled.div`
  margin-right: 2rem;
`;

const paginationOptions = [
  { id: 1, name: '10' },
  { id: 2, name: '15' },
  { id: 3, name: '20' },
  { id: 4, name: '50' },
  { id: 5, name: '100' },
  { id: 6, name: 'All' },
] as SelectOptionType[];

interface PaginationRowProps {
  currentPage: number;
  totalPages: number;
  totalElements: number;
}

export const PaginationRow: FC<PaginationRowProps> = ({
  currentPage,
  totalPages,
  totalElements,
}) => {
  const theme = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const { amountOutItemsPerPage } = useAppSelector((state) => state.messageAckReducer);
  const handleAmountOfDisplayedElementsChange = (option: SelectOptionType) => {
    if (option.name) {
      dispatch(setAmountOutItemsPerPage(option.name !== 'All' ? parseInt(option.name) : 0));
    }
  };

  const handlePageChangeWithSelectInput = (option: SelectOptionType) => {
    if (option.name) {
      dispatch(setPage(parseInt(option.name)));
    }
  };
  const currentItemsMin = (currentPage - 1) * amountOutItemsPerPage + 1;
  const currentItemsMax =
    currentPage < totalPages ? currentPage * amountOutItemsPerPage : totalElements;

  return (
    <PaginationRowContainer>
      <PaginationSelectContainer>
        <span>Show</span>
        <SelectInput
          selectOption={handleAmountOfDisplayedElementsChange}
          options={paginationOptions}
          preselectedOption={paginationOptions.find(
            (element) =>
              element.name === amountOutItemsPerPage.toString() ||
              (amountOutItemsPerPage === 0 && element.name === 'All'),
          )}
          placeholder="Selelect amout per page"
          width="5rem"
          bgColor={theme.divTableHeadingBg}
          openUpwards
        />
        <span>messages</span>
      </PaginationSelectContainer>
      <AmountOfItemsInfoContainer>
        Showing {currentItemsMin} to {currentItemsMax} of {totalElements} items
      </AmountOfItemsInfoContainer>
      {/* {totalPages > 1 && ( */}
      <>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={(page: number) => dispatch(setPage(page))}
          numberOfPages={totalPages}
        />
        <PageSelectContainer>
          <span>Page</span>
          <SelectInput
            selectOption={handlePageChangeWithSelectInput}
            options={Array.from({ length: totalPages }, (_, i) => i + 1).map(
              (page) => ({ id: page, name: `${page}` } as SelectOptionType),
            )}
            preselectedOption={{ id: currentPage, name: `${currentPage}` }}
            placeholder="Selelect page"
            width="5rem"
            bgColor={theme.divTableHeadingBg}
            openUpwards
          />
        </PageSelectContainer>
      </>
      {/* )} */}
    </PaginationRowContainer>
  );
};
