import { FC, useState, useEffect, useCallback } from 'react';
import {
  SelectButton,
  SelectContainer,
  OptionsContainer,
  SelectOption,
  Icon,
  PlaceholderText,
  SelectedText,
  SearchContainer,
} from './styled';
import { useClickOutside } from 'utils/hooks';
import {
  Controller,
  Control,
  useForm,
  UseFormSetError,
  FieldValues,
  FieldError,
} from 'react-hook-form';
import { SearchInput } from 'components';
import { InputError } from '../InputError';
import { TimeZone } from 'timezones-list';

interface SelectInputProps {
  selectOption?: (option: TimeZone) => void; // TODO: this could probably be replaced with form functions across whole app
  options?: TimeZone[];
  placeholder: string;
  width?: string;
  preselectedOption?: TimeZone;
  isFrameless?: boolean;
  filtersCleared?: boolean;
  isDisabled?: boolean;
  identifierSelect?: boolean; // This flag is used when we want to select identifiers it enables displaying both schema and identifier name
  accountSelect?: boolean; // This flag is used when we want to select accounts it enables displaying account and balancegroup name
  setError?: UseFormSetError<FieldValues>;
  fieldName?: string;
  error?: FieldError;
  bgColor?: string;
  openUpwards?: boolean;
}

export const TimezoneSelectInput: FC<SelectInputProps> = ({
  preselectedOption,
  selectOption,
  options = [{ name: 'CET', tzCode: '', label: '', utc: '' }],
  placeholder,
  width,
  isFrameless,
  filtersCleared,
  isDisabled = false,
  identifierSelect = false,
  accountSelect = false,
  setError,
  fieldName,
  error,
  bgColor,
  openUpwards,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState<TimeZone>({
    tzCode: '',
    name: 'CET',
    utc: '',
    label: '',
  });
  const [searchKeyword, setSearchKeyword] = useState('');
  const closeOptionsContainer = () => setIsExpanded(false);
  const ref = useClickOutside(closeOptionsContainer);
  const { register, reset, getValues, watch } = useForm({
    defaultValues: {
      selectInputSearch: '',
    },
  });

  const setOption = (option: TimeZone) => {
    setSelectedOption(option);
    selectOption && selectOption(option);
    closeOptionsContainer();
  };

  useEffect(() => {
    if (preselectedOption) {
      setSelectedOption(preselectedOption);
    }
  }, [preselectedOption]);

  useEffect(() => {
    if (filtersCleared) {
      setSelectedOption({
        tzCode: '',
        name: 'CET',
        utc: '',
        label: '',
      });
    }
  }, [filtersCleared]);

  const selectInputSearchValue = watch('selectInputSearch');

  const handleUpdate = useCallback(() => {
    const { selectInputSearch } = getValues();
    setSearchKeyword(selectInputSearch.toLowerCase());
  }, [setSearchKeyword]);

  const optionsToDisplay =
    searchKeyword.length > 2 && options
      ? options.filter(({ name }) => name?.toLowerCase().includes(searchKeyword))
      : options;

  useEffect(() => {
    const isSelectedOptionValid = options.some(
      (option) =>
        option.name === '' ||
        option.tzCode === selectedOption.tzCode ||
        selectedOption.tzCode === '',
    );

    if (!isSelectedOptionValid && fieldName && setError && options.length > 0) {
      setError(fieldName, {
        message: 'This value is no longer valid, please select different value',
      });
    }
  }, [options, selectedOption]);

  const buildOptionName = (option: TimeZone): string => {
    if (identifierSelect) {
      return `${option.name.replace('GMT', 'UTC')}`;
    }

    if (accountSelect) {
      return `${option.name.replace('GMT', 'UTC')}`;
    }

    return option && option.name ? option.name.replace('GMT', 'UTC') : '';
  };

  return (
    <SelectContainer ref={ref} width={width} bgColor={bgColor}>
      <SelectButton
        type="button"
        onClick={() => setIsExpanded(!isExpanded)}
        isFrameless={isFrameless}
        disabled={isDisabled}
        isOpen={isExpanded}
        isError={!!error}
      >
        {selectedOption.tzCode === '' && <PlaceholderText>{placeholder}</PlaceholderText>}
        {selectedOption.tzCode !== '' && (
          <SelectedText>{buildOptionName(selectedOption)}</SelectedText>
        )}
        <Icon $isOpen={isExpanded} />
      </SelectButton>
      {!!error && <InputError>{error.message}</InputError>}
      {isExpanded && (
        <OptionsContainer containerSize={options.length} openUpwards={openUpwards}>
          {options.length > 9 && (
            <SearchContainer>
              <SearchInput
                register={register('selectInputSearch')}
                reset={() => {
                  reset();
                  setSearchKeyword('');
                }}
                handleUpdate={handleUpdate}
                placeholder="Search"
                value={selectInputSearchValue}
              />
            </SearchContainer>
          )}
          {optionsToDisplay.map((option) => (
            <SelectOption onClick={() => setOption(option)} key={option.name}>
              {buildOptionName(option)}
            </SelectOption>
          ))}
        </OptionsContainer>
      )}
    </SelectContainer>
  );
};

interface ControlledSelectInputProps extends SelectInputProps {
  name: string;
  control: Control;
  required?: boolean;
  options: TimeZone[];
}

export const ControlledTimezoneSelectInput: FC<ControlledSelectInputProps> = ({
  name,
  options,
  placeholder,
  width,
  control,
  required,
  isDisabled,
  identifierSelect,
  accountSelect,
  setError,
  openUpwards,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TimezoneSelectInput
          options={options}
          placeholder={placeholder}
          selectOption={(option: TimeZone) => onChange(option)}
          preselectedOption={value}
          width={width}
          isDisabled={isDisabled}
          identifierSelect={identifierSelect}
          fieldName={name}
          setError={setError}
          error={error}
          accountSelect={accountSelect}
          openUpwards={openUpwards}
        />
      )}
    />
  );
};
