import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  ClassicButton,
  VARIANT,
  QuantityIndicator,
  ConfirmationPopup,
  FormSection,
  FormSectionTitle,
  HeadingBar,
  FormTitle,
  ContentContainer,
  FormContainer,
  FieldContainerWithLabel,
  StyledTextInput,
  FlexContainer,
  DatePickerContainer,
  ActionBar,
  ConfirmationPopupText,
  MainContainer,
  ControlledDatePicker,
  EditButton,
  ControlledToggleInputWithLabel,
  IdentifiersTable,
  FormMetadataBox,
  ErrorBox,
} from 'components';
import { FieldValues, useForm } from 'react-hook-form';
import { ControlledSelectInput } from 'components/SelectInput';
import { MessageDefinitionModal } from './MessageDefinitionModal';
import { useAppDispatch } from 'store/hooks';
import { openModal } from 'store/modalSlice';
import {
  Identifier,
  SelectOptionType,
  MessageDefinitionSlim,
  MainCustomErrorType,
} from 'utils/types';
import { useAreasSlimList, useBalancegroup, useBalanceGropuSave } from './balancegroupApi';
import { LoadingScreen } from 'components/LoadingScreen';
import { useIdentifier } from 'utils/hooks/useIdentifier';
import { createIdentifierArray } from 'components/IdentifierComboForm';
import styled from 'styled-components';
import { formatDateForInput } from 'utils/dateFormating';

interface BalancegroupFormProps {
  option: 'edit' | 'create';
  counterpartyName: string;
  balanceGroupId?: string;
  counterpartyId: string;
}

const FlexContainerWithSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const BalancegroupForm: FC<BalancegroupFormProps> = ({
  option,
  counterpartyName,
  balanceGroupId,
  counterpartyId,
}) => {
  const [localState, setLocalState] = useState({
    messageDefinitions: [] as Array<MessageDefinitionSlim>,
    identifiers: [] as Array<Identifier>,
    hasChanged: false,
  });
  const currentDate = new Date();
  const [errors, setErrors] = useState<MainCustomErrorType>();

  const updateErrors = (error: MainCustomErrorType) => {
    setErrors(error);
  };

  const dispatch = useAppDispatch();
  const {
    register,
    reset,
    getValues,
    formState: { isDirty, isValid },
    control,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      area: { id: 0, name: '' },
      validFrom: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
      validTo: new Date('2099-09-30'),
      active: true,
    } as FieldValues,
  });
  const history = useHistory();
  const { mutate: balanceGroupSave, isLoading: submitLoading } = useBalanceGropuSave(
    option,
    updateErrors,
  );

  const { data, isLoading } = useBalancegroup(balanceGroupId);
  const areaList = useAreasSlimList();
  const { data: cleanIdentifiers } = useIdentifier();

  useEffect(() => {
    if (option === 'edit' && data) {
      const { area, name, identifiers, validFrom, validTo, active, messageDefinitions } = data;

      reset({
        active,
        name,
        validFrom: new Date(validFrom),
        validTo: new Date(validTo),
        area,
      });

      setLocalState({
        ...localState,
        messageDefinitions,
        identifiers,
      });
    }
    if (option === 'create' && cleanIdentifiers) {
      const newIdentifiers = createIdentifierArray(cleanIdentifiers.content).filter(
        (identifier) => identifier.type.scheme === 'ZSO',
      );
      setLocalState({
        ...localState,
        identifiers: newIdentifiers,
      });
    }
  }, [data, option, cleanIdentifiers]);

  const areaOption = areaList.data as Array<SelectOptionType>;

  const addNewIdentifier = (identifier: Identifier) => {
    setLocalState({
      ...localState,
      identifiers: [...localState.identifiers, identifier],
      hasChanged: true,
    });
  };

  const updateIdentifiers = (identifiers: Array<Identifier>) => {
    setLocalState({
      ...localState,
      identifiers,
      hasChanged: true,
    });
  };

  const { messageDefinitions, hasChanged, identifiers } = localState;
  const submitChanges = () => {
    const { name, validFrom, validTo, area, active } = getValues();

    const sendObject = {
      ...localState,
      area,
      name,
      userDefined: data?.userDefined || true,
      validFrom: formatDateForInput(validFrom),
      validTo: formatDateForInput(validTo),
      id: balanceGroupId ? parseInt(balanceGroupId) : undefined,
      active,
      counterparty: {
        id: parseInt(counterpartyId),
      },
      messageDefinitions,
      identifiers,
    };

    if (option === 'edit' && data) {
      balanceGroupSave({
        ...data,
        ...sendObject,
      });
    } else {
      balanceGroupSave(sendObject);
    }
  };

  const balanceGroupName = watch('name');

  const shouldDisableSubmit = () => {
    if (option === 'edit') {
      return !hasChanged && (!isDirty || !isValid);
    } else {
      return !isDirty || !isValid || !hasChanged;
    }
  };
  return (
    <MainContainer>
      <ContentContainer width="65%">
        <HeadingBar>
          <FormTitle>
            {option === 'create' ? 'Add new balance group' : 'Edit balance group'}
          </FormTitle>
        </HeadingBar>
        {isLoading && option === 'edit' ? (
          <LoadingScreen />
        ) : (
          <FormContainer>
            <FlexContainerWithSpaceBetween>
              <FormSection>
                <FormSectionTitle>Base data</FormSectionTitle>
                <FieldContainerWithLabel labelSpace="0.625rem">
                  <p>Counterparty name</p>
                  {decodeURIComponent(counterpartyName)}
                </FieldContainerWithLabel>
                <FieldContainerWithLabel>
                  <p>Area</p>
                  <ControlledSelectInput
                    width="20rem"
                    control={control}
                    placeholder="Choose"
                    options={areaOption}
                    name="area"
                  />
                </FieldContainerWithLabel>
                <StyledTextInput
                  type="text"
                  label="Display name"
                  placeholder="e.g. THE0BFH000140000"
                  register={register('name', { required: true })}
                  width="20rem"
                  isLabelBold
                />
              </FormSection>
              {option === 'edit' &&
                data?.fipDatCreated &&
                data?.fipDate &&
                data.fipUser &&
                data.fipUserCreated && (
                  <FormMetadataBox
                    fipDatCreated={data?.fipDatCreated}
                    fipDate={data?.fipDate}
                    fipUser={data.fipUser}
                    fipUserCreated={data.fipUserCreated}
                  />
                )}
            </FlexContainerWithSpaceBetween>
            <FormSection>
              <FormSectionTitle>Status</FormSectionTitle>
              <ControlledToggleInputWithLabel control={control} name="active" />
            </FormSection>

            <FormSection>
              <FormSectionTitle>More information</FormSectionTitle>
              <FieldContainerWithLabel>
                <p>Message definitions</p>
                <FlexContainer>
                  {messageDefinitions.length > 0 && (
                    <QuantityIndicator
                      quantity={messageDefinitions.length}
                      margin="margin-right: 0.5rem"
                    />
                  )}
                  <EditButton
                    onClick={() =>
                      dispatch(
                        openModal({
                          modalId: 'messageDefinitionModal',
                          props: { counterpartyName },
                        }),
                      )
                    }
                  >
                    {messageDefinitions.length === 0 ? <></> : 'View'}
                  </EditButton>
                </FlexContainer>
              </FieldContainerWithLabel>
            </FormSection>
            <FormSection>
              <FormSectionTitle>Valid</FormSectionTitle>
              <DatePickerContainer>
                <label>From:</label>
                <ControlledDatePicker name="validFrom" control={control} required />
              </DatePickerContainer>
              <DatePickerContainer>
                <label>To:</label>
                <ControlledDatePicker name="validTo" control={control} />
              </DatePickerContainer>
            </FormSection>
            <FormSection>
              <FormSectionTitle>Identifier</FormSectionTitle>
              <IdentifiersTable
                identifiers={localState.identifiers}
                addIdentifier={addNewIdentifier}
                updateIdentifiers={updateIdentifiers}
              />
            </FormSection>
            {errors && errors.errors.length > 0 && (
              <ErrorBox
                errors={errors.errors.map((error) => error.message)}
                header={errors.message}
              />
            )}
          </FormContainer>
        )}
        <ActionBar>
          <ClassicButton
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={() => history.push('/app/masterdata/')}
          >
            Cancel
          </ClassicButton>
          <ClassicButton
            width="10rem"
            variant={VARIANT.PURPLE}
            onClick={() => dispatch(openModal({ modalId: 'balancegroupConfirmationModal' }))}
            disabled={shouldDisableSubmit()}
          >
            {option === 'edit' ? 'Save' : 'Add'}
          </ClassicButton>
        </ActionBar>
      </ContentContainer>
      <MessageDefinitionModal
        currentMessageDefinitions={messageDefinitions}
        balanceGroupName={balanceGroupName}
      />
      <ConfirmationPopup
        confirmationPopupId="balancegroupConfirmationModal"
        confirmButtonAction={submitChanges}
        isLoading={submitLoading}
      >
        <ConfirmationPopupText>
          {option === 'create' ? (
            <>
              Shall we go ahead and save <span>{getValues().name}</span> as a new balance group?{' '}
            </>
          ) : (
            <>
              Shall we go ahead and update the Masterdata for Balance Group{' '}
              <span>{getValues().name}</span>{' '}
            </>
          )}
        </ConfirmationPopupText>
      </ConfirmationPopup>
    </MainContainer>
  );
};
