import { Checkbox } from 'components';
import { FC, useEffect, useState } from 'react';
import {
  FilterButton,
  ClearButton,
  FilterContainer,
  OptionsContainer,
  FilterOption,
  FilterCount,
  Icon,
  OptionLabel,
} from './styled';
import { OptionsSearch } from './OptionSearch';
import { useClickOutside } from 'utils/hooks';

interface MultiSelectInputProps {
  onOptionSelect: (options: string[]) => void;
  isFrameless?: boolean;
  filtersCleared?: boolean;
  options: string[];
  placeholder: string;
  setOptionsSearch?: (keyword: string) => void;
  width?: number;
}

export const MultiSelectInput: FC<MultiSelectInputProps> = ({
  onOptionSelect,
  isFrameless,
  filtersCleared,
  options,
  placeholder,
  setOptionsSearch,
  width,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const closeOptionsContainer = () => setIsExpanded(false);
  const ref = useClickOutside(closeOptionsContainer);

  const setOptions = (option: string) => {
    const selectedOption = selectedOptions.findIndex((opt) => opt === option);
    const optionsCopy = [...selectedOptions];
    if (selectedOption > -1) {
      optionsCopy.splice(selectedOption, 1);
    } else {
      optionsCopy.push(option);
    }
    setSelectedOptions(optionsCopy);
    onOptionSelect(optionsCopy);
  };

  const clearAll = () => {
    setSelectedOptions([]);
    onOptionSelect([]);
  };

  useEffect(() => {
    if (filtersCleared) {
      clearAll();
    }
  }, [filtersCleared]);

  const filtersActive = Object.entries(selectedOptions).filter((option) => option[1]).length;
  return (
    <FilterContainer ref={ref}>
      <FilterButton
        onClick={() => setIsExpanded(!isExpanded)}
        isFrameless={isFrameless}
        isExpanded={isExpanded}
        width={width ? `${width}px` : undefined}
      >
        {placeholder}
        {filtersActive > 0 && <FilterCount>{filtersActive}</FilterCount>}
        <Icon $isOpen={isExpanded} />
      </FilterButton>
      {isExpanded && (
        <OptionsContainer isFrameless={isFrameless} width={width}>
          {setOptionsSearch && <OptionsSearch setSearchKeyword={setOptionsSearch} />}
          <form>
            {options.map((option) => (
              <FilterOption key={option}>
                <Checkbox
                  checked={selectedOptions.indexOf(option) > -1}
                  onChange={() => setOptions(option)}
                />
                <OptionLabel>{option}</OptionLabel>
              </FilterOption>
            ))}
          </form>
          {filtersActive > 0 && <ClearButton onClick={clearAll}>Clear all filters</ClearButton>}
        </OptionsContainer>
      )}
    </FilterContainer>
  );
};
