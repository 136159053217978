import styled, { css } from 'styled-components';
import { Triangle } from 'assets/icons';
import { BaseButton } from 'components/Buttons/ClassicButton';

const FilterButton = styled(BaseButton)<{ isFrameless?: boolean; isExpanded: boolean }>`
  ${({ theme, isFrameless, isExpanded }) => css`
    ${!isFrameless && `border: 1px solid ${theme.lightGrey};`}
    ${!isFrameless && 'border-radius: 0.25rem;'}
    height: ${isFrameless ? '100%' : '2.5rem'};
    :hover {
      background-color: ${!isExpanded && theme.milkGrey};
    }
  `}
  display: flex;
  align-items: center;
  width: 17.125rem;
  padding: 0 1rem;
  margin-left: ${({ isFrameless }) => (isFrameless ? '0' : '0.5rem')};
  justify-content: space-between;
`;

const ClearButton = styled(BaseButton)`
  color: ${({ theme }) => theme.primary};
  text-decoration: underline;
  margin-top: 1.25rem;
  text-align: right;
  font-size: 0.875rem;
`;

const FilterContainer = styled.div`
  position: relative;
`;

const OptionsContainer = styled.div<{ isFrameless?: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 13.125rem;

  border-top: none;

  top: 2.3125rem;
  right: 0%;
  padding: 1.25rem;
  z-index: 10;
  ${({ isFrameless, theme }) => css`
    ${isFrameless &&
    css`
      left: -0.0625rem;
      width: 17.25rem;
    `}
    border: 1px solid ${theme.lightGrey};
    border-top: none;
    background: ${theme.mainBackgroundColor};
  `}
`;

const FilterOption = styled.label`
  display: flex;
  align-items: center;
  height: 2.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
`;

const OptionLabel = styled.span`
  display: flex;
  align-items: center;
  svg {
    flex-shrink: 0;
    margin-right: 0.3125rem;
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const FilterCount = styled.span`
  background-color: ${({ theme }) => theme.baseBlack};
  display: flex;
  width: 0.9375rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  align-items: center;
  justify-content: center;
  color: white;
`;

const Icon = styled(Triangle)<{ $isOpen: boolean }>`
  transform: rotate(${({ $isOpen }) => ($isOpen ? '90deg' : '-90deg')});
  width: 0.5625rem;
  path {
    fill: ${({ theme }) => theme.baseBlack};
  }
`;
export {
  FilterButton,
  ClearButton,
  FilterContainer,
  OptionsContainer,
  FilterOption,
  FilterCount,
  Icon,
  OptionLabel,
};
