import { useEffect, FC, createContext, useContext, useState } from 'react';
import { TopControlRow } from './components/TopControlRow';
import { useNominations } from './nominationsApi';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setInitialValues, clearChangedValues, clearFilters } from './nominationReduxSlice'; // This could me moved to api call
import { VersionModal } from './components/VersionModal';
import { ControlPanel } from './components/ControlPanel';
import { FileUploadModal } from './components/FileUploadModal';
import { MarketArea } from './components/MarketArea';
import { SubmitModal } from './components/SubmitModal';
import { SaveModal } from './components/SaveModal';
import { CopyFromModal } from './components/CopyFromModal';
import { NominationTimeLeadValidationSaveModal } from './components/NominationTimeLeadValidationSaveModal';
import { NominationTimeLeadValidationSubmitModal } from './components/NominationTimeLeadValidationSubmitModal';
import { NominationOptimisticLockingErrorModal } from './components/NominationOptimisticLockingErrorModal';
import { UploadWarningModal } from './components/UploadWarningModal';
import { CopyToModal } from './components/CopyToModal';
import { LoadingScreen } from 'components/LoadingScreen';
import { ThemeFunctionsContext } from 'App';
import { setDate } from 'store/globalSlice';
import { SubmitErrorModal } from './components/SubmitModalError';
import { UploadTimeValidationModal } from './components/UploadTimeValidationModal';
import { CopyToErrorModal } from './components/CopyToModal/CopyToModalError';
import { PageContainerScrollTrackingContext } from './components/PageContainerScrollTracking';
import { FloatingContainer, FloatingContainerReplacer } from './components/FloatingContainer';

// TODO request more colors for frames
const darkModeColors = [
  '#E1954F',
  '#F7C76F',
  '#678C88',
  '#8DC5A6',
  '#CACC52',
  '#87678C',
  '#AD6178',
  '#8D8FC5',
];
const lightModeColors = [
  '#F3E2B6',
  '#D0C9D9',
  '#CCE8D2',
  '#EDCCCC',
  '#CBE1EB',
  '#87678c',
  '#ad6178',
  '#8d8fc5',
];

export const MarketAreaThemeContext = createContext('');

export const Nominations: FC = () => {
  const { areas } = useAppSelector((state) => state.nominationReducer);
  const { selectedDate } = useAppSelector((state) => state.globalSlice);
  const currentDate = new Date(selectedDate);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const { data, isLoading } = useNominations(currentDate);
  const dispatch = useAppDispatch();
  const { themeName } = useContext(ThemeFunctionsContext);

  const { scrollValue, widthValue } = useContext(PageContainerScrollTrackingContext);
  const currentColorPalette = themeName === 'darkTheme' ? darkModeColors : lightModeColors;
  const setSelectedDate = (date: Date) => {
    dispatch(setDate({ date: date.toString() }));
  };

  useEffect(() => {
    if (data) {
      dispatch(
        setInitialValues({ areas: data.areas, gasDayHourDefinition: data.gasDayHourDefinition }),
      );
    }
  }, [data]);

  useEffect(() => {
    dispatch(clearChangedValues({}));
  }, [selectedDate]);

  useEffect(() => {
    return () => {
      dispatch(clearFilters());
    };
  }, []);

  const areasToDisplayCount = areas?.filter((area) => area.shouldBeDisplayed).length || 0;

  return (
    <>
      <FloatingContainer scrollValue={scrollValue} widthValue={widthValue}>
        <TopControlRow
          selectedDate={currentDate}
          setSelectedDate={setSelectedDate}
          setNumberOfDays={setNumberOfDays}
        />
        <ControlPanel />
      </FloatingContainer>
      <FloatingContainerReplacer scrollValue={scrollValue} widthValue={widthValue} />
      {isLoading ? (
        <LoadingScreen />
      ) : (
        areas?.map(
          (area, key) =>
            area.shouldBeDisplayed && (
              <MarketAreaThemeContext.Provider value={currentColorPalette[key % 8]} key={area.name}>
                <MarketArea
                  area={area}
                  frameColor={currentColorPalette[key % 8]}
                  selectedDate={currentDate}
                  numberOfDays={numberOfDays}
                />
              </MarketAreaThemeContext.Provider>
            ),
        )
      )}
      {areasToDisplayCount === 0 && !isLoading && <h1>No results</h1>}
      <VersionModal selectedDate={currentDate} />
      <FileUploadModal />
      <SubmitModal selectedDate={currentDate} numberOfDays={numberOfDays} />
      <SaveModal selectedDate={currentDate} numberOfDays={numberOfDays} />
      <CopyFromModal currentDate={currentDate} />
      <CopyToModal currentDate={currentDate} />
      <NominationTimeLeadValidationSaveModal
        selectedDate={currentDate}
        numberOfDays={numberOfDays}
      />
      <NominationTimeLeadValidationSubmitModal
        selectedDate={currentDate}
        numberOfDays={numberOfDays}
      />
      <NominationOptimisticLockingErrorModal
        selectedDate={currentDate}
        numberOfDays={numberOfDays}
      />
      <UploadTimeValidationModal />
      <UploadWarningModal />
      <SubmitErrorModal />
      <CopyToErrorModal />
    </>
  );
};
