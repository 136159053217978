import { ConfirmationPopupText, DeletionConfirmationPopup } from 'components';
import { LoadingScreen } from 'components/LoadingScreen';
import { useBalanceGroupRemoval, useCounterparties } from './masterdataApi';
import { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { SortDirection } from 'utils/types';
import { ControlRow } from './components/ControlRow';
import { MasterdataCounterparties } from './components/MasterdataCounterparties';
import { TopControlRow } from './components/TopControlRow';

// const fontSize = parseFloat(getComputedStyle(document.getElementsByName('html')[0]).fontSize);

const MasterdataPanelContainer = styled.div`
  padding: 1.25rem 2.5rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 10rem);
`;

const MasterdataPaginationContainer = styled.div`
  flex: 1;
`;

export const MasterdataPanel: FC = () => {
  const [searchKeywoard, setSearchKeyword] = useState('');
  const [activeSort, setActiveSort] = useState<{ name: string; direction: SortDirection }>({
    name: 'name',
    direction: 'asc',
  });
  const masterdataPaginationContainerRef = useRef<HTMLDivElement>(null);

  const updateSort = (name: string, direction: SortDirection) => {
    setActiveSort({ name, direction });
  };
  const { data, isLoading } = useCounterparties(searchKeywoard, activeSort);
  const setSearch = (keyword: string) => setSearchKeyword(keyword);
  return (
    <MasterdataPanelContainer>
      <TopControlRow />
      <MasterdataPaginationContainer ref={masterdataPaginationContainerRef}>
        <ControlRow setSearch={setSearch} />
        {data && !isLoading && data.content && (
          <MasterdataCounterparties
            counterparties={data.content}
            updateSort={updateSort}
            activeSort={activeSort}
          />
        )}
        {isLoading && <LoadingScreen />}
      </MasterdataPaginationContainer>
      <DeletionConfirmationPopup
        confirmationPopupId="balanceGroupRemovalConfirmationModal"
        deleteQuery={useBalanceGroupRemoval}
      >
        <ConfirmationPopupText>Are you sure you want to remove this entry?</ConfirmationPopupText>
      </DeletionConfirmationPopup>
    </MasterdataPanelContainer>
  );
};
