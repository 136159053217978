import { FC, useEffect, useRef, useState } from 'react';
import { SortDirection } from 'utils/types';
import {
  DivTableHeadingCell,
  DivTableHeadingCell as TableHeadingCell,
  LinkToMessage,
  MergedCell,
  NotificationTypeIndicator,
  TableHeadingWithSort,
} from 'components';
import { GasDayIconActive, MessageDayIconActive } from 'assets/icons';
import styled from 'styled-components';
import { CustomDateContainer, TableContainer, Row, WhiteTableCells, HeadingRow } from './styled';
import { useDashboardUpdates } from '../dashboardApi';
import { SortType } from 'utils/types/generalTypes';
import { formatFromUtc, standardDateFormat } from 'utils/dateFormating';
import { PaginationRow } from './PaginationRow';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setAmountOutItemsPerPage, setPage } from '../dashboardSlice';

const TableHeadingSortContainer = styled(DivTableHeadingCell)`
  padding: 0 0.625rem;
`;

const UpdatesContainer = styled.div`
  flex: 1;
`;

const NoUpdatesContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 2rem;
    font-weight: 600;
  }
`;

const PaginationContainerWithMargin = styled.div`
  margin: 1rem 0 0 0;
`;

interface LatestUpdatesTableProps {
  date: Date;
  searchKeyword: string;
}

export const LatestUpdatesTable: FC<LatestUpdatesTableProps> = ({ date, searchKeyword }) => {
  const [activeSort, setActiveSort] = useState<SortType>({
    name: 'lastChange',
    direction: 'asc',
  });
  const dispatch = useAppDispatch();
  const { selectedPage, amountOutItemsPerPage } = useAppSelector((state) => state.dashboardSlice);
  const { timezone } = useAppSelector((state) => state.globalSlice);

  const paginatedElementRef = useRef<HTMLDivElement>(null);

  const updateSort = (name: string, direction: SortDirection) => {
    setActiveSort({ name, direction });
  };
  const { name: sortName, direction: sortDirection } = activeSort;

  const { data } = useDashboardUpdates(
    date,
    activeSort,
    amountOutItemsPerPage,
    selectedPage,
    searchKeyword,
  );

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
    };
  }, []);

  return (
    <TableContainer>
      <HeadingRow>
        <TableHeadingCell width="3.5rem" align="center" padding="0">
          Status
        </TableHeadingCell>
        <TableHeadingSortContainer width="12.1875rem">
          <TableHeadingWithSort
            name="lastChange"
            direction={sortName === 'lastChange' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            <MessageDayIconActive />
            Last change
          </TableHeadingWithSort>
        </TableHeadingSortContainer>
        <TableHeadingSortContainer width="8.5rem">
          <TableHeadingWithSort
            name="gasDay"
            direction={sortName === 'gasDay' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            <GasDayIconActive />
            Gasday
          </TableHeadingWithSort>
        </TableHeadingSortContainer>
        <TableHeadingCell>Update</TableHeadingCell>
        <TableHeadingCell>Description</TableHeadingCell>
        <TableHeadingCell width="3.5rem" align="center">
          Action
        </TableHeadingCell>
      </HeadingRow>
      <UpdatesContainer ref={paginatedElementRef}>
        {data?.content.length ? (
          data?.content.map(
            ({ notificationType, update, description, creationDate, messageId, gasDay }, key) => {
              const [date, hour] = formatFromUtc(
                new Date(`${creationDate}Z`),
                timezone?.timeZone,
              ).split(' ');
              return (
                <Row isFailure={notificationType === 'ERROR'} key={`${messageId}_${key}`}>
                  <WhiteTableCells width="3.5rem" align="center" padding="0">
                    <NotificationTypeIndicator notificationType={notificationType} />
                  </WhiteTableCells>
                  <WhiteTableCells width="12.1875rem" padding="0">
                    <MergedCell>
                      <CustomDateContainer>{date}</CustomDateContainer>
                      <CustomDateContainer>{hour}</CustomDateContainer>
                    </MergedCell>
                  </WhiteTableCells>
                  <WhiteTableCells width="8.5rem">
                    {gasDay ? standardDateFormat(new Date(gasDay)) : ''}
                  </WhiteTableCells>
                  <WhiteTableCells>{update}</WhiteTableCells>
                  <WhiteTableCells>{description}</WhiteTableCells>
                  <WhiteTableCells width="3.5rem" align="center">
                    {messageId && <LinkToMessage link={`/app/history/message/${messageId}`} />}
                  </WhiteTableCells>
                </Row>
              );
            },
          )
        ) : (
          <NoUpdatesContainer>
            <h3>No updates</h3>
          </NoUpdatesContainer>
        )}
      </UpdatesContainer>
      {data?.content && data.content.length > 0 ? (
        <PaginationContainerWithMargin>
          <PaginationRow
            totalPages={data.totalPages}
            totalElements={data.totalElements}
            currentPage={selectedPage}
            setCurrentPage={(pageNumber: number) => dispatch(setPage(pageNumber))}
            amountOutItemsPerPage={amountOutItemsPerPage}
            setAmountOutItemsPerPage={(amount: number) =>
              dispatch(setAmountOutItemsPerPage(amount))
            }
          />
        </PaginationContainerWithMargin>
      ) : (
        <></>
      )}
    </TableContainer>
  );
};
