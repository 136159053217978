import { FC } from 'react';
import { PageContainer, SectionTitle } from 'components';
import { History } from 'features/Messages';

export const MessageHistory: FC = () => {
  return (
    <PageContainer>
      <SectionTitle section="messageHistory" />

      <History />
    </PageContainer>
  );
};
