import { Failure } from 'assets/icons';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;

  ${({ theme }) => css`
    background-color: ${theme.transparentRed};
    border: 1px solid ${theme.extraRed};
  `}
`;

const ErrorHeading = styled.h3`
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 1rem;
  svg {
    margin-right: 0.5rem;
  }
`;

const ErrorsList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: disc;

  li {
    margin: 0 2rem;
  }
`;

interface ErrorBoxProps {
  header?: string;
  errors: string[];
}

export const ErrorBox: FC<ErrorBoxProps> = ({ errors, header }) => (
  <ErrorContainer>
    <ErrorHeading>
      <Failure />
      Errors:
    </ErrorHeading>
    {header && <ErrorHeading>{header}</ErrorHeading>}
    <ErrorsList>
      {errors.map((error, key) => (
        <li key={`${error}_${key}`}>{error}</li>
      ))}
    </ErrorsList>
  </ErrorContainer>
);
