import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageFormContainer } from 'components';
import { MessageDefinitionForm } from 'features/MessageDefinitionForm';

interface ParamTypes {
  option: 'create' | 'edit';
  id?: string;
}

export const MessageDefinitionFormPage: FC = () => {
  const { option, id } = useParams<ParamTypes>();

  return (
    <PageFormContainer>
      <MessageDefinitionForm option={option} id={id} />
    </PageFormContainer>
  );
};
