import { FC } from 'react';
import styled from 'styled-components';
import { MessageDefinitionSlim } from 'utils/types';
import { PreviewBoxComponent } from 'components';

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0.625rem;
`;

const TableHeadingCell = styled.th`
  background-color: ${({ theme }) => theme.tableHeadingBg};
  height: 2.5rem;
  padding: 0 1rem;
`;

const TableCell = styled.td`
  height: 2.5rem;
  padding: 0 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
  color: ${({ theme }) => theme.darkGrey};
`;

interface MessageDefinitionPreviewBoxProps {
  messageDefinitions: Array<MessageDefinitionSlim>;
}

export const MessageDefinitionPreviewBox: FC<MessageDefinitionPreviewBoxProps> = ({
  messageDefinitions,
}) => {
  return (
    <PreviewBoxComponent amountOfElements={messageDefinitions.length} countFromOne>
      <StyledTable>
        <thead>
          <tr>
            <TableHeadingCell>Name</TableHeadingCell>
          </tr>
        </thead>
        <tbody>
          {messageDefinitions.map(({ description }) => (
            <tr key={description}>
              <TableCell>{description}</TableCell>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </PreviewBoxComponent>
  );
};
