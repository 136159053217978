import styled from 'styled-components';

import { WarningIcon } from 'assets/icons';

export const ConfirmationPopupContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  justify-content: space-between;
  height: calc(100% - 5rem);
`;

export const StyledHeading = styled.h2`
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 1.5rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const StyledWarningIcon = styled(WarningIcon)`
  width: 2rem;
  margin-top: 0.333rem;
`;
