import { FC } from 'react';
import styled from 'styled-components';
import { useNominationConfiguration } from './asTraficApi';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

const TraficLight = styled.div<{ enabled: boolean }>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-left: 0.5rem;
  background-color: ${({ enabled, theme }) => (enabled ? theme.extraGreen : theme.extraRed)};
`;

export const ASTraficLight: FC = () => {
  const { data } = useNominationConfiguration();

  return data?.value ? (
    <Container>
      <p>AS2/AS4: </p>
      <TraficLight enabled={data.value !== 'false'} />
    </Container>
  ) : (
    <></>
  );
};
