import { useQuery } from 'react-query';
import { useApiGet } from 'utils/hooks';
import { RoleType } from 'utils/types';

interface RoleTypes {
  content: Array<RoleType>;
}

export const useRoles = () => {
  const fetchRoles = useApiGet('/role?sort=role,asc');
  return useQuery<RoleTypes, Error>(['roles'], fetchRoles);
};
