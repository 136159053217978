import { FC } from 'react';
import { ClassicButton, Modal, VARIANT } from 'components';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ErrorIcon } from 'assets/icons';
import { closeModal } from 'store/modalSlice';

const ModalHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  svg {
    margin-left: 0.5rem;
  }
  margin-bottom: 1rem;
`;

const MainErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 3.125rem;
  overflow: hidden;
  height: calc(100% - 8rem);
`;

const ErrorsCoontainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-bottom: 1rem;
`;

const ModalParagraph = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
  white-space: pre-wrap;
`;

const externalContentStyles = {
  height: '40rem',
  width: '56.25rem',
  padding: '0',
  overflow: 'hidden',
};

export const GlobalErrorModal: FC = () => {
  const { props } = useAppSelector(({ modalReducer }) => modalReducer.globalErrorModal);
  const dispatch = useAppDispatch();

  return (
    <Modal
      modalId="globalErrorModal"
      externalContentStyles={externalContentStyles}
      headerType="error"
    >
      <MainErrorContainer>
        <ModalHeading>
          Error
          <ErrorIcon />
        </ModalHeading>
        {props?.message ? <ModalHeading>{props.message}</ModalHeading> : ''}
        <ErrorsCoontainer>
          {props?.errors ? (
            props.errors.map((error, index) => (
              <ModalParagraph key={`${error.code}_${index}`}>{error.message}</ModalParagraph>
            ))
          ) : (
            <ModalParagraph>Something went wrong. Please contact our support team.</ModalParagraph>
          )}
        </ErrorsCoontainer>
        <ClassicButton
          variant={VARIANT.BLACK}
          onClick={() => dispatch(closeModal({ modalId: 'globalErrorModal' }))}
          width="15rem"
        >
          Close
        </ClassicButton>
      </MainErrorContainer>
    </Modal>
  );
};
