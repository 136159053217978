import { FC } from 'react';
import { ButtonWithLoader, ErrorBox, Modal } from 'components';
import styled from 'styled-components';
import { ClassicButton, VARIANT } from 'components/Buttons/ClassicButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'store/modalSlice';
import { WarningIcon } from 'assets/icons';

const SubmitModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 3.125rem;
  height: calc(100% - 9rem);
`;
// TODO merge common components for nomination validation popups
const ModalHeading = styled.h2`
  display: flex;
  align-items: center;
  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 1rem;

  svg {
    margin-left: 0.5rem;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 21.875rem;
  display: flex;
  justify-content: space-between;
`;

const ErrorsContainer = styled.div`
  flex: 1;
  margin-bottom: 0.5rem;
  overflow-y: auto;
`;

const externalContentStyles = {
  height: '50%',
  width: '60%',
  padding: '0',
};

interface NominationTimeLeadValidationSubmitModalProps {
  isLoading: boolean;
  submitChanges: (arg0: boolean) => unknown;
}

export const ErrorConfirmationModal: FC<NominationTimeLeadValidationSubmitModalProps> = ({
  isLoading,
  submitChanges,
}) => {
  const dispatch = useAppDispatch();
  const { props } = useAppSelector(
    (state) => state.modalReducer.nominationTrackDeleteInvalidGasDayConfirmationModal,
  );

  return (
    <Modal
      modalId="nominationTrackDeleteInvalidGasDayConfirmationModal"
      externalContentStyles={externalContentStyles}
      headerType="warning"
    >
      <SubmitModalContainer>
        <ModalHeading>
          Confirmation <WarningIcon />
        </ModalHeading>
        {props?.message ? <ModalHeading>{props.message}</ModalHeading> : ''}

        {props?.errors && (
          <ErrorsContainer>
            <ErrorBox errors={props.errors.map((errorDetail) => errorDetail.message)} />
          </ErrorsContainer>
        )}

        <ButtonContainer>
          <ButtonWithLoader
            isLoading={isLoading}
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={() => submitChanges(true)}
          >
            Yes
          </ButtonWithLoader>
          <ClassicButton
            disabled={isLoading}
            onClick={() =>
              dispatch(
                closeModal({ modalId: 'nominationTrackDeleteInvalidGasDayConfirmationModal' }),
              )
            }
            width="10rem"
            variant={VARIANT.PRIMARY}
          >
            No
          </ClassicButton>
        </ButtonContainer>
      </SubmitModalContainer>
    </Modal>
  );
};
