import { FC } from 'react';
import { ButtonWithLoader, ErrorBox, Modal } from 'components';
import styled from 'styled-components';
import { ClassicButton, VARIANT } from 'components/Buttons/ClassicButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'store/modalSlice';
import { useSelectedNominationSubmit } from '../nominationsApi';
import { WarningIcon } from 'assets/icons';

const SubmitModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 3.125rem;
  height: calc(100% - 9rem);
`;
// TODO merge common components for nomination validation popups
const ModalHeading = styled.h2`
  display: flex;
  align-items: center;
  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 1rem;

  svg {
    margin-left: 0.5rem;
  }
`;

const ModalParagraph = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 21.875rem;
  display: flex;
  justify-content: space-between;
`;

const ErrorsContainer = styled.div`
  flex: 1;
  margin-bottom: 0.5rem;
  overflow-y: auto;
`;

const externalContentStyles = {
  height: '50%',
  width: '60%',
  padding: '0',
};

interface NominationTimeLeadValidationSubmitModalProps {
  selectedDate: Date;
  numberOfDays: number;
}

export const NominationTimeLeadValidationSubmitModal: FC<
  NominationTimeLeadValidationSubmitModalProps
> = ({ selectedDate, numberOfDays }) => {
  const dispatch = useAppDispatch();
  const { props } = useAppSelector(
    (state) => state.modalReducer.nominationTimeLeadValidationSubmitModal,
  );
  const { isLoading, mutate: selectedNominationSubmit } = useSelectedNominationSubmit();

  return (
    <Modal
      modalId="nominationTimeLeadValidationSubmitModal"
      externalContentStyles={externalContentStyles}
      headerType="warning"
    >
      <SubmitModalContainer>
        <ModalHeading>
          Confirmation <WarningIcon />
        </ModalHeading>
        <ModalParagraph>{props?.message}</ModalParagraph>
        {props?.error && (
          <ErrorsContainer>
            <ErrorBox errors={props.error.errors.map((errorDetail) => errorDetail.message)} />
          </ErrorsContainer>
        )}

        <ButtonContainer>
          <ButtonWithLoader
            isLoading={isLoading}
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={() =>
              selectedNominationSubmit({
                selectedDate,
                numberOfDays,
                ignoreNominationLeadTime: true,
                sendAll: props?.sendAll || false,
              })
            }
          >
            Yes
          </ButtonWithLoader>
          <ClassicButton
            disabled={isLoading}
            onClick={() =>
              dispatch(closeModal({ modalId: 'nominationTimeLeadValidationSubmitModal' }))
            }
            width="10rem"
            variant={VARIANT.PRIMARY}
          >
            No
          </ClassicButton>
        </ButtonContainer>
      </SubmitModalContainer>
    </Modal>
  );
};
