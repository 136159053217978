import { Triangle } from 'assets/icons';
import { BaseButton } from 'components/Buttons/ClassicButton';
import { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';

const PaginationLineContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 1.5rem;
`;

const PaginationComponentContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;

  li {
    list-style: none;
    margin-right: 0.5rem;
  }
`;

interface PaginationProps {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  numberOfPages: number;
}

const PaginationButtonPrev = styled(BaseButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  font-size: 1rem;

  svg {
    path {
      fill: ${({ theme }) => theme.primary};
    }
  }

  :hover {
    svg {
      path {
        fill: ${({ theme }) => theme.lightGrey};
      }
    }
  }
`;

const PaginationButtonNext = styled(PaginationButtonPrev)`
  transform: rotate(180deg);
`;

const Arrow = styled(Triangle)`
  width: 0.5625rem;
`;

const PaginationPageLink = styled(BaseButton)<{ isCurrent?: boolean }>`
  ${({ isCurrent, theme }) => css`
    ${isCurrent && 'text-decoration: underline;'}
    color: ${theme.primary};
  `}
`;

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const Pagination: FC<PaginationProps> = ({ currentPage, numberOfPages, setCurrentPage }) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = numberOfPages;
    const siblingCount = 2;

    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, null, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, null, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, null, ...middleRange, null, lastPageIndex];
    }
  }, [numberOfPages, currentPage]);

  return (
    <PaginationLineContainer>
      <PaginationComponentContainer>
        {currentPage !== 1 && (
          <PaginationButtonPrev onClick={() => setCurrentPage(currentPage - 1)}>
            <Arrow />
          </PaginationButtonPrev>
        )}
        {paginationRange?.map((page, key) =>
          page === null ? (
            <li key={key}>&#8230;</li>
          ) : (
            <li key={key}>
              <PaginationPageLink
                isCurrent={currentPage === page}
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </PaginationPageLink>
            </li>
          ),
        )}
        {currentPage !== numberOfPages && (
          <PaginationButtonNext onClick={() => setCurrentPage(currentPage + 1)}>
            <Arrow />
          </PaginationButtonNext>
        )}
      </PaginationComponentContainer>
    </PaginationLineContainer>
  );
};
