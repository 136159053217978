import styled, { css } from 'styled-components';

export const FloatingContainer = styled.div<{
  scrollValue: number;
  widthValue: number;
  jumpValue?: number;
}>`
  ${({ scrollValue, widthValue, jumpValue = 95 }) => {
    if (scrollValue && widthValue && jumpValue) {
      return (
        scrollValue > jumpValue &&
        css`
          position: fixed;
          width: ${widthValue - 10 + 'px'};
          top: 3.5rem;
          z-index: 10;
          background-color: ${({ theme }) => theme.white};
          padding: 0.833rem 2.833rem 0 1.333rem;
          margin-left: -1.313rem;
        `
      );
    }
  }}
`;

export const FloatingContainerReplacer = styled.div<{
  scrollValue: number;
  widthValue: number;
  jumpValue?: number;
}>`
  ${({ scrollValue, widthValue, jumpValue = 95 }) => {
    if (scrollValue && widthValue && jumpValue) {
      return (
        scrollValue > jumpValue &&
        css`
          height: 10rem;
        `
      );
    }
  }}
`;
