import { GasDayIconActive } from 'assets/icons';
import { FC } from 'react';
import styled from 'styled-components';
import { standardDateFormat } from 'utils/dateFormating';
import { MessageStatusTable } from './MessageStatusTable';

const MessageBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.milkGrey};
  padding: 1.5rem;
  height: 100%;
`;

const GasdayHeader = styled.div`
  display: flex;
  width: 100%;
  height: 2.5rem;
  background-color: ${({ theme }) => theme.milkGrey};
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  * {
    margin-right: 0.5rem;
  }
`;

interface MessageStatusBlock {
  date: Date;
}

export const MessageStatusBlock: FC<MessageStatusBlock> = ({ date }) => {
  return (
    <MessageBlockContainer>
      <GasdayHeader>
        <GasDayIconActive />
        <span>Gasday</span>
        <span>{standardDateFormat(date)}</span>
      </GasdayHeader>
      <MessageStatusTable date={date} />
    </MessageBlockContainer>
  );
};
