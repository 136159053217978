import { FC } from 'react';
import {
  Germany,
  France,
  Italy,
  Switzerland,
  Austria,
  Netherlands,
  Belgium,
  CompanyIcon,
  Denmark,
  GreatBritain,
  Bulgaria,
  Estonia,
  Finland,
  Hungary,
  Latvia,
  Lithuania,
  Norway,
  Poland,
  Portugal,
  Romania,
  Slovakia,
  Slovenia,
  Spain,
  CzechRepublic,
} from 'assets/icons/countryIcons';
import { CountryCode } from 'utils/types/generalTypes';

interface CountryFlagProps {
  countryCode: CountryCode;
}

export const CountryFlag: FC<CountryFlagProps> = ({ countryCode }) => {
  switch (countryCode) {
    case 'DE':
      return <Germany />;
    case 'FR':
      return <France />;
    case 'IT':
      return <Italy />;
    case 'CH':
      return <Switzerland />;
    case 'AT':
      return <Austria />;
    case 'NL':
      return <Netherlands />;
    case 'BE':
      return <Belgium />;
    case 'DK':
      return <Denmark />;
    case 'GB':
      return <GreatBritain />;
    case 'BG':
      return <Bulgaria />;
    case 'EE':
      return <Estonia />;
    case 'FI':
      return <Finland />;
    case 'HU':
      return <Hungary />;
    case 'LV':
      return <Latvia />;
    case 'LT':
      return <Lithuania />;
    case 'NO':
      return <Norway />;
    case 'PL':
      return <Poland />;
    case 'PT':
      return <Portugal />;
    case 'RO':
      return <Romania />;
    case 'SK':
      return <Slovakia />;
    case 'SI':
      return <Slovenia />;
    case 'ES':
      return <Spain />;
    case 'CZ':
      return <CzechRepublic />;
    default:
      return <CompanyIcon />;
  }
};
