import { FC } from 'react';
import styled from 'styled-components';
import { BaseButton } from 'components';
import { ExpandToggleButtonClosed, ExpandToggleButtonOpen } from 'assets/icons';

const StyledToggleButton = styled(BaseButton)<{ isExpanded: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 100%;
  background-color: ${({ isExpanded, theme }) => (isExpanded ? theme.lightGrey : theme.milkGrey)};

  :hover {
    background-color: ${({ theme }) => theme.lightGrey};
  }
`;
interface ToggleButtonProps {
  isExpanded: boolean;
  toggleFunction: () => void;
}

export const PlusMinusExpandToggleButton: FC<ToggleButtonProps> = ({
  isExpanded,
  toggleFunction,
}) => {
  return (
    <StyledToggleButton onClick={toggleFunction} isExpanded={isExpanded}>
      {!isExpanded ? <ExpandToggleButtonClosed /> : <ExpandToggleButtonOpen />}
    </StyledToggleButton>
  );
};
