import { InfoSquare } from 'assets/icons';
import { FC } from 'react';
import styled from 'styled-components';

const InfoSquareCssed = styled(InfoSquare)`
  background-color: #5796b9;
  color: white;
`;

export const NameShortener: FC<{ fipUser: string; maxCharacters: number }> = ({
  fipUser,
  maxCharacters,
}) => {
  const name =
    fipUser.length > maxCharacters ? fipUser.substring(0, maxCharacters) + '...' : fipUser;

  return (
    <>
      {name}
      {fipUser.length > maxCharacters && <InfoSquareCssed title={fipUser} />}
    </>
  );
};

export default NameShortener;
