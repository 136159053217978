import { MessageDetails } from '../types';
import { FC } from 'react';
import styled from 'styled-components';
import { BaseButton, StatusIndicator } from 'components';
import { GasIcon, MessageIcon, XmlOpenIcon } from 'assets/icons';
import { standardDateFormat } from 'utils/dateFormating';
import { useXmlDocument } from 'utils/hooks';
import { TitleLabel, TopInformationCell } from './StyledComponents';
import { formatFromUtc } from 'utils/dateFormating';
import { useAppSelector } from 'store/hooks';

const TopInformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnCell = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
`;

const ColumnCellWithMargin = styled(ColumnCell)`
  margin-top: 1rem;
`;

const ColumnHeader = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const SendTimeHourContainer = styled.span`
  display: inline-block;
  font-weight: 400;
  margin-left: 0.5rem;
  font-size: 0.875rem;
`;

const StatusText = styled.span`
  text-transform: capitalize;
  margin-left: 0.5rem;
`;

const XmlStyledIcon = styled(XmlOpenIcon)`
  margin-left: 0.3rem;
  path {
    fill: ${({ theme }) => theme.primary};
  }
`;

interface MessageDetailTableProps {
  data: MessageDetails;
}

export const MessageDetailTable: FC<MessageDetailTableProps> = ({ data }) => {
  const {
    documentIdentifier,
    documentType,
    documentVersion,
    sender,
    recipient,
    contractReference,
    messageStatus,
    messageDefinitionDescription,
    ediGasVersion,
    issuerMarketParticipant,
    recipientMarketParticipant,
    documentName,
    gasDate,
    id,
    direction,
    fipUserCreated,
    sendTime,
  } = data;

  const { timezone: userTimezone } = useAppSelector((store) => store.globalSlice);

  const [date, hour] = data?.creationTime
    ? formatFromUtc(new Date(`${data?.creationTime}Z`), userTimezone?.timeZone).split(' ')
    : '';

  const { mutate } = useXmlDocument();

  return (
    <>
      <TopInformationContainer>
        <InfoColumn>
          <ColumnHeader>Message Details</ColumnHeader>
          <ColumnCell>
            <TitleLabel>Document Identifier: </TitleLabel>
            {documentIdentifier}
          </ColumnCell>
          <ColumnCell>
            <TitleLabel>Document Type: </TitleLabel>
            {documentType}
          </ColumnCell>
          <ColumnCell>
            <TitleLabel>Version: </TitleLabel>
            {documentVersion}
          </ColumnCell>
          <ColumnCell>
            <TitleLabel>Issuer Code: </TitleLabel>
            {sender}
          </ColumnCell>
          <ColumnCell>
            <TitleLabel>Recipient Code: </TitleLabel>
            {recipient}
          </ColumnCell>
          <ColumnCell>
            <TitleLabel>Status: </TitleLabel>
            <StatusIndicator status={messageStatus} />
            <StatusText>{messageStatus.toLocaleLowerCase().replace('_', ' ')}</StatusText>
          </ColumnCell>
          <ColumnCell>
            <TitleLabel>Contract Reference: </TitleLabel>
            {contractReference}
          </ColumnCell>
        </InfoColumn>
        <InfoColumn>
          <ColumnHeader>dispoGas details </ColumnHeader>

          <ColumnCell>
            <TitleLabel>Message definition: </TitleLabel>
            {messageDefinitionDescription}
          </ColumnCell>
          <ColumnCell>
            <TitleLabel>Edi@Gas version: </TitleLabel>
            {ediGasVersion}
          </ColumnCell>
          <ColumnCell>
            <TitleLabel>Issuer Name: </TitleLabel>
            {issuerMarketParticipant}
          </ColumnCell>
          <ColumnCell>
            <TitleLabel>Recipient Name: </TitleLabel>
            {recipientMarketParticipant}
          </ColumnCell>
        </InfoColumn>
        <InfoColumn>
          <TopInformationCell flexProperty="0">
            <TitleLabel>Gasday: </TitleLabel>
            <GasIcon />
            {gasDate ? standardDateFormat(new Date(gasDate)) : ''}
          </TopInformationCell>
          <TopInformationCell>
            <TitleLabel>Created by: </TitleLabel>
            {fipUserCreated}
          </TopInformationCell>
        </InfoColumn>
        <InfoColumn>
          <TopInformationCell flexProperty="0">
            <TitleLabel>Creation date:</TitleLabel> <MessageIcon />
            {date}
            <SendTimeHourContainer>{hour}</SendTimeHourContainer>
          </TopInformationCell>
          {sendTime && (
            <TopInformationCell>
              <TitleLabel>Process date: </TitleLabel>
              {formatFromUtc(new Date(`${sendTime}Z`), userTimezone?.timeZone)}
            </TopInformationCell>
          )}
        </InfoColumn>
      </TopInformationContainer>

      <ColumnCellWithMargin>
        <TitleLabel>File name:</TitleLabel>
        {documentName == null ? '' : documentName}
        <BaseButton onClick={() => mutate({ id, direction, name: documentName })}>
          <XmlStyledIcon />
        </BaseButton>
      </ColumnCellWithMargin>
    </>
  );
};
