import { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { SearchInput } from 'components';
import styled from 'styled-components';

const SearchContainer = styled.div`
  max-width: 21.875rem;
`;

interface MessageDetailsSearchProps {
  searchKeyword: string;
  setSearchKeyword: (keyword: string) => void;
}

export const MessageDetailsSearch: FC<MessageDetailsSearchProps> = ({
  searchKeyword,
  setSearchKeyword,
}) => {
  const { register, getValues, reset } = useForm();

  const handleUpdate = useCallback(() => {
    const { messageDefinitionSearchKeyword } = getValues();
    setSearchKeyword(messageDefinitionSearchKeyword);
  }, [setSearchKeyword]);

  const resetSearch = () => {
    reset();
    setSearchKeyword('');
  };

  useEffect(() => {
    reset({
      messageDefinitionSearchKeyword: searchKeyword,
    });
  }, [searchKeyword]);

  return (
    <SearchContainer>
      <SearchInput
        register={register('messageDefinitionSearchKeyword')}
        placeholder="Search"
        handleUpdate={handleUpdate}
        value={searchKeyword}
        reset={resetSearch}
      />
    </SearchContainer>
  );
};
