/**
 * Function getInitialDate - generate initial date.
 *
 * @param firstDayOfnextMonth
 * @returns Date
 */
export const getInitialDate = (firstDayOfnextMonth = false) => {
  const newDate = new Date();

  if (true === firstDayOfnextMonth) {
    newDate.setMonth(new Date().getMonth() + 1);
    newDate.setDate(1);
  } else {
    newDate.setDate(newDate.getDate() + 2);
  }

  return newDate;
};
