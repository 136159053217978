import { FC } from 'react';
import styled from 'styled-components';
import { GridTableHeadingCell, GridMergedCell, TableHeadingWithSort } from 'components';
import { AreaRow } from './AreaRow';
import { Area, SortDirection } from 'utils/types';
import { SortType } from 'utils/types/generalTypes';

const AreasTableContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

// const Row = styled.div`
//   display: flex;
//   width: 100%;
// `;

const Row = styled.div`
  display: grid;
  grid-template-columns: 14rem 8rem minmax(14rem, 1fr) minmax(15rem, 1fr) minmax(11rem, 1fr) 5rem 4.5rem 4rem 2.5rem;
  grid-column-gap: 0.3125rem;
`;

const BlankCell = styled.div`
  width: 2.5rem;
  display: flex;
`;

const SortingContainer = styled(GridTableHeadingCell)`
  padding: 0 0.625rem;
`;

interface AreasMasterdataProps {
  areas: Array<Area>;
  updateSort: (name: string, direction: SortDirection) => void;
  activeSort: SortType;
}

export const AreasMasterdata: FC<AreasMasterdataProps> = ({ areas, updateSort, activeSort }) => {
  const { name: sortName, direction: sortDirection } = activeSort;
  return (
    <AreasTableContainer>
      <Row>
        <SortingContainer>
          <TableHeadingWithSort
            name="name"
            direction={sortName === 'name' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            Area
          </TableHeadingWithSort>
        </SortingContainer>
        <SortingContainer>
          <TableHeadingWithSort
            name="code"
            direction={sortName === 'code' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            Short Name
          </TableHeadingWithSort>
        </SortingContainer>
        <GridTableHeadingCell>Area Identifier</GridTableHeadingCell>

        <GridTableHeadingCell>Area Responsible Party</GridTableHeadingCell>
        <GridMergedCell>
          <SortingContainer>
            <TableHeadingWithSort
              name="validFrom"
              direction={sortName === 'validFrom' ? sortDirection : 'none'}
              setFilter={updateSort}
            >
              Valid from
            </TableHeadingWithSort>
          </SortingContainer>
          <SortingContainer>
            <TableHeadingWithSort
              name="validTo"
              direction={sortName === 'validTo' ? sortDirection : 'none'}
              setFilter={updateSort}
            >
              Valid to
            </TableHeadingWithSort>
          </SortingContainer>
        </GridMergedCell>

        <SortingContainer>
          {' '}
          <TableHeadingWithSort
            name="sortOrder"
            direction={sortName === 'sortOrder' ? sortDirection : 'none'}
            setFilter={updateSort}
          >
            Sort order
          </TableHeadingWithSort>
        </SortingContainer>
        <GridTableHeadingCell align="center">Active</GridTableHeadingCell>
        <GridTableHeadingCell>Action</GridTableHeadingCell>
        <BlankCell />
      </Row>
      {areas.map((area) => (
        <AreaRow key={area.id} area={area} />
      ))}
    </AreasTableContainer>
  );
};
