import { FC } from 'react';
import { Modal } from 'components';
import styled from 'styled-components';
import { ClassicButton, VARIANT } from 'components/Buttons/ClassicButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'store/modalSlice';

const SubmitModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 3.125rem;
  height: calc(100% - 10rem);
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 21.875rem;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const UploadErrorHeading = styled.h3`
  display: flex;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  align-items: center;
`;

const ModalParagraphs = styled.p`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  white-space: pre-wrap;

  strong {
    font-weight: 600;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const externalContentStyles = {
  height: '40%',
  width: '50rem',
  padding: '0',
};

export const ResponseModal: FC = () => {
  const dispatch = useAppDispatch();
  const { props } = useAppSelector((state) => state.modalReducer.connectionTestResponseModal);

  return (
    <Modal
      modalId="connectionTestResponseModal"
      externalContentStyles={externalContentStyles}
      // headerType={status}
    >
      <SubmitModalContainer>
        <ContentContainer>
          {props?.testUploadResponse?.remoteFile && (
            <>
              <ModalParagraphs>
                <strong>Upload ID:</strong> {props.testUploadResponse.id}
              </ModalParagraphs>
              <ModalParagraphs>
                <strong>Upload status:</strong>{' '}
                {props.testUploadResponse.success ? 'Success' : 'Failure'}
              </ModalParagraphs>
              <ModalParagraphs>
                <strong>Original file name:</strong>{' '}
                {props.testUploadResponse.remoteFile.originalFilename}
              </ModalParagraphs>
              <ModalParagraphs>
                <strong>Remote file name:</strong>{' '}
                {props.testUploadResponse.remoteFile.remoteFilename}
              </ModalParagraphs>
            </>
          )}
          {props?.testUploadResponse?.error && (
            <>
              <UploadErrorHeading>Error</UploadErrorHeading>
              <ModalParagraphs>{props.testUploadResponse.error}</ModalParagraphs>
            </>
          )}
        </ContentContainer>

        <ButtonContainer>
          <ClassicButton
            onClick={() => dispatch(closeModal({ modalId: 'connectionTestResponseModal' }))}
            width="10rem"
            variant={VARIANT.PRIMARY}
          >
            Ok
          </ClassicButton>
        </ButtonContainer>
      </SubmitModalContainer>
    </Modal>
  );
};
