import { FC, useEffect, useState } from 'react';
import { Modal, ClassicButton, MediumLoaderWithBlackBg, InputError } from 'components';
import { FieldValues, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { MasterDataFile } from '../types';
import { useFileUpload } from '../nominationsApi';
import { ErrorMessage } from '@hookform/error-message';
import { useAppSelector } from 'store/hooks';

const StyledFileInput = styled.input`
  min-width: 14rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
`;

const InputCover = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: 0.075rem solid #ddd;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.2rem 0.4rem rgb(0 0 0 / 5%);
  user-select: none;

  ::before {
    position: absolute;
    top: -0.075rem;
    right: -0.075rem;
    bottom: -0.075rem;
    z-index: 6;
    display: block;
    content: 'Browse';
    padding: 0.4375rem 1.5625rem;
    line-height: 1.5;
    color: #555;
    background-color: #eee;
    border: 0.075rem solid #ddd;
    border-radius: 0 0.25rem 0.25rem 0;
  }
`;

const StyledFileLabel = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
  margin-right: 1.25rem;
  flex: 1;
`;

const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 1.5625rem;
`;

const UploadForm = styled.form`
  display: flex;
`;

const ButtonLoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadFormContianer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
`;

const FileModalHeading = <StyledModalHeader>Upload Gasday data file</StyledModalHeader>;

const FileUpload: FC<UseFormReturn<FieldValues>> = ({
  register,
  handleSubmit,
  setError,
  reset,
  formState: { errors },
}) => {
  const [fileName, setFileName] = useState('Choose file...');
  const { mutate: fileUpload, isLoading } = useFileUpload(setError);
  const { selectedDate } = useAppSelector((state) => state.globalSlice);
  const currentDate = new Date(selectedDate);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    fileUpload({ file: data as MasterDataFile, currentDate: currentDate });
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget.files?.item(0) || { name: '' };
    if (name.length) {
      setFileName(name);
    }
  };

  useEffect(() => {
    reset();
  }, []);

  return (
    <UploadFormContianer>
      <UploadForm onSubmit={handleSubmit(onSubmit)}>
        <StyledFileLabel>
          <StyledFileInput
            {...register('masterData')}
            type="file"
            onChange={onChange}
            accept=".xlsx,.xls"
          />
          <InputCover>{fileName}</InputCover>
        </StyledFileLabel>
        <ClassicButton width="6.25rem" type="submit" disabled={isLoading}>
          {isLoading ? (
            <ButtonLoaderContainer>
              <MediumLoaderWithBlackBg />
            </ButtonLoaderContainer>
          ) : (
            'Import'
          )}
        </ClassicButton>
      </UploadForm>
      <ErrorMessage
        errors={errors}
        name="masterData"
        render={({ message }) => <InputError>{message}</InputError>}
      />
    </UploadFormContianer>
  );
};

export const FileUploadModal: FC = () => {
  const formProps = useForm();
  return (
    <Modal
      modalId="fileUploadModal"
      externalContentStyles={{ height: '14rem', width: '40rem' }}
      headerContentComponent={FileModalHeading}
    >
      <FileUpload {...formProps} />
    </Modal>
  );
};
