import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { ControlledSelectInput, ControlledDatePicker, ClassicButton, VARIANT } from 'components';
import { FieldValues, useForm } from 'react-hook-form';
import { Role, RoleType, SelectOptionType } from 'utils/types';
import { ContentCell, RoleTableBaseCell } from './styled';
import { getInitialDate } from 'utils/getInitialDate';
import { formatDateForInput } from 'utils/dateFormating';

const RoleFormContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1rem;
  background-color: ${({ theme }) => theme.comboFormBgColor};
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const RoleFormRow = styled.form`
  width: 100%;
  display: flex;
  margin-bottom: 1.5rem;

  *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

interface RoleFormProps {
  roleList: Array<RoleType>;
  onCancel: () => void;
  submitRole?: (role: Role) => void;
  role?: Role;
}

export const RoleForm: FC<RoleFormProps> = ({ roleList, onCancel, submitRole, role }) => {
  const { control, watch, getValues, reset } = useForm({
    defaultValues: {
      role: { id: 0, role: '' } as RoleType,
      validFrom: getInitialDate(),
      validTo: new Date('2099-09-30'),
    } as FieldValues,
  });

  useEffect(() => {
    if (role) {
      reset({
        role: role.role,
        validFrom: new Date(role.validFrom),
        validTo: new Date(role.validTo),
      });
    }
  }, [role]);

  const roleCode = watch('role')?.code || '';

  const onSubmit = () => {
    const { role, validFrom, validTo } = getValues();
    if (submitRole && role && validFrom && validTo) {
      submitRole({
        role,
        validFrom: formatDateForInput(validFrom),
        validTo: formatDateForInput(validTo),
      });
    }
  };

  return (
    <RoleFormContainer>
      <RoleFormRow>
        <ControlledSelectInput
          control={control}
          name="role"
          placeholder="Select role"
          options={roleList as unknown as Array<SelectOptionType>}
          width="100%"
        />
        <ContentCell>{roleCode}</ContentCell>
        <ControlledDatePicker control={control} name="validFrom" isSlim withFrame />
        <ControlledDatePicker control={control} name="validTo" isSlim withFrame />
        <RoleTableBaseCell width="2rem" />
      </RoleFormRow>
      <ButtonRow>
        <ClassicButton
          width="10rem"
          variant={VARIANT.SECONDARY}
          onClick={onCancel}
          margin="0 2rem 0 0"
        >
          Cancel
        </ClassicButton>
        <ClassicButton
          width="10rem"
          variant={VARIANT.PRIMARY}
          onClick={onSubmit}
          disabled={!roleCode.length}
        >
          Submit
        </ClassicButton>
        <RoleTableBaseCell width="2.5rem" />
      </ButtonRow>
    </RoleFormContainer>
  );
};
