import { InfoIcon } from 'assets/icons';
import { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';
import { ReplyMessageStatus } from 'utils/types/generalTypes';

const StyledTooltipTrigger = styled.a`
  /* width: 0.25rem; */
  background-color: 'rgba(163, 186, 198, 0.1)';
  position: absolute;
  top: 0;
  right: 0.125rem;
  svg {
    width: 1rem;
    fill: ${({ theme }) => theme.balanceGroupValueCellColor};
    fill-opacity: 1;

    path {
      fill: ${({ theme }) => theme.balanceGroupValueCellColor};
      fill-opacity: 1;
    }

    transform: rotate(180deg);
  }
`;

const StatusDescription = styled.li`
  display: flex;
  /* align-items: s; */
  list-style: none;
  margin-bottom: 0.25rem;
  b {
    font-weight: 600;
    margin: 0px 0.25rem;
  }
`;

const StyledReactTooltip = styled(ReactTooltip)`
  ${({ theme }) => css`
    background-color: ${theme.messageDetailsGroupHeading}!important;
    color: ${theme.defaultFontColor}!important;
    opacity: 1 !important;
    max-width: 40rem;

    &.place-top {
      &:after {
        border-top-color: ${theme.messageDetailsGroupHeading}!important;
        border-top-style: ${theme.messageDetailsGroupHeading}!important;
        border-top-width: ${theme.messageDetailsGroupHeading}!important;
      }
    }
  `}
`;

interface StatusTooltipProps {
  listOfStatuses: Array<ReplyMessageStatus>;
  tooltipId: string;
}

export const StatusTooltip: FC<StatusTooltipProps> = ({ listOfStatuses, tooltipId }) => {
  return (
    <>
      <StyledTooltipTrigger data-tip data-for={tooltipId}>
        <InfoIcon />
      </StyledTooltipTrigger>
      <StyledReactTooltip id={tooltipId} aria-haspopup="true" effect="solid">
        <ul>
          {listOfStatuses.map(({ code, title, definition }, index) => (
            <StatusDescription key={index}>
              {code}
              <b>{title}</b>

              {definition}
            </StatusDescription>
          ))}
        </ul>
      </StyledReactTooltip>
    </>
  );
};
