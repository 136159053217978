import { ConfigProperty, PartnershipsListOfValues, SlimCounterpartiesListOfValues } from './types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API, useApiGet } from 'utils/hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CustomErrorType, MainCustomErrorType } from 'utils/types/generalTypes';
import { closeModal, openModal } from 'store/modalSlice';
import { formatDateForInput } from 'utils/dateFormating';

export const useCounterpartiesLov = () => {
  const fetchCounterparties = useApiGet(`/lov/counterparty?size=0`);
  return useQuery<SlimCounterpartiesListOfValues, Error>(['counterpatiesLov'], fetchCounterparties);
};

export const usePartneships = (counterpartyId: number, connectionType: 'AS4' | 'AS2') => {
  const fetchPartnerships = useApiGet(
    `/partnership?size=0&filters=counterparty.id,${counterpartyId},type,${connectionType}`,
  );
  return useQuery<PartnershipsListOfValues, Error>(
    ['partneships', counterpartyId, connectionType],
    fetchPartnerships,
    {
      enabled: !!counterpartyId && !!connectionType,
    },
  );
};

export interface FileUploadResponseModel {
  sheetNames: string;
  workbookName: string;
  results: Array<FileUploadResults>;
}

interface FileUploadResults {
  successful: string[];
}

interface ConnectionTestFiled {
  file: FileList;
  partnershipId: number;
}

export const useFileUpload = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();

  return useMutation(
    (data: ConnectionTestFiled) => {
      const formData = new FormData();
      formData.append('file', data.file[0]);

      if (token.length) {
        return API.UPLOAD_FILE(`/upload/message?partnershipId=${data.partnershipId}`, {
          body: formData,
          headers: {
            Authorization: token,
          },
        });
      }
      return Promise.reject();
    },
    {
      onError: (errors: Array<CustomErrorType>) => {
        if (errors) {
          dispatch(
            openModal({
              modalId: 'connectionTestErrorModal',
            }),
          );
        }
      },
      onSuccess: async (data: Response) => {
        const parsedData = await data.json();
        dispatch(
          openModal({
            modalId: 'connectionTestResponseModal',
            props: { testUploadResponse: parsedData },
          }),
        );
      },
    },
  );
};

export const useMasterDataExport = () => {
  const { token } = useAppSelector((state) => state.authReducer);

  return useMutation(
    () => {
      if (token.length) {
        return API.GET('/masterdata/xlsx?asAttachment=true', {
          headers: {
            Authorization: token,
          },
        });
      }
      return Promise.reject();
    },
    {
      onError: (error: CustomErrorType) => {
        console.error(JSON.stringify(error));
      },
      onSuccess: async (data) => {
        const buffer = await data.arrayBuffer();
        const header = data.headers.get('Content-Disposition');
        const parts = header ? header.split(';') : [];
        const filename = parts[1].split('=')[1];
        const blob = new Blob([new Uint8Array(buffer)], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;`',
        });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.href = blobUrl;

        link.download = filename;
        // Append link to the body
        document.body.appendChild(link);

        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        );

        // Remove link from body
        document.body.removeChild(link);
      },
    },
  );
};

interface MasterDataImport {
  file: FileList;
  truncateTables: boolean;
  resetTenant: boolean;
  resetConfigProperties: boolean;
  resetHibernateSequence: boolean;
  resetAudit: boolean;
  secureString: string;
  ignoreMissingSheets: boolean;
  ignoreMissingEntities: boolean;
  ignoreMasterDataTables: boolean;
  importExpiredOrNotActive: boolean;
}
export const useMasterdataImport = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();

  return useMutation(
    ({
      file,
      truncateTables,
      resetTenant,
      resetConfigProperties,
      resetHibernateSequence,
      resetAudit,
      secureString,
      ignoreMissingSheets,
      ignoreMissingEntities,
      ignoreMasterDataTables,
      importExpiredOrNotActive,
    }: MasterDataImport) => {
      const formData = new FormData();
      formData.append('file', file[0]);

      const queryParams = new URLSearchParams({
        truncateTables: truncateTables.toString(),
        resetTenant: resetTenant.toString(),
        resetConfigProperties: resetConfigProperties.toString(),
        resetHibernateSequence: resetHibernateSequence.toString(),
        resetAudit: resetAudit.toString(),
        secureString,
        ignoreMissingSheets: ignoreMissingSheets.toString(),
        ignoreMissingEntities: ignoreMissingEntities.toString(),
        ignoreMasterDataTables: ignoreMasterDataTables.toString(),
        importExpiredOrNotActive: importExpiredOrNotActive.toString(),
      });

      if (token.length) {
        return API.UPLOAD_FILE(`/masterdata/xlsx?${queryParams}`, {
          body: formData,
          headers: {
            Authorization: token,
          },
        });
      }
      return Promise.reject();
    },
    {
      onError: (errors: MainCustomErrorType) => {
        if (errors) {
          dispatch(
            openModal({
              modalId: 'adminErrorModal',
              props: { errors: errors.errors, message: errors.message },
            }),
          );
        }
      },
      onSuccess: async (data: Response) => {
        const parsedData = await data.json();
        dispatch(
          openModal({
            modalId: 'connectionTestResponseModal',
            props: { testUploadResponse: parsedData },
          }),
        );
      },
    },
  );
};

export const useConfigProperties = () => {
  const fetchConfigProperties = useApiGet('/configproperty');
  return useQuery<Array<ConfigProperty>, Error>(['configProperties'], fetchConfigProperties);
};

interface NewPropertyValue {
  key: string;
  value: string;
}

export const useSaveConfigProperty = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (data: NewPropertyValue) => {
      if (token.length) {
        return API.PUT(`/configproperty/${data.key}`, {
          body: data.value,

          headers: {
            Authorization: token,
          },
        });
      }
      return Promise.reject();
    },
    {
      onError: (errors: Array<CustomErrorType>) => {
        if (errors) {
          dispatch(
            openModal({
              modalId: 'connectionTestErrorModal',
            }),
          );
        }
      },
      onSuccess: async () => {
        queryClient.invalidateQueries('configProperties');
      },
    },
  );
};

interface ResetParams {
  gasday: Date;
  forceReset: boolean;
  secureString: string;
}

export const useResetGasday = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();

  return useMutation(
    (data: ResetParams) => {
      if (token.length) {
        const formattedDate = formatDateForInput(data.gasday);
        return API.DELETE(
          `/day/${formattedDate}?secureString=${data.secureString}&force=${data.forceReset}`,
          {
            headers: {
              Authorization: token,
            },
          },
        );
      }
      return Promise.reject();
    },
    {
      onError: (error: CustomErrorType) => {
        dispatch(
          openModal({
            modalId: 'deletionErrorModal',
            props: { errors: error?.errors },
          }),
        );
      },
      onSuccess: async () => {
        dispatch(closeModal({ modalId: 'resetGasdayConfirmation' }));
      },
    },
  );
};
