import { FC } from 'react';
import { PageContainer } from 'components';
import { Dashboard } from 'features/Dashboard';

export const DashboardPage: FC = () => {
  return (
    <PageContainer>
      <Dashboard />
    </PageContainer>
  );
};
