import { FC } from 'react';
import { InfoIcon, WarningIcon, ErrorIcon, Success } from 'assets/icons';
import { NotificationType } from 'utils/types';

interface NotificationTypeIndicatorProps {
  notificationType: NotificationType;
}

export const NotificationTypeIndicator: FC<NotificationTypeIndicatorProps> = ({
  notificationType,
}) => {
  switch (notificationType) {
    case 'SUCCESS':
      return <Success title="Suceess" />;
    case 'ERROR':
      return <ErrorIcon title="Error" />;
    case 'INFO':
      return <InfoIcon title="Info" />;
    case 'WARNING':
      return <WarningIcon title="Warning" />;
    default:
      return <></>;
  }
};
